/**
 * Get merchant product list
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

const postMerchantProductMapping = async ({
  merchantID,
  values,
}: {
  merchantID: number;
  values: any;
}) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/merchants/${merchantID}/products/update_or_create`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(values),
        }
      );

      if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return { success: data.success };
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default postMerchantProductMapping;
