import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import LoginView from "./views/LoginView";
import SetNewPassView from "./views/SetNewPassView";
import PDFPreView from "./views/DashboardView/PDFView";
import Error404 from "./views/Error404";
import withRoot from "./withRoot";
import MainContainer from "./containers/MainContainer";
import registerFonts from "./views/DashboardView/PDFView/config/registerFonts";

const App: React.FC = () => {
  useEffect(() => {
    registerFonts();
  }, []);

  return (
    <Router>
      <Switch>
        <Route exact path="/login" component={LoginView} />
        <Route path="/set-new-password/:id" component={SetNewPassView} />
        <ProtectedRoute path="/pdf-preview" component={PDFPreView} />
        <ProtectedRoute path="/" component={MainContainer} />
        <Route component={Error404} />
      </Switch>
    </Router>
  );
};

export default withRoot(App);
