import { ErrorPayload } from "../../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchSoldVolumesAPI from "../../../../api/sustainability/soldVolumes/get";
import createSoldVolumesAPI from "../../../../api/sustainability/soldVolumes/create";
import editSoldVolumesAPI from "../../../../api/sustainability/soldVolumes/edit";
import deleteSoldVolumesAPI from "../../../../api/sustainability/soldVolumes/delete";

// State Interface
export interface SoldVolumesState {
  isFetching: boolean;
  data: any;
  limit: any;
  default: any;
  gasolineLimitReached: boolean;
  biodieselLimitReached: boolean;
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: {},
  limit: {},
  default: {},
  gasolineLimitReached: false,
  biodieselLimitReached: false,
  error: undefined,
} as SoldVolumesState;

// Actions
const SOLD_VOLUMES_REQUEST = "sustainability/soldVolumes/REQUEST";
const CREATE_SOLD_VOLUMES_REQUEST =
  "sustainability/soldVolumes/CREATE_SOLD_VOLUMES_REQUEST";
const UPDATE_SOLD_VOLUMES_REQUEST =
  "sustainability/soldVolumes/UPDATE_SOLD_VOLUMES_REQUEST";
const DELETE_SOLD_VOLUMES_REQUEST =
  "sustainability/soldVolumes/DELETE_SOLD_VOLUMES_REQUEST";
const SOLD_VOLUMES_ERROR = "sustainability/soldVolumes/ERROR";
const SOLD_VOLUMES_SUCCESS = "sustainability/soldVolumes/SUCCESS";
const CREATE_SOLD_VOLUMES_SUCCESS =
  "sustainability/soldVolumes/CREATE_SOLD_VOLUMES_SUCCESS";
const UPDATE_SOLD_VOLUMES_SUCCESS =
  "sustainability/soldVolumes/UPDATE_SOLD_VOLUMES_SUCCESS";
const DELETE_SOLD_VOLUMES_SUCCESS =
  "sustainability/soldVolumes/DELETE_SOLD_VOLUMES_SUCCESS";
const SET_STATUS_SOLD_VOLUMES =
  "sustainability/soldVolumes/SET_STATUS_SOLD_VOLUMES";

// Action creators
function requestSoldVolumes() {
  return {
    type: SOLD_VOLUMES_REQUEST,
  };
}

function requestCreateSoldVolumes() {
  return {
    type: CREATE_SOLD_VOLUMES_REQUEST,
  };
}

function requestUpdateSoldVolumes() {
  return {
    type: UPDATE_SOLD_VOLUMES_REQUEST,
  };
}

function requestDeleteSoldVolumes() {
  return {
    type: DELETE_SOLD_VOLUMES_REQUEST,
  };
}

function errorSoldVolumes(error: any) {
  return {
    type: SOLD_VOLUMES_ERROR,
    payload: { error },
  };
}

function receiveSoldVolumes(data: any, limit: any, default_value: any) {
  return {
    type: SOLD_VOLUMES_SUCCESS,
    payload: { ...data, limit: limit, default: default_value },
  };
}

function receiveCreateSoldVolumes(data: any) {
  return {
    type: CREATE_SOLD_VOLUMES_SUCCESS,
    payload: data,
  };
}

function receiveUpdateSoldVolumes(data: any) {
  return {
    type: UPDATE_SOLD_VOLUMES_SUCCESS,
    payload: data,
  };
}

function receiveDeleteSoldVolumes() {
  return {
    type: DELETE_SOLD_VOLUMES_SUCCESS,
  };
}

function setStatusSoldVolumes(
  gasolineLimitReached: any,
  biodieselLimitReached: any
) {
  return {
    type: SET_STATUS_SOLD_VOLUMES,
    payload: {
      gasolineLimitReached: gasolineLimitReached,
      biodieselLimitReached: biodieselLimitReached,
    },
  };
}

export function getSoldVolumes() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.sustainability.allocation.soldVolumes) {
      dispatch(requestSoldVolumes());
      try {
        const response = await fetchSoldVolumesAPI();
        dispatch(
          receiveSoldVolumes(response.data, response.limit, response.default)
        );
        dispatch(checkSoldVolumes());
      } catch (error) {
        dispatch(errorSoldVolumes(error));
      }
    }
    return Promise.resolve;
  };
}

export function createSoldVolumes() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.sustainability.allocation.soldVolumes) {
      dispatch(requestCreateSoldVolumes());
      try {
        const response = await createSoldVolumesAPI({
          etanol: 0,
          nafta: 0,
          fame: 0,
          hvo_hro: 0,
        });
        dispatch(receiveCreateSoldVolumes(response.data));
        dispatch(checkSoldVolumes());
      } catch (error) {
        dispatch(errorSoldVolumes(error));
      }
    }
    return Promise.resolve;
  };
}

export function updateSoldVolumes(value: number, type: string) {
  return async (dispatch: any, getState: any) => {
    const soldVolumes = getState().sustainability.allocation.soldVolumes;
    if (soldVolumes) {
      dispatch(requestUpdateSoldVolumes());
      let newSoldVolumes = { ...soldVolumes.data, [type]: value };
      try {
        const response = await editSoldVolumesAPI(
          newSoldVolumes,
          soldVolumes.data.id
        );
        dispatch(receiveUpdateSoldVolumes(response.data));
        dispatch(checkSoldVolumes());
      } catch (error) {
        dispatch(errorSoldVolumes(error));
      }
    }
    return Promise.resolve;
  };
}

export function deleteSoldVolumes() {
  return async (dispatch: any, getState: any) => {
    const soldVolumes = getState().sustainability.allocation.soldVolumes;
    if (soldVolumes) {
      dispatch(requestDeleteSoldVolumes());
      try {
        const response = await deleteSoldVolumesAPI(soldVolumes.data.id);
        if (response.success) dispatch(receiveDeleteSoldVolumes());
      } catch (error) {
        dispatch(errorSoldVolumes(error));
      }
    }
    return Promise.resolve;
  };
}

export function checkSoldVolumes(value?: number, type?: string) {
  return async (dispatch: any, getState: any) => {
    const soldVolumes = getState().sustainability.allocation.soldVolumes;
    if (soldVolumes && soldVolumes.limit) {
      let newSoldVolumes = soldVolumes.data;
      if (value && type) newSoldVolumes[type] = value;
      let gasolineLimitReached = false;
      let biodieselLimitReached = false;
      if (
        newSoldVolumes.etanol + newSoldVolumes.nafta >
        soldVolumes.limit.gasoline_limit
      )
        gasolineLimitReached = true;
      if (
        newSoldVolumes.fame + newSoldVolumes.hvo_hro >
        soldVolumes.limit.biodiesel_limit
      )
        biodieselLimitReached = true;
      dispatch(
        setStatusSoldVolumes(gasolineLimitReached, biodieselLimitReached)
      );
    }
    return Promise.resolve;
  };
}

// Reducer Definition
const SoldVolumesReducer = handleActions<SoldVolumesState>(
  {
    [SOLD_VOLUMES_REQUEST]: handleSoldVolumesRequest,
    [SOLD_VOLUMES_ERROR]: handleSoldVolumesError,
    [SOLD_VOLUMES_SUCCESS]: handleSoldVolumesSuccess,
    [UPDATE_SOLD_VOLUMES_SUCCESS]: handleSoldVolumesSuccess,
    [CREATE_SOLD_VOLUMES_SUCCESS]: handleSoldVolumesSuccess,
    [DELETE_SOLD_VOLUMES_SUCCESS]: handleDeleteSoldVolumesSuccess,
    [SET_STATUS_SOLD_VOLUMES]: handleSetStatusSoldVolumes,
  } as any,
  initialState
);

// Reducer Description
function handleSoldVolumesRequest(state: SoldVolumesState) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handleSoldVolumesError(
  state: SoldVolumesState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handleSoldVolumesSuccess(
  state: SoldVolumesState,
  action: Action<any>
) {
  let { limit: limit, default: default_value, ...data } = action.payload;
  return {
    ...state,
    isFetching: false,
    data: data,
    limit: limit ? limit : state.limit,
    default: default_value ? default_value : state.default,
    error: undefined as any,
  };
}

function handleDeleteSoldVolumesSuccess(
  state: SoldVolumesState,
  action: Action<any>
) {
  return {
    ...state,
    isFetching: false,
    data: {},
    error: undefined as any,
  };
}

function handleSetStatusSoldVolumes(
  state: SoldVolumesState,
  action: Action<any>
) {
  return {
    ...state,
    gasolineLimitReached: action.payload.gasolineLimitReached,
    biodieselLimitReached: action.payload.biodieselLimitReached,
  };
}

export default SoldVolumesReducer;
