import React from "react";
import { FormattedMessage } from "react-intl";

export default [
  {
    name: <FormattedMessage id="settingsView.index.energyContents.component" />,
    id: "component",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.energyContents.rate_kg" />,
    id: "rate_kg",
    type: "text",
    optionnal: "true",
    decimals_format: 2,
  },
  {
    name: (
      <FormattedMessage id="settingsView.index.energyContents.rate_liter" />
    ),
    id: "rate_liter",
    type: "text",
    optionnal: "true",
    decimals_format: 2,
  },
  {
    name: (
      <FormattedMessage id="settingsView.index.energyContents.fossil_rate" />
    ),
    id: "fossil_rate",
    type: "text",
    optionnal: "true",
    decimals_format: 2,
  },
  {
    name: (
        <FormattedMessage id="settingsView.index.energyContents.red_2_fossil_ref" />
    ),
    id: "red_2_fossil_ref",
    type: "text",
    optionnal: "true",
    decimals_format: 2,
  },
];
