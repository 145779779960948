/**
 *
 */
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      position: "relative",
      width: "25px",
      height: "15px",
      borderRadius: "2px",
      display: "block",
    },
    div: {
      display: "flex",
      alignItems: "centre",
    },
    title: {
      paddingLeft: "8px",
      paddingRight: "40px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "10px",
      },
    },
  })
);

interface Props {
  labelsList: any[];
}

const LegendLabels: React.FC<Props> = ({ labelsList }) => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
      {Array.isArray(labelsList) &&
        labelsList.map((item, idx) => (
          <Box
            display="flex"
            alignItems="center"
            key={"legendLabel" + item.name + idx}
          >
            <div
              className={classes.box}
              style={{
                background: item.background,
                border: item.borderColor
                  ? `2px solid ${item.borderColor}`
                  : "none",
              }}
            ></div>
            <Typography variant="body2" className={classes.title}>
              {item.text}
            </Typography>
          </Box>
        ))}
    </div>
  );
};

export default LegendLabels;
