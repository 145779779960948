import React, { useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CustomerReport from "../../../PDFView/CardCustomerReportV2";
import CustomerReportFull from "../../../PDFView/CardCustomerReportV2/CustomerReportV2T2";
import ButtonRounded from "../../../../../components/ButtonRounded";
import Box from "@material-ui/core/Box";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      minWidth: "170px",
      margin: theme.spacing(1),
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

interface ButtonDownloadPDFProps {
  language: "EN" | "NO";
  data: any;
  version: "Simple" | "Full";
}

const ButtonDownloadPDF: React.FC<ButtonDownloadPDFProps> = ({
  language,
  data,
  version,
}) => {
  const classes = useStyles();

  if (!data) return null;

  if (language === "EN" && data.position === "Daglig leder")
    data = { ...data, position: "CEO" };

  const Button = () => (
    <Box flex={1} display="flex" className={classes.margin}>
      <ButtonRounded
        className={classes.btn}
        variant="contained"
        color="primary"
      >
        {<FormattedMessage id="customerRequirement.downloadPdf" />}
      </ButtonRounded>
    </Box>
  );

  return (
    <PDFDownloadLink
      style={{ textDecoration: "none" }}
      document={
        version === "Full" ? (
          <CustomerReportFull language={language} data={data} />
        ) : (
          <CustomerReport language={language} data={data} />
        )
      }
      fileName={language === "NO" ? ("Bærekraftrapport-" + data.delivery_period + "-Preem-Norge-AS-til-" + data.customer):
        ("Sustainability-report(card)-" + data.delivery_period + "-Preem-Norge-AS-to-" + data.customer)
      }
    >
      {({ blob, url, loading, error }) => {
        if (!loading) return Button();
      }}
    </PDFDownloadLink>
  );
};

export default React.memo(ButtonDownloadPDF);
