import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const allocationRequirements = async (step_1: any) => {
  const token = getToken();
  let valid = false;

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/sustainability_reports/requirement_allocation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({ step_1: step_1 }),
        }
      );

      var result = await response.json();
      valid =
        result &&
        result.success &&
        result.delivered_fuels &&
        result.customer_requirements &&
        result.purchased_volumes &&
        result.gasoline_req &&
        result.adv_biofuels_req &&
        result.general_req &&
        result.above_req;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (result && result.errors)
      return {
        valid: false,
        message: result.errors,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    delivered_fuels: result.delivered_fuels,
    customer_requirements: result.customer_requirements,
    purchased_volumes: result.purchased_volumes,
    gasoline_req: result.gasoline_req,
    adv_biofuels_req: result.adv_biofuels_req,
    general_req: result.general_req,
    above_req: result.above_req,
  };
};

export default allocationRequirements;
