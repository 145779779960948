import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.error.main,
    },
  })
);

const RequiredAsterisk: React.FC = () => {
  const classes = useStyles();

  return <span className={classes.root}>*</span>;
};

export default RequiredAsterisk;
