import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchTaxPeriods from "../../../api/exciseduty/getTaxPeriods";

// State Interface
export interface TaxPeriodsState {
  isFetching: boolean;
  data: any[];
  selectedYear: number;
  selectedMonth: number;
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: [],
  selectedYear: 2020,
  selectedMonth: 0,
  error: undefined,
} as TaxPeriodsState;

// Actions
const TAX_PERIODS_REQUEST = "exciseDuty/taxPeriods/REQUEST";
const TAX_PERIODS_ERROR = "exciseDuty/taxPeriods/ERROR";
const TAX_PERIODS_SUCCESS = "exciseDuty/taxPeriods/SUCCESS";
const SET_SELECTED_YEAR = "exciseDuty/taxPeriods/SET_SELECTED_YEAR";
const SET_SELECTED_MONTH = "exciseDuty/taxPeriods/SET_SELECTED_MONTH";

// Action creators
function requestTaxPeriods() {
  return {
    type: TAX_PERIODS_REQUEST,
  };
}

function errorTaxPeriods(error: any) {
  return {
    type: TAX_PERIODS_ERROR,
    payload: { error },
  };
}

function receiveTaxPeriods(data: any) {
  return {
    type: TAX_PERIODS_SUCCESS,
    payload: data,
  };
}

export function setSelectedYear(year: number) {
  return {
    type: SET_SELECTED_YEAR,
    payload: year,
  };
}

export function setSelectedMonth(month: number) {
  return {
    type: SET_SELECTED_MONTH,
    payload: month,
  };
}

export function getTaxPeriods() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.exciseDuty.taxPeriods) {
      dispatch(requestTaxPeriods());
      try {
        const data = await fetchTaxPeriods();
        dispatch(receiveTaxPeriods(data));
      } catch (error) {
        dispatch(errorTaxPeriods(error));
      }
    }
    return Promise.resolve;
  };
}

// Reducer Definition
const TaxPeriodsReducer = handleActions<TaxPeriodsState>(
  {
    [TAX_PERIODS_REQUEST]: handleTaxPeriodsRequest,
    [TAX_PERIODS_ERROR]: handleTaxPeriodsError,
    [TAX_PERIODS_SUCCESS]: handleTaxPeriodsSuccess,
    [SET_SELECTED_YEAR]: handleSetSelectedYear,
    [SET_SELECTED_MONTH]: handleSetSelectedMonth,
  } as any,
  initialState
);

// Reducer Description
function handleTaxPeriodsRequest(state: TaxPeriodsState) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handleTaxPeriodsError(
  state: TaxPeriodsState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handleTaxPeriodsSuccess(state: TaxPeriodsState, action: Action<any>) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
    error: undefined as any,
  };
}

function handleSetSelectedYear(state: TaxPeriodsState, action: Action<any>) {
  return {
    ...state,
    selectedYear: parseInt(action.payload),
    error: undefined as any,
  };
}

function handleSetSelectedMonth(state: TaxPeriodsState, action: Action<any>) {
  return {
    ...state,
    selectedMonth: parseInt(action.payload),
    error: undefined as any,
  };
}

export default TaxPeriodsReducer;
