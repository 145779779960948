/**
 * Get users list
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../utils";
import { BASE_URL } from "../../endpoints";

export const BIOFUEL_TYPE = 0;
export const RAW_MATERIAL = 1;
export const RAW_MATERIAL_ORIGIN = 2;

const getBkvMappings = async () => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/bkv_mappings`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.bkv_mappings;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const getBkvMappingByType = async (type: number) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/bkv_mappings/by_type/${type}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default getBkvMappings;
