import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { colors, typography } from "../config/theme";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 3,
  },

  label: {
    ...typography.default,
    textAlign: "left",
    padding: 0.5,
    display: "flex",
    flexWrap: "nowrap",
    fontFamily: "PreemSans Bold",
    fontSize: 8,
  },
  input: {
    ...typography.default,
    backgroundColor: colors.white,
    marginRight: 10,
    textAlign: "left",
    padding: 2,
    fontSize: 8,
    flexWrap: "nowrap",
  },
});

interface Props {
  label: string;
  input: string;
  inputSize?: number;
  superscript?: string;
  labelstyle?: ReactPDF.Style | ReactPDF.Style[]; // | ReactPDF.Styles;
}

const InputfieldV2: React.FC<Props> = ({
  label,
  input,
  inputSize,
  superscript, //how to implement this?
  labelstyle = [],
}) => {
  const externalStyles = Array.isArray(labelstyle) ? labelstyle : [labelstyle];

  return (
    <View style={styles.container}>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          width: inputSize ? 73 - inputSize : 73,
        }}
      >
        <Text style={[styles.label, ...externalStyles]}>{label}</Text>
        <Text
          style={[
            styles.label,
            { fontSize: 6, marginTop: -1, fontFamily: "PreemSans" },
          ]}
        >
          {superscript}
        </Text>
      </View>
      <Text
        style={[
          styles.input,
          {
            width: inputSize ? 130 + inputSize : 130,
          },
        ]}
      >
        {input}
      </Text>
    </View>
  );
};

export default InputfieldV2;
