import { getToken } from "../utils";
import { BASE_URL } from "../endpoints";

export default (setting: string) => {
  return async (id: number) => {
    const token = getToken();
    if (token) {
      try {
        const response = await fetch(`${BASE_URL}/api/${setting}/${id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        });

        if (response.status === 200) {
          const data = await response.json();
          return { success: data.success };
        } else {
          return null;
        }
      } catch {
        return null; // network error
      }
    } else {
      return null; // No token
    }
  };
};
