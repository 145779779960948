import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import CompaniesView from "./CompaniesView";
import UsersView from "./UsersView";
import ModalAddEditCompany from "./ModalAddEditCompany";
import ModalDeleteCompany from "./ModalDeleteCompany";
import getMerchants from "../../../../api/settings/users/merchants/getMerchants";

const UsersMainView: React.FC = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentCompany, setCurrentCompany] = useState<any>(null);
  const [merchantsList, setMerchantsList] = useState<any>(null);

  const match = useRouteMatch();

  const openAddModal = () => setShowAddModal(true);
  const closeAddModal = () => {
    fetchMerchantList();
    setShowAddModal(false);
  };

  const openEditModal = (company: any) => {
    if (company) {
      setCurrentCompany(company);
    }
    setShowEditModal(true);
  };
  const closeEditModal = () => {
    fetchMerchantList();
    setShowEditModal(false);
  };

  const openDeleteModal = (company: any) => {
    if (company) {
      setCurrentCompany(company);
    }
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    fetchMerchantList();
    setShowDeleteModal(false);
    // setCurrentCompany(null);
  };

  const fetchMerchantList = async () => {
    const res = await getMerchants();
    if (res) {
      setMerchantsList(res);
    }
  };

  useEffect(() => {
    fetchMerchantList();
  }, []);

  if (match) {
    return (
      <>
        <Switch>
          <Route exact path={match.path}>
            <CompaniesView
              companyList={merchantsList}
              setCurrentCompany={setCurrentCompany}
              openAddModal={openAddModal}
              openEditModal={openEditModal}
              openDeleteModal={openDeleteModal}
            />
          </Route>
          {currentCompany && (
            <Route path={`${match.path}/:id`}>
              <UsersView
                currentCompany={{
                  id: currentCompany.id,
                  name: currentCompany.name,
                  org_number: currentCompany.org_number,
                  email: currentCompany.email,
                }}
                openModalEditCompany={() => openEditModal(currentCompany)}
                openModalDeleteCompany={() => openDeleteModal(currentCompany)}
              />
            </Route>
          )}
          <Redirect to={`${match.path}`} />
        </Switch>

        <ModalAddEditCompany
          open={showAddModal}
          onClose={closeAddModal}
          mode="add"
        />
        {currentCompany && (
          <>
            <ModalAddEditCompany
              open={showEditModal}
              onClose={closeEditModal}
              mode="edit"
              setCurrentCompany={setCurrentCompany}
              company={{
                id: currentCompany.id,
                name: currentCompany.name,
                org_number: currentCompany.org_number,
                email: currentCompany.email,
                customer_id: currentCompany.customer_id,
                logo_url: currentCompany.logo ? currentCompany.logo.url : "",
              }}
            />
            <ModalDeleteCompany
              open={showDeleteModal}
              onClose={closeDeleteModal}
              setCurrentCompany={setCurrentCompany}
              company={{ id: currentCompany.id, name: currentCompany.name }}
            />
          </>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default UsersMainView;
