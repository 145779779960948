import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { colors, typography } from "../config/theme";
import FooterInfoText from "./FooterInfoText";
import translation from "../CustomerReport/translation";

const styles = StyleSheet.create({
  root: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 30,
  },
  container: {
    flexDirection: "row",
    paddingTop: 5,
    paddingBottom: 8,
    borderTop: `1 solid ${colors.green}`,
  },
  sectionLeft: {
    marginRight: 60,
  },
  pageNumber: {
    ...typography.smallBold,
    color: colors.green,
  },
});

interface FooterProps {
  style?: ReactPDF.Style | ReactPDF.Styles;
  language: "EN" | "NO";
}

const Footer: React.FC<FooterProps> = ({ style = [], language }) => {
  const externalStyles = Array.isArray(style) ? style : [style];
  const t = translation[language];

  return (
    <View style={[styles.root, ...externalStyles]} fixed>
      <View style={styles.container}>
        <View style={styles.sectionLeft}>
          <FooterInfoText title="Preem Norge AS" value="(919 502 193)" />
          <FooterInfoText
            title={t.footer_address}
            value={
              t.footer_addressBox +
              " 458, 1327 Lysaker, " +
              t.footer_addressNorway
            }
          />
          <FooterInfoText
            title={t.footer_email}
            value="kundeservice@preem.no"
          />
          <FooterInfoText title={t.footer_web} value="www.preem.no" />
        </View>
        <View style={{ flex: 1 }}>
          <FooterInfoText title={t.footer_phone} value="+47 62 80 90 25" />
          <FooterInfoText title={t.footer_customerSupport} value="+47 04211" />
        </View>
        <View>
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${t.footer_side} ${pageNumber} ${t.footer_of} ${totalPages}`
            }
            fixed
          />
        </View>
      </View>
    </View>
  );
};

export default Footer;
