import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import TransactionsView from "../../views/DashboardView/ExciseDutyView/TransactionsView";
import { IAppVariables } from "../../types/ApiInterfaces";
import {
  setSelectedYear,
  setSelectedMonth,
} from "../../reducers/exciseDuty/taxPeriods";

interface Props {
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  selectedMonth: number;
  setSelectedMonth: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      taxPeriods: state.exciseDuty.taxPeriods.data,
      selectedYear: state.exciseDuty.taxPeriods.selectedYear,
      selectedMonth: state.exciseDuty.taxPeriods.selectedMonth,
    }),
    (dispatch: any) => ({
      dispatch,
      setSelectedYear: (year: number) => dispatch(setSelectedYear(year)),
      setSelectedMonth: (month: number) => dispatch(setSelectedMonth(month)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(TransactionsView);
