import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import DashboardView from "../views/DashboardView";
import { getAppVariables } from "../reducers/settings/app_variables";
import { getTaxPeriods } from "../reducers/exciseDuty/taxPeriods";
import { getTaxYears } from "../reducers/sustainability/main/index";
import { getTaxYearsCard } from "../reducers/card/main/index";
import { setShowModalDownloadPOS, setShowModalDownloadHBLPOS } from "../reducers/modals";

interface Props {
  isSimulation: boolean;
  isCardSimulation: boolean;
  getAppVariables: any;
  getTaxPeriods: any;
  getTaxYears: any;
  getTaxYearsCard: any;
  modal_show_download_pos: boolean;
  setShowModalDownloadPOS: any;
  showModalDownloadHBLPOS: boolean;
  setShowModalDownloadHBLPOS: any;
}

// @ts-ignore
export default compose<Props, {}>(
  connect(
    (state: State) => ({
      isSimulation: state.sustainability.main.isSimulation,
      isCardSimulation: state.card.main.isSimulation,
      modal_show_download_pos: state.modals.show_download_pos,
      showModalDownloadHBLPOS: state.modals.show_download_hbl_pos,
    }),
    (dispatch: any) => ({
      dispatch,
      getAppVariables: () => dispatch(getAppVariables()),
      getTaxPeriods: () => dispatch(getTaxPeriods()),
      getTaxYears: () => dispatch(getTaxYears()),
      getTaxYearsCard: () => dispatch(getTaxYearsCard()),
      setShowModalDownloadPOS: (show: boolean) =>
        dispatch(setShowModalDownloadPOS(show)),
      setShowModalDownloadHBLPOS: (show: boolean) =>
        dispatch(setShowModalDownloadHBLPOS(show)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {
      this.props.getAppVariables();
      this.props.getTaxPeriods();
      this.props.getTaxYears();
      this.props.getTaxYearsCard();
    },
  })
)(DashboardView);
