import { connect } from "react-redux";
import { State } from "./reducers/reducer";
import { IntlProvider } from "react-intl";
import messages_no from "./translations/no.json";
import messages_en from "./translations/en.json";

function getMessage(id: string): any {
  switch (id) {
    case "no":
      return messages_no;
    case "en":
      return messages_en;
  }
}

function mapStateToProps(state: State) {
  const language = state.profile.language;
  return { locale: "en", messages: getMessage(language) };
}

export default connect(mapStateToProps)(IntlProvider);
