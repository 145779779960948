import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import CustomersTable from "./CustomersTable";
import CustomerRequirementView from "../CustomersView/CustomerRequirement";
//import GenerateCustomerReportView from "./CustomerRequirement/GenerateCustomerReportView";
import ModalAddEditCustomer from "./ModalAddEditCustomer";
import ModalDeleteCustomer from "./ModalDeleteCustomer";
import getAllCustomers from "../../../../api/sustainability/customers/getAllCustomers";

import useModal from "../../../../components/modal/useModal";
import { FormattedMessage } from "react-intl";
import ModalDownloadReport from "./ModalDownloadReport";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(3),
    },
  })
);

interface Props {
  modal_show_download_report: boolean;
  setShowModalDownloadReport: (show: boolean) => void;
  selected_customer: any;
  setSelectedCustomer: (customer: any) => void;
  setSelectedYear: (year: any) => void;
  getCustomerPDFFiles: (id: number, data: any) => any;
  CustomerPDFdata: any;
  taxPeriods: any;
  selectedYear: any;
  clearErrorfromLastcall: any;
}

const CustomersView: React.FC<Props> = ({
  modal_show_download_report,
  setShowModalDownloadReport,
  selected_customer,
  setSelectedCustomer,
  setSelectedYear,
  getCustomerPDFFiles,
  CustomerPDFdata,
  taxPeriods,
  selectedYear,
  clearErrorfromLastcall,
}) => {
  const classes = useStyles();
  // const history = useHistory();
  const match = useRouteMatch();

  const [customerList, setCustomerList] = useState<any[]>([]);
  const { openModalId, toggle } = useModal();

  const fetchAllCustomers = async () => {
    const results = await getAllCustomers();
    if (Array.isArray(results)) {
      setCustomerList(results);
    }
  };

  useEffect(() => {
    fetchAllCustomers();
  }, []);

  const openModal = (id: string, selectedCustomer?: any) => {
    if (selectedCustomer) setSelectedCustomer(selectedCustomer);
    toggle(id);
  };

  const closeModal = () => {
    toggle();
  };

  if (match) {
    return (
      <div>
        <Switch>
          <Route exact path={`${match.path}`}>
            <div>
              <Typography variant="h2" className={classes.title}>
                <FormattedMessage id="customersView.index.heading" />
              </Typography>
              <CustomersTable
                customerList={customerList}
                setSelectedCustomer={setSelectedCustomer}
                addCustomer={() => openModal("addCustomer")}
                editCustomer={(customer: any) => {
                  openModal("editCustomer", customer);
                }}
                deleteCustomer={(customer: any) => {
                  openModal("deleteCustomer", customer);
                }}
              />
            </div>
          </Route>
          {selected_customer && (
            <>
              <Route exact path={`${match.path}/:id`}>
                <CustomerRequirementView
                  selected_customer={selected_customer}
                  editCustomer={() => {
                    openModal("editCustomer", selected_customer);
                  }}
                  deleteCustomer={() => {
                    openModal("deleteCustomer", selected_customer);
                  }}
                  modal_show_download_report={modal_show_download_report}
                  setShowModalDownloadReport={setShowModalDownloadReport}
                  setSelectedYear={setSelectedYear}
                  taxPeriods={taxPeriods}
                  selectedYear={selectedYear}
                />
              </Route>
              {/* this part is replaced by a popup selection window */}
              {/* <Route exact path={`${match.path}/:id/generate-report/:year`}>
                <GenerateCustomerReportView
                  customer={{
                    id: selected_customer.id,
                    name: selected_customer.name,
                  }}
                />
              </Route> */}
            </>
          )}
          <Redirect to={`${match.path}`} />
        </Switch>
        {selected_customer && (
          <>
            <ModalAddEditCustomer
              open={openModalId === "editCustomer"}
              onClose={closeModal}
              mode="edit"
              customer={{
                id: selected_customer.id,
                name: selected_customer.name,
                organizationNum: selected_customer.organization_number,
              }}
              onSucceed={(customer) => {
                setSelectedCustomer(customer);
                fetchAllCustomers();
              }}
            />
            <ModalDeleteCustomer
              open={openModalId === "deleteCustomer"}
              onClose={closeModal}
              customer={{
                id: selected_customer.id,
                name: selected_customer.name,
              }}
              onSucceed={() => {
                fetchAllCustomers();
              }}
            />
          </>
        )}
        <ModalAddEditCustomer
          open={openModalId === "addCustomer"}
          onClose={closeModal}
          mode="add"
          onSucceed={() => fetchAllCustomers()}
        />
        <ModalDownloadReport
          open={modal_show_download_report}
          onClose={() => setShowModalDownloadReport(false)}
          selected_customer={selected_customer}
          taxPeriods={taxPeriods}
          selectedYear={selectedYear}
          setSelectedYear={setSelectedYear}
          getCustomerPDFFiles={getCustomerPDFFiles}
          CustomerPDFdata={CustomerPDFdata}
          clearErrorfromLastcall={clearErrorfromLastcall}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default CustomersView;
