import React from "react";
import { Document, Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import { page, colors, typography } from "../config/theme";
import InfoBox from "../components/InfoBox";
import InfoText from "../components/InfoText";
import Table from "../components/Table";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Description from "./Description";
import { posTableData } from "../config/tableData";

const styles = StyleSheet.create({
  page: {
    paddingTop: 25,
    paddingHorizontal: 30,
    paddingBottom: 60,
  },
  root: {},
  midSection: {
    flexDirection: "row",
    marginBottom: 15,
  },
  infoContainer: {
    flex: 1,
    marginRight: 30,
  },
  paraContainer: {
    flex: 2,
    paddingRight: 20,
  },
  bottomSection: {
    flexDirection: "row",
  },
  tableContainer: {
    flex: 1,
  },
  tableBottom: {
    flexDirection: "row",
    backgroundColor: colors.lightGrey,
    paddingHorizontal: 10,
    paddingVertical: 5,
  },
  tableBottomSection: {
    marginLeft: 35,
    marginRight: 55,
  },
  total: { paddingRight: 10 },
});

const ProofOfSust: React.FC = () => {
  return (
    <Document>
      <Page size={page.size} orientation="landscape" style={styles.page}>
        <Header
          title="Biofuel sustainability documentation"
          subtitle="Issued by Preem Norge AS"
          logo="/assets/preem-logo.png"
        />
        <View style={styles.root}>
          <View style={styles.midSection}>
            <View style={styles.infoContainer}>
              <InfoBox title="General information">
                <InfoText
                  title="Customer"
                  value="Circle K Ireland Fuel Trading Limited"
                  colored
                  spaceBottom
                />
                <InfoText title="Product" value="HVO/HRO" colored />
                <InfoText title="Quantity" value="165 748 m3" colored />
                <InfoText
                  title="Delivery period"
                  value="Q3 2019"
                  colored
                  spaceBottom
                />
                <InfoText title="Issued" value="22 November 2019" colored />
                <InfoText
                  title="Note ref."
                  value="Preem-NO-221120191458"
                  colored
                />
              </InfoBox>
            </View>
            <View style={styles.paraContainer}>
              <Description />
            </View>
          </View>

          <View style={styles.bottomSection}>
            <View style={styles.tableContainer}>
              <Table
                smallTextSize
                columns={posTableData.columns}
                rows={posTableData.rows}
              />
              <View style={styles.tableBottom}>
                <View style={styles.tableBottomSection}>
                  <Text style={typography.defaultBold}>Total</Text>
                </View>
                <View>
                  <Text style={typography.defaultBold}>
                    151 639 liter of biofuel products
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <Footer language={"EN"} />
      </Page>
    </Document>
  );
};

export default ProofOfSust;
