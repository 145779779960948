import * as React from "react";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import theme from "./theme";
import ConnectedIntlProvider from "./ConnectedIntlProvider";
import error_messages from "./translations/error_messages";
import { setLocale } from "yup";

function getErrorMessages(id: string): any {
  switch (id) {
    case "no":
      return error_messages.no;
    case "en":
      return error_messages.en;
  }
}

let language = navigator.language.split(/[-_]/)[0]; // language without region code from the navigator
language = "en"; // forces language

function withRoot(Component: any) {
  function WithRoot(props: object) {
    // set up the right error_messages according to the selected language
    const messages = getErrorMessages(language);
    setLocale({
      mixed: {
        required: messages.required,
        oneOf: params => params.path && messages[params.path],
      },
      string: {
        min: params => messages.min(params.min),
        max: params => messages.max(params.max),
      },
    });

    return (
      <ThemeProvider theme={theme}>
        {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <ConnectedIntlProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Component {...props} />
          </MuiPickersUtilsProvider>
        </ConnectedIntlProvider>
      </ThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
