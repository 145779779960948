import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { typography, colors } from "../config/theme";
import translation from "./translation";
import moment from "moment";

const styles = StyleSheet.create({
  root: {},
  title: {
    ...typography.defaultBold,
    color: colors.green,
    marginTop: 4,
    marginBottom: 10,
  },
  para: {
    ...typography.default,
    lineHeight: 1.7,
    fontSize: 7.5,
    marginTop: -2,
    marginBottom: 15,
    textAlign: "left",
  },
  bullet: {
    ...typography.default,
    lineHeight: 1.7,
    fontSize: 7.5,
    marginTop: -2,
    textAlign: "left",
  },
});

interface DescriptionProps {
  description: any;
  language: "EN" | "NO";
  style?: ReactPDF.Style | ReactPDF.Styles;
}

const Description: React.FC<DescriptionProps> = ({
  language,
  style = [],
  description,
}) => {
  const externalStyles = Array.isArray(style) ? style : [style];
  const t = translation[language];

  return (
    <View style={[styles.root, ...externalStyles]}>
      <Text style={styles.para}>{t.description_paragraph1}</Text>
      <Text style={styles.para}>{t.description_paragraph2}</Text>
      <Text style={styles.para}>{t.description_paragraph3}</Text>
      <Text style={styles.bullet}>{t.description_bullet1}</Text>
      <Text style={styles.bullet}>{t.description_bullet2}</Text>
      <Text style={styles.bullet}>{t.description_bullet3}</Text>
      <Text style={styles.bullet}>{t.description_bullet4}</Text>
      <Text style={styles.bullet}>{t.description_bullet5}</Text>
      <Text style={styles.bullet}>{t.description_bullet6}</Text>
      <Text style={[styles.bullet, { marginBottom: 15 }]}>
        {t.description_bullet7}
      </Text>
      <Text style={styles.para}>{t.description_paragraph4}</Text>
      <Text style={styles.para}>{t.description_paragraph5}</Text>
    </View>
  );
};

export default Description;
