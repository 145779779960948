import { BASE_URL } from "./endpoints";

const authResetUserPassword = async (token: string, password: string) => {
  const data = {
    reset_password_token: token,
    password,
  };

  let valid = false;
  let loginResult;

  try {
    const response = await fetch(`${BASE_URL}/api/auth/reset_user_password`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    loginResult = await response.json();
    valid = loginResult.success;
  } catch (except) {
    valid = false;
  }

  if (!valid) {
    if (
      loginResult &&
      loginResult.error &&
      loginResult.error.user_authentication
    ) {
      return {
        valid: false,
        message: loginResult.error.user_authentication,
      };
    }

    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    auth_token: loginResult.auth_token,
  };
};

export default authResetUserPassword;
