import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../../reducers/reducer";
import OverviewTableConstructionView from "../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/OverviewTableConstruction";

interface Props {
  construction_quantities_hvo: any;
  construction_quantities_fame: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      construction_quantities_hvo:
        state.sustainability.allocation.multiStep.construction_quantities_hvo,
      construction_quantities_fame:
        state.sustainability.allocation.multiStep.construction_quantities_fame,
    }),
    (dispatch: any) => ({
      dispatch,
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(OverviewTableConstructionView);
