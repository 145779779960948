import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ButtonRounded from "../../../../components/ButtonRounded";
import { getAppVariables } from "../../../../reducers/settings/app_variables";
import { IAppVariables } from "../../../../types/ApiInterfaces";
import getBiofuelList from "../../../../api/sustainability/storage/reconciliation/getBiofuelList";
import {IFilterParameters} from "../../../../components/FilterPanel/Filters";
import ReconciliationList from "./ReconciliationList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    subtitle: {
      margin: "7px 0"
    },
    btn: {
      width: "170px",
      padding: "13px 0",
      lineHeight: "14px",
      "&:not(:first-child)": {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("md")]: {
        width: "130px",
        padding: "12px 0 9px 0",
        "&:not(:first-child)": {
          marginLeft: "10px",
        },
      },
    },
    toggleContainer: {
      marginTop: theme.spacing(3),
    },
    topCard: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      boxShadow: "-10px 10px 20px 0 rgba(30, 30, 30, 0.05)",
      padding: theme.spacing(2),
    },
    cardContent: {
      "&.MuiCardContent-root:last-child": {
        paddingBottom: theme.spacing(2),
      },
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1, 1.5),
      },
    },
    cardTable: {
      padding: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    fields: {
      position: "relative",
      "& .MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        bottom: theme.spacing(-1.6),
        whiteSpace: "nowrap",
      },
      "& .MuiFormHelperText-contained": {
        margin: 0,
      },
    },
    creatableSelect: {
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: 0,
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        minHeight: "35px",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__value-container": {
        padding: "0px 8px",
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
  })
);

interface Props {
  app_variables: IAppVariables;
}

const BiofuelReconciliationView: React.FC<Props> = ({ app_variables }) => {
  const [lists, setLists] = useState<{}>({});
  const classes = useStyles();
  const dispatch = useDispatch();

  const getReconciliationList = async () => {
    const res= await getBiofuelList();

    if (res && res.valid) {
        const sortedByTerminals = Object.keys(res.data).sort()
        const sortedTerminalsObject = {}
        sortedByTerminals.forEach((terminal)=>{
            const sortedByBiofuel = Object.keys(res.data[terminal]).sort();
            const sortedBiofuelObject = {};
            sortedByBiofuel.forEach((biofuel) => {
                sortedBiofuelObject[biofuel] = res.data[terminal][biofuel];
            });
            sortedTerminalsObject[terminal] = sortedBiofuelObject
        })
        setLists(sortedTerminalsObject);
    }
  };

  useEffect(() => {
    let isMounted = true;
    getReconciliationList();
    if (isMounted) { // Only update the state if the component is still mounted
      dispatch(getAppVariables());
    }
    return () => { // Add a cleanup function to cancel any ongoing tasks
      isMounted = false;
    };
  }, []);
  return (
    <div className={classes.root}>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h2" className={classes.h2}>
            Stock Reconciliation
          </Typography>
        </Grid>
      </Grid>
      {Object.keys(lists).map((terminal, index) => (
      <Card key={index}> {/* Make sure to provide a unique key for each Card */}
        <CardContent className={classes.cardTable}>
          <Grid
              container
              spacing={2}
              alignItems="center"
              className={classes.cardContent}
          >
            <Grid item xs>
              <Typography variant="subtitle1">
                {terminal}
              </Typography>
            </Grid>
          </Grid>
          <ReconciliationList data={lists[terminal]} terminal={terminal} />
        </CardContent>
      </Card>
      ))}
    </div>
  );
};

export default BiofuelReconciliationView;

