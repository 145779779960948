import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2),
      position: "relative",
      boxShadow: "0px -8px 16px 4px rgba(30,30,30,0.05)", // "0px 0px 20px 5px rgba(30, 30, 30, 0.1)",
      cursor: "pointer",
      userSelect: "none",
    },
    icon: {
      position: "absolute",
      top: theme.spacing(-1.5),
      marginBottom: theme.spacing(1),
      borderRadius: "100%",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
  })
);

interface OverviewPanelProps {
  className?: string;
  children: React.ReactNode;
}

const OverviewPanel: React.FC<OverviewPanelProps> = ({
  className = "",
  children,
}) => {
  const [showContent, setShowContent] = useState<boolean>(false);

  const toggleShowContent = () => setShowContent(prevValue => !prevValue);

  const classes = useStyles();

  return (
    <div className={className}>
      <div className={classes.root} onClick={toggleShowContent}>
        {showContent ? (
          <ExpandMoreIcon className={classes.icon} />
        ) : (
          <ExpandLessIcon className={classes.icon} />
        )}
        <div>
          <Typography variant="body1" color="primary">
            {showContent ? "Hide" : "Show"} Overview
          </Typography>
        </div>
      </div>
      <Collapse in={showContent}>
        <div>{children}</div>
      </Collapse>
    </div>
  );
};

export default OverviewPanel;
