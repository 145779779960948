/**
 *  Side navigation panel
 */

import React, { useState } from "react";
import {
  NavLink as NLink,
  NavLinkProps,
  useHistory,
  useLocation,
} from "react-router-dom";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import DashboardIcon from "@material-ui/icons/Dashboard";
import FolderOutlinedIcon from "@material-ui/icons/FolderOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import EcoOutlinedIcon from "@material-ui/icons/EcoOutlined";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import CreditCardOutlinedIcon from "@material-ui/icons/CreditCardOutlined";
import { clearToken } from "../../api/utils";
import { FormattedMessage } from "react-intl";
import { isAuditor } from "../../api/utils";
import getDownloadReport from "../../api/sustainability/reports/getDownloadReport";
import ModalInfo from "../../components/modal/ModalInfo";
import moment from "moment";

// TS & MUI fix for react-router NavLink component to be used as ListItem component
const NavLink = React.forwardRef((props: NavLinkProps, ref: any) => (
  <NLink {...props} innerRef={ref} />
));

const drawerWidth = 250;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      "& .MuiDrawer-paperAnchorDockedLeft": {
        border: "none",
        boxShadow: "1px 0 15px 0 rgba(66, 75, 90, 0.1)",
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.grey[600],
        "& .MuiSvgIcon-root": {
          fontSize: "1.25rem",
        },
      },
      "& .MuiIconButton-root": {
        color: theme.palette.grey[600],
      },
      "& .MuiDivider-root": {
        backgroundColor: "inherit",
        boxShadow: "0 2px 0 0 rgba(0, 0, 0, 0.03)",
      },
      "& .MuiDivider-middle": {
        height: "1px",
        backgroundColor: "#dbdfe9",
        boxShadow: "none",
      },
      "& .active": {
        // For nested sub menu items
        color: theme.palette.primary.main,
        "& .MuiListItemText-primary": {
          fontWeight: "500",
        },
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      "& .MuiListItemIcon-root": {
        minWidth: theme.spacing(5),
      },
      "& .MuiListItem-gutters": {
        paddingLeft: theme.spacing(3),
      },
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
      "& .MuiListItemIcon-root": {
        paddingLeft: theme.spacing(1),
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0, 2),
      paddingLeft: theme.spacing(3),
      ...theme.mixins.toolbar,
    },
    toolbarClosed: {
      padding: theme.spacing(0, 1),
      justifyContent: "center",
    },
    menuContainer: {
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      color: "#94979b",
      "& .MuiListItemText-primary": {
        fontSize: "0.85rem",
      },
    },
    menuSelected: {
      "& .MuiListItem-root": {
        "&.Mui-selected": {
          color: theme.palette.primary.main,
          backgroundColor: "rgba(255, 172, 18, 0.2)",
          "&::before": {
            content: "''",
            borderLeft: `${theme.spacing(0.5)}px solid ${
              theme.palette.primary.main
            }`,
            position: "absolute",
            left: 0,
            height: "100%",
          },
          "& .MuiListItemIcon-root": {
            color: theme.palette.primary.main,
          },
          "& .MuiListItemText-primary": {
            fontWeight: "500",
          },
        },
      },
    },
    nestedSubMenuItemText: {
      marginLeft: theme.spacing(3),
    },
    dividerBottom: {
      marginBottom: theme.spacing(2),
    },
    auditButton: {
      color: theme.palette.primary.main,
      fontSize: "0.5rem",
      fontWeight: 500,
      padding: theme.spacing(0.5),
      margin: theme.spacing(3),
    },
  })
);

interface SidePanelProps {
  modal_show_download_pos: boolean;
  setShowModalDownloadPOS: (show: boolean) => void;
  showModalDownloadHBLPOS: boolean;
  setShowModalDownloadHBLPOS: (show: boolean) => void;
}

const SidePanel: React.FC<SidePanelProps> = ({
  modal_show_download_pos,
  setShowModalDownloadPOS,
  showModalDownloadHBLPOS,
  setShowModalDownloadHBLPOS,
}) => {
  const [open, setOpen] = React.useState<boolean>(true); // sidepanel
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const isExciseDutyPage = location.pathname.indexOf("excise-duty") > 0;
  const isSustainabilityPage = location.pathname.indexOf("sustainability") > 0;
  const isMDIRreportPage =
    location.pathname.indexOf("sustainability/allocation") > 0 ||
    location.pathname.indexOf("sustainability/reports") > 0;
//neeed to change here
  const isCardPage = location.pathname.indexOf("card") > 0;

  const isCardRreportPage =
  location.pathname.indexOf("card/allocation") > 0 ||
  location.pathname.indexOf("card/reports") > 0;

  const openErrorModal = (msg: string) => {
    setErrorMessage(msg);
    setShowErrorModal(true);
  };
  const closeErrorModal = () => {
    setErrorMessage("");
    setShowErrorModal(false);
  };

  const toggleSidePanel = () => {
    setOpen(!open);
  };

  const handleSignOut = () => {
    // clear the token & redirect to the login page
    clearToken();
    history.push("/");
  };

  const handleDownloadAudit = async () => {
    const response = await getDownloadReport({
      type: "audit_excel",
      taxYear: moment().year() - 1,
    });

    if (response === null) {
      openErrorModal("Failed to download the file");
    }

    if (response !== null) {
      // emulate file download by clicking on an <a /> link
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `audit-report.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  return (
    <div>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        {/* Sidepanel: Top most section: Logo & toggle icon */}
        <div
          className={clsx(classes.toolbar, {
            [classes.toolbarClosed]: !open,
          })}
        >
          <Typography variant="h5" className={clsx({ [classes.hide]: !open })}>
            <FormattedMessage id="sidePanel.bkv" />
          </Typography>
          <IconButton onClick={toggleSidePanel}>
            <MenuIcon />
          </IconButton>
        </div>
        <Divider />

        {/* Sidepanel: menu container */}
        <div className={clsx(classes.menuContainer, classes.menuSelected)}>
          <div>
            {/* Sidepanel: top menu container */}
            <List>
              <ListItem
                button
                component={NavLink}
                activeClassName="Mui-selected"
                exact
                to="/"
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="sidePanel.dashboard" />}
                />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/document-database"
                activeClassName="Mui-selected"
              >
                <ListItemIcon>
                  <FolderOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="sidePanel.documentDatabase" />}
                />
              </ListItem>
              <ListItem
                button
                component={NavLink}
                to="/excise-duty/files"
                className={isExciseDutyPage ? "Mui-selected" : ""}
                activeClassName=""
              >
                <ListItemIcon>
                  <DescriptionOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="sidePanel.exciseDuty" />}
                />
              </ListItem>

              {/* Excise duty reporting sub menu items */}
              <Collapse
                in={isExciseDutyPage && open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem button component={NavLink} to="/excise-duty/files">
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.files" />}
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/excise-duty/transactions"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.transactions" />}
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/excise-duty/reports"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.reports" />}
                    />
                  </ListItem>
                </List>
              </Collapse>


              <ListItem
                button
                component={NavLink}
                to="/sustainability/files"
                className={isSustainabilityPage ? "Mui-selected" : ""}
                activeClassName=""
              >
                <ListItemIcon>
                  <EcoOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="sidePanel.sustainability" />}
                />
              </ListItem>

              {/* sustainability reporting sub menu items */}
              <Collapse
                in={isSustainabilityPage && open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    component={NavLink}
                    to="/sustainability/files"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.files" />}
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/sustainability/storage"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.storage" />}
                    />
                  </ListItem>

                  {/* Storage > Sub menu */}
                  <>
                    {/*
                    <ListItem
                      button
                      component={NavLink}
                      to="/sustainability/storage/outgoing-transaction"
                    >
                      <ListItemIcon>
                        <span />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage id="sidePanel.addPOSVolume" />
                        }
                        className={classes.nestedSubMenuItemText}
                      />
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => setShowModalDownloadPOS(true)}
                      className={modal_show_download_pos ? "active" : ""}
                    >
                      <ListItemIcon>
                        <span />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <FormattedMessage id="sidePanel.downloadPoS" />
                        }
                        className={classes.nestedSubMenuItemText}
                      />
                    </ListItem> */}
                    <ListItem
                        button
                        component={NavLink}
                        to="/sustainability/storage/outgoing-transaction-hbl"
                    >
                      <ListItemIcon>
                        <span />
                      </ListItemIcon>
                      <ListItemText
                          primary={
                            <FormattedMessage id="sidePanel.addHBLPOSVolume" />
                          }
                          className={classes.nestedSubMenuItemText}
                      />
                    </ListItem>
                    <ListItem
                        button
                        onClick={() => setShowModalDownloadHBLPOS(true)}
                        className={showModalDownloadHBLPOS ? "active" : ""}
                    >
                      <ListItemIcon>
                        <span />
                      </ListItemIcon>
                      <ListItemText
                          primary={
                            <FormattedMessage id="sidePanel.downloadHBLPoS" />
                          }
                          className={classes.nestedSubMenuItemText}
                      />
                    </ListItem>
                    <ListItem
                        button
                        component={NavLink}
                        to="/sustainability/storage/biofuel-volume-transaction"
                    >
                      <ListItemIcon>
                        <span />
                      </ListItemIcon>
                      <ListItemText
                          primary={
                            <FormattedMessage id="sidePanel.volumeTransfer" />
                          }
                          className={classes.nestedSubMenuItemText}
                      />
                    </ListItem>
                    <ListItem
                        button
                        component={NavLink}
                        to="/sustainability/storage/biofuel-reconciliation"
                    >
                      <ListItemIcon>
                        <span />
                      </ListItemIcon>
                      <ListItemText
                          primary={
                            <FormattedMessage id="sidePanel.reconciliation" />
                          }
                          className={classes.nestedSubMenuItemText}
                      />
                    </ListItem>
                  </>

                  <ListItem
                    button
                    component={NavLink}
                    to="/sustainability/customers"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <FormattedMessage id="sidePanel.customerRequirements" />
                      }
                    />
                  </ListItem>

                  <ListItem
                    button
                    component={NavLink}
                    to="/sustainability/allocation"
                    className={isMDIRreportPage ? "active" : ""}
                    activeClassName=""
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.mdirReport" />}
                    />
                  </ListItem>

                  <ListItem
                    button
                    component={NavLink}
                    to="/sustainability/allocation"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.allocate" />}
                      className={classes.nestedSubMenuItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/sustainability/reports"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.report" />}
                      className={classes.nestedSubMenuItemText}
                    />
                  </ListItem>
                </List>
              </Collapse>

              {/* Card  */}
              <ListItem
                button
                component={NavLink}
                to="/card/files"
                className={isCardPage ? "Mui-selected" : ""}
                activeClassName=""
              >
                <ListItemIcon>
                  <CreditCardOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<FormattedMessage id="sidePanel.card" />}
                />
              </ListItem>

                    {/* Card -card detail menu */}
              <Collapse
                in={isCardPage && open}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem
                    button
                    component={NavLink}
                    to="/card/files"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.files" />}
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/card/storage"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.storage" />}
                    />
                  </ListItem>

               

                  <ListItem
                    button
                    component={NavLink}
                    to="/card/customers"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <FormattedMessage id="sidePanel.customerRequirements" />
                      }
                    />
                  </ListItem>

                  <ListItem
                    button
                    component={NavLink}
                    to="/card/allocation"
                    className={isCardRreportPage ? "active" : ""}
                    activeClassName=""
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.cardProcessReport" />}
                    />
                  </ListItem>

                  <ListItem
                    button
                    component={NavLink}
                    to="/card/allocation"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.allocate" />}
                      className={classes.nestedSubMenuItemText}
                    />
                  </ListItem>
                  <ListItem
                    button
                    component={NavLink}
                    to="/card/reports"
                  >
                    <ListItemIcon>
                      <span />
                    </ListItemIcon>
                    <ListItemText
                      primary={<FormattedMessage id="sidePanel.report" />}
                      className={classes.nestedSubMenuItemText}
                    />
                  </ListItem>
                </List>
              </Collapse>
            </List>
          </div>
          {/* Sidepanel: bottom menu container */}
          <List>
            {/* auditor download button */}
            {isAuditor() && open && (
              <Button
                variant="outlined"
                size="small"
                color="primary"
                className={classes.auditButton}
                onClick={handleDownloadAudit}
              >
                <FormattedMessage id="sidePanel.downloadAudit" />
              </Button>
            )}

            <Divider variant="middle" className={classes.dividerBottom} />
            <ListItem
              button
              component={NavLink}
              to="/profile"
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <PersonOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidePanel.myProfile" />}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/settings"
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <SettingsOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidePanel.settings" />}
              />
            </ListItem>
            <ListItem
              button
              component={NavLink}
              to="/support"
              activeClassName="Mui-selected"
            >
              <ListItemIcon>
                <QuestionAnswerOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidePanel.support" />}
              />
            </ListItem>
            <ListItem button onClick={handleSignOut}>
              <ListItemIcon>
                <ExitToAppOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={<FormattedMessage id="sidePanel.signOut" />}
              />
            </ListItem>
          </List>
        </div>
      </Drawer>

      <ModalInfo
        text={errorMessage}
        open={showErrorModal}
        onClose={closeErrorModal}
      />
    </div>
  );
};

export default SidePanel;
