import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { colors, typography } from "../config/theme";

const styles = StyleSheet.create({
  root: {
    backgroundColor: colors.lightYellow,
  },
  titleContainer: {
    backgroundColor: colors.green,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginBottom: 10,
  },
  title: {
    ...typography.defaultBold,
    color: colors.yellow,
    fontSize: 8,
  },
  content: {
    paddingHorizontal: 10,
  },
});

interface InfoBoxProps {
  title: string;
  children: React.ReactNode;
  style?: ReactPDF.Style | ReactPDF.Style[]; // | ReactPDF.Styles;
}

const InfoBox: React.FC<InfoBoxProps> = ({ title, children, style = [] }) => {
  const externalStyles = Array.isArray(style) ? style : [style];

  return (
    <View style={[styles.root, ...externalStyles]}>
      <View style={styles.titleContainer} fixed>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.content}>{children}</View>
    </View>
  );
};

export default InfoBox;
