import { getToken } from "../utils";
import { BASE_URL } from "../endpoints";

export default (setting: string) => {
  return async (params: any) => {
    const token = getToken();

    let valid = false;
    if (token) {
      try {
        var API_URL = `${BASE_URL}/api/${setting}`;
        if (setting === "tax_years") API_URL = `${BASE_URL}/api/v1/${setting}`;

        const response = await fetch(API_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(params),
        });
        var createResult = await response.json();
        valid = createResult && createResult.success;
      } catch (except) {
        valid = false; // network error
      }
    }

    if (!valid) {
      if (createResult && createResult.errors)
        return {
          valid: false,
          message: createResult.errors,
        };
      return {
        valid: false,
        message: "Something unexpected happened",
      };
    }

    return {
      valid: true,
      ...createResult,
    };
  };
};
