import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: any) => ({
      height: "100%",
      border: `2px solid ${props.status ? "#01823b" : "#ff441a"}`,
      borderRadius: "5px",
      padding: theme.spacing(1.5, 2),
      color: `${props.status ? "#01823b" : "#ff441a"}`,
      backgroundColor: `${props.status ? "#cce6d8" : "#ffdad1"}`,
    }),
  })
);

interface StatusProps {
  status: boolean;
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

const Status: React.FC<StatusProps> = ({ status, title, subtitle }) => {
  const classes = useStyles({ status });
  return (
    <div className={classes.root}>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="caption">{subtitle}</Typography>
    </div>
  );
};

export default Status;
