/**
 * Generate a user name
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../utils";
import { BASE_URL } from "../../endpoints";

const generateUserName = async (merchantId: number) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/merchants/${merchantId}/users/generate_user_name`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        return { user_name: data.user_name, company_email: data.company_email };
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default generateUserName;
