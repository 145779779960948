import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import NewExciseDutyCard from "./NewExciseDutyCard";
import DropZoneCard from "../../../../components/DropZoneCard";
import FilesTable, { IFile } from "../../../../components/FilesTable";
import SelectYearMonth from "../../../../components/SelectYearMonth";
import uploadEDFile from "../../../../api/exciseduty/uploadEDFile";
import deleteEDFile from "../../../../api/exciseduty/deleteEDFile";
import { FormattedMessage } from "react-intl";
import useModal from "../../../../components/modal/useModal";
import ModalInfo from "../../../../components/modal/ModalInfo";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filelistContainer: {
      marginTop: theme.spacing(2),
    },
  })
);

interface IEDFile extends IFile {
  type: string; // "transaction_files" | "manual_report_groups";
  year: number;
  month: number;
}

interface Props {
  files: any;
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  selectedMonth: number;
  setSelectedMonth: any;
  getFiles: any;
  getTaxPeriods: any;
}

const FilesView: React.FC<Props> = ({
  files,
  getFiles,
  getTaxPeriods,
  taxPeriods,
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
}) => {
  const [filterList, setFilterList] = useState<any[]>([]);
  const [infoModal, setInfoModal] = useState({ title: "", description: "" });

  const { openModalId, toggle } = useModal();
  const classes = useStyles();

  const openModal = (id: string, text: string, message: string) => {
    if (id === "info") {
      setInfoModal({ title: text, description: message });
    }
    toggle(id);
  };

  const closeModal = () => {
    toggle();
  };

  const filterFileList = (year: number, month: number, files: any[]) => {
    if (files && Array.isArray(files)) {
      const newFilteredList = files.filter(
        (item: any) =>
          parseInt(item.tax_year) === year && parseInt(item.month) === month + 1
      );
      setFilterList(newFilteredList);
    }
  };

  useEffect(() => {
    filterFileList(selectedYear, selectedMonth, files);
  }, [selectedMonth, selectedYear, files]);

  const handleUploadFile = async (
    files: File[],
    type: string // "transaction_files" | "manual_report_groups"
  ) => {
    const res = await uploadEDFile({
      file: files[0],
      year: selectedYear,
      month: selectedMonth + 1, // conver to months 1 - 12
      type,
    });

    if (res && res.success) {
      openModal("info", `File uploaded successfully`, "");
      getFiles();
      getTaxPeriods();
    } else {
      let warning = new String();
      if (res) {
        warning = res.msg;
      }
      openModal(
        "info",
        `File uploaded failed`,
        warning.toString()
      );
    }
  };

  const handleDeleteFile = async (file: IEDFile) => {
    const res = await deleteEDFile({
      id: file.id,
      type: file.type,
    });

    if (res && res.success) {
      openModal("info", `${file.type}: File deleted successfully`, "");
      getFiles();
      getTaxPeriods();
      return { success: true };
    } else {
      openModal(
        "info",
        `${file.type}: File deleted failed`,
        "Network related error"
      );
      return { success: false };
    }
  };

  return (
    <div>
      <Typography variant="h2">
        {" "}
        <FormattedMessage id={"exciseDutyView.filesView.heading"} />
      </Typography>
      <SelectYearMonth
        dataYearMonth={taxPeriods}
        selectedYearMonth={{
          selectedMonthIndex: selectedMonth,
          SetSelectedMonthIndex: setSelectedMonth,
          selectedYear: selectedYear,
          SetSelectedYear: setSelectedYear,
        }}
      />
      <Grid container spacing={2}>
        {/* <Grid item xs={4}>
          <DropZoneCard
            title={
              <FormattedMessage
                id={"exciseDutyView.filesView.transactionFileUpload"}
              />
            }
            handleFiles={files => handleUploadFile(files, "transaction_files")}
          />
        </Grid> */}
        <Grid item xs={4}>
          <DropZoneCard
            title={
              <FormattedMessage
                id={"exciseDutyView.filesView.exciseDutyReportUploadTitle"}
              />
            }
            handleFiles={files =>
              handleUploadFile(files, "manual_report_groups")
            }
          />
        </Grid>
        <Grid item xs={4}>
          <NewExciseDutyCard
            selectedYearMonth={{
              selectedMonthIndex: selectedMonth,
              SetSelectedMonthIndex: setSelectedMonth,
              selectedYear: selectedYear,
              SetSelectedYear: setSelectedYear,
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.filelistContainer}>
        <FilesTable fileList={filterList} handleDeleteFile={handleDeleteFile} />
      </div>
      <ModalInfo
        text={infoModal.title}
        message={infoModal.description}
        open={openModalId === "info"}
        onClose={closeModal}
      />
    </div>
  );
};

export default FilesView;
