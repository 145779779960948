import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../ButtonRounded";
import ModalContainer from "../../ModalContainer";
import Delete from "../../../api/settings/bkvMappings/delete";
// import { ModalDeleteProps } from "./Interfaces";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    userContainer: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 0),
    },
    btnCancel: {},
  })
);

const DeleteModal: React.FC<any> = ({ open, onClose, title, id, fetchData }) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const handleDelete = async () => {
    const response = await Delete(id);
    fetchData();
    if (response && response.success) {
      onClose();
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={<FormattedMessage id="settingsView.modalDelete.message" />}
      subtitle={title}
    >
      <div className={classes.modalContent}>
        <Grid container spacing={2} className={classes.btnContainer}>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="outlined"
              onClick={onClose}
              color="secondary"
            >
              {<FormattedMessage id="settingsView.modal.cancel" />}
            </ButtonRounded>
          </Grid>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={isError}
            >
              {<FormattedMessage id="settingsView.modal.delete" />}
            </ButtonRounded>
          </Grid>
        </Grid>
        {isError && (
          <Typography align="center" variant="body2" color="error">
            {<FormattedMessage id="settingsView.modalDelete.failed" />}
            {"Hello"}
            {"."}
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default DeleteModal;
