// export const BASE_URL = "http://localhost:4000";

function apiRoot() {
  console.log("API_ROOT: " + window.server.API_ROOT);

  if (window.server.API_ROOT !== "undefined") {
    return window.server.API_ROOT;
  } else {
    return "http://preem-bkv-dev.northeurope.cloudapp.azure.com/";
  }
}

export const BASE_URL = apiRoot();

// http://localhost:4000
