/**
 * add/edit a merchant
 */

import { getToken } from "../../../utils";
import { BASE_URL } from "../../../endpoints";
import axios from "axios";

interface IAddEditMerchant {
  mode: "add" | "edit";
  merchant: {
    id?: number;
    name: string;
    org_number: string;
    email: string;
    logo?: any;
    remove_logo?: boolean;
  };
}

const addEditMerchant = async ({ mode, merchant }: IAddEditMerchant) => {
  const token = getToken();
  const urlSuffix = mode === "edit" ? `/${merchant.id}` : "";

  if (token) {
    const fd = new FormData();
    fd.append("merchant[name]", String(merchant.name));
    fd.append("merchant[org_number]", String(merchant.org_number));
    fd.append("merchant[email]", String(merchant.email));
    if (merchant.logo) {
      fd.append("merchant[logo]", merchant.logo);
    }
    if (merchant.remove_logo) {
      fd.append("remove_logo", "true");
    }

    try {
      const response = await axios(`${BASE_URL}/api/v1/merchants${urlSuffix}`, {
        method: mode === "add" ? "POST" : "PUT",
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
        data: fd,
      });

      if (response.status === 200 || response.status === 201) {
        return response.data.success;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default addEditMerchant;
