import React from "react";
import { FormattedMessage } from "react-intl";

export default [
  [
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col14" />
      ),
      id: "fuel_type",
      type: "select",
      params: "biocomponent_type",
    },
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col1" />
      ),
      id: "biofuel_type",
      type: "select",
      params: "biofuel_type",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.doubleCounting" />
      ),
      id: "double_counted_norway",
      type: "select",
      params: "flag_variable",
    },
    {
      name: <FormattedMessage id="sustainabilityView.storage.filters.ghgRed" />,
      id: "reduction_in_climate_emission",
      type: "slider",
      params: { min: 0, max: 100, step: 1 },
    },
  ],
  [
    {
      name: (
        <FormattedMessage id="modalSave.red_version" />
      ),
      id: "red_version",
      type: "select",
      params: "red_version",
    },
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col3" />
      ),
      id: "raw_material",
      type: "select",
      params: "raw_material",
    },
    {
      name: (
        <FormattedMessage id="sustainabilityView.storage.filters.rawOrigin" />
      ),
      id: "raw_material_origin",
      type: "select",
      params: "origin",
    },
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col2" />
      ),
      id: "volume",
      type: "slider",
      params: { min: 0, max: 20000000, step: 1000 },
    },
  ],
  [
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col12" />
      ),
      id: "documentation_mass_balance",
      type: "select",
      params: "documentation_mass_balance",
    },
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col11" />
      ),
      id: "documentation_area_criteria",
      type: "select",
      params: "documentation_area_criteria",
    },
    {
      name: <FormattedMessage id="sustainabilityView.storage.filters.docGhg" />,
      id: "documentation_emission_of_climate",
      type: "select",
      params: "documentation_ghg_reduction",
    },
    {
      name: {
        from: (
          <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col18" />
        ),
        to: (
          <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col19" />
        ),
      },
      id: { from: "period_from", to: "period_to" },
      type: "period",
    },
  ],
  [
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col16" />
      ),
      id: "storage_terminal",
      type: "select",
      params: "storage_terminals",
    },
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col13" />
      ),
      id: "certification_id",
      type: "text",
      placeholder: "E.g. ISCC-1234567",
    },
    {
      name: (
        <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col17" />
      ),
      id: "transaction_type",
      type: "select",
      params: "transaction_type",
    },
    {
      name: (
        <FormattedMessage id="modalSave.pos_id" />
      ),
      id: "pos_id",
      type: "text",
      placeholder: "E.g. BKV-1234567-123-1234",
    },
  ],
];
