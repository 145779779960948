import { combineReducers } from "redux";
import MultiStepReducer, { MultiStepState } from "./multiStep";
import soldVolumesReducer, { SoldVolumesState } from "./soldVolumes";
import card_volumesReducer, { CardVolumesState } from "./cardVolumes";
import ConstructionDieselVolumesReducer, {
  ConstructionDieselVolumesState,
} from "./constructionDieselVolumes";

export interface AllocationState {
  multiStep: MultiStepState;
  soldVolumes: SoldVolumesState;
  constructionDieselVolumes: ConstructionDieselVolumesState;
  cardVolumes:CardVolumesState;
}

export default combineReducers({
  multiStep: MultiStepReducer,
  soldVolumes: soldVolumesReducer,
  constructionDieselVolumes: ConstructionDieselVolumesReducer,
  cardVolumes: card_volumesReducer,
});
