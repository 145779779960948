import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import MainView from "./MainView";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typograhpy from "@material-ui/core/Typography";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
  })
);

const DocumentDBView: React.FC = () => {
  const classes = useStyles();

  const match = useRouteMatch();

  if (match) {
    return (
      <Switch>
        <Route exact path={`${match.path}`}>
          <MainView pageType="main" />
        </Route>
        <Route exact path={`${match.path}` + "/:id"}>
          <MainView pageType="sub" />
        </Route>
        <Redirect to={`${match.path}`} />
      </Switch>
    );
  } else {
    return null;
  }
};

export default DocumentDBView;
