import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import ButtonRounded from "../../../../components/ButtonRounded";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ModalContainer from "../../../../components/ModalContainer";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";
import makeValidate from "../../../../components/form/makeValidate";
import getAllCustomers from "../../../../api/sustainability/customers/getAllCustomers";
import addEditMerchant from "../../../../api/settings/users/merchants/addEditMerchant";
import CreatableSelect from "react-select/creatable";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      marginTop: theme.spacing(3),
    },
    select: {
      marginBottom: theme.spacing(2),
      color: theme.palette.primary.main,
    },
    selectEmpty: {
      color: theme.palette.action.disabled,
    },
    inputContainer: {
      marginBottom: theme.spacing(1),
    },
    creatableSelect: {
      marginBottom: theme.spacing(1),
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: theme.spacing(0.25),
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
    logoNameContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      backgroundColor: theme.palette.grey[50],
      borderRadius: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
    },
    hiddenInput: {
      display: "none",
    },
    btnUpload: {
      borderRadius: theme.spacing(3),
      padding: theme.spacing(1.5),
    },
  })
);

interface ModalAddEditCompanyProps {
  open: boolean;
  onClose: () => void;
  mode: "add" | "edit";
  setCurrentCompany?: (company: any) => void;
  company?: {
    id: number;
    name: string;
    org_number: string;
    email: string;
    customer_id: number;
    logo_url: string;
  };
}

const ModalAddEditCompany: React.FC<ModalAddEditCompanyProps> = ({
  open,
  onClose,
  mode,
  company,
  setCurrentCompany,
}) => {
  const [customerList, setCustomerList] = useState<any[]>([]);
  const [companyLogo, setCompanyLogo] = useState<File | null>(null);
  const [enableLogoSelect, setEnableLogoSelect] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const fetchAllCustomers = async () => {
    const results = await getAllCustomers();
    if (Array.isArray(results)) {
      setCustomerList(results);
    }
  };

  useEffect(() => {
    if (open) {
      setIsError(false);
      setCompanyLogo(null);
      setEnableLogoSelect(
        mode === "add"
          ? true
          : mode === "edit" && company && company.logo_url
          ? false
          : true
      );
      fetchAllCustomers();
    }
  }, [open]);

  const validationSchema = yup.object({
    name: yup.string().required(),
    email: yup
      .string()
      .email()
      .required(),
    org_number: yup
      .number()
      .typeError("Please enter a number.")
      .required(),
  });

  const onSubmit = async (values: any) => {
    setIsError(false);

    const companyIdObj = company && mode === "edit" ? { id: company.id } : {}; // add 'id' in the edit mode

    const updatedCompany = {
      name: values.name,
      email: values.email,
      org_number: values.org_number,
      logo: companyLogo,
      remove_logo: Boolean(
        mode === "edit" && company && company.logo_url && enableLogoSelect
      ),
      ...companyIdObj,
    };

    const res = await addEditMerchant({
      mode,
      merchant: updatedCompany,
    });

    if (res) {
      setIsError(false);
      if (mode === "edit" && setCurrentCompany) {
        setCurrentCompany(updatedCompany);
      }
      onClose();
    } else {
      setIsError(true);
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      setCompanyLogo(files[0]);
    }
  };

  const handleCompanyLogoDelete = () => {
    setCompanyLogo(null);
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={
        mode === "add" ? (
          <FormattedMessage id="settingsView.index.users.addNewCompany" />
        ) : (
          <FormattedMessage id="settingsView.users.modal.editCompany" />
        )
      }
    >
      <div className={classes.modalContent}>
        <div>
          <Form
            onSubmit={onSubmit}
            validate={values => makeValidate(values, validationSchema)}
            initialValues={
              mode === "edit" && company
                ? {
                    name: company.name,
                    email: company.email,
                    org_number: company.org_number,
                    customer_id: company.customer_id,
                  }
                : {
                    name: "",
                    email: "",
                    org_number: "",
                    customer_id: "",
                  }
            }
            mutators={{
              setOrgNumber: (args, state, { changeValue }) => {
                changeValue(state, "org_number", () => args[0]);
              },
            }}
          >
            {({ handleSubmit, submitting, hasValidationErrors, form }) => (
              <form onSubmit={handleSubmit} noValidate>
                <FormattedMessage id="settingsView.index.users.companyName">
                  {companyNameMsg => (
                    <>
                      <Typography variant="body2" gutterBottom>
                        {companyNameMsg} <RequiredAsterisk />
                      </Typography>
                      <Field name="name">
                        {props => (
                          <CreatableSelect
                            className={classes.creatableSelect}
                            classNamePrefix="react-select"
                            placeholder={companyNameMsg}
                            components={{
                              DropdownIndicator: () => <ArrowDropDownIcon />,
                            }}
                            options={customerList.map(customer => ({
                              value: customer.name,
                              label: customer.name,
                            }))}
                            value={
                              props.input.value
                                ? {
                                    value: props.input.value,
                                    label: props.input.value,
                                  }
                                : null
                            }
                            onChange={(value: any) => {
                              props.input.onChange(value.label);
                              const foundCustomer = customerList.find(
                                customer => customer.name === value.label
                              );

                              if (foundCustomer) {
                                form.mutators.setOrgNumber(
                                  foundCustomer.organization_number
                                );
                              }
                            }}
                          />
                        )}
                      </Field>
                    </>
                  )}
                </FormattedMessage>

                <FormattedMessage id="settingsView.users.modal.orgNumber">
                  {orgNumberMsg => (
                    <>
                      <Typography variant="body2" gutterBottom>
                        {orgNumberMsg} <RequiredAsterisk />
                      </Typography>
                      <Field
                        name="org_number"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        placeholder={orgNumberMsg as string}
                        className={classes.inputContainer}
                      />
                    </>
                  )}
                </FormattedMessage>

                <FormattedMessage id="settingsView.users.modal.companyEmail">
                  {companyEmailMsg => (
                    <>
                      <Typography variant="body2" gutterBottom>
                        {companyEmailMsg} <RequiredAsterisk />
                      </Typography>
                      <Field
                        name="email"
                        component={TextField}
                        variant="outlined"
                        fullWidth
                        placeholder={companyEmailMsg as string}
                        className={classes.inputContainer}
                      />
                    </>
                  )}
                </FormattedMessage>

                <>
                  <Typography variant="body2" gutterBottom>
                    <FormattedMessage id="settingsView.users.modal.companyLogo" />
                  </Typography>
                  {mode === "edit" &&
                    !companyLogo &&
                    company &&
                    !enableLogoSelect && (
                      <div className={classes.logoNameContainer}>
                        {company.logo_url && (
                          <Typography variant="body1" color="primary">
                            {String(company.logo_url)
                              .split("/")
                              .slice(-1)}
                          </Typography>
                        )}
                        <IconButton
                          color="secondary"
                          onClick={() => setEnableLogoSelect(true)}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </div>
                    )}
                  {companyLogo && (
                    <div className={classes.logoNameContainer}>
                      <Typography variant="body1" color="primary">
                        {companyLogo.name}
                      </Typography>
                      <IconButton
                        color="secondary"
                        onClick={handleCompanyLogoDelete}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </div>
                  )}

                  {enableLogoSelect && !companyLogo && (
                    <>
                      <input
                        accept="image/png, image/jpeg"
                        className={classes.hiddenInput}
                        id="contained-button-file"
                        multiple={false}
                        type="file"
                        onChange={handleFileInput}
                      />
                      <label htmlFor="contained-button-file">
                        <Button
                          variant="outlined"
                          component="span"
                          color="primary"
                          fullWidth
                          className={classes.btnUpload}
                        >
                          <FormattedMessage id="settingsView.users.modal.button.selectImage" />
                        </Button>
                      </label>
                    </>
                  )}
                </>

                <Grid container spacing={2} className={classes.btnContainer}>
                  <Grid item xs={6}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={onClose}
                    >
                      {<FormattedMessage id="settingsView.modal.cancel" />}
                    </ButtonRounded>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                    >
                      {<FormattedMessage id="settingsView.modal.save" />}
                    </ButtonRounded>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </div>

        {isError && (
          <Typography align="center" variant="body2" color="error">
            {mode === "add" ? (
              <FormattedMessage id="settingsView.users.modal.failedCompany" />
            ) : (
              <FormattedMessage id="settingsView.users.modal.failedEdit" />
            )}
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalAddEditCompany;
