/**
 *  Settings: sub views configurations
 */

import React from "react";
import UsersView from "./UsersView";
import BkvMappingsView from "./BkvMappingsView";
import SettingView from "./SettingView";

import { ISettingProps } from "../../../components/modal/Interfaces";

//Settings APIs
import Get from "../../../api/settings/get";
import Delete from "../../../api/settings/delete";
import Create from "../../../api/settings/create";
import Edit from "../../../api/settings/edit";

import settings from "./SettingProperties/index";
import { FormattedMessage } from "react-intl";

const getApiFunctions = (id: string) => {
  return {
    get: Get(id),
    create: Create(id),
    Edit: Edit(id),
    Delete: Delete(id),
  };
};

export interface IView {
  title: string | React.ReactElement;
  isRestricted?: boolean;
  path: string;
  component:
    | React.FunctionComponent
    | React.FunctionComponent<any>
    | React.ComponentClass; // React.ReactNode;
  props: ISettingProps | any;
}

const getView = (
  title: string | React.ReactElement,
  id: string,
  params: any,
  isRestricted?: boolean
) => {
  return {
    title: title,
    isRestricted: isRestricted,
    path: "/".concat(id),
    component: SettingView,
    props: {
      name: title,
      table: params,
      api: getApiFunctions(id),
    },
  };
};

export const getViews = (settingAccesses: any) => {
  const settingViews: IView[] = [];
  settings.map(
    setting =>
      Array.isArray(settingViews) &&
      settingViews.push(
        getView(
          setting.title,
          setting.id,
          setting.params,
          settingAccesses[setting.id]
        )
      )
  );

  settingViews.push({
    title: <FormattedMessage id="settingsView.users" />,
    isRestricted: settingAccesses.users,
    path: "/users",
    component: UsersView,
    props: null,
  });

  settingViews.push({
    title: <FormattedMessage id="settingsView.bkvMappings" />,
    isRestricted: settingAccesses.bkv_mappings,
    path: "/bkv_mappings",
    component: BkvMappingsView,
    props: null,
  });

  return settingViews;
};
