/**
 *  Dashboard: Settings: Main View
 */

import React, { useState, useEffect } from "react";
import { match as Match } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import SettingsCard from "./SettingsCard";
import { getViews } from "./config";
import { getSettingAccesses } from "../../../api/settings/getSettingAccesses";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
  })
);

interface MainViewProps {
  match: Match;
}

const MainView: React.FC<MainViewProps> = ({ match }) => {
  const [settingViews, setSettingViews] = useState<any>(null);
  const classes = useStyles();

  const fetchData = async () => {
    const data = await getSettingAccesses();
    if (data && data.valid) {
      setSettingViews(getViews(data));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (!settingViews) { return <div></div>; }

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {settingViews.map((card: any, idx: any) => (
          <Grid item md={6} sm={6} key={idx}>
            <SettingsCard
              title={card.title}
              isRestricted={card.isRestricted}
              path={`${match.url}${card.path}`}
            />
          </Grid>
        ))}
        <Grid item md={6} sm={6}>
          <SettingsCard
            title={"App Variables"}
            path={`${match.url}/app_variables`}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default MainView;
