import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { Form, Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ButtonRounded from "../../../../components/ButtonRounded";
import ModalContainer from "../../../../components/ModalContainer";
import editMerchantUser from "../../../../api/settings/users/merchants/editMerchantUser";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      paddingTop: theme.spacing(2),
    },
    select: {
      marginBottom: theme.spacing(2),
    },
    inputContainer: {
      "margin-bottom": theme.spacing(2),
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 10px",
      },
    },
  })
);

interface ModalEditUserProps {
  open: boolean;
  onClose: () => void;
  user: {
    merchantID: number;
    id: number;
    name: string;
    email: string;
    role: string;
  };
}

const ModalEditUser: React.FC<ModalEditUserProps> = ({
  open,
  onClose,
  user,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();
  const userRole = user.role.toLowerCase().replace(" ", "_");

  const handleSave = async (newUserRole: string) => {
    const response = await editMerchantUser({
      merchantID: user.merchantID,
      userID: user.id,
      userRole: newUserRole === "reseller" ? "merchant" : newUserRole,
    });
    if (response && response.success) {
      onClose();
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  const onSubmit = async (values: any) => {
    await handleSave(values.user_role);
  };
  const validate = (values: any) => {
    const errors: { [k: string]: any } = {};
    if (values.user_role === userRole) {
      errors.user_role = (
        <FormattedMessage id="settingsView.users.modalEdit.choose" />
      );
    }
    return errors;
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={<FormattedMessage id="settingsView.users.modal.editUser" />}
    >
      <div className={classes.modalContent}>
        <div>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{
              user_role: userRole,
              user_name: user.name,
              company_email: user.email,
            }}
          >
            {({ handleSubmit, submitting, hasValidationErrors, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Typography variant="body1" gutterBottom>
                  {<FormattedMessage id="settingsView.users.modal.role" />}
                </Typography>
                <Field name="user_role">
                  {props => (
                    <div className={classes.select}>
                      <Select
                        {...props.input}
                        fullWidth
                        input={<OutlinedInput labelWidth={0} />}
                      >
                        <MenuItem value="system_administrator">
                          {
                            <FormattedMessage id="settingsView.users.modal.systemAdministrator" />
                          }
                        </MenuItem>
                        <MenuItem value="admin">
                          {
                            <FormattedMessage id="settingsView.users.modal.admin" />
                          }
                        </MenuItem>
                        <MenuItem value="operator">
                          {
                            <FormattedMessage id="settingsView.users.modal.operator" />
                          }
                        </MenuItem>
                        <MenuItem value="visitor">
                          {
                            <FormattedMessage id="settingsView.users.modal.visitor" />
                          }
                        </MenuItem>
                        <MenuItem value="reseller">
                          <FormattedMessage id="settingsView.users.modal.reseller" />
                        </MenuItem>
                        <MenuItem value="auditor">
                          <FormattedMessage id="settingsView.users.modal.auditor" />
                        </MenuItem>
                      </Select>
                      {props.meta.error && props.meta.touched && (
                        <Typography variant="body1" color="error">
                          {props.meta.error}
                        </Typography>
                      )}
                    </div>
                  )}
                </Field>

                <Typography variant="body1" gutterBottom>
                  {<FormattedMessage id="settingsView.index.users.userName" />}
                </Typography>
                <TextField
                  className={classes.inputContainer}
                  name="user_name"
                  value={values.user_name}
                  disabled
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                />
                <Typography variant="body1" gutterBottom>
                  {
                    <FormattedMessage id="settingsView.users.modal.companyEmail" />
                  }
                </Typography>
                <TextField
                  className={classes.inputContainer}
                  name="company_email"
                  value={values.company_email}
                  disabled
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                />

                <Grid container spacing={2} className={classes.btnContainer}>
                  <Grid item xs={6}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={onClose}
                    >
                      {<FormattedMessage id="settingsView.modal.cancel" />}
                    </ButtonRounded>
                  </Grid>
                  <Grid item xs={6}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                    >
                      {<FormattedMessage id="settingsView.modal.save" />}
                    </ButtonRounded>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </div>

        {isError && (
          <Typography align="center" variant="body2" color="error">
            {<FormattedMessage id="settingsView.users.modal.failedEdit" />}
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalEditUser;
