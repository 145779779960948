import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { colors, typography } from "../config/theme";
import Inputfield from "./InputfieldV2";
import translation from "../CustomerReportV2/translationV2";
const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 3,
    borderLeft: 2,
    borderLeftColor: colors.green,
  },
  container_rows: {
    display: "flex",
    flexDirection: "column",
  },
  labels: {
    color: colors.green,
    fontSize: 8,
  },
});

interface Props {
  language: "EN" | "NO";
  inputBiofuel: string;
  inputRaw: string;
  inputOrigin: string;
  inputCo2: string;
  inputCert: string;
  inputAdv: string;

  style?: ReactPDF.Style | ReactPDF.Style[];
}

const InfoBlockV2: React.FC<Props> = ({
  language,
  inputBiofuel,
  inputRaw,
  inputOrigin,
  inputCo2,
  inputCert,
  inputAdv,

  style = [],
}) => {
  const externalStyles = Array.isArray(style) ? style : [style];
  const t = translation[language];
  return (
    <View style={styles.container}>
      <View style={styles.container_rows}>
        <Inputfield
          label={t.infoBlock_biofuels}
          input={inputBiofuel}
          labelstyle={[styles.labels, { marginLeft: 5 }]}
        ></Inputfield>
        <Inputfield
          label={t.infoBlock_raw}
          input={inputRaw}
          labelstyle={[styles.labels, { marginLeft: 5 }]}
        ></Inputfield>
        <Inputfield
          label={t.infoBlock_origin}
          input={inputOrigin}
          labelstyle={[styles.labels, { marginLeft: 5 }]}
        ></Inputfield>
      </View>
      <View style={styles.container_rows}>
        <Inputfield
          label={t.infoBlock_co2}
          input={inputCo2}
          labelstyle={styles.labels}
        ></Inputfield>
        <Inputfield
          label={t.infoBlock_cert}
          input={inputCert}
          labelstyle={styles.labels}
        ></Inputfield>
        <Inputfield
          label={t.infoBlock_adv}
          input={inputAdv}
          labelstyle={styles.labels}
        ></Inputfield>
      </View>
    </View>
  );
};

export default InfoBlockV2;
