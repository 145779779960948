import React from "react";
import { FormattedMessage } from "react-intl";

export default [
  {
    name: <FormattedMessage id="settingsView.index.ghgRates.component" />,
    id: "component",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.ghgRates.value" />,
    id: "value",
    type: "text",
    optionnal: "true",
    decimals_format: 2,
  },
];
