export default [
  {
    idFormattedName: "exciseDutyView.reportsView.mainView.period",
    id: "period",
  },
  {
    idFormattedName: "exciseDutyView.reportsView.mainView.lineNumber",
    id: "serial",
  },
  {
    idFormattedName: "exciseDutyView.reportsView.mainView.taxType",
    id: "tax_type",
  },
  {
    idFormattedName: "exciseDutyView.reportsView.mainView.taxGroup",
    id: "tax_group",
  },
  {
    idFormattedName: "exciseDutyView.reportsView.mainView.additionalCode",
    id: "additional_code",
  },
  {
    idFormattedName: "exciseDutyView.reportsView.mainView.quantity",
    id: "quantity",
    alignRight: true,
  },
];
