import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async () => {
  const token = getToken();

  let valid = false;

  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/card_allocation_volumes`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      var result = await response.json();
      valid = result && result.success;
      var card_volumes = result.card_allocation_volumes;
    } catch (except) {
      valid = false; // network error
    }
  }

  if (!valid) {
    if (result && result.error)
      return {
        valid: false,
        message: result.error,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data: card_volumes,
  };
};
