import { Document, Page, StyleSheet, View } from "@react-pdf/renderer";
import moment from "moment";
import React from "react";
import parseData from "../../../../components/texts/parseData";
import Footer from "../components/Footer";
import Header from "../components/Header";
import InfoBox from "../components/InfoBox";
import InfoText3 from "../components/InfoText3";
import InfoText4 from "../components/InfoText4";
import { colors, typography } from "../config/theme";
import Description from "./Description";
import translation from "./translation";
import InfoText5 from "../components/InfoText5";

const styles = StyleSheet.create({
  page: {
    paddingTop: 25,
    paddingHorizontal: 30,
    paddingBottom: 70,
  },
  root: {},
  midSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  infoContainer: {
    flex: 1,
  },
  total: { paddingRight: 10 },
});

interface Props {
  language: "EN" | "NO";
  data: any;
}

const CustomerReport: React.FC<Props> = ({ language, data }) => {
  const t = translation[language];

  const getTotal = (rows: any[]) => {
    let sum: number = 0;
    Array.isArray(rows) &&
      rows.map((row) => (sum = parseFloat(row.quantity) + sum));
    return parseData(sum, 3);
  };
  const renewableTitle = (data: any[]) => {
    return data.length == 1 ? t.renewable_batch_title : t.renewable_batches_title
  }
  const totalQuantity = (rows: any[]) => {
    let sum = 0;
    if (rows.length > 1) {
      Array.isArray(rows) &&
        rows.map((row: any) => (sum = parseFloat(row.quantity) + sum));
    } else {
      sum = parseFloat(rows[0].quantity)
    }
    return parseData(sum, 3);
  };

  const totalEmission = (rows: any[]) => {
    let emission = 0;
    if (rows.length > 1) {
      const totalQuantityShare = rows.map((batch: any) =>
        (
          (Math.round((batch.quantity / parseFloat(totalQuantity(rows))) * 100) * batch.etot) /
          100
        ).toFixed(2)
      );
      emission = totalQuantityShare.reduce(
        (accumulator: number, currentValue: string) => accumulator + parseFloat(currentValue),
        0
      );
    } else {
      emission = rows[0].etot;
    }
    return parseFloat(emission.toFixed(2));
  };

  const ghgReduction = (rows: any[]) =>
    parseFloat((((94 - totalEmission(rows)) / 94) * 100).toFixed(2));

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <Header
          title={t.header_title}
          subtitle={t.header_subtitle}
          logo="/assets/preem-logo.png"
        />
        <View style={styles.root}>
          <View style={styles.midSection}>
            <View style={styles.infoContainer}>
              <InfoBox title={t.infoBox1_title}>
                <InfoText3
                  title={"Note ref."}
                  value={data.pos_id}
                  colored
                  largeValue
                  spaceBottom
                  style={{ ...typography.defaultBold }}
                />
                <InfoText3
                  title={t.infoBox1_customer}
                  value={data.customer_name}
                  colored
                  largeValue
                />
                <InfoText3
                  title={"Supplier"}
                  value={"Preem Norge AS"}
                  colored
                  largeValue
                />
                <InfoText3
                  title={t.infoBox1_issued}
                  value={moment(data.report_issued_at).format("YYYY-MM-DD")}
                  colored
                  spaceBottom
                  largeValue
                />

                <InfoText3
                  title={"Contact"}
                  value={"kundeservice@preem.no"}
                  colored
                  spaceBottom
                  largeValue
                />
              </InfoBox>
            </View>
            <View style={{ flex: 1, marginHorizontal: "10px" }}>
              <InfoBox
                title={t.infoBox2_title}
                style={{ ...typography.defaultBold, backgroundColor: "#ece9e5" }}
              >
                <InfoText3
                  title={t.infoBox2_vessel}
                  value={data.vessel}
                  colored
                />
                <InfoText3
                  title={t.infoBox2_shipment}
                  value={data.shipment_id}
                  colored
                />
                <InfoText3
                  title={t.infoBox2_bl_date}
                  value={moment(data.b_l_date).format("D MMMM YYYY")}
                  colored
                  spaceBottom
                />
                <InfoText3
                  title={t.infoBox2_loading}
                  value={data.load_port}
                  colored
                />

                <InfoText3
                  title={t.infoBox2_discharge}
                  value={data.discharge_port}
                  colored
                  spaceBottom
                />
              </InfoBox>
            </View>
            <View style={styles.infoContainer}>
              <InfoBox
                title={t.infoBox3_title}
                style={{ backgroundColor: "#d0dad6", ...typography.defaultBold }}
              >
                <InfoText3
                  title={t.infoBox3_bio_product}
                  value={data.biofuel_type}
                  colored
                />
                <InfoText3
                  title={t.infoBox3_quantity}
                  value={`${parseData(data.total_quantity, 3)} m3 @ 15 C`}
                  colored
                  spaceBottom
                />
                <InfoText3
                  title={t.infoBox3_total_emission}
                  value={`${parseData(data.total_emission, 2)} gCO2e/MJ`}
                  colored
                />
                <InfoText3
                  title={t.infoBox3_ghg_reduction}
                  value={`${parseData(data.total_ghg_reduction, 2)} %`}
                  colored
                />
                {data.allocations.length > 1 &&
                  <InfoText3
                    title={t.infoBox3_batches}
                    value={`${data.allocations.length} ${t.infoBox3_type_batches}`}
                    colored
                    spaceBottom
                  />
                }
                {data.allocations.length == 1 &&
                  <InfoText3
                    title={t.infoBox3_batch}
                    value={`${data.allocations.length} ${t.infoBox3_type_batch}`}
                    colored
                    spaceBottom
                  />
                }
              </InfoBox>
            </View>
          </View>

          <View style={{ flexDirection: "column", marginBottom: 15 }}>

            <View>
              <InfoBox
                title={renewableTitle(data.allocations)}
                style={{
                  backgroundColor: "#d0dad6",
                  ...typography.defaultBold,
                  padding: 0,
                }}
              >
                {Array.isArray(data.allocations) && data.allocations.length > 0 &&
                  data.allocations.map((entry: any, index: number) => {
                    // @ts-ignore
                    return (
                        <View
                            style={{
                              borderBottom:
                                  index != data.allocations.length - 1 ? 2 : 0,
                              borderBottomColor: colors.green,
                            }}
                        >
                      <View
                        style={{
                          flexDirection: "row",
                          paddingTop: "8px",
                          borderBottom: `none`,
                        }}
                      >
                        <View style={{ flex: 1}}>
                          <InfoText3
                            title={t.renewable_batch_id}
                            value={entry.serial_number}
                            colored
                            largeValue
                          />
                          <InfoText3
                            title={t.renewable_batch_quantity}
                            value={`${parseData(entry.quantity, 3)} m3 @ 15 C`}
                            colored
                            largeValue
                          />
                          <InfoText3
                            title={t.renewable_batch_raw_material}
                            value={entry.raw_material}
                            colored
                            largeValue
                          />
                          <InfoText3
                            title={t.renewable_batch_country_of_origin}
                            value={entry.raw_material_origin}
                            colored
                            largeValue
                          />
                          <InfoText3
                            title={t.renewable_batch_site_start}
                            value={(entry.site_start) ? moment(entry.site_start).format("D MMMM YYYY") : "-"}
                            colored
                            largeValue
                          />
                        </View>
                        <View style={{ flex: 0.5, marginHorizontal: "12px", }}>
                          <InfoText4
                            title={t.renewable_batch_etot}
                            value={`${parseData(entry.etot, 2)} gCO2/MJ`}
                            colored
                            largeValue
                          />
                          <InfoText4
                            title={t.renewable_batch_eec}
                            value={parseData(entry.eec, 2)}
                            colored
                            largeValue
                          />
                          <InfoText4
                            title={t.renewable_batch_el}
                            value={parseData(entry.el, 2)}
                            colored
                            largeValue
                          />
                          <InfoText4
                            title={t.renewable_batch_ep}
                            value={parseData(entry.ep, 2)}
                            colored
                            largeValue
                          />
                          <InfoText4
                            title={t.renewable_batch_etd}
                            value={parseData(entry.etd, 2)}
                            colored
                            largeValue
                          />
                        </View>
                        <View style={{ flex: 0.5 }}>
                          <InfoText4
                            title={t.renewable_batch_ghg}
                            value={`${parseData(entry.reduction_in_climate_emission, 2)}%`}
                            colored
                            largeValue
                          />
                          <InfoText4
                            title={t.renewable_batch_eu}
                            value={parseData(entry.eu, 2)}
                            colored
                            largeValue
                          />
                          <InfoText4
                            title={t.renewable_batch_esca}
                            value={parseData(entry.esca, 2)}
                            colored
                            largeValue
                          />
                          <InfoText4
                            title={t.renewable_batch_eccs}
                            value={parseData(entry.eccs, 2)}
                            colored
                            largeValue
                          />
                          <InfoText4
                            title={t.renewable_batch_eccr}
                            value={parseData(entry.eccr, 2)}
                            colored
                            largeValue
                          />
                        </View>
                      </View>
                          <View
                              style={{
                                flexDirection: "row",
                                paddingTop: 0,
                                paddingBottom: "7px",
                                borderBottom: `none`,
                              }}
                          >
                            <View style={{ flexBasis: 100, flexGrow: 1, flex: 1 }}>
                              <InfoText5
                                  title={t.renewable_batch_certificate}
                                  value={(entry.certification_id) ? entry.certificate + ' (' + entry.certification_id + ')' : entry.certificate}
                                  colored
                                  largeValue={(data.allocations.length > 1) ? true : false }
                              />
                            </View>
                          </View>
                      </View>
                    )
                  })
                }
              </InfoBox>
            </View>
          </View>

          <View style={{ flexDirection: "row" }}>
            <View style={styles.infoContainer}>
              <InfoBox
                title={t.description_title}
                style={{ backgroundColor: "#ece9e5", padding: 0 }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    paddingBottom: "10px",
                  }}
                >
                  <View style={styles.infoContainer}>
                    <Description
                      language={language}
                      description={{
                        name: data.name,
                        position: data.position,
                        date: data.date,
                      }}
                    />
                  </View>
                </View>
              </InfoBox>
            </View>
          </View>
        </View>

        <Footer language={language} />
      </Page>
    </Document >

  );
};

export default CustomerReport;
