import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Error404: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <h1>404</h1>
      <p>{<FormattedMessage id="errorView.notExist" />}</p>
      <p>
        {<FormattedMessage id="errorView.click" />}{" "}
        <Link to="/">{<FormattedMessage id="errorView.here" />}</Link>{" "}
        {<FormattedMessage id="errorView.goBack" />}
      </p>
    </div>
  );
};

export default Error404;
