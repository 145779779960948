import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../../reducers/reducer";
import EditableTableView from "../../../views/DashboardView/CardView/AllocationView/MultiStepView/components/EditableTable";
import { editAllocatedData } from "../../../reducers/card/allocation/multiStep/index";

interface Props {
  data: any[];
  activeStep: number;
  allocated: any;
  available_quantities_part1: any;
  available_quantities_part2: any;
  available_quantities_construction: any;
  editAllocatedData: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      data: state.card.allocation.multiStep.steps[
        state.card.allocation.multiStep.activeStep
      ].data,
      allocated:
        state.card.allocation.multiStep.steps[
          state.card.allocation.multiStep.activeStep
        ].allocated,
      activeStep: state.card.allocation.multiStep.activeStep,
      available_quantities_part1:
        state.card.allocation.multiStep.available_quantities_part1,
      available_quantities_part2:
        state.card.allocation.multiStep.available_quantities_part2,
      available_quantities_construction:
        state.card.allocation.multiStep.available_quantities_construction,
    }),
    (dispatch: any) => ({
      dispatch,
      editAllocatedData: (
        id: number,
        index: number,
        value: number,
        isDoubleCount: boolean,
        isDiesel: boolean
      ) =>
        dispatch(editAllocatedData(id, index, value, isDoubleCount, isDiesel)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(EditableTableView);
