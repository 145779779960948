export default {
  EN: {
    header_title: "Biofuel sustainability documentation",
    header_subtitle: "Issued by Preem Norge AS",
    infoBox1_title: "General information",
    infoBox1_customer: "Customer",
    infoBox1_product: "Product",
    infoBox1_quantity: "Quantity",
    infoBox1_m3: "m³",
    infoBox1_deliveryPeriod: "Delivery period",
    infoBox1_issued: "Issued",
    infoBox1_noteRef: "Note ref.",

    description_title: "Background",
    description_paragraph1:
      "The products covered by this declaration complies with the EC Renewable Energy Directive (RED) on the promotion of use of energy from renewable sources.",
    description_paragraph2:
      "GHG values in the document includes feedstock transportation, possible pre-treatment, biofuel production, product transportation to customer location and distribution. The biomass used to produce the products fulfills all sustainability criteria according to RED Article 17 and local Norwegian legislation.",
    description_paragraph3:
      "The production plant has been in operation on or before 5 October 2015.",

    table_product: "Product",
    table_volume: "m³ @ 15°C",
    table_rawMaterial: "Raw Material",
    table_country: "Country",
    table_doubleCount: "Advanced",
    table_ghgReduction: "GHG reduction",
    table_bonusFactorEb: "Bonus factor Eb",
    table_docBonusFactorEb: "Doc. bonus factor Eb",
    table_bonusFactorESCA: "Bonus factor ESCA",
    table_docBonusFactorESCA: "Doc. bonus factor ESCA",
    table_docClimaEmission: "Doc. of climate emission reduction",
    table_docLandCriteria: "Documentation of land criteria",
    table_docMassBalance: "Documentation of mass balance",

    table_total: "Total",
    table_noData: "No data",

    footer_address: "Address",
    footer_addressBox: "Box",
    footer_addressNorway: "Norway",
    footer_email: "E-mail",
    footer_web: "Web",
    footer_phone: "Phone",
    footer_customerSupport: "Customer Support",
    footer_side: "Page",
    footer_of: "of",
  },
  NO: {
    header_title: "Dokumentasjon for bærekraftig biodrivstoff",
    header_subtitle: "Utstedt av Preem Norge AS",
    infoBox1_title: "Generell informasjon",
    infoBox1_customer: "Kunde",
    infoBox1_product: "Produkt",
    infoBox1_quantity: "Volum",
    infoBox1_m3: "m³",
    infoBox1_deliveryPeriod: "Leveringsperiode",
    infoBox1_issued: "Utstedt",
    infoBox1_noteRef: "Dokumentreferanse",

    description_title: "Bakgrunn",
    description_paragraph1:
      "Produktene som er dekket av dette dokumentet er i overenstemmelse med EU sitt fornybardirektiv (RED).",
    description_paragraph2:
      "GHG-verdiene i tabellen under inkluderer transport av råvarer, eventuell forhåndsbehandling, produksjon av biodrivstoff, transport til kunde og distribusjon. Biomassen som er brukt for å produsere produktene oppfyller alle bærekraftskriterier slik dette er spesifisert i RED artikkel 17 og kapittel 3 i produktforskriften.",
    description_paragraph3:
      "Produksjonsanlegget har vært i drift siden før 5. oktober 2015.",

    table_product: "Produkt",
    table_volume: "m³ @ 15°C",
    table_rawMaterial: "Råstoff",
    table_country: "Land",
    table_doubleCount: "Avansert",
    table_ghgReduction: "GHG- reduksjon",
    table_bonusFactorEb: "Bonus- faktor Eb",
    table_docBonusFactorEb: "Dok. bonus- faktor Eb",
    table_bonusFactorESCA: "Bonus- faktor ESCA",
    table_docBonusFactorESCA: "Dok. bonus- faktor ESCA",
    table_docClimaEmission: "Dokumentasjon klimagassutslipp",
    table_docLandCriteria: "Dokumentasjon arealkriterier",
    table_docMassBalance: "Dokumentasjon massebalanse",

    table_total: "Totalt",
    table_noData: "Ingen data",

    footer_address: "Adresse",
    footer_addressBox: "Postboks",
    footer_addressNorway: "Norge",
    footer_email: "E-post",
    footer_web: "Web",
    footer_phone: "Telefon",
    footer_customerSupport: "Kundeservice",
    footer_side: "Side",
    footer_of: "av",
  },
};
