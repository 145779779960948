import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import AllocationContainer from "../../../../containers/sustainability/AllocationContainer";
import MultiStepContainer from "../../../../containers/sustainability/multiStep/MultiStepContainer";

const AllocationView: React.FC = () => {
  const match = useRouteMatch();
  if (match) {
    return (
      <Switch>
        <Route exact path={match.path} component={AllocationContainer}></Route>
        <Route
          path={`${match.path}/start`}
          component={MultiStepContainer}
        ></Route>
        <Redirect to={match.path} />
      </Switch>
    );
  } else {
    return null;
  }
};

export default AllocationView;
