/**
 *  Email sent confirmation
 */

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // maxWidth: "270px",
  },
  icon: {
    fontSize: "5rem",
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2.5),
    textAlign: "center",
    maxWidth: "270px",
  },
  body: {
    marginBottom: theme.spacing(7.875),
    textAlign: "center",
  },
  backLogInBtn: {
    height: "40px",
    fontSize: "12px",
    letterSpacing: "1px",
  },
}));

interface EmailSentProps {
  goBackToLogin: () => void;
}

const EmailSent: React.FC<EmailSentProps> = ({ goBackToLogin }) => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <CheckCircleOutlineIcon color="primary" className={classes.icon} />
      <div className={classes.title}>
        <Typography variant="h4">
          <FormattedMessage id={"loginView.emailSentTitle"} />
        </Typography>
      </div>
      <Typography className={classes.body} variant="body1">
        <FormattedMessage id={"loginView.emailSentDescription"} />
      </Typography>
      <Button
        onClick={goBackToLogin}
        color="primary"
        variant="contained"
        fullWidth
        className={classes.backLogInBtn}
      >
        <FormattedMessage id={"loginView.backToLogin"} />
      </Button>
    </div>
  );
};

export default EmailSent;
