import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import getCustomerReport from "../../../../../api/sustainability/customerReport/getCustomerReport";
import ButtonDownloadPDF from "./ButtonDownloadPDF";
import CircularProgress from "@material-ui/core/CircularProgress";
import ModalContainer from "../../../../../components/ModalContainer";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      minWidth: "420px",
      minHeight: "100px",
      display: "flex",
      flexDirection: "column",
    },
    buttons: {
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      zIndex: 10,
    },
    circularProgress: {
      position: "absolute",
      width: "48px",
      height: "48px",
      zIndex: 0,
    },
  })
);

interface ModalDownloadCustomerReportProps {
  open: boolean;
  onClose: () => void;
  paramData: any;
  extraData: any;
}

const ModalDownloadCustomerReport: React.FC<ModalDownloadCustomerReportProps> = ({
  open,
  onClose,
  paramData,
  extraData,
}) => {
  // [data, setData] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  //console.log("modaldcp" + JSON.stringify(extraData.CustomerPDFdata));

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={<FormattedMessage id="customerView.downloadReport" />}
    >
      <div className={classes.modalContent}>
        {isError ? (
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Typography align="center" variant="body1" color="error">
              <FormattedMessage id="customerRequirement.failed" />
            </Typography>
          </Box>
        ) : (
          <Box
            flex={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress className={classes.circularProgress} />
            {extraData.CustomerPDFdata && (
              <Box flex={1} display="flex" className={classes.buttons}>
                <ButtonDownloadPDF
                  language={extraData.langu === "English" ? "EN" : "NO"}
                  data={extraData.CustomerPDFdata.data}
                  version={extraData.version}
                ></ButtonDownloadPDF>
              </Box>
            )}
          </Box>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalDownloadCustomerReport;
