import { FormattedMessage } from "react-intl";
import React from "react";
export default [
  {
    name: <FormattedMessage id="settingsView.index.exciseDutyRate.taxCode" />,
    id: "tax_code",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="settingsView.index.exciseDutyRate.description" />
    ),
    id: "description",
    type: "text",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.exciseDutyRate.rate" />,
    id: "rate",
    type: "text",
    decimals_format: 2,
  },
  {
    name: <FormattedMessage id="settingsView.index.exciseDutyRate.startDate" />,
    id: "start_date",
    type: "date",
  },
  {
    name: <FormattedMessage id="settingsView.index.exciseDutyRate.endDate" />,
    id: "end_date",
    type: "date",
  },
];
