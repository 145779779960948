/**
 * Delete a file of excise duty reporting
 * accepts both transaction/excise duty report file
 */

import axios, { AxiosRequestConfig } from "axios";
import { getToken } from "../utils";
import { BASE_URL } from "../endpoints";

interface IdeleteEDFile {
  id: number;
  type: string; // "transaction" | "report";
}

const deleteEDFile = async ({
  id,
  type,
}: IdeleteEDFile): Promise<{ success: boolean } | null> => {
  const token = getToken();
  if (token) {
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token,
      },
    };
    try {
      const res = await axios.delete(`${BASE_URL}/api/${type}/${id}`, config);
      if (res.status === 200) {
        return { success: res.data.success };
      } else {
        return { success: false };
      }
    } catch {
      return { success: false };
    }
  } else {
    return null; // No token
  }
};

export default deleteEDFile;
