/**
 *  User Login page
 */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

// sub components of this page
import Welcome from "./Welcome";
import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import EmailSent from "./EmailSent";

// styles
const useStyles = makeStyles((theme: Theme) => ({
  // styles for the whole page
  root: {
    display: "flex",
    height: "100vh",
  },
  // styles for the left panel
  leftPanelContainer: {
    flex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.spacing(2),
    }, 
  },

  // styles for the right panel
  rightPanel: {
    flex: 7,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: theme.spacing(2),
  },
  loginContent: {
    maxWidth: "330px",
  },
}));

type TypeDisplayForm = "Login" | "ForgotPassword" | "EmailSent";

interface LoginViewProps {
  history: any; // React router
}

const LoginView: React.FC<LoginViewProps> = ({ history }) => {
  const [displayForm, setDisplayForm] = useState<TypeDisplayForm>("Login");
  const classes = useStyles();

  const goBackToLogin = () => {
    setDisplayForm("Login");
  };
  const goToForgotPassword = () => {
    setDisplayForm("ForgotPassword");
  };

  const goToEmailSent = () => {
    setDisplayForm("EmailSent");
  };

  return (
    <div className={classes.root}>
      <div className={classes.leftPanelContainer}>
        <Welcome />
      </div>
      <Box
        bgcolor="text.primary"
        color="common.white"
        className={classes.rightPanel}
      >
        <div className={classes.loginContent}>
          {displayForm === "Login" && (
            <LoginForm
              goToForgotPassword={goToForgotPassword}
              history={history}
            />
          )}
          {displayForm === "ForgotPassword" && (
            <ForgotPasswordForm
              goBackToLogin={goBackToLogin}
              goToEmailSent={goToEmailSent}
            />
          )}
          {displayForm === "EmailSent" && (
            <EmailSent goBackToLogin={goBackToLogin} />
          )}
        </div>
      </Box>
    </div>
  );
};

export default LoginView;
