import React, { Fragment } from "react";
import clsx from "clsx";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import CustomerReqPanel from "./CustomerReqPanel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    btnContainer: {
      marginTop: theme.spacing(4),
    },
    btn: {
      paddingTop: theme.spacing(1.2),
      paddingBottom: theme.spacing(1.2),
      minWidth: "160px",
      "&:first-child": {
        marginRight: theme.spacing(2),
      },
    },
    btnDark: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
    },
  })
);

interface CustomerRequirementsProps {
  stepData: any;
  allocateUpdateCustomerReq: any;
  handleNext: () => void;
  handleBack: (() => void) | null;
}

const CustomerRequirements: React.FC<CustomerRequirementsProps> = ({
  stepData,
  allocateUpdateCustomerReq,
  handleBack,
  handleNext,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {Array.isArray(stepData.data) &&
        stepData.data.map((customer: any, index: any) => (
          <Fragment key={index}>
            <CustomerReqPanel
              stepData={stepData}
              data={customer}
              customer={{ id: customer.id, index: index }}
              allocateUpdateCustomerReq={allocateUpdateCustomerReq}
            />
          </Fragment>
        ))}

      <Grid
        container
        alignItems="center"
        justify="flex-end"
        className={classes.btnContainer}
      >
        <Grid item>
          {handleBack && (
            <ButtonRounded
              variant="contained"
              className={clsx(classes.btn, classes.btnDark)}
              onClick={handleBack}
            >
              Back
            </ButtonRounded>
          )}
          <ButtonRounded
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleNext}
          >
            Next
          </ButtonRounded>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerRequirements;
