import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { element } from "prop-types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.text.primary,
      userSelect: "none",
      cursor: "pointer",
      borderRadius: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        minWidth: "40px",
      },
    },
    monthshape: {
      padding: theme.spacing(0.75, 1.1),
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0.65, 0.65),
      },
    },
    monthtext: {
      lineHeight: "1",
      paddingLeft: theme.spacing(0.75),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(0.2),
        fontSize: "12px",
      },
    },
    rootSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    circle: {
      width: "12px",
      height: "12px",
      borderRadius: "100%",
      [theme.breakpoints.down("md")]: {
        width: "10px",
        height: "10px",
      },
    },
    circleSelected: {
      backgroundColor: theme.palette.common.white,
    },
    circleUnselectedEmpty: {
      backgroundColor: theme.palette.grey[900],
    },
    circleUnselectedFilled: {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

interface MonthButtonProps {
  month: string | React.ReactElement;
  index: number;
  selected: boolean;
  handleSelect: (index: number) => void;
  filled: boolean;
}
const MonthButton: React.FC<MonthButtonProps> = ({
  month,
  index,
  selected,
  handleSelect,
  filled,
}) => {
  const classes = useStyles();

  const handleClick = () => handleSelect(index);

  return (
    <div
      className={clsx(classes.root, { [classes.rootSelected]: selected })}
      onClick={handleClick}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.monthshape}
      >
        <Grid item>
          <div
            className={clsx(classes.circle, {
              [classes.circleUnselectedFilled]: !selected && filled,
              [classes.circleUnselectedEmpty]: !selected && !filled,
              [classes.circleSelected]: selected,
            })}
          ></div>
        </Grid>
        <Grid item>
          <Typography variant="body2" className={classes.monthtext}>
            {month}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default MonthButton;
