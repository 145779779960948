import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import ButtonRounded from "../../../../components/ButtonRounded";
import ModalContainer from "../../../../components/ModalContainer";
import deleteCollab from "../../../../api/sustainability/allocation/collaboration/delete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    userContainer: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 0),
    },
    btnCancel: {},
  })
);

interface ModalDeleteProps {
  open: boolean;
  onClose: () => void;
  id: number;
}

const ModalDeleteCollab: React.FC<ModalDeleteProps> = ({
  open,
  onClose,
  id,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const handleDelete = async () => {
    setIsError(false);
    const res = await deleteCollab(id);

    if (res && res.success) {
      onClose();
    } else {
      setIsError(true);
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title="Are you sure you want to delete this Collaboration?"
    >
      <div className={classes.modalContent}>
        <Grid container spacing={2} className={classes.btnContainer}>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="outlined"
              onClick={onClose}
              color="secondary"
            >
              Cancle
            </ButtonRounded>
          </Grid>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={isError}
            >
              Delete
            </ButtonRounded>
          </Grid>
        </Grid>
        {isError && (
          <Typography align="center" variant="body2" color="error">
            Failed to delete this collaboration.
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalDeleteCollab;
