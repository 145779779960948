export const customerTableData = {
  columns: [
    "Product",
    "Volume",
    "Raw material",
    "Country",
    "CO2 red.",
    "Advanced",
  ],
  // sample data
  rows: [
    ["FAME", "30 019 liter", "Raps", "Russland", "53,51%", "Nei"],
    ["FAME", "60 029 liter", "Raps", "Litauen", "53,51%", "Nei"],
    [
      "HVO/HRO",
      "59 591 liter",
      "Animalske bi-produkter I kategori og ||",
      "De Forente Stater",
      "73,00%",
      "Ja",
    ],
    ["FAME", "30 019 liter", "Raps", "Russland", "53,51%", "Nei"],
    ["FAME", "60 029 liter", "Raps", "Litauen", "53,51%", "Nei"],
    [
      "HVO/HRO",
      "59 591 liter",
      "Animalske bi-produkter I kategori og ||",
      "De Forente Stater",
      "73,00%",
      "Ja",
    ],
    ["FAME", "30 019 liter", "Raps", "Russland", "53,51%", "Nei"],
    ["FAME", "60 029 liter", "Raps", "Litauen", "53,51%", "Nei"],
    [
      "HVO/HRO",
      "59 591 liter",
      "Animalske bi-produkter I kategori og ||",
      "De Forente Stater",
      "73,00%",
      "Ja",
    ],
  ],
};

export const posTableData = {
  columns: [
    "Product",
    "m³ @ 15°C",
    "Raw material",
    "Country",
    "Advanced",
    "GHG reduction",
    "Bonus factor Eb",
    "Doc. bonus factor Eb",
    "Bonus factor ESCA",
    "Doc. bonus factor ESCA",
    "Doc. of climate emission reduction",
    "Documentation of land criteria",
    "Documentation of mass balance",
  ],
  // sample data
  rows: [
    [
      "HVO/HRO",
      "20 500 000,00",
      "Animalske bi-produkter",
      "De Forente Stater",
      "Ja",
      "77,49%",
      "41 000 000,00",
      "Nei",
      "Nei",
      "Egen dokumentasjon",
      "Egen dokumentasjon",
      "Egen dokumentasjon",
      "DGD",
    ],
  ],
};
