import React from "react";
import { useRouteMatch, useParams, Redirect } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FilesTable from "../../../components/FilesTable";
import ButtonArrowBack from "../../../components/ButtonArrowBack";
import DropZoneCard from "../../../components/DropZoneCard";
import SettingsCard from "../SettingsView/SettingsCard";
import { FormattedMessage } from "react-intl";

const cards = [
  {
    title: <FormattedMessage id="documentDatabaseView.index.governigDoc" />,
    path: "/governing-docs",
  },
  {
    title: <FormattedMessage id="documentDatabaseView.index.processDes" />,
    path: "/process-descriptions",
  },
  {
    title: <FormattedMessage id="documentDatabaseView.index.supportingDoc" />,
    path: "/supporting-docs",
  },
  {
    title: <FormattedMessage id="documentDatabaseView.index.templates" />,
    path: "/templates",
  },
  {
    title: <FormattedMessage id="documentDatabaseView.index.reportArchive" />,
    path: "/report-archive",
  },
];

const fileList: any = [];

for (let i = 0; i < 10; i++) {
  fileList.push({
    id: i,
    file_name: `Document-Name.pdf`,
    created_at: "23.02.2018",
    file_path: "/server/file/${i}",
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    buttonArrowBack: {
      marginLeft: theme.spacing(-2),
    },
    subPageTitle: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    filelistContainer: {
      marginTop: theme.spacing(2),
    },
  })
);

interface MainViewProps {
  pageType: "main" | "sub";
}

const MainView: React.FC<MainViewProps> = ({ pageType }) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { id } = useParams();

  const isMainPage = pageType === "main";
  const isSubPage = pageType === "sub";

  const allSubPagesURL = cards.map(item => item.path.replace("/", ""));

  const isValidSubpage = id && allSubPagesURL.indexOf(id) > -1;
  const subPageTitle =
    id && isValidSubpage
      ? cards.filter(c => c.path.indexOf(id) > -1)[0].title
      : "";

  if (match) {
    if (isSubPage && !isValidSubpage) {
      return <Redirect to="/document-database" />;
    }

    return (
      <div>
        {isSubPage && (
          <div>
            <ButtonArrowBack className={classes.buttonArrowBack} />
            <Typography variant="h2" className={classes.subPageTitle}>
              {subPageTitle}
            </Typography>
            <DropZoneCard
              title={<FormattedMessage id="allView.newDocument" />}
              handleFiles={() => {}}
              disableTitleMinHeight
            />
          </div>
        )}
        {isMainPage && (
          <Grid container spacing={2}>
            {cards.map((card, index) => (
              <Grid item xs key={index}>
                <SettingsCard
                  title={card.title}
                  path={`${match.path}${card.path}`}
                  smallSize
                />
              </Grid>
            ))}
          </Grid>
        )}
        <div className={classes.filelistContainer}>
          <FilesTable
            title={
              isMainPage ? (
                <FormattedMessage id="allView.allDocuments" />
              ) : (
                <FormattedMessage id="allView.documents" />
              )
            }
            fileList={fileList}
            handleDeleteFile={() => {}}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default MainView;
