/**
 *  Dashboard main page
 */
import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Box from "@material-ui/core/Box";

import { Doughnut, Bar, Line } from "react-chartjs-2";
import Status from "./Status";
import { lineData } from "./sample-data";
import YearSelect from "./YearSelect";

import LegendLabels from "../../../components/LegendLabels";
import parseData from "../../../components/texts/parseData";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    section: {
      marginBottom: theme.spacing(3),
      "&:last-child": {
        marginBottom: theme.spacing(0),
      },
    },
    card: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      margin: theme.spacing(2, 2, 1, 2),
    },
    verticalBarContainer: {
      display: "flex",
      alignItems: "center",
    },
    verticalBar: {
      display: "inline-block",
      backgroundImage:
        "linear-gradient(to bottom, rgba(255, 172, 18, 0.5), #ffac12)",
      marginRight: theme.spacing(2),
      height: theme.spacing(4),
      width: theme.spacing(0.5),
    },
    title: {
      fontWeight: "normal",
    },
    greyText: {
      marginLeft: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    marginBottom: {
      marginBottom: theme.spacing(3),
    },
    volumeCardContainer: {
      display: "flex",
    },
    volumeCardLeft: { flex: 3, marginRight: theme.spacing(3) },
    volumeCardRight: {
      flex: 2,
      display: "flex",
      alignItems: "stretch",
    },
    chartsCardContainer: {
      display: "flex",
    },
    chartContainer: {
      paddingTop: theme.spacing(2),
    },
    chartCardLeft: { flex: 2, marginRight: theme.spacing(3) },
    chartCardRight: { flex: 3 },
  })
);

interface MainViewProps {
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  data: any;
  getDashboardData: () => void;
}

const MainView: React.FC<MainViewProps> = ({
  taxPeriods,
  selectedYear,
  setSelectedYear,
  data,
  getDashboardData,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getDashboardData();
  }, [selectedYear]);

  const sumSusStorage =
    data.sustainability_storage.biodiesel_single_count +
    data.sustainability_storage.biodiesel_double_count +
    data.sustainability_storage.bioethanol_single_count +
    data.sustainability_storage.bioethanol_double_count;

  const barData = (canvas: any) => {
    const ctx = canvas.getContext("2d");
    var gradientGreen = ctx.createLinearGradient(0, 0, 0, 200);
    gradientGreen.addColorStop(0, "rgba(1,130,59, 0.25)");
    gradientGreen.addColorStop(1, "rgba(1,130,59, 1)");
    var gradientBlue = ctx.createLinearGradient(0, 0, 0, 200);
    gradientBlue.addColorStop(0, "rgba(0,137,156,0.25)");
    gradientBlue.addColorStop(1, "rgba(0,137,156,1)");

    return {
      labels: [
        "Bio i diesel (ET)",
        "Bio i diesel (DT)",
        "Bio i bensin (ET)",
        "Bio i bensin (DT)",
      ],
      datasets: [
        {
          label: "Lager",
          barPercentage: 0.8,
          categoryPercentage: 0.4,
          backgroundColor: [
            gradientGreen,
            gradientGreen,
            gradientBlue,
            gradientBlue,
          ],
          borderWidth: 0,
          data: [
            data.sustainability_storage.biodiesel_single_count,
            data.sustainability_storage.biodiesel_double_count,
            data.sustainability_storage.bioethanol_single_count,
            data.sustainability_storage.bioethanol_double_count,
          ],
        },
        {
          label: "Omsatt",
          barPercentage: 0.8,
          categoryPercentage: 0.4,
          backgroundColor: [
            "rgba(1,130,59, 0.25)",
            "rgba(1,130,59, 0.25)",
            "rgba(0,137,156,0.25)",
            "rgba(0,137,156,0.25)",
          ],
          borderColor: ["#28823C", "#28823C", "#00899C", "#00899C"],
          borderWidth: 2,
          data: [
            data.delivered_volumes.biodiesel_single,
            data.delivered_volumes.biodiesel_double,
            data.delivered_volumes.bioethanol_single,
            data.delivered_volumes.bioethanol_double,
          ],
        },
      ],
    };
  };

  const getDateInterval = () => {
    const monthFrom = moment().startOf("year").format("MMM");
    const monthTo =
      moment().format("YYYY") === selectedYear.toString()
        ? moment().format("MMM")
        : moment().endOf("year").format("MMM");
    return (
      <Typography variant="body2" color="primary">
        {monthFrom + " " + selectedYear + " - " + monthTo + " " + selectedYear}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <Card className={classes.section}>
        <CardContent className={classes.card}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography className={classes.title} variant="h5">
                <FormattedMessage id="dashboardView.mainView.box1.bioMandate" />
              </Typography>
            </Grid>
            <Grid item className={classes.verticalBarContainer}>
              <div className={classes.verticalBar} />
              <div>
                <Typography variant="h5" component="span">
                  {parseData(data.requirements.gen_req_percentage)} %
                </Typography>
                <Typography
                  variant="caption"
                  component="span"
                  className={classes.greyText}
                >
                  <FormattedMessage id="dashboardView.mainView.box1.caption.mainReq" />
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.verticalBarContainer}>
              <div className={classes.verticalBar} />
              <div>
                <Typography variant="h5" component="span">
                  {parseData(data.requirements.gasoline_percentage)} %
                </Typography>
                <Typography
                  variant="caption"
                  component="span"
                  className={classes.greyText}
                >
                  <FormattedMessage id="dashboardView.mainView.box1.caption.subReqGasoline" />
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.verticalBarContainer}>
              <div className={classes.verticalBar} />
              <div>
                <Typography variant="h5" component="span">
                  {parseData(data.requirements.adv_biofuel_percentage, 2)} %
                </Typography>
                <Typography
                  variant="caption"
                  component="span"
                  className={classes.greyText}
                >
                  <FormattedMessage id="dashboardView.mainView.box1.caption.subReqAdvanced" />
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <YearSelect
                taxPeriods={taxPeriods}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <div className={clsx(classes.section, classes.volumeCardContainer)}>
        <Card className={classes.volumeCardLeft}>
          <CardContent className={classes.card}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.marginBottom}
            >
              <Grid item>
                <Typography className={classes.title} variant="h5">
                  <FormattedMessage id="dashboardView.mainView.box2.volumeStat" />
                </Typography>
              </Grid>
              <Grid item>{getDateInterval()}</Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs>
                <Status
                  title={`${parseData(data.volume_status.biofuel_fulfillment)}`}
                  subtitle={
                    <FormattedMessage id="dashboardView.mainView.box2.caption.fulfilAboveMandate" />
                  }
                  status={data.volume_status.biofuel_fulfillment >= 0}
                />
              </Grid>
              <Grid item xs>
                <Status
                  title={`${parseData(
                    data.volume_status.bio_percentage_l,
                    2
                  )} %`}
                  subtitle={
                    <FormattedMessage id="dashboardView.mainView.box2.caption.bioShareL15" />
                  }
                  status={data.volume_status.bio_percentage_l >= 0}
                />
              </Grid>
              <Grid item xs>
                <Status
                  title={`${parseData(
                    data.volume_status.bio_percentage_le,
                    2
                  )} %`}
                  subtitle={
                    <FormattedMessage id="dashboardView.mainView.box2.caption.bioShareLE" />
                  }
                  status={data.volume_status.bio_percentage_le >= 0}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card className={classes.volumeCardRight}>
          <CardContent className={classes.card}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              className={classes.marginBottom}
            >
              <Grid item>
                <Typography className={classes.title} variant="h5">
                  <FormattedMessage id="dashboardView.mainView.box3.EstimatedAnnualCalc" />
                </Typography>
              </Grid>
              <Grid item>{getDateInterval()}</Grid>
            </Grid>

            <Status
              title={`${parseData(data.tax_status.net_tax)}`}
              subtitle={
                <FormattedMessage id="dashboardView.mainView.box3.caption.roadTax" />
              }
              status={data.tax_status.net_tax >= 0}
            />
          </CardContent>
        </Card>
      </div>

      <div className={clsx(classes.section, classes.chartsCardContainer)}>
        <Card className={classes.chartCardLeft}>
          <CardContent className={classes.card}>
            <Typography className={classes.title} variant="h5">
              <FormattedMessage id="dashboardView.mainView.box4.susStorage" />
            </Typography>
            <div className={classes.chartContainer}>
              <Doughnut
                data={{
                  labels: ["BD ET", "BD DT", "BT ET", "BT DT"],
                  datasets: [
                    {
                      data: [
                        (100 *
                          data.sustainability_storage.biodiesel_single_count) /
                          sumSusStorage,
                        (100 *
                          data.sustainability_storage.biodiesel_double_count) /
                          sumSusStorage,
                        (100 *
                          data.sustainability_storage.bioethanol_single_count) /
                          sumSusStorage,
                        (100 *
                          data.sustainability_storage.bioethanol_double_count) /
                          sumSusStorage,
                      ],
                      backgroundColor: [
                        "#7FC4CD",
                        "#FEAE46",
                        "#00899C",
                        "#80C09D",
                      ],
                      borderWidth: 0,
                    },
                  ],
                }}
                options={{
                  cutoutPercentage: 25,
                  legend: { position: "left" },
                  tooltips: {
                    xPadding: 15,
                    callbacks: {
                      label: (tooltipItem: any, data: any) => {
                        var label =
                          parseData(data.datasets[0].data[tooltipItem.index]) +
                          " %";
                        return label;
                      },
                    },
                    displayColors: false,
                  },
                }}
                height={170}
              />
            </div>
          </CardContent>
        </Card>
        <Card className={classes.chartCardRight}>
          <CardContent className={classes.card}>
            <Box
              display="flex"
              alignItems="center"
              className={classes.marginBottom}
            >
              <Box flexGrow={1}>
                <Typography className={classes.title} variant="h5">
                  <FormattedMessage id="dashboardView.mainView.box5.turnAndStor" />
                </Typography>
              </Box>
              <LegendLabels
                labelsList={[
                  {
                    text: (
                      <FormattedMessage id="dashboardView.mainView.box5.caption.storage" />
                    ),
                    background:
                      "linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.7)",
                  },
                  {
                    text: (
                      <FormattedMessage id="dashboardView.mainView.box5.caption.turnover" />
                    ),
                    background: "rgba(0,0,0,0.2)",
                    borderColor: "rgba(0,0,0,1)",
                  },
                ]}
              />
              <YearSelect
                taxPeriods={taxPeriods}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
            </Box>
            <div className={classes.chartContainer}>
              <Bar
                data={barData}
                height={100}
                options={{
                  elements: {
                    rectangle: {
                      borderSkipped: "none",
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        ticks: {
                          fontSize: 12,
                          fontColor: "#a1aeb7",
                        },
                        gridLines: {
                          display: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          padding: 15,
                          fontSize: 12,
                          fontColor: "#a1aeb7",
                          beginAtZero: true,
                          maxTicksLimit: 8,
                          callback: (value: number, index: number) => {
                            return parseData(value);
                          },
                        },
                        gridLines: {
                          color: "#f2f5f7",
                          zeroLineColor: "#f2f5f7",
                          drawBorder: false,
                        },
                      },
                    ],
                  },
                  legend: { display: false },
                  tooltips: {
                    xPadding: 15,
                    callbacks: {
                      title: () => "",
                      label: (tooltipItem: any) => {
                        var label = parseData(tooltipItem.yLabel) + " (L)";
                        return label;
                      },
                    },
                    displayColors: false,
                  },
                }}
              />
            </div>
          </CardContent>
        </Card>
      </div>

      <Card className={classes.section}>
        <CardContent className={classes.card}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.marginBottom}
          >
            <Box flexGrow={1}>
              <Typography className={classes.title} variant="h5">
                <FormattedMessage id="dashboardView.mainView.box6.soldBioVol" />
              </Typography>
            </Box>
            <LegendLabels
              labelsList={[
                {
                  text: (
                    <FormattedMessage id="dashboardView.mainView.box6.caption.totBioVol" />
                  ),
                  background: "rgba(255,172,18, 0.5)",
                  borderColor: "rgba(255,172,18, 1)",
                },
                {
                  text: (
                    <FormattedMessage id="dashboardView.mainView.box6.caption.bioInDies" />
                  ),
                  background: "rgba(1,130,59, 0.5)",
                  borderColor: "rgba(1,130,59, 1)",
                },
                {
                  text: (
                    <FormattedMessage id="dashboardView.mainView.box6.caption.bioInGaso" />
                  ),
                  background: "rgba(0,137,156,0.5)",
                  borderColor: "rgba(0,137,156,1)",
                },
              ]}
            />
            <YearSelect
              taxPeriods={taxPeriods}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </Box>

          <div className={classes.chartContainer}>
            <Line
              data={lineData}
              height={65}
              options={{
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        padding: 15,
                        fontSize: 13,
                        fontColor: "#a1aeb7",
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                  yAxes: [
                    {
                      ticks: {
                        padding: 15,
                        fontSize: 13,
                        fontColor: "#a1aeb7",
                        maxTicksLimit: 6,
                        callback: (value: number, index: number) => {
                          return Math.round(value / 1000000) + "M";
                        },
                      },
                      gridLines: {
                        display: false,
                      },
                    },
                  ],
                },
                legend: { display: false },
                tooltips: {
                  xPadding: 15,
                  mode: "nearest",
                  callbacks: {
                    title: () => "",
                    label: (tooltipItem: any) => {
                      var label = parseData(tooltipItem.yLabel) + " (L)";
                      return label;
                    },
                  },
                  displayColors: false,
                },
              }}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default MainView;
