import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../common/payloads/ErrorActionPayload";

// State Interface
export interface NavigationState {
  isRequesting: boolean;
  error?: any;
}

// Initial state
const initialState = {
  isRequesting: false,
  error: undefined,
} as NavigationState;

// Actions
const NAVIGATION_REQUEST = "navigation/REQUEST";
const NAVIGATION_ERROR = "navigation/ERROR";
const NAVIGATION_SUCCESS = "navigation/SUCCESS";

// Action creators
export function requestOuterBody() {
  return {
    type: NAVIGATION_REQUEST,
  };
}

export function errorOuterBody(error: any) {
  return {
    type: NAVIGATION_ERROR,
    payload: { error },
  };
}

export function receiveOuterBody(data: any) {
  return {
    type: NAVIGATION_SUCCESS,
    payload: data,
  };
}

// Reducer Definition
export default handleActions<NavigationState>(
  {
    [NAVIGATION_REQUEST]: handleRequest,
    [NAVIGATION_ERROR]: handleError,
    [NAVIGATION_SUCCESS]: handleSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleRequest(state: NavigationState) {
  return {
    ...state,
    isRequesting: true,
    error: undefined as any,
  };
}

function handleError(state: NavigationState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isRequesting: false,
    error: action.payload.error,
  };
}

function handleSuccess(state: NavigationState, action: Action<any>) {
  return {
    ...state,
    isRequesting: false,
    error: undefined as any,
  };
}
