/**
 * add/edit a customer
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

interface IAddEditCustomer {
  mode: "add" | "edit";
  customer: {
    id?: number;
    name: string;
    organization_number: number;
  };
}

const addEditCustomer = async ({ mode, customer }: IAddEditCustomer) => {
  const token = getToken();
  const urlSuffix = mode === "edit" ? `/${customer.id}` : "";

  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/customers${urlSuffix}`, {
        method: mode === "add" ? "POST" : "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          name: customer.name,
          organization_number: customer.organization_number,
        }),
      });

      if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return data.success;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default addEditCustomer;
