import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../../../../components/ButtonRounded";
import ButtonArrowBack from "../../../../../components/ButtonArrowBack";
import ExtendableList from "../../../../../components/ExtendableList";
import { Card, CardContent, Typography} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import CustomerRequirementProperties from "./CustomerRequirementProperties";
import getCustomerSoldProducts from "../../../../../api/card/customerSoldProducts/getCustomerSoldProducts";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      "&:not(:first-child)": {
        marginLeft: "20px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "10px",
        },
      },
    },
    select: {
      width: "150px",
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
    root: {
      paddingLeft: theme.spacing(3.75),
      paddingRight: theme.spacing(3.75),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    iconDelete: {
      // color: theme.palette.error.main,
      marginLeft: "20px",
    },
    iconEdit: {
      marginLeft: "30px",
    },
  })
);

interface ICustomerRequirementsViewProps {
  selected_customer: any;
  setShowModalDownloadReport: (show: boolean) => void;
  selectedYear: any;
  setSelectedYear: any;
  taxPeriods: any;
}

const CustomerRequirementView: React.FC<ICustomerRequirementsViewProps> = ({
  selected_customer,
  setShowModalDownloadReport,
  setSelectedYear,
  taxPeriods,
  selectedYear,
}) => {
  const classes = useStyles();
  const [list, setList] = useState<any[]>([]);
  const [filteredList, setFilteredList] = useState<any[]>(list);

  const filterRequirements = () => {
    setFilteredList(
      list.filter((item: any) => item.tax_year.includes(selectedYear))
    );
  };

  useEffect(() => {
    fetchCustomerSoldProducts();
    filterRequirements();
  }, [selectedYear]);

  const fetchCustomerSoldProducts = async () => {
    //const res = await getStorages();
    const res = await getCustomerSoldProducts(selected_customer.id);
    if (res && res.valid) {
      setList(res.data);
      setFilteredList(
        res.data.filter((item: any) => item.tax_year.includes(selectedYear))
      );
    }
  };

  return (
    <div>
      <Grid container justify="space-between" className={classes.titleBar}>
        <Grid item>
          <ButtonArrowBack />
        </Grid>
        <Grid item>
          <ButtonRounded
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={() => setShowModalDownloadReport(true)}
          >
            <FormattedMessage id="customerView.downloadReport" />
          </ButtonRounded>
        </Grid>
      </Grid>
      {/* select year */}
      <Card>
        <CardContent className={classes.root}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.titleBar}
          >
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              item
              xs="auto"
              sm={8}
            >
              <Grid item>
                <Typography variant="h5">{selected_customer.name}</Typography>
                <Typography variant="subtitle1">
                  {"Customer nr. " + selected_customer.customer_no}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs="auto" sm="auto">
              <Select
                fullWidth
                className={classes.select}
                input={<OutlinedInput labelWidth={0} />}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                {taxPeriods
                  ? taxPeriods.map((year: any, index: number) => (
                      <MenuItem
                        value={Object.keys(year)[0]}
                        key={
                          "Sus.View_Reports_Year_" +
                          Object.keys(year)[0] +
                          "-" +
                          index
                        }
                      >
                        {Object.keys(year)[0]}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>
          </Grid>
          <ExtendableList
           // need a key property here??
            expandProperties={CustomerRequirementProperties}
            list={filteredList}
            action={{
              deleteEntry: null,
              editEntry: null,
            }}
          />
        </CardContent>
      </Card>

    </div>
  );
};

export default CustomerRequirementView;
