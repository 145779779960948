import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import Box from "@material-ui/core/Box";
import ButtonRounded from "../ButtonRounded";
import ModalContainer from "../ModalContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { FormattedMessage } from "react-intl";
import SmartSelect from "../../components/SmartSelect";
import { Form, Field } from "react-final-form";
import Select, { SelectProps } from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import Button from "@material-ui/core/Button";

import postBkvUnMapData from "../../api/settings/bkvMappings/postBkvUnMapData";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContent: {},
        marginTop: {
            paddingTop: theme.spacing(2),
        },
        btnContainer: {
            paddingTop: theme.spacing(6),
        },
        iconDelete: {
            color: theme.palette.error.main,
        },
        marginBottom: {
            "margin-bottom": theme.spacing(2),
        },
        select: {
            width: "150px",
            [theme.breakpoints.down("md")]: {
                width: "100px",
            },
        },
        userContainer: {
            padding: theme.spacing(2),
        },
        btnCancel: {},
        btnNewLine: {
            marginTop: theme.spacing(2),
            textTransform: "none",
        },
        addNewLine: {
            letterSpacing: "0.0",
            fontWeight: 500,
        },
        table: {
            // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
            //   backgroundColor: theme.palette.grey[50],
            // },
            "& .MuiTableBody-root .MuiTableRow-root": {
                backgroundColor: theme.palette.grey[50],
            },
            "& .MuiTableCell-root": {
                border: "none",
                padding: theme.spacing(0.5, 1),
                "&:first-child": {
                    paddingLeft: theme.spacing(2.5),
                },
            },
            "& .MuiTableCell-head": {
                color: "inherit",
                ...theme.typography.body1,
                fontWeight: "bold",
                paddingBottom: theme.spacing(1),
            },
            "& .MuiIconButton-root": {
                paddingLeft: theme.spacing(0.5),
            },
        },
        fixedWidth: {
            width: theme.spacing(22),
        },
    })
);

const BIOFUEL_TYPE = 0;
const RAW_METARIAL = 1;
const RAW_METARIAL_ORIGIN = 2;

export interface IModalMappedProps {
    open: boolean;
    title: any;
    initVariable: any;
    handleSubmit: () => void;
    onClose: () => void;
}

const ModalMapped: React.FC<any> = ({open, title, initVariable, handleSubmit, onClose }) => {
    const classes = useStyles();
    const [isError, setIsError] = useState<boolean>(false);
    const [biofuelTypeData, setBiofuelTypeData] = useState<any>([]);
    const [rawMaterialData, setRawMaterialData] = useState<any>([]);
    const [rawMaterialOriginData, setRawMaterialOriginData] = useState<any>([]);



    const onSubmit = async () => {
        return false;
    };

    const setData = (initVariable: any) => {

        let biofuelType: any[] = [];
        let rawMaterial: any[] = [];
        let rawMaterialOrigin: any[] = [];

        initVariable.biofuel_type.map((data: any, idx: any) =>{
            biofuelType.push(
                    {
                        value: data,
                        translation: "",
                        translation_type: BIOFUEL_TYPE,
                    }
            )
        })

        initVariable.raw_material.map((data: any, idx: any) =>{
                rawMaterial.push(
                        {
                            value: data,
                            translation: "",
                            translation_type: RAW_METARIAL,
                        }
                    )
            })

        initVariable.raw_material_origin.map((data: any, idx: any) =>{
            rawMaterialOrigin.push(
                    {
                        value: data,
                        translation: "",
                        translation_type: RAW_METARIAL_ORIGIN,
                    }
                )
        })

        setBiofuelTypeData(biofuelType);
        setRawMaterialData(rawMaterial);
        setRawMaterialOriginData(rawMaterialOrigin);

    };

    const handleProductMappingSubmit = async (values: any) => {
        setIsError(false);
        const res = await postBkvUnMapData({
            values,
        });
        if (res && res.success) {
            onClose();
        } else {
            setIsError(true);
        }
    };

    // basic validations
    const validateRequired = (value: any) => (value ? undefined : "Required");
    const validateRequiredArray = (value: any) =>
        value && value.length > 0 ? undefined : "Required";

    useEffect(() => {
        if (open) {
            setIsError(false);
            setData(initVariable);
        }
    }, [open, initVariable]);
    // @ts-ignore
    return (
        <ModalContainer
            open={open}
            onClose={onClose}
            title={
                initVariable ? (
                    <FormattedMessage id="settingsView.modalAddEdit.title.edit" />
                ) : (
                    <FormattedMessage id="settingsView.modalAddEdit.title.addNew" />
                )
            }
            subtitle={title}
            maxWidth="md"
        >
            <Form
            onSubmit={handleProductMappingSubmit}
            mutators={{ ...arrayMutators }}
            initialValues={{
                biofuel_type : biofuelTypeData,
                raw_material : rawMaterialData,
                raw_material_origin : rawMaterialOriginData,
            }}
        >
            {({
                  handleSubmit,
                  submitting,
                  form: {
                      mutators: { push },
                  },
              }) => (
                <form onSubmit={handleSubmit}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="HBL" />{" "}
                                </TableCell>
                                <TableCell></TableCell>

                                <TableCell>
                                    <FormattedMessage id="BKV" />
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <FieldArray name="biofuel_type" >
                                {({ fields }) =>
                                    fields.map((name, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    <Field
                                                        name={`${name}.value`}
                                                        
                                                    >
                                                        {props => (
                                                            <FormattedMessage id="settingsView.users.modalProductMapping.col2.title">
                                                                {fmsg => (
                                                                    <TextField
                                                                        {...props.input}
                                                                        variant="outlined"
                                                                        placeholder={fmsg as string}
                                                                        className={classes.fixedWidth}
                                                                    />
                                                                )}
                                                            </FormattedMessage>
                                                        )}
                                                    </Field>
                                                </TableCell>
                                                <TableCell>
                                                    <ArrowRightAltIcon
                                                        color="primary"
                                                        fontSize="large"
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Field
                                                        name={`${name}.translation`}
                                                        
                                                    >
                                                        {props => (
                                                            <SmartSelect
                                                                {...props.input}
                                                                displayEmpty
                                                                type="biofuel_type"
                                                                className={classes.fixedWidth}
                                                                emptyMenuItem={
                                                                    <FormattedMessage id="Biofuel Type" />
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </TableCell>
                                                <TableCell>
                                                    <input
                                                        type="hidden"
                                                        name={`${name}.translation_type`}
                                                        value={BIOFUEL_TYPE}
                                                    />
                                                </TableCell>

                                            </TableRow>
                                        )})
                                }
                            </FieldArray>
                            <FieldArray name="raw_material" >
                                {({ fields }) =>
                                    fields.map((name, index) => {
                                        return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Field
                                                    name={`${name}.value`}
                                                    
                                                >
                                                    {props => (
                                                        <FormattedMessage id="settingsView.users.modalProductMapping.col2.title">
                                                            {fmsg => (
                                                                <TextField
                                                                    {...props.input}
                                                                    variant="outlined"
                                                                    placeholder={fmsg as string}
                                                                    className={classes.fixedWidth}
                                                                />
                                                            )}
                                                        </FormattedMessage>
                                                    )}
                                                </Field>
                                            </TableCell>
                                            <TableCell>
                                                <ArrowRightAltIcon
                                                    color="primary"
                                                    fontSize="large"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    name={`${name}.translation`}
                                                    
                                                >
                                                    {props => (
                                                        <SmartSelect
                                                            {...props.input}
                                                            displayEmpty
                                                            type="raw_material"
                                                            className={classes.fixedWidth}
                                                            emptyMenuItem={
                                                                <FormattedMessage id="Raw Material" />
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="hidden"
                                                    name={`${name}.translation_type`}
                                                    value={RAW_METARIAL}
                                                />
                                            </TableCell>

                                        </TableRow>
                                    )})
                                }
                            </FieldArray>
                            <FieldArray name="raw_material_origin" >
                                {({ fields }) =>
                                    fields.map((name, index) => {
                                        return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Field
                                                    name={`${name}.value`}
                                                    
                                                >
                                                    {props => (
                                                        <FormattedMessage id="settingsView.users.modalProductMapping.col2.title">
                                                            {fmsg => (
                                                                <TextField
                                                                    {...props.input}
                                                                    variant="outlined"
                                                                    placeholder={fmsg as string}
                                                                    className={classes.fixedWidth}
                                                                />
                                                            )}
                                                        </FormattedMessage>
                                                    )}
                                                </Field>
                                            </TableCell>
                                            <TableCell>
                                                <ArrowRightAltIcon
                                                    color="primary"
                                                    fontSize="large"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Field
                                                    name={`${name}.translation`}
                                                    
                                                >
                                                    {props => (
                                                        <SmartSelect
                                                            {...props.input}
                                                            displayEmpty
                                                            type="origin"
                                                            className={classes.fixedWidth}
                                                            emptyMenuItem={
                                                                <FormattedMessage id="Raw Material Origin" />
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </TableCell>
                                            <TableCell>
                                                <input
                                                    type="hidden"
                                                    name={`${name}.translation_type`}
                                                    value={RAW_METARIAL_ORIGIN}
                                                />
                                            </TableCell>

                                        </TableRow>
                                    )})
                                }
                            </FieldArray>
                        </TableBody>
                    </Table>
                    <Grid container spacing={2} className={classes.btnContainer}>
                        <Grid item xs={6}>
                            <ButtonRounded
                                fullWidth
                                variant="contained"
                                onClick={onClose}
                                color="secondary"
                            >
                                {<FormattedMessage id="settingsView.modal.cancel" />}
                            </ButtonRounded>
                        </Grid>
                        <Grid item xs={6}>
                            <ButtonRounded
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={submitting}
                            >
                                {<FormattedMessage id="settingsView.modal.save" />}
                            </ButtonRounded>
                        </Grid>
                    </Grid>
                        </form>
                    )}
               </Form>
        </ModalContainer>
    );
};

export default ModalMapped;

