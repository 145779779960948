import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const setAllocationConstruction = async (
  construction_volume_requirement: any
) => {
  const token = getToken();
  let valid = false;
  const params = {
    construction_volume_requirement: construction_volume_requirement,
  };

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/sustainability_reports/set_construction_allocation`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(params),
        }
      );

      var result = await response.json();
      valid = result && result.success;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (result && result.errors)
      return {
        valid: false,
        message: result.errors,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
  };
};

export default setAllocationConstruction;
