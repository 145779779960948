import { ErrorPayload } from "../../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchcard_volumesAPI from "../../../../api/card/cardVolumes/get";


// State Interface
export interface CardVolumesState {
  isFetching: boolean;
  data: any;
  sum:any;
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: {},
  sum:0,
  error: undefined,
} as CardVolumesState;

// Actions
const CARD_VOLUMES_REQUEST = "card/card_volumes/REQUEST";
const CARD_VOLUMES_ERROR = "card/card_volumes/ERROR";
const CARD_VOLUMES_SUCCESS = "card/card_volumes/SUCCESS";
const CARD_VOLUMES_SUM = "card/card_volumes/SUM";
// Action creators
function requestcard_volumes() {
  return {
    type: CARD_VOLUMES_REQUEST,
  };
}

function successcard_volumes(data: any) {
  return {
    type: CARD_VOLUMES_SUCCESS,
    payload: { data },
  };
}
function errorcard_volumes(error: any) {
  return {
    type: CARD_VOLUMES_ERROR,
    payload: { error },
  };
}
function successcard_sum(data: any) {
  return {
    type: CARD_VOLUMES_SUM,
    payload: { data },
  };
}


export function getcardVolumes() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
   
    if (state.card.allocation.cardVolumes) {
      dispatch(requestcard_volumes());
      try {
        const response = await fetchcard_volumesAPI();
        dispatch(successcard_volumes(response.data));
        dispatch(successcard_sum(response.data));
      } catch (error) {
        dispatch(errorcard_volumes(error));
      }
    }
    return Promise.resolve;
  };
}



// Reducer Definition
const card_volumesReducer = handleActions<CardVolumesState>(
  {
    [CARD_VOLUMES_REQUEST]: handlecard_volumesRequest,
    [CARD_VOLUMES_ERROR]: handlecard_volumesError,
    [CARD_VOLUMES_SUCCESS]: handlecard_volumesSuccess,
    [CARD_VOLUMES_SUM]:handlecard_sum,
  } as any,
  initialState
);

// Reducer Description
function handlecard_volumesRequest(state: CardVolumesState) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handlecard_volumesError(
  state: CardVolumesState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handlecard_volumesSuccess(
  state: CardVolumesState,
  action: Action<any>
) {
  return {
    ...state,
    isFetching: false,
    data: action.payload.data,
    error: undefined as any,
  };
}

function handlecard_sum(
  state: CardVolumesState,
  action: Action<any>
) {
  let a = action.payload.data
  let sum1= a.volume_fame +
  a.volume_hvo_hro +
  a.volume_nafta +
  a.volume_etanol+
  a.volume_fossil;
  return {
    ...state,
    sum: sum1
  };
}


export default card_volumesReducer;
