import { combineReducers } from "redux";
//this should  not change? 
import MainCardReducer, { MainCardState } from "./main";
import FilesReducer, { FilesState } from "./files";
import AllocationReducer, { AllocationState } from "./allocation";
import CustomerReducer, { CustomerState } from "./customer";
import ReportsReducer, { ReportsState } from "./reports";

export interface CardState {
  main: MainCardState;
  files: FilesState;
  allocation: AllocationState;
  customer: CustomerState;
  reports: ReportsState;
}

export default combineReducers({
  main: MainCardReducer,
  files: FilesReducer,
  allocation: AllocationReducer,
  customer: CustomerReducer,
  reports: ReportsReducer,
});
