/**
 * KeyboardDatePicker
 */

// import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles, Theme } from "@material-ui/core/styles";

export default withStyles((theme: Theme) => ({
  root: {
    "& .MuiIconButton-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: theme.spacing(1),
    },
    "& .MuiInputAdornment-positionEnd": {
      marginLeft: 0,
    },
  },
}))(KeyboardDatePicker);
