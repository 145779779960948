/**
 * A general purpose Table component that displays a list of files
 * with some actions associated with each item
 */

import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import SearchIcon from "@material-ui/icons/Search";
import GetAppIcon from "@material-ui/icons/GetApp";
// import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import ModalDelete from "./ModalDelete";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContent: {
      padding: theme.spacing(2, 3),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    inputSearch: {
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 8px",
      },
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "inherit",
        fontWeight: "bold",
        paddingBottom: theme.spacing(2),
      },
    },
    tableEmpltyRow: {
      "& .MuiTableCell-root": {
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
      },
    },
    iconDelete: {
      color: theme.palette.error.main,
    },
    linkDownload: {
      color: "inherit",
      paddingTop: theme.spacing(0.5),
    },
  })
);

export interface IFile {
  [key: string]: string | number;
  id: number;
  file_name: string;
  created_at: string;
  file_path: string;
}

interface FilesTableProps {
  title?: string | React.ReactElement;
  fileList: IFile[];
  handleDeleteFile: (file: any) => void;
}

const FilesTable: React.FC<FilesTableProps> = ({
  title,
  fileList,
  handleDeleteFile,
}) => {
  const [currentFile, setCurrentFile] = useState<IFile | null>(null);

  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);

  const [searchValue, setSearchValue] = useState<string>("");

  const [filterFileList, setFilterFileList] = useState<IFile[]>(fileList);

  const classes = useStyles();

  const openModalDelete = (file: IFile) => {
    setCurrentFile(file);
    setShowModalDelete(true);
  };
  const closeModalDelete = () => {
    setShowModalDelete(false);
  };

  const filterList = () => {
    const sv = searchValue.toLowerCase();
    if (fileList)
      setFilterFileList(
        fileList.filter((item) => {
          if (item.file_name) {
            const file_name = item.file_name.toLowerCase();
            return (
              file_name.indexOf(sv) > -1 || item.created_at.indexOf(sv) > -1
            );
          }
        })
      );
  };

  useEffect(() => {
    filterList();
  }, [searchValue, fileList]);

  return (
    <div>
      <Card>
        <CardContent className={classes.cardContent}>
          {/* Top Search Bar */}
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.titleBar}
          >
            <Grid item>
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item>
              <TextField
                placeholder={"Search"}
                className={classes.inputSearch}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {/* Files Table */}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <FormattedMessage id={"component.filesTable.fileName"} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={"component.filesTable.fileType"} />
                </TableCell>
                <TableCell>
                  <FormattedMessage id={"component.filesTable.uploadDate"} />
                </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(filterFileList) &&
                filterFileList.map((file, idx) => (
                  <TableRow key={`${file.id}-${idx}`}>
                    <TableCell>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <DescriptionOutlinedIcon />
                        </Grid>
                        <Grid item>{file.file_name}</Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{file.file_category}</TableCell>
                    <TableCell>{file.created_at}</TableCell>
                    <TableCell>
                      <Grid container justify="flex-end" alignItems="center">
                        <Grid item>
                          {/* <IconButton color="primary">
                            <VisibilityOutlinedIcon fontSize="small" />
                          </IconButton> */}
                          <IconButton color="primary">
                            <a
                              className={classes.linkDownload}
                              href={file.file_path}
                              download
                            >
                              <GetAppIcon fontSize="small" />
                            </a>
                          </IconButton>
                          <IconButton
                            className={classes.iconDelete}
                            onClick={() => openModalDelete(file)}
                          >
                            <DeleteOutlinedIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              {Array.isArray(filterFileList) && filterFileList.length === 0 && (
                <TableRow className={classes.tableEmpltyRow}>
                  <TableCell>
                    <FormattedMessage id={"component.filesTable.noFiles"} />
                  </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {currentFile && (
        <>
          <ModalDelete
            handleDelete={() => {
              handleDeleteFile(currentFile);
              closeModalDelete();
            }}
            open={showModalDelete}
            onClose={closeModalDelete}
            item={{ id: currentFile.id, name: currentFile.file_name }}
          />
        </>
      )}
    </div>
  );
};

FilesTable.defaultProps = {
  title: <FormattedMessage id={"component.filesTable.title"} />,
};

export default FilesTable;
