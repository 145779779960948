/**
 * Get users list
 * only accessible to admins
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async () => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/tax_years`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};
