import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
    },
    iconLess: {
      transform: "rotate(180deg)",
    },
  })
);

interface ViewMoreProps {
  more?: boolean;
  onClick?: () => void;
}

const ViewMore: React.FC<ViewMoreProps> = ({
  more = false,
  onClick = () => null,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root} onClick={onClick}>
      <Grid item>
        <Typography variant="body1" color="primary">
          <FormattedMessage id="exciseDutyView.reportsView.index.view" />{" "}
          {more ? (
            <FormattedMessage id="exciseDutyView.reportsView.index.less" />
          ) : (
            <FormattedMessage id="exciseDutyView.reportsView.index.more" />
          )}
        </Typography>
      </Grid>
      <Grid item>
        <ExpandMoreIcon
          color="primary"
          className={more ? classes.iconLess : ""}
        />
      </Grid>
    </Grid>
  );
};

export default ViewMore;
