import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { StepIconProps } from "@material-ui/core/StepIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
      userSelect: "none",
      cursor: "pointer",
      borderRadius: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        minWidth: "40px",
      },
    },
    shape: {
      padding: theme.spacing(0.75, 1.1),
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0.65, 0.65),
      },
    },
    label: {
      lineHeight: "1",
      paddingLeft: theme.spacing(0.75),
      [theme.breakpoints.down("md")]: {
        paddingLeft: theme.spacing(0.2),
        fontSize: "12px",
      },
    },
    labelSelected: {
      fontWeight: "bold",
    },
    rootSelected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    rootDisabled: {
      backgroundColor: theme.palette.grey[300],
      color: theme.palette.primary.contrastText,
    },
    circle: {
      width: "12px",
      height: "12px",
      borderRadius: "100%",
      [theme.breakpoints.down("md")]: {
        width: "10px",
        height: "10px",
      },
    },
    circleWhite: {
      backgroundColor: theme.palette.common.white,
    },
    circleUnselectedComplete: {
      backgroundColor: "#8fcc60",
    },
    circleUnselectedNotComplete: {
      backgroundColor: "#ff441a",
    },
    circleUnselectedOverallocated: {
      backgroundColor: "#ffac12",
    },
  })
);

const StepIcon: React.FC<
  StepIconProps & {
    disabled: boolean;
    status: "Complete" | "Not Complete" | "Overallocated" | null;
  }
> = ({ active, disabled, status, icon }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootSelected]: active,
        [classes.rootDisabled]: disabled,
      })}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.shape}
      >
        <Grid item>
          <div
            className={clsx(classes.circle, {
              [classes.circleUnselectedComplete]:
                status !== null && !active && status === "Complete",
              [classes.circleUnselectedNotComplete]:
                status !== null && !active && status === "Not Complete",
              [classes.circleUnselectedOverallocated]:
                status !== null && !active && status === "Overallocated",
              [classes.circleWhite]: active || status === null,
            })}
          ></div>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            className={clsx(classes.label, { [classes.labelSelected]: active })}
          >
            Step {icon}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default StepIcon;
