import React, { useState, useEffect, SetStateAction } from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import Box from "@material-ui/core/Box";
import SmartSelect from "../../../../components/SmartSelect";
import CircularProgress from "@material-ui/core/CircularProgress";

// import * as yup from "yup";
import KeyboardDatePicker from "../../../../components/inputs/KeyboardDatePicker";
// import makeValidate from "../../../../../components/form/makeValidate";

import ButtonRounded from "../../../../components/ButtonRounded";
import DownloadHBLPOSTablePDF from "./DownloadHBLPOSTablePDF";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ModalContainer from "../../../../components/ModalContainer";
import { FormattedMessage } from "react-intl";
import { getAppVariables } from "../../../../../src/reducers/settings/app_variables";
import getHBLPOSReports from "../../../../api/sustainability/storage/outgoing-transaction/getHBLPOSReports";
import {useDispatch} from "react-redux";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      minHeight: "220px",
      minWidth: "500px",
      display: "flex",
      flexDirection: "column",
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnLeft: {
      marginRight: theme.spacing(3),
    },
    labelName: {
      fontSize: "13px",
      color: theme.palette.grey[400],
    },
    circularProgressContainer: {
      position: "absolute",
      zIndex: 10,
      left: 0,
      right: 0,
      top: "100px",
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
    },
    circularProgress: {
      width: "48px",
      height: "48px",
    },
  })
);

interface ModalDownloadHBLPOSProps {
  open: boolean;
  onClose: () => void;
}

const ModalDownloadHBLPOS: React.FC<ModalDownloadHBLPOSProps> = ({
  open,
  onClose,
}) => {
  const [data, setData] = useState<any>(null);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();
  const dispatch = useDispatch();

  const generateReports = async (values: any) => {
    setIsLoading(true);

    const res = await getHBLPOSReports(values);
    setData(null);
    setIsError(false);
    setIsLoading(false);
    if (res && res.valid) {
      setData(res.data);
      setIsLoading(false);
    } else if (res && !res.valid) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  const today = new Date();
  const fetchAppVariables = async () => {
    await getAppVariables();
  }

  useEffect(() => {
    setTimeout(function () {
      setData(null);
      setIsError(false);
      setIsLoading(false);
    }, 500);
    dispatch(getAppVariables());
    // fetchAppVariables();
  }, [open]);

  return (
    <ModalContainer
        open={open}
        onClose={onClose}
        title="Download Proof of Sustainability"
    >
      <div className={classes.modalContent}>
        {isLoading && (
          <Box flex={1} className={classes.circularProgressContainer}>
            <CircularProgress className={classes.circularProgress} />
          </Box>
        )}
        <Form
          onSubmit={() => {}}
          initialValues={{
            customer_name: null,
            date_from: today.getFullYear() + "/01/01",
            date_to: today.getFullYear() + "/12/31",
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={3} className={classes.marginBottom}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Customer <RequiredAsterisk />
                  </Typography>
                  <Field name={"customer_name"}>
                    {(props) => (
                      <SmartSelect
                        {...props.input}
                        displayEmpty
                        fullWidth
                        type="res_customer_hbl"
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Date From <RequiredAsterisk />
                  </Typography>

                  <Field name={"date_from"}>
                    {({input,meta}) => (
                      <KeyboardDatePicker
                        {...input}
                        inputVariant="outlined"
                        format="YYYY-MM-DD"
                        maxDate={values.date_to}
                        placeholder={moment().format("YYYY-MM-DD")}
                        value={input.value ? input.value : null}
                        onChange={(value: any) => {
                          input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                        }}
                        invalidDateMessage={null}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Date To
                    <RequiredAsterisk />
                  </Typography>

                  <Field name={"date_to"}>
                    {({input,meta}) => (
                      <KeyboardDatePicker
                        {...input}
                        inputVariant="outlined"
                        format="YYYY-MM-DD"
                        minDate={values.date_from}
                        placeholder={moment().format("YYYY-MM-DD")}
                        value={input.value ? input.value : null}
                        onChange={(value: any) => {
                          input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                        }}
                        invalidDateMessage={null}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ButtonRounded
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={() => generateReports(values)}
                    disabled={
                      !values.customer_name ||
                      !values.date_to ||
                      !values.date_from
                    }
                  >
                    <FormattedMessage id="sustainabilityView.storage.modalPOS" />
                  </ButtonRounded>
                </Grid>
              </Grid>
              {data && <DownloadHBLPOSTablePDF data={data} />}
            </form>
          )}
        </Form>

        {isError && (
          <Typography align="center" variant="body2" color="error">
            Failed to perform this operation. There is no reservation of this
            customer for the selected year.
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalDownloadHBLPOS;
