/**
 * Slider with styles
 * adapted from MUI docs IOS slider example
 */

// import React from "react";
import Slider from "@material-ui/core/Slider";
import { withStyles, Theme } from "@material-ui/core/styles";

export default withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    height: 2,
    padding: "15px 0",
    width: `calc(100% - ${theme.spacing(5)}px)`,
  },
  thumb: {
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.primary.main}`,
    marginTop: theme.spacing(-0.75),
    marginLeft: theme.spacing(-0.75),
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 2px)",
    top: theme.spacing(3),
    "& *": {
      background: "transparent",
      color: theme.palette.primary.main,
    },
  },
  track: {
    height: theme.spacing(1),
  },
  rail: {
    height: theme.spacing(1),
    opacity: 0.5,
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.spacing(2),
  },
  mark: {
    backgroundColor: theme.palette.grey[300],
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    backgroundColor: "currentColor",
  },
}))(Slider);
