import { combineReducers } from "redux";
import MainSustainabilityReducer, { MainSustainabilityState } from "./main";
import FilesReducer, { FilesState } from "./files";
import AllocationReducer, { AllocationState } from "./allocation";
import CustomerReducer, { CustomerState } from "./customer";
import ReportsReducer, { ReportsState } from "./reports";

export interface SustainabilityState {
  main: MainSustainabilityState;
  files: FilesState;
  allocation: AllocationState;
  customer: CustomerState;
  reports: ReportsState;
}

export default combineReducers({
  main: MainSustainabilityReducer,
  files: FilesReducer,
  allocation: AllocationReducer,
  customer: CustomerReducer,
  reports: ReportsReducer,
});
