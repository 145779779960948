import { Font } from "@react-pdf/renderer";

export default function() {
  // disable hyphenation
  Font.registerHyphenationCallback(word => {
    return [word];
  });

  Font.register({
    family: "PreemSans",
    src: "/fonts/PreemSans-Regular.ttf",
  });

  Font.register({
    family: "PreemSans Italic",
    src: "/fonts/PreemSans-Italic.ttf",
  });

  Font.register({
    family: "PreemSans Medium",
    src: "/fonts/PreemSans-Medium.ttf",
  });

  Font.register({
    family: "PreemSans Bold",
    src: "/fonts/PreemSans-Bold.ttf",
  });
}
