import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../../reducers/reducer";
import IndicatorBoxView from "../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/IndicatorBox";

interface Props {
  sustainability_quantities: any;
  requirement_quantities: any;
  construction_quantities_fame: any;
  construction_quantities_hvo: any;
  activeStep: number;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      sustainability_quantities:
        state.sustainability.allocation.multiStep.sustainability_quantities,
      requirement_quantities:
        state.sustainability.allocation.multiStep.requirement_quantities,
      construction_quantities_hvo:
        state.sustainability.allocation.multiStep.construction_quantities_hvo,
      construction_quantities_fame:
        state.sustainability.allocation.multiStep.construction_quantities_fame,
      activeStep: state.sustainability.allocation.multiStep.activeStep,
    }),
    (dispatch: any) => ({
      dispatch,
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(IndicatorBoxView);
