/**
 * Forgot username component
 */

import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import InfoIcon from "@material-ui/icons/Info";
import { FormattedMessage } from "react-intl";

const useStyle = makeStyles((theme: Theme) => ({
  forgotUserName: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    cursor: "pointer",
  },
  popoverText: {
    padding: theme.spacing(1),
    fontSize: "10px",
    lineHeight: "12px",
    width: "150px",
    textAlign: "center",
    position: "relative",
    overflow: "hidden",
    display: "block !important",
  },
  popover: {
    pointerEvents: "none",
    '&>.MuiPaper-root': {
      overflowX: "visible",
      overflowY: "inherit",
      '&:after': {
        position: "absolute",
        content: "' '",
        top: "-5px",
        left: "50%",
        marginLeft: "-5px",
        width: "0",
        height: "0",
        display: "block !important",
        borderLeft: "5px solid transparent",
        borderRight: "5px solid transparent",
        borderBottom: '5px solid white', 
      }
    },
  },
}));

const ForgotUsername: React.FC = () => {
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Grid
        container
        spacing={1}
        className={classes.forgotUserName}
      >
        <Grid item
          onMouseOver={handleOpen}
          onMouseOut={handleClose}
        >
          <InfoIcon color={open ? "primary" : "inherit"} />
        </Grid>
        <Grid item>
          <Typography variant="body2">
            <FormattedMessage id={"loginView.forgotUsername"} />
          </Typography>
        </Grid>
      </Grid>
      <Popover
        id={id}
        className={classes.popover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography variant="body2" className={classes.popoverText}>
          <FormattedMessage id={"loginView.forgotUsernameInfo"} />
        </Typography>
      </Popover>
    </>
  );
};

export default ForgotUsername;
