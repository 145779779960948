/**
 *  Dashboard: Settings View with routing
 */

import React, { useState, useEffect } from "react";
import { Switch, Route, match as Match, Redirect } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import MainView from "../MainView";
import { getViews } from "../config";
import {FormattedMessage} from "react-intl";
import {useRouteMatch} from "react-router";
import SettingView from "../SettingView";
import Grid from "@material-ui/core/Grid";
import SettingsCard from "../SettingsCard";
import ButtonArrowBack from "../../../../components/ButtonArrowBack";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        btnArrowBack: {
            marginLeft: theme.spacing(-2),
        },
    })
);

const bkvMappingConfig = [
    {
        id: "biofuel_type_mappings",
        title: <FormattedMessage id="settingsView.bkvMappings.biofuel" />,
        path: "/biofuel_type_mappings",
        component: SettingView,
    },
    {
        id: "raw_material_mappings",
        title: <FormattedMessage id="settingsView.bkvMappings.rawMaterial" />,
        path: "/raw_material_mappings",
        component: SettingView,
    },
    {
        id: "rawmaterial_origins_mappings",
        title: <FormattedMessage id="settingsView.bkvMappings.rawMaterialOrigins" />,
        path: "/rawmaterial_origins_mappings",
        component: SettingView,
    },
];

const BkvMappingsView: React.FC = () => {
    const [settingViews, setSettingViews] = useState<any>(null);
    const classes = useStyles();
    const match = useRouteMatch();
    useEffect(() => {
        setSettingViews(bkvMappingConfig);
        // tslint:disable-next-line:no-console
        // console.error(match.url);
    }, []);
    return (
        <div className={classes.root}>
            <Grid item>
                <ButtonArrowBack className={classes.btnArrowBack} />
            </Grid>
            <Grid container spacing={4}>
                {settingViews &&
                settingViews.map((view: any, idx: any) =>
                    view.isRestricted ? null : (
                        <Grid item md={6} sm={6} key={view.id}>
                            <SettingsCard
                                title={view.title}
                                // @ts-ignore
                                path={`${match.path}${view.path}`}
                            />
                        </Grid>
                    )
                )}
            </Grid>
        </div>
    );
};

export default BkvMappingsView;
