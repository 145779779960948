import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import * as yup from "yup";
import SmartSelect from "../../components/SmartSelect";
import ModalContainer from "../ModalContainer";
import makeValidate from "../form/makeValidate";
import ButtonRounded from "../ButtonRounded";
import KeyboardDatePicker from "../inputs/KeyboardDatePicker";
import RequiredAsterisk from "../../components/texts/RequiredAsterisk";
import moment from "moment";
import createDecorator from "final-form-calculate";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      paddingTop: theme.spacing(2),
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-around",
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
    select: {
      marginBottom: theme.spacing(0),
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnCancel: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      marginRight: theme.spacing(0),
    },
    labelName: {
      fontSize: "13px",
      color: theme.palette.grey[400],
    },
    textSpace: {
      paddingi: theme.spacing(2),
    },
  })
);

interface ModalAddStorageProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (params: any, id?: any) => Promise<any>;
  mode: "add" | "edit";
  bioTransactions?: {
    id: number;
    transaction_type: string;
    biofuel_type: string;
    fuel_type: string; // biocomponent type
    double_counted_norway: string; // double counting
    volume: string;
    equivalent_volume: string;
    raw_material: string;
    raw_material_origin: string;
    bonus_factor_eb: string;
    bonus_factor_esca: string;
    documentation_emission_of_climate: string; // documentaion Ghg Reduction
    reduction_in_climate_emission: string; // Ghg Reduction
    documentation_bonus_factor_eb: string;
    documentation_bonus_factor_esca: string;
    documentation_area_criteria: string; // land criteria
    documentation_mass_balance: string;
    certification_id: string;
    storage_date: string;
    biofuel_type_usage: string;
    comment: string;
  };
}

const fieldValueCalculator = createDecorator(
  {
    field: "bonus_factor_eb",
    updates: {
      documentation_bonus_factor_eb: (value, allValues) =>
        value === "Nei" ? "N/A" : "",
    },
  },
  {
    field: "bonus_factor_esca",
    updates: {
      documentation_bonus_factor_esca: (value, allValues) =>
        value === "Nei" ? "N/A" : "",
    },
  },
  {
    field: "volume",
    updates: {
      equivalent_volume: (value, allValues: any) => {
        if (value && allValues) {
          if (
            allValues.double_counted_norway === "Ja" ||
            allValues.double_counted_norway === "yes"
          ) {
            return Number(value) * 2;
          } else {
            return Number(value);
          }
        } else {
          return 0;
        }
      },
    },
  },
  {
    field: "double_counted_norway",
    updates: {
      equivalent_volume: (value, allValues: any) => {
        if (value && allValues) {
          if (value === "Ja" || value === "yes") {
            return Number(allValues.volume) * 2;
          } else {
            return Number(allValues.volume);
          }
        } else {
          return 0;
        }
      },
    },
  }
);

const ModalAddStorage: React.FC<ModalAddStorageProps> = ({
  open,
  onClose,
  onConfirm,
  mode,
  bioTransactions,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const validationSchema = yup.object({
    transaction_type: yup.string().required(),
    biofuel_type: yup.string().required(),
    volume: yup.string().required(),
    raw_material: yup.string().required(),
    raw_material_origin: yup.string().required(),
    bonus_factor_eb: yup.string().required(),
    bonus_factor_esca: yup.string().required(),
    documentation_emission_of_climate: yup.string().required(), // documentaion Ghg Reduction
    reduction_in_climate_emission: yup.string().required(), // Ghg Reduction
    documentation_bonus_factor_eb: yup.string().required(),
    documentation_bonus_factor_esca: yup.string().required(),
    documentation_area_criteria: yup.string().required(), // land criteria
    documentation_mass_balance: yup.string().required(),
    certification_id: yup.string().required(),
    storage_date: yup.string().required(),
    comment: yup.string().required(),
  });

  const onSubmit = async (values: any) => {
    setIsError(false);

    const bioTransaction = {
      id: values.id ? values.id : null,
      transaction_type: values.transaction_type,
      biofuel_type: values.biofuel_type,
      fuel_type: values.fuel_type ? values.fuel_type : "N/A ", // biocomponent type those two could be blank
      double_counted_norway: values.double_counted_norway
        ? values.double_counted_norway
        : "N/A", // double counting
      volume: values.volume,
      equivalent_volume: values.equivalent_volume,
      raw_material: values.raw_material,
      raw_material_origin: values.raw_material_origin,
      bonus_factor_eb: values.bonus_factor_eb,
      bonus_factor_esca: values.bonus_factor_esca,
      documentation_emission_of_climate:
        values.documentation_emission_of_climate, // documentaion Ghg Reduction
      reduction_in_climate_emission: values.reduction_in_climate_emission, // Ghg Reduction
      documentation_bonus_factor_eb: values.documentation_bonus_factor_eb,
      documentation_bonus_factor_esca: values.documentation_bonus_factor_esca,
      documentation_area_criteria: values.documentation_area_criteria, // land criteria
      documentation_mass_balance: values.documentation_mass_balance,
      certification_id: values.certification_id,
      storage_date: moment(values.storage_date).format("YYYY-MM-DD"),
      biofuel_type_usage: values.biofuel_type_usage,
      comment: values.comment,
      storage_terminal: values.storage_terminal || null,
    };
    const res = await onConfirm(bioTransaction, bioTransaction.id);
    if (res.valid) {
      onClose();
    } else {
      setIsError(true);
    }
  };

  const rawMaterialEnabled = [
    "Alger",
    "Cellulose",
    "Lignocellulose",
    "Sagflis",
    "Annet - spesifiser",
    "Annet – spesifiser",
  ];

  const biofuelEnabled = ["Annet – spesifiser", "Annet - spesifiser"];
  const required = (value: any) =>
    value && value !== "" ? undefined : "Required";
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={`${mode === "add" ? "Add" : "Edit"} Biofuel Transaction`}
      maxWidth="lg"
      fullWidth={true}
    >
      <div className={classes.modalContent}>
        <div>
          <Form
            onSubmit={onSubmit}
            //validate={values => makeValidate(values, validationSchema)}
            initialValues={bioTransactions ? bioTransactions : {}}
            decorators={[fieldValueCalculator]}
          >
            {({ handleSubmit, submitting, hasValidationErrors, values }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Transaction Type <RequiredAsterisk />
                    </Typography>
                    <Field name="transaction_type" validate={required}>
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"transaction_type"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Biofuel Type <RequiredAsterisk />
                    </Typography>
                    <Field name="biofuel_type" validate={required}>
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"biofuel_type"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Biocomponent Type
                    </Typography>
                    <Field name="fuel_type">
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"biocomponent_type"}
                          disabled={
                            !biofuelEnabled.includes(values.biofuel_type)
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Advanced
                    </Typography>
                    <Field name="double_counted_norway">
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"double_counting"}
                          disabled={
                            !rawMaterialEnabled.includes(values.raw_material)
                          }
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Volume <RequiredAsterisk />
                    </Typography>
                    <Field
                      validate={required}
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="volume"
                      placeholder="0,00"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Equivalent Volume
                    </Typography>
                    <Field
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="equivalent_volume"
                      placeholder="0,00"
                      fullWidth
                      disabled
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Raw Material <RequiredAsterisk />
                    </Typography>
                    <Field name="raw_material" validate={required}>
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"raw_material"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Raw Material Origin <RequiredAsterisk />
                    </Typography>
                    <Field name="raw_material_origin" validate={required}>
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"origin"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Bonus Factor Eb <RequiredAsterisk />
                    </Typography>
                    <Field name="bonus_factor_eb" validate={required}>
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"flag_variable"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Bonus Factor Esca <RequiredAsterisk />
                    </Typography>
                    <Field name="bonus_factor_esca" validate={required}>
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"flag_variable"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Documentation Emission of Climate <RequiredAsterisk />
                    </Typography>
                    <Field
                      name="documentation_emission_of_climate"
                      validate={required}
                    >
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"documentation_ghg_reduction"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Reduction in Climate Emission <RequiredAsterisk />
                    </Typography>
                    <Field
                      validate={required}
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="reduction_in_climate_emission"
                      placeholder="0,00 %"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Documentation Bonus Factor Eb <RequiredAsterisk />
                    </Typography>
                    <Field
                      validate={required}
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="documentation_bonus_factor_eb"
                      placeholder="Documentation Bonus Factor Eb"
                      fullWidth
                      disabled={values.bonus_factor_eb === "Nei"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Documentation Bonus Factor Esca <RequiredAsterisk />
                    </Typography>
                    <Field
                      validate={required}
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="documentation_bonus_factor_esca"
                      placeholder="Documentation Bonus Factor Esca"
                      fullWidth
                      disabled={values.bonus_factor_esca === "Nei"}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Documentation of Land Criteria <RequiredAsterisk />
                    </Typography>
                    <Field
                      name="documentation_area_criteria"
                      validate={required}
                    >
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"documentation_area_criteria"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Documentation Mass Balance <RequiredAsterisk />
                    </Typography>
                    <Field
                      name="documentation_mass_balance"
                      validate={required}
                    >
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"documentation_mass_balance"}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Certification ID <RequiredAsterisk />
                    </Typography>
                    <Field
                      validate={required}
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="certification_id"
                      placeholder=""
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Storage Date <RequiredAsterisk />
                    </Typography>
                    <Field name="storage_date">
                      {({input, meta}) => (
                        <KeyboardDatePicker
                          {...input}
                          inputVariant="outlined"
                          format="YYYY-MM-DD"
                          placeholder={moment().format("YYYY-MM-DD")}
                          value={input.value ? input.value : null}
                          onChange={(value: any) => {
                            input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                          }}
                          // maxDate={values.end_date}
                          invalidDateMessage={null}
                          fullWidth
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Comment <RequiredAsterisk />
                    </Typography>
                    <Field
                      validate={required}
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="comment"
                      placeholder="Write a comment"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      Storage Terminal
                    </Typography>
                    <Field name="storage_terminal" validate={required}>
                      {(props) => (
                        <SmartSelect
                          {...props.input}
                          fullWidth
                          displayEmpty
                          type={"storage_terminals"}
                        />
                      )}
                    </Field>
                  </Grid>
                </Grid>
                <Grid container className={classes.btnContainer} spacing={2}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <ButtonRounded
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={onClose}
                      className={classes.btnCancel}
                    >
                      <Typography>Cancel</Typography>
                    </ButtonRounded>
                  </Grid>
                  <Grid item xs={3}>
                    <ButtonRounded
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                      fullWidth
                      variant="contained"
                      color="primary"
                    >
                      <Typography>Save</Typography>
                    </ButtonRounded>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </form>
            )}
          </Form>
        </div>

        {isError && (
          <Typography align="center" variant="body2" color="error">
            Failed to {mode} this Biofuel Transaction.
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalAddStorage;
