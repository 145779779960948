import React, { Fragment } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import AllocStepsStatusTable from "./AllocStepsStatusTable";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { overflowY: "auto" },
  })
);

interface Props {
  sustainable_batches: any[];
  allocation_status_volume: any;
  allocation_status_ton_equivalence: any;
}

const MatchingTables: React.FC<Props> = ({
  sustainable_batches,
  allocation_status_volume,
  allocation_status_ton_equivalence,
}) => {
  const classes = useStyles();

  const volumeHeaders = [
    "Component",
    "Sold Volume",
    "Allocated Volume",
    "To be allocated Volume",
    "Status",
  ];

  const {
    sold_volume,
    allocated_volume,
    net_volume,
  } = allocation_status_volume;

  const volumeRows = [
    [
      "Etanol",
      sold_volume.sold_etanol,
      allocated_volume.allocated_etanol,
      net_volume.net_etanol,
      net_volume.net_etanol_status,
    ],
    [
      "Nafta",
      sold_volume.sold_nafta,
      allocated_volume.allocated_nafta,
      net_volume.net_nafta,
      net_volume.net_nafta_status,
    ],
    [
      "FAME",
      sold_volume.sold_fame,
      allocated_volume.allocated_fame,
      net_volume.net_fame,
      net_volume.net_fame_status,
    ],
    [
      "HVO/HRO",
      sold_volume.sold_hvo_hro || sold_volume.sold_hvo,
      allocated_volume.allocated_hvo_hro || allocated_volume.allocated_hvo,
      net_volume.net_hvo_hro || net_volume.net_hvo,
      net_volume.net_hvo_hro_status || net_volume.net_hvo_status,
    ],
  ];

  const tonHeaders = [
    "Component",
    "Sold tCO2e",
    "Allocated tCO2e",
    "To be allocated tCO2e",
    "Status",
  ];

  const {
    sold_ton_equivalence,
    allocated_ton_equivalence,
    net_ton_equivalence,
  } = allocation_status_ton_equivalence;

  const tonRows = [
    [
      "Etanol",
      sold_ton_equivalence.sold_etanol,
      allocated_ton_equivalence.allocated_etanol,
      net_ton_equivalence.net_etanol,
      net_ton_equivalence.net_etanol_status,
    ],
    [
      "Nafta",
      sold_ton_equivalence.sold_nafta,
      allocated_ton_equivalence.allocated_nafta,
      net_ton_equivalence.net_nafta,
      net_ton_equivalence.net_nafta_status,
    ],
    [
      "FAME",
      sold_ton_equivalence.sold_fame,
      allocated_ton_equivalence.allocated_fame,
      net_ton_equivalence.net_fame,
      net_ton_equivalence.net_fame_status,
    ],
    [
      "HVO/HRO",
      sold_ton_equivalence.sold_hvo_hro || sold_ton_equivalence.sold_hvo,
      allocated_ton_equivalence.allocated_hvo_hro ||
        allocated_ton_equivalence.allocated_hvo,
      net_ton_equivalence.net_hvo_hro || net_ton_equivalence.net_hvo,
      net_ton_equivalence.net_hvo_hro_status ||
        net_ton_equivalence.net_hvo_status,
    ],
  ];

  const poolHeaders = [
    "Sustainability criteria",
    "Energy content & emissions",
    "Emission red.",
  ];

  const poolSubHeaders = [
    [
      "Sus. batch",
      "Product type",
      "Biofuel type",
      "Raw material",
      "Raw material origin",
      "Double counting",
      "Ghg red.",
      "Volume",
    ],
    ["MJ", "WTW tCO2e", "Fossil ref. tCO2e"],
    ["Ghg red. tCO2e", "Ghg red."],
  ];

  const poolRows = Array.isArray(sustainable_batches)
    ? sustainable_batches.map((b) => {
        return [
          b.id,
          b.fuel_type,
          b.biofuel_type,
          b.raw_material,
          b.raw_material_origin,
          b.double_counted_norway,
          b.reduction_in_climate_emission,
          b.volume,
          b.mj_liter,
          b.emissions_ton,
          b.fossil_ton,
          b.ghg_reduction_ton,
          b.ghg_reduction_percentage,
        ];
      })
    : [];

  return (
    <div className={classes.root}>
      <AllocStepsStatusTable
        headers={["Status Volume"]}
        subHeaders={[volumeHeaders]}
        rows={volumeRows}
        decimal={0}
      />
      <AllocStepsStatusTable
        headers={["Status tCO2e"]}
        subHeaders={[tonHeaders]}
        rows={tonRows}
      />
      <AllocStepsStatusTable
        headers={poolHeaders}
        subHeaders={poolSubHeaders}
        rows={poolRows}
        percentageIndexes={{ 6: true, 12: true }}
        specials={[7,8]}
      />
    </div>
  );
};

export default MatchingTables;
