import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchFiles from "../../../api/exciseduty/getFileList";

// State Interface
export interface FilesState {
  isFetching: boolean;
  data: any[];
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: [],
  error: undefined,
} as FilesState;

// Actions
const FILES_REQUEST = "exciseDuty/files/REQUEST";
const FILES_ERROR = "exciseDuty/files/ERROR";
const FILES_SUCCESS = "exciseDuty/files/SUCCESS";

// Action creators
function requestFiles() {
  return {
    type: FILES_REQUEST,
  };
}

function errorFiles(error: any) {
  return {
    type: FILES_ERROR,
    payload: { error },
  };
}

function receiveFiles(data: any) {
  return {
    type: FILES_SUCCESS,
    payload: data,
  };
}

export function getFiles() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.exciseDuty.files) {
      dispatch(requestFiles());
      try {
        const data = await fetchFiles();
        dispatch(receiveFiles(data));
      } catch (error) {
        dispatch(errorFiles(error));
      }
    }
    return Promise.resolve;
  };
}

// Reducer Definition
const FilesReducer = handleActions<FilesState>(
  {
    [FILES_REQUEST]: handleFilesRequest,
    [FILES_ERROR]: handleFilesError,
    [FILES_SUCCESS]: handleFilesSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleFilesRequest(state: FilesState) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handleFilesError(state: FilesState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handleFilesSuccess(state: FilesState, action: Action<any>) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
    error: undefined as any,
  };
}

export default FilesReducer;
