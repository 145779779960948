import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import ButtonRounded from "../../../components/ButtonRounded";
import changePassword from "../../../api/changePassword";
import { FORM_ERROR } from "final-form";
import { isString } from "util";
import * as yup from "yup";
import makeValidate from "../../../components/form/makeValidate";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
    content: {
      minWidth: "430px",
      padding: theme.spacing(6.25),
      position: "relative",
    },
    iconClose: {
      position: "absolute",
      top: theme.spacing(1),
      left: theme.spacing(1),
      color: theme.palette.grey[900],
    },
    inputContainer: {
      "margin-bottom": theme.spacing(2),
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 10px",
      },
      "& input": {
        fontSize: "14px",
      },
    },
    labelName: {
      fontSize: "13px",
      // color: "#505D68",
      color: theme.palette.grey[400],
    },
    btnContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
    btnCancel: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      marginRight: theme.spacing(2.5),
    },
  })
);

interface ChangePasswordModalProps {
  open: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();

  const validationSchema = yup.object({
    current_password: yup
      .string()
      .min(6)
      .required(),
    new_password: yup
      .string()
      .min(6)
      .required(),
    repeat_password: yup
      .string()
      .oneOf([yup.ref("new_password"), null])
      .required(),
  });

  const onSubmit = async (values: any) => {
    let res = null;
    // add check current Password API here
    res = await changePassword(values.current_password, values.new_password);

    if (res && res.valid) {
      onClose();
    } else {
      return res && isString(res.message)
        ? { [FORM_ERROR]: res.message }
        : res && res.message;
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={classes.content}>
        <IconButton className={classes.iconClose} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          {<FormattedMessage id="myProfileView.index.changePassword" />}
        </Typography>
        <Form
          onSubmit={onSubmit}
          validate={values => makeValidate(values, validationSchema)}
        >
          {({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelName}
              >
                <FormattedMessage id="myProfileView.changePassword.currentPassword" />
              </Typography>
              <Field
                type="password"
                className={classes.inputContainer}
                component={TextField}
                placeholder="Current Password"
                name="current_password"
                fullWidth
                InputProps={{ disableUnderline: true }}
              />
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelName}
              >
                {
                  <FormattedMessage id="myProfileView.changePassword.newPassword" />
                }
              </Typography>
              <Field
                type="password"
                className={classes.inputContainer}
                component={TextField}
                placeholder="New Password"
                name="new_password"
                fullWidth
                InputProps={{ disableUnderline: true }}
              />
              <Typography
                variant="body1"
                gutterBottom
                className={classes.labelName}
              >
                {
                  <FormattedMessage id="myProfileView.changePassword.repeatNewPassword" />
                }
              </Typography>
              <Field
                type="password"
                className={classes.inputContainer}
                component={TextField}
                placeholder="Repeat New Password"
                name="repeat_password"
                fullWidth
                InputProps={{ disableUnderline: true }}
              />
              <Grid container className={classes.btnContainer}>
                <ButtonRounded
                  fullWidth
                  variant="contained"
                  className={classes.btnCancel}
                  onClick={onClose}
                >
                  {<FormattedMessage id="settingsView.modal.cancel" />}
                </ButtonRounded>

                <ButtonRounded
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                >
                  {<FormattedMessage id="settingsView.modal.save" />}
                </ButtonRounded>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
