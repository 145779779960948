import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Form, Field } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import SmartSelect from "../../../../components/SmartSelect";
import ButtonRounded from "../../../../components/ButtonRounded";
import ModalContainer from "../../../../components/ModalContainer";
import { FormattedMessage } from "react-intl";
import getMerchantProductMapping from "../../../../api/settings/users/merchants/getMerchantProductMapping";
import postMerchantProductMapping from "../../../../api/settings/users/merchants/postMerchantProductMapping";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    userContainer: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      paddingTop: theme.spacing(2),
    },
    btnCancel: {},
    btnNewLine: {
      marginTop: theme.spacing(2),
      textTransform: "none",
    },
    addNewLine: {
      letterSpacing: "0.0",
      fontWeight: 500,
    },
    table: {
      // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
      //   backgroundColor: theme.palette.grey[50],
      // },
      "& .MuiTableBody-root .MuiTableRow-root": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        padding: theme.spacing(0.5, 1),
        "&:first-child": {
          paddingLeft: theme.spacing(2.5),
        },
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        ...theme.typography.body1,
        fontWeight: "bold",
        paddingBottom: theme.spacing(1),
      },
      "& .MuiIconButton-root": {
        paddingLeft: theme.spacing(0.5),
      },
    },
    fixedWidth: {
      width: theme.spacing(22),
    },
  })
);

interface ModalProps {
  merchantID: number;
  open: boolean;
  onClose: () => void;
}

const ModalProductMapping: React.FC<ModalProps> = ({
  open,
  onClose,
  merchantID,
}) => {
  const [productMapping, setProductMapping] = useState<any[]>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const fetchProductMapping = async () => {
    const res = await getMerchantProductMapping(merchantID);
    if (res && Array.isArray(res)) {
      setProductMapping(res);
    }
  };

  useEffect(() => {
    if (open) {
      setIsError(false);
      fetchProductMapping();
    }
  }, [open]);

  const productsInitialValues =
    productMapping.length > 0
      ? productMapping
      : [
          {
            merchant_product_id: "",
            merchant_product: "",
            owner_product: "",
            assign_value: "",
          },
        ];

  const handleProductMappingSubmit = async (values: any) => {
    setIsError(false);
    const res = await postMerchantProductMapping({
      merchantID,
      values,
    });
    if (res && res.success) {
      onClose();
    } else {
      setIsError(true);
    }
  };

  // basic validations
  const validateRequired = (value: any) => (value ? undefined : "Required");
  const validateRequiredArray = (value: any) =>
    value && value.length > 0 ? undefined : "Required";

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="md"
      title={
        <FormattedMessage id="settingsView.users.modalProductMapping.title" />
      }
    >
      <div className={classes.modalContent}>
        <Form
          onSubmit={handleProductMappingSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={{
            products: productsInitialValues,
          }}
        >
          {({
            handleSubmit,
            hasValidationErrors,
            pristine,
            submitting,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage id="settingsView.users.modalProductMapping.col1.title" />{" "}
                    </TableCell>
                    <TableCell>
                      <FormattedMessage id="settingsView.users.modalProductMapping.col2.title" />{" "}
                    </TableCell>
                    <TableCell></TableCell>

                    <TableCell>
                      <FormattedMessage id="settingsView.users.modalProductMapping.col3.title" />
                    </TableCell>
                      <TableCell>
                          <FormattedMessage id="settingsView.users.modalProductMapping.col4.title" />
                      </TableCell>
                      <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="products" validate={validateRequiredArray}>
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <Field
                              name={`${name}.merchant_product_id`}
                              validate={validateRequired}
                            >
                              {props => (
                                <FormattedMessage id="settingsView.users.modalProductMapping.col1.title">
                                  {fmsg => (
                                    <TextField
                                      {...props.input}
                                      variant="outlined"
                                      placeholder={fmsg as string}
                                      className={classes.fixedWidth}
                                      type="number"
                                    />
                                  )}
                                </FormattedMessage>
                              )}
                            </Field>
                          </TableCell>
                          <TableCell>
                            <Field
                              name={`${name}.merchant_product`}
                              validate={validateRequired}
                            >
                              {props => (
                                <FormattedMessage id="settingsView.users.modalProductMapping.col2.title">
                                  {fmsg => (
                                    <TextField
                                      {...props.input}
                                      variant="outlined"
                                      placeholder={fmsg as string}
                                      className={classes.fixedWidth}
                                    />
                                  )}
                                </FormattedMessage>
                              )}
                            </Field>
                          </TableCell>
                          <TableCell>
                            <ArrowRightAltIcon
                              color="primary"
                              fontSize="large"
                            />
                          </TableCell>
                            <TableCell>
                                <Field
                                    name={`${name}.owner_product`}
                                    validate={validateRequired}
                                >
                                    {props => (
                                        <SmartSelect
                                            {...props.input}
                                            displayEmpty
                                            type="bio_sales_products"
                                            className={classes.fixedWidth}
                                            emptyMenuItem={
                                                <FormattedMessage id="settingsView.users.modalProductMapping.col3.title" />
                                            }
                                        />
                                    )}
                                </Field>
                            </TableCell>
                          <TableCell>
                              <Field
                                  name={`${name}.assign_value`}
                              >
                                  {props => (
                                      <FormattedMessage id="settingsView.users.modalProductMapping.col4.title">
                                          {fmsg => (
                                              <TextField
                                                  {...props.input}
                                                  variant="outlined"
                                                  placeholder={fmsg as string}
                                                  className={classes.fixedWidth}
                                              />
                                          )}
                                      </FormattedMessage>
                                  )}
                              </Field>
                          </TableCell>

                          <TableCell>
                            <IconButton onClick={() => fields.remove(index)}>
                              <DeleteOutlinedIcon
                                fontSize="small"
                                color={"error"}
                              />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </FieldArray>
                </TableBody>
              </Table>

              <Button
                color="primary"
                size="small"
                className={classes.btnNewLine}
                onClick={() =>
                  push("products", {
                    merchant_product_id: "",
                    merchant_product: "",
                    owner_product: "",
                    assign_value: "",
                  })
                }
              >
                <AddCircleOutlineIcon />
                &nbsp;&nbsp;
                <Typography variant="body2" className={classes.addNewLine}>
                  <FormattedMessage id="exciseDutyView.reportsView.addLine" />
                </Typography>
              </Button>

              <Grid container spacing={2} className={classes.btnContainer}>
                <Grid item xs={6}>
                  <ButtonRounded
                    fullWidth
                    variant="contained"
                    onClick={onClose}
                    color="secondary"
                  >
                    {<FormattedMessage id="settingsView.modal.cancel" />}
                  </ButtonRounded>
                </Grid>
                <Grid item xs={6}>
                  <ButtonRounded
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting || pristine || hasValidationErrors}
                  >
                    {<FormattedMessage id="settingsView.modal.save" />}
                  </ButtonRounded>
                </Grid>
              </Grid>

              {isError && (
                <Typography align="center" variant="body2" color="error">
                  {<FormattedMessage id="allView.failedToSave" />}
                </Typography>
              )}
            </form>
          )}
        </Form>
      </div>
    </ModalContainer>
  );
};

export default ModalProductMapping;
