import { FormattedMessage } from "react-intl";
import React from "react";
import { object } from "yup";
export const allocationTableData = {
  columns: [
    <FormattedMessage id="cardView.report.allocationstatus.col1" />,
    <FormattedMessage id="cardView.report.allocationstatus.col2" />,
    <FormattedMessage id="cardView.report.allocationstatus.col3" />,
    <FormattedMessage id="cardView.report.allocationstatus.col4" />,
    //<FormattedMessage id="sustainabilityView.reportsView.allocationTable.col5" />,
    <FormattedMessage id="cardView.report.allocationstatus.col5" />,
    // <FormattedMessage id="sustainabilityView.reportsView.allocationTable.col7" />,
    // <FormattedMessage id="sustainabilityView.reportsView.allocationTable.col8" />,
    // <FormattedMessage id="sustainabilityView.reportsView.allocationTable.col9" />,
  ],
  // sample data
  rows: [[true, true, true, true, true, false, false, false, false]],

  extractAPIData(data: any, isJoint: boolean) {
    return typeof data === "object" && Object.keys(data).length > 0
      ? [
          [
            data.fame_status===true,
            data.hvo_hro_status===true,
            data.etanol_status===true,
            data.nafta_status===true,
            data.customer_requirement_status===true,


            // // Joint Fulfillment Section
            // data.biodiesel_single_count_status === true,
            // data.biodiesel_double_count_status === true,
            // data.bioethanol_single_count_status === true,
            // data.bioethanol_double_count_status === true,

            // // Own fulfillment Section
            // //data.customer_requirement_status === true,
            // data.bioethanol_requirement_status === true,
            // data.advanced_biofuel_requirement_status === true,
            // data.general_requirement_status === true,
            // data.deviation_status === true,
          ],
        ]
      : [];
  },
};

export const sustainableTableData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col3" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col4" />,
    <FormattedMessage id="settingsView.index.masterData.doubleCount" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col6" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col16" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col7" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col8" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col9" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col10" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col11" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col12" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col13" />,
    <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col14" />,
    // <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col15" />,
  ],
  // sample data
  rows: [
    [
      "HVO/HRO",
      "20 500 000,00",
      "Animalske bi-produkter",
      "De Forente Stater",
      "Ja",
      "77,49%",
      "41 000 000,00",
      "Nei",
      "Nei",
      "Egen dokumentasjon",
      "Egen dokumentasjon",
      "Egen dokumentasjon",
      "DGD",
      "Biokomponent for diesel",
    ],
  ],

  extractAPIData(dataAPI: any) {
    return Array.isArray(dataAPI) && dataAPI.length > 0
      ? dataAPI.map((data: any) => [
          data.biofuel_type,
          data.volume,
          data.raw_material,
          data.raw_material_origin,
          data.double_counted_norway,
          data.reduction_in_climate_emission,
          data.storage_terminal,
          data.equivalent_volume,
          data.bonus_factor_eb,
          data.bonus_factor_esca,
          data.documentation_emission_of_climate,
          data.documentation_area_criteria,
          data.documentation_mass_balance,
          data.certification_id,
          data.fuel_type,
          // data.biofuel_type_usage,
        ])
      : [];
  },
};

export const reportedTableData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col3" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col4" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col5" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col6" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col7" />,

    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col8" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col9" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col10" />,
    <FormattedMessage id="sustainabilityView.reportsView.volumesTable.col11" />,
  ],
  // sample data
  rows: [["-", "-", "-", "12 889 329,00", "1 021 755,00", "0", "0"]],

  extractAPIData(data: any) {
    return typeof data === "object" && Object.keys(data).length > 0
      ? [
          [
            data.information_about_responsible_unit,
            data.information_about_the_facility,
            data.introduction,
            data.blended_fuel_for_road_transportation_fossil_fuel,
            data.blended_fuel_for_road_transportation_bio_fuel,
            data.sustainable_biogas_for_road_transportation,
            data.inbound_and_outbound_balance,

            data.delivered_autodiesel_quantity,
            data.delivered_biodiesel_quantity,
            data.delivered_gasoline_quantity,
            data.delivered_bioethanol_quantity,
          ],
        ]
      : [];
  },
};

export const jointTableData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.jointTable.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.jointTable.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.jointTable.col3" />,
    <FormattedMessage id="sustainabilityView.reportsView.jointTable.col4" />,
    <FormattedMessage id="sustainabilityView.reportsView.jointTable.col5" />,
    <FormattedMessage id="sustainabilityView.reportsView.jointTable.col6" />,
  ],
  // sample data
  rows: [["Customer", "0987654321", "0,00", "-50 000,00", "0,00", "0,00"]],

  extractAPIData(data: any) {
    return typeof data === "object" && Object.keys(data).length > 0
      ? [
          [
            data.organization_name,
            data.organization_number,
            data.bio_components_in_diesel_single_counting_liter,
            data.bio_components_in_diesel_double_counting_liter,
            data.bio_components_in_gasoline_single_counting_liter,
            data.bio_components_in_gasoline_double_counting_liter,
          ],
        ]
      : [];
  },
};

export const aggregatedTableData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.aggregated.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.aggregated.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.aggregated.col3" />,
    <FormattedMessage id="sustainabilityView.reportsView.aggregated.col4" />,
  ],
  smartColumns: [
    {
      idFormattedName: "sustainabilityView.reportsView.aggregated.col1",
      id: "period",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.aggregated.col2",
      id: "quantity",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.aggregated.col3",
      id: "road_transport",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.aggregated.col4",
      id: "additional_code",
    },
  ],
  // sample data
  rows: [
    ["2019/01", 9334, "BT 100", ""],
    ["2019/01", 313495, "BD 300", ""],
  ],

  extractAPIData(dataAPI: any) {
    return Array.isArray(dataAPI) && dataAPI.length > 0
      ? dataAPI.map((data: any) => [
          data.period,
          data.quantity,
          data.road_transport,
          data.additional_code,
        ])
      : [];
  },
};

export const summarizedReportTableData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.summarizedReport.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.summarizedReport.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.summarizedReport.col3" />,
    <FormattedMessage id="sustainabilityView.reportsView.summarizedReport.col4" />,
    <FormattedMessage id="sustainabilityView.reportsView.summarizedReport.col5" />,
    <FormattedMessage id="sustainabilityView.reportsView.summarizedReport.col6" />,
  ],
  smartColumns: [
    {
      idFormattedName: "sustainabilityView.reportsView.summarizedReport.col1",
      id: "road_transport_code",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.summarizedReport.col2",
      id: "reported_quantity",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.summarizedReport.col3",
      id: "correct_quantity",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.summarizedReport.col4",
      id: "deviation_quantity",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.summarizedReport.col5",
      id: "additional_code",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.summarizedReport.col6",
      id: "tax_year",
    },
  ],
  disableParseDataOnColumns: [false, false, false, false, false, true],
  // sample data
  rows: [["BD 100", 714951, 0, 714951, "94", 2019]],

  extractAPIData(dataAPI: any) {
    return Array.isArray(dataAPI) && dataAPI.length > 0
      ? dataAPI.map((data: any) => [
          data.road_transport_code,
          data.reported_quantity,
          data.correct_quantity,
          data.deviation_quantity,
          data.additional_code,
          data.tax_year,
        ])
      : [];
  },
};

export const calculationTaxTableData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.calculationTax.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.calculationTax.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.calculationTax.col3" />,
  ],
  smartColumns: [
    {
      idFormattedName: "sustainabilityView.reportsView.calculationTax.col1",
      id: "biodiesel_quantity",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.calculationTax.col2",
      id: "bioetanol_quantity",
    },
    {
      idFormattedName: "sustainabilityView.reportsView.calculationTax.col3",
      id: "total",
    },
  ],
  // sample data
  rows: [[449051, 70377, 519428]],

  extractAPIData(dataAPI: any) {
    return Array.isArray(dataAPI) && dataAPI.length > 0
      ? dataAPI.map((data: any) => [
          data.biodiesel_quantity,
          data.bioetanol_quantity,
          data.total,
        ])
      : [];
  },
};

export const availablePoolBiotemplatesData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col3" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col4" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col5" />,
    // <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col6" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col7" />,

    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col8" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col9" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col10" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col11" />,

    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col12" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col13" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col14" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col15" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col16" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col7" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col18" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col19" />,

    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col20" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col21" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col22" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col23" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col24" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col25" />,
    <FormattedMessage id="sustainabilityView.reportsView.AvailablePoolTable.col26" />,
  ],

  extractAPIData(data: any) {
    return Array.isArray(data) && data.length > 0
      ? data.map((i: any) => [
          i.allocated_ton_quantity,
          i.allocated_volume,
          i.available_ton_quantity,
          i.available_volume,
          i.biofuel_type,
          // i.biofuel_type_usage,
          i.bonus_factor_eb,
          i.bonus_factor_esca,
          i.certification_id,
          i.documentation_area_criteria,
          i.documentation_emission_of_climate,

          i.documentation_mass_balance,
          i.double_counted_norway,
          i.emissions_ton,
          i.equivalent_volume,
          i.fossil_ton,
          i.fuel_type,
          i.ghg_reduction_percentage,
          i.ghg_reduction_ton,
          i.id,
          i.mj_liter,
          i.raw_material,
          i.raw_material_origin,
          i.reduction_in_climate_emission,
          i.storage_terminal,
          i.volume,
        ])
      : [];
  },
};
export const poolStatusDieselData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.poolStatusDiesel.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.poolStatusDiesel.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.poolStatusDiesel.col3" />,
  ],

  extractAPIData(data: any) {
    return typeof data === "object" && Object.keys(data).length > 0
      ? [
          [
            data.diesel.actual_diesel,
            data.diesel.minimum_diesel,
            data.diesel.surplus_diesel,
          ],
        ]
      : [];
  },
};
export const poolStatusGasolineData = {
  columns: [
    <FormattedMessage id="sustainabilityView.reportsView.poolStatusGasoline.col1" />,
    <FormattedMessage id="sustainabilityView.reportsView.poolStatusGasoline.col2" />,
    <FormattedMessage id="sustainabilityView.reportsView.poolStatusGasoline.col3" />,
  ],

  extractAPIData(data: any) {
    return typeof data === "object" && Object.keys(data).length > 0
      ? [
          [
            data.gasoline.actual_gasoline,
            data.gasoline.minimum_gasoline,
            data.gasoline.surplus_gasoline,
          ],
        ]
      : [];
  },
};
