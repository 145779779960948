import { FormattedMessage } from "react-intl";
import React from "react";
export default [
  {
    name: <FormattedMessage id="settingsView.index.govtRequirement.taxYear" />,
    id: "tax_year",
    type: "select",
    params: "tax_years",
  },
  {
    name: <FormattedMessage id="settingsView.index.govtRequirement.quarter" />,
    id: "quarter",
    type: "select",
    params: "quarters",
  },
  {
    name: (
      <FormattedMessage id="settingsView.index.govtRequirement.generalRequirement" />
    ),
    id: "biofuel_requirement",
    type: "text",
    decimals_format: 2,
  },
  {
    name: (
      <FormattedMessage id="settingsView.index.govtRequirement.subRequirementAdvanced" />
    ),
    id: "advanced_biofuel_sub",
    type: "text",
    decimals_format: 2,
  },
  {
    name: (
      <FormattedMessage id="settingsView.index.govtRequirement.subRequirementBiocomponents" />
    ),
    id: "bioethanol_sub",
    type: "text",
    decimals_format: 2,
  },
];
