/**
 * Upload a biotemplate file to sustainability reporting
 */

import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "../../endpoints";
import { getToken } from "../../utils";

interface IUploadFile {
  file: File;
  year: number;
  type: "biotemplate" | "customer_sale" | "preem_ab_biotemplate";
  m3: boolean;
}

const uploadFile = async ({
  file,
  year,
  type,
  m3 = false,
}: IUploadFile): Promise<{ success: boolean, msg: String, data: any[] } | null> => {
  const token = getToken();
  if (token) {
    let hbl = false;
    if (type == "preem_ab_biotemplate") {
      hbl = true;
    }
    const fd = new FormData();
    fd.append("xlxs_file", file);
    fd.append("tax_year", String(year));
    fd.append("m3", String(m3));
    fd.append("from_hbl", String(hbl));

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      let API_NAME = "";
      switch (type) {
        case "biotemplate":
          // API_NAME = "biotemplate_groups";
          API_NAME = "biotemplate_groups/preem_ab";
          break;
        case "customer_sale":
          API_NAME = "v1/customer_sales_groups";
          break;
        case "preem_ab_biotemplate":
          API_NAME = "biotemplate_groups/preem_ab";
          break;
        default:
          // API_NAME = "biotemplate_groups";
          API_NAME = "biotemplate_groups/preem_ab";
          break;
      }
      const res = await axios.post(`${BASE_URL}/api/${API_NAME}`, fd, config);
      if (res.status === 200 && res.data.success) {
        if (res.data.data && Array.isArray(res.data.data)) {
          return { success: res.data.success, msg: "", data: res.data };
        } else {        
          return { success: res.data.success, msg: "Your data field is Empty", data: [] };
        }
      } else {
        return { success: res.data.success, msg: res.data.error, data: res.data };
      }
    } catch {
      return { success: false, msg: "Your Action is failed. Please try Again", data: [] };
    }
  } else {
    return null; // No token
  }
};

export default uploadFile;
