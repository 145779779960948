import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: "auto",
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
      //   backgroundColor: theme.palette.grey[50],
      // },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.body2,
        fontWeight: "bold",
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontWeight: "bold",
      },
    },
    topHeading: {
      "&.MuiTableCell-root": {
        color: theme.palette.primary.main,
      },
    },
    tableBody: {
      backgroundColor: theme.palette.grey[50],
    },
    bgGreen: {
      backgroundColor: "#cce6d8",
    },
    bgRed: {
      backgroundColor: "#ffdad1",
    },
  })
);

const getCellValue = (value: any): React.ReactNode => {
  return value === true ? "OK" : value === false ? "Not Sufficient" : "-";
};

interface AllocStatusTableProps {
  isJoint: boolean;
  columns: React.ReactNode[];
  rows: React.ReactNode[][];
  className?: string;
}

const AllocStatusTable: React.FC<AllocStatusTableProps> = ({
  isJoint,
  columns,
  rows,
  className = "",
}) => {
  const classes = useStyles();

  const headingColumns = isJoint ? columns : columns.slice(-5);
  const filteredRows = isJoint ? rows : rows.map(row => row.slice(-5));

  return (
    <div className={clsx(classes.root, className)}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {isJoint && (
              <TableCell colSpan={4} className={classes.topHeading}>
                <FormattedMessage id="sustainabilityView.reportsView.jointFulfillment" />
              </TableCell>
            )}
            <TableCell colSpan={5} className={classes.topHeading}>
              <FormattedMessage id="sustainabilityView.reportsView.allocationTable.ownFulfillment" />
            </TableCell>
          </TableRow>
          <TableRow>
            {headingColumns.map((heading, headingIndex) => (
              <TableCell key={`heading-${headingIndex}`}>{heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {filteredRows.map((cells, cellsIndex) => (
            <TableRow key={`row-${cellsIndex}`}>
              {cells.map((cell, cellIndex) => (
                <TableCell
                  key={`cell-${cellsIndex}-${cellIndex}`}
                  className={clsx({
                    [classes.bgGreen]: cell === true,
                    [classes.bgRed]: cell === false,
                  })}
                >
                  {getCellValue(cell)}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {filteredRows.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Typography variant="body2" align="center">
                  <FormattedMessage id="exciseDutyView.reportsView.mainView.noData" />
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default AllocStatusTable;
