import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormattedMessage } from "react-intl";
import POSReport from "../../PDFView/POSReport";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      paddingLeft: theme.spacing(0.5),
    },
    margin: {
      margin: theme.spacing(1),
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0),
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "inherit",
        fontWeight: "bold",
        paddingBottom: theme.spacing(2),
      },
    },
  })
);

interface Props {
  data: any;
}

const ButtonDownloadCustomerReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  const Button = (loading: boolean, language: any) => (
    <IconButton disabled={loading}>
      <GetAppIcon fontSize="small" />
      <Typography className={classes.btn} variant="body2" gutterBottom>
        {language}
      </Typography>
    </IconButton>
  );

  const downloadButton = (
    language: "EN" | "NO",
    reportData: any,
    idx: number
  ) => (
    <PDFDownloadLink
      key={idx + language}
      style={{ textDecoration: "none" }}
      document={<POSReport language={language} data={reportData} />}
      fileName={"Bærekraftrapport-" + reportData.note_ref}
    >
      {({ blob, url, loading, error }) => {
        return Button(loading, language);
      }}
    </PDFDownloadLink>
  );

  let Buttons = data.map((report: any, idx: number) => {
    const en = downloadButton("EN", report, idx);
    const no = downloadButton("NO", report, idx);
    return { en: en, no: no };
  });

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>
            <FormattedMessage id="sustainabilityView.storage.modalPOS.deliveryPeriod" />
          </TableCell>
          <TableCell>
            <FormattedMessage id="sustainabilityView.storage.modalPOS.product" />
          </TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((report: any, idx: number) => (
          <TableRow key={idx}>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                {report.delivery_period}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" gutterBottom>
                {report.biofuel_type}
              </Typography>
            </TableCell>
            <TableCell style={{ textAlign: "right" }}>
              {Buttons[idx].en}
              {Buttons[idx].no}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default React.memo(ButtonDownloadCustomerReport);
