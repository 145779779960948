import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ButtonRounded from "../../../../components/ButtonRounded";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ModalContainer from "../../../../components/ModalContainer";
import {postBiotemplateReservations} from "../../../../api/sustainability/storage/outgoing-transaction/postBiotemplateReservations";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      marginTop: theme.spacing(3),
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnLeft: {
      marginRight: theme.spacing(3),
    },
    noteText: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    commentTitle: {
      fontWeight: 500,
    },
    errorMsg: {
      marginTop: theme.spacing(2),
    },
  })
);

interface ModalSaveTransactionProps {
  open: boolean;
  onClose: () => void;
  data: {
    customer_name: any;
    volume: any;
    biofuel_type: any;
    delivery_period: any;
    storage_date: any;
    status: any;
    biotemplates: any[];
  };
}

const ModalSaveTransaction: React.FC<ModalSaveTransactionProps> = ({
  open,
  onClose,
  data,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [comment, setComment] = useState<string>("");
  const classes = useStyles();
  const history = useHistory();

  const hasData: boolean =
    Array.isArray(data.biotemplates) && data.biotemplates.length > 0;

  const canSubmit: boolean = hasData && comment.length > 0;

  const handleSave = async () => {
    const res = await postBiotemplateReservations({
      customer_name: data.customer_name,
      volume: data.volume,
      biofuel_type: data.biofuel_type,
      delivery_period: data.delivery_period,
      storage_date: data.storage_date,
      status: data.status,
      comment,
      biotemplates: data.biotemplates,
    });
    if (res && res.success) {
      onClose();
      history.goBack();
    } else {
      setIsError(true);
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title="Save Transaction"
      maxWidth="xs"
    >
      <div className={classes.modalContent}>
        <Typography variant="body1" paragraph className={classes.noteText}>
          Note: Saving this transaction will reset your allocation (reporting
          and simulation)
        </Typography>
        <Typography variant="body1" paragraph>
          To save the transaction, leave a comment describing what changes were
          made and why.
        </Typography>

        <Typography variant="body1" className={classes.commentTitle}>
          Comment <RequiredAsterisk />
        </Typography>
        <TextField
          variant="outlined"
          name="comment"
          placeholder="Write a comment"
          multiline
          fullWidth
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />

        <Grid container className={classes.btnContainer} spacing={2}>
          <Grid item xs>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="secondary"
              onClick={onClose}
            >
              Cancel
            </ButtonRounded>
          </Grid>
          <Grid item xs>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="primary"
              disabled={!canSubmit}
              onClick={handleSave}
            >
              Save
            </ButtonRounded>
          </Grid>
        </Grid>

        {isError && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}
          >
            Failed to save.
          </Typography>
        )}
        {!hasData && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}
          >
            Allocated quantity in the table can not be blank.
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalSaveTransaction;
