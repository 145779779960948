import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../ButtonRounded";
import ModalContainer from "../ModalContainer";

import { IModalInfoProps } from "./Interfaces";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    userContainer: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 0),
    },
    btnCancel: {},
  })
);

const ModalDeleteUser: React.FC<IModalInfoProps> = ({
  text,
  message,
  open,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={text}
      details={message}
    >
      <div className={classes.modalContent}>
        <Grid container spacing={2} className={classes.btnContainer}>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="primary"
              onClick={onClose}
            >
              OK
            </ButtonRounded>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </div>
    </ModalContainer>
  );
};

export default ModalDeleteUser;
