import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../../../../components/ButtonRounded";
import ButtonArrowBack from "../../../../../components/ButtonArrowBack";
import ExtendableList from "../../../../../components/ExtendableList";
import { Card, CardContent, Typography, Fab } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import CustomerRequirementProperties from "./CustomerRequirementProperties";
import ModalAddEditCustomerRequirements from "./ModalAddEditCustomerRequirements";
import ModalDelete from "../../../../../components/modal/ModalDelete";
import useModal from "../../../../../components/modal/useModal";

import deleteCustomerRequirements from "../../../../../api/sustainability/customerRequirements/deleteCustomerRequirements";
import getCustomerSoldProducts from "../../../../../api/sustainability/customerSoldProducts/getCustomerSoldProducts";
import getTaxYears from "../../../../../api/sustainability/taxYears/get";
import SmartSelect from "../../../../../components/SmartSelect";
import ModalInfo from "../../../../../components/modal/ModalInfo";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btn: {
      "&:not(:first-child)": {
        marginLeft: "20px",
        [theme.breakpoints.down("md")]: {
          marginLeft: "10px",
        },
      },
    },
    select: {
      width: "150px",
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
    root: {
      paddingLeft: theme.spacing(3.75),
      paddingRight: theme.spacing(3.75),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    iconDelete: {
      // color: theme.palette.error.main,
      marginLeft: "20px",
    },
    iconEdit: {
      marginLeft: "30px",
    },
    // select: {
    //   width: "120px",
    //   margin: theme.spacing(3, 0),
    //   marginRight: theme.spacing(1),
    //   color: theme.palette.primary.main,
    //   [theme.breakpoints.down("md")]: {
    //     width: "100px",
    //   },
    // },
  })
);

interface ICustomerRequirementsViewProps {
  selected_customer: any;
  editCustomer: any;
  deleteCustomer: any;
  modal_show_download_report: boolean;
  setShowModalDownloadReport: (show: boolean) => void;
  selectedYear: any;
  setSelectedYear: any;
  taxPeriods: any;
}

const CustomerRequirementView: React.FC<ICustomerRequirementsViewProps> = ({
  selected_customer,
  editCustomer,
  deleteCustomer,
  modal_show_download_report,
  setShowModalDownloadReport,
  setSelectedYear,
  taxPeriods,
  selectedYear,
}) => {
  const classes = useStyles();
  const [list, setList] = useState<any[]>([]);
  const [infoModal, setInfoModal] = useState({ title: "", description: "" });
  const { openModalId, toggle } = useModal();
  const [selectedEntry, setSelectedEntry] = useState<any>(null);
  const [filteredList, setFilteredList] = useState<any[]>(list);

  const openInfoModal = (id: string, text: string, message: string) => {
    if (id === "info") {
      setInfoModal({ title: text, description: message });
    }
    toggle(id);
  };

  const closeInfoModal = () => {
    toggle();
  };

  const openModal = (id: string, selectedEntry?: any) => {
    if (selectedEntry) setSelectedEntry(selectedEntry);
    toggle(id);
  };

  const closeModal = () => {
    fetchCustomerSoldProducts();
    filterRequirements();
    toggle();
  };

  const onResponse = (response: string, description?: string) => {
    openInfoModal("info", response, description ? description : "");
  };

  const filterRequirements = () => {
    setFilteredList(
      list.filter((item: any) => item.tax_year.includes(selectedYear))
    );
  };

  useEffect(() => {
    fetchCustomerSoldProducts();
    filterRequirements();
  }, [selectedYear]);

  const fetchCustomerSoldProducts = async () => {
    //const res = await getStorages();
    const res = await getCustomerSoldProducts(selected_customer.id);
    if (res && res.valid) {
      setList(res.data);
      setFilteredList(
        res.data.filter((item: any) => item.tax_year.includes(selectedYear))
      );
    }
  };

  return (
    <div>
      <Grid container justify="space-between" className={classes.titleBar}>
        <Grid item>
          <ButtonArrowBack />
        </Grid>
        <Grid item>
          <ButtonRounded
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={() => setShowModalDownloadReport(true)}
          >
            <FormattedMessage id="customerView.downloadReport" />
          </ButtonRounded>
        </Grid>
      </Grid>
      {/* select year */}
      <Card>
        <CardContent className={classes.root}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.titleBar}
          >
            <Grid
              container
              justify="flex-start"
              alignItems="center"
              item
              xs="auto"
              sm={8}
            >
              <Grid item>
                <Typography variant="h5">{selected_customer.name}</Typography>
                <Typography variant="subtitle1">
                  {"Customer nr. " + selected_customer.customer_no}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs="auto" sm="auto">
              <Select
                fullWidth
                className={classes.select}
                input={<OutlinedInput labelWidth={0} />}
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                {taxPeriods
                  ? taxPeriods.map((year: any, index: number) => (
                      <MenuItem
                        value={Object.keys(year)[0]}
                        key={
                          "Sus.View_Reports_Year_" +
                          Object.keys(year)[0] +
                          "-" +
                          index
                        }
                      >
                        {Object.keys(year)[0]}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </Grid>
          </Grid>
          <ExtendableList
            expandProperties={CustomerRequirementProperties}
            list={filteredList}
            action={{
              deleteEntry: null,
              editEntry: null,
            }}
          />
        </CardContent>
      </Card>
      {/* {selectedEntry && (
        <>
          <ModalDelete
            name={"Customer Requirement"}
            id={selectedEntry.id}
            Delete={deleteCustomerRequirements}
            open={openModalId === "delete"}
            onClose={closeModal}
          />
          <ModalAddEditCustomerRequirements
            open={openModalId === "edit"}
            onClose={closeModal}
            onResponse={onResponse}
            mode="edit"
            customerRequirements={selectedEntry}
          />
        </>
      )}
      <ModalAddEditCustomerRequirements
        open={openModalId === "add"}
        onClose={closeModal}
        onResponse={onResponse}
        mode="add"
        customerRequirements={{ customer_id: selected_customer.id }}
      />
      <ModalInfo
        text={infoModal.title}
        message={infoModal.description}
        open={openModalId === "info"}
        onClose={closeInfoModal}
      /> */}
    </div>
  );
};

export default CustomerRequirementView;
