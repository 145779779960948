import { FormattedMessage } from "react-intl";
import React from "react";

export default [
  {
    name: <FormattedMessage id="settingsView.index.storageTerminal.code" />,
    id: "code",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.storageTerminal.name" />,
    id: "name",
    type: "text",
  },
];
