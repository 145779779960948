import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import Link from "@material-ui/core/Link";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormattedMessage } from "react-intl";
import HBLPOSReport from "../../PDFView/HBLPOSReport";
import GetAppIcon from "@material-ui/icons/GetApp";
import IconButton from "@material-ui/core/IconButton";
import ButtonRounded from "../../../../components/ButtonRounded";
import getDownloadHBLReport from "../../../../api/sustainability/storage/getDownloadHBLReport";
import ReactPDF, { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      btn: {
        paddingLeft: theme.spacing(0.5),
      },
      margin: {
        margin: theme.spacing(1),
      },
      table: {
        "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
          backgroundColor: theme.palette.grey[50],
        },
        "& .MuiTableCell-root": {
          border: "none",
          paddingTop: theme.spacing(0),
          paddingBottom: theme.spacing(0),
        },
        "& .MuiTableCell-head": {
          color: "inherit",
          fontSize: "inherit",
          fontWeight: "bold",
          paddingBottom: theme.spacing(2),
        },
      },
    })
);

interface Props {
  data: any;
}

const ButtonDownloadCustomerReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  const Button = (loading: boolean, language: any) => (
      <IconButton>
        <GetAppIcon fontSize="small" />
        <Typography className={classes.btn} variant="body2" gutterBottom>
          {language}
        </Typography>
      </IconButton>
  );

  const handleDownloadPdfClick = async (language: string, reportData: any, fileName: string) => {
    try {
      const pdfBlob = await pdf(<HBLPOSReport language={language as "EN" | "NO"} data={reportData} />).toBlob();
      saveAs(pdfBlob, fileName + ".pdf");
    } catch (error) {
      console.error("Error generating or downloading PDF:", error);
    }
  };

  const downloadButton = (
      language: "EN" | "NO",
      reportData: any,
      idx: number
  ) => (
      <div>
        <Link
            key={idx + language}
            onClick={() => handleDownloadPdfClick(language, reportData, reportData.pos_id +"_POS")}
        >
          <IconButton>
            <GetAppIcon fontSize="small" />
            <Typography className={classes.btn} variant="body2" gutterBottom>
              {language}
            </Typography>
          </IconButton>
        </Link>
      </div>
  );

  const handleDownload = async (reservation_id: number, POS_ID: String) => {
    const response = await getDownloadHBLReport(reservation_id);
    if (response !== null) {
      // emulate file download by clicking on an <a /> link
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${POS_ID}_data.xlsx`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  let Buttons = data.map((report: any, idx: number) => {
    const en = downloadButton("EN", report, idx);
    const no = downloadButton("NO", report, idx);
    return { en: en, no: no };
  });

  return (
      <div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                <FormattedMessage id="sustainabilityView.storage.modalPOS.deliveryPeriod" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="sustainabilityView.storage.modalPOS.product" />
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((report: any, idx: number) => (
                <TableRow key={idx}>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {report.delivery_period}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" gutterBottom>
                      {report.biofuel_type}
                    </Typography>
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    <ButtonRounded
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={(e) => handleDownload(Number(e.currentTarget.dataset['id']), String(report.pos_id))}
                        key={report.reservation_id}
                        data-id={report.reservation_id}
                    >(EXCEL)
                    </ButtonRounded>
                  </TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {Buttons[idx].en}
                    {Buttons[idx].no}
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
  );
};

export default React.memo(ButtonDownloadCustomerReport);
