import * as Yup from "yup";
import { setIn } from "final-form";

const makeValidate = async (
  values: any,
  validationSchema: Yup.ObjectSchema
) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
  } catch (e) {
    return e.inner.reduce((errors: any, error: any) => {
      return setIn(errors, error.path, error.message);
    }, {});
  }
};

export default makeValidate;
