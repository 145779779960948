import { Action, handleActions } from "redux-actions";
import { ErrorPayload } from "../../../../common/payloads/ErrorActionPayload";
import api_allocationRequirements from "../../../../api/card/allocationSteps/allocationRequirements";
import api_setAllocationRequirement from "../../../../api/card/allocationSteps/setAllocationRequirement";
import api_sustainabilityPool from "../../../../api/card/allocationSteps/sustainabilityPool";
import api_allocateCustomer from "../../../../api/card/allocationSteps/allocateCustomer";
import api_setAllocateCustomer from "../../../../api/card/allocationSteps/setAllocateCustomer";

import api_startAllocation from "../../../../api/card/allocationSteps/allocationConstruction";
import api_setAllocationConstruction from "../../../../api/card/allocationSteps/setAllocationConstruction";
import api_constructionStatus from "../../../../api/card/allocationSteps/constructionStatus";
import api_allocateConstructionReports from "../../../../api/card/allocationSteps/allocateConstructionReports";
import api_setAllocateConstructionReports from "../../../../api/card/allocationSteps/setAllocateConstructionReports";

// State Interface
export interface MultiStepState {
  isFetching: boolean;
  hasStarted: boolean;
  activeStep: number;
  sustainability_quantities: any;
  customer_requirements: any;
  purchased: any;
  requirement_quantities_init: any;
  requirement_quantities: any;
  available_quantities_part1: any;
  available_quantities_part2: any;
  available_quantities_construction: any;
  construction_quantities_hvo: any;
  construction_quantities_fame: any;
  construction_quantities_etanol: any;
  construction_quantities_nafta: any;
  steps: {
    [x: number]: {
      name: string;
      isDirty: boolean;
      status: "Complete" | "Not Complete" | "Overallocated";
      [y: string]: any;
    };
  };
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  hasStarted: false,
  activeStep: 0,
  sustainability_quantities: null,
  available_quantities_part1: null,
  purchased: {
    biodiesel_double_count: null,
    biodiesel_single_count: null,
    bioethanol_double_count: null,
    bioethanol_single_count: null,
  },
  requirement_quantities_init: null,
  requirement_quantities: null,
  available_quantities_part2: null,
  customer_requirements: {
    biodiesel_double_count: null,
    biodiesel_single_count: null,
    bioethanol_double_count: null,
    bioethanol_single_count: null,
  },
  available_quantities_construction: null,
  construction_quantities_hvo: null,
  construction_quantities_fame: null,
  construction_quantities_etanol: null,
  construction_quantities_nafta: null,
  steps: {
    0: {
      name: "FAME Requirements",
      isDirty: false,
      status: "Not Complete",
      isComplete: false,
      data: [],
      allocated: {},
    },
    1: {
      name: "HVO/HRO Requirements",
      isDirty: false,
      status: "Not Complete",
      isComplete: false,
      data: [],
      allocated: {},
    },
    2: {
      name: "Etanol Requirements",
      isDirty: false,
      status: "Not Complete",
      isComplete: false,
      data: [],
      allocated: {},
    },
    3: {
      name: "Nafta Requirements",
      isDirty: false,
      status: "Not Complete",
      isComplete: false,
      data: [],
      allocated: {},
    },
    4: {
      name: "Matching",
      isDirty: false,
      status: "Not Complete",
      isComplete: false,
      sustainable_batches: [],
      allocation_status_volume: {
        sold_volume: {},
        allocated_volume: {},
        net_volume: {},
      },
      allocation_status_ton_equivalence: {
        sold_ton_equivalence: {},
        allocated_ton_equivalence: {},
        net_ton_equivalence: {},
      },
    },
    5: {
      name: "Customer Requirements",
      isDirty: false,
      status: "Not Complete",
      isComplete: false,
      data: [],
      allocated: {},
      allocated_sorted: {},
      sumAllocated: {},
    },
    6: {
      name: "Overview",
      isDirty: false,
      status: "Not Complete",
    },
  },
  error: undefined,
} as MultiStepState;

// Actions
const MULTISTEP_CARD_START_ALLOCATION_REQUEST =
  "card/multiStep/START_ALLOCATION_REQUEST";
const MULTISTEP_CARD_START_ALLOCATION_SUCCESS =
  "card/multiStep/START_ALLOCATION_SUCCESS";
const MULTISTEP_STEP_2_REQUEST = "multiStep/STEP_2_REQUEST";
const MULTISTEP_STEP_2_SUCCESS = "multiStep/STEP_2_SUCCESS";
const MULTISTEP_SAVE_STEP_5_REQUEST = "multiStep/SAVE_STEP_5_REQUEST";
const MULTISTEP_SAVE_STEP_5_SUCCESS = "multiStep/SAVE_STEP_5_SUCCESS";
const MULTISTEP_STEP_6_REQUEST = "multiStep/STEP_6_REQUEST";
const MULTISTEP_STEP_6_SUCCESS = "multiStep/STEP_6_SUCCESS";
const MULTISTEP_CUSTOMER_REQ_REQUEST = "multiStep/CUSTOMER_REQ_REQUEST";
const MULTISTEP_CUSTOMER_REQ_SUCCESS = "multiStep/CUSTOMER_REQ_SUCCESS";
const MULTISTEP_SAVE_CUSTOMER_REQ_REQUEST =
  "multiStep/SAVE_CUSTOMER_REQ_REQUEST";
const MULTISTEP_SAVE_CUSTOMER_REQ_SUCCESS =
  "multiStep/SAVE_CUSTOMER_REQ_SUCCESS";
const MULTISTEP_ALLOCATION_CONSTR_REQUEST =
  "multiStep/ALLOCATION_CONSTR_REQUEST";
const MULTISTEP_ALLOCATION_CONSTR_SUCCESS =
  "multiStep/ALLOCATION_CONSTR_SUCCESS";
const MULTISTEP_CARD_SAVE_ALLOCATION_CONSTR_REQUEST =
  "card/multiStep/SAVE_ALLOCATION_CONSTR_REQUEST";
const MULTISTEP_CARD_SAVE_ALLOCATION_CONSTR_SUCCESS =
  "card/multiStep/SAVE_ALLOCATION_CONSTR_SUCCESS";
const MULTISTEP_CARD_CONSTRUCTION_MATCHING_REQUEST =
  "card/multiStep/CONSTRUCTION_MATCHING_REQUEST";
const MULTISTEP_CARD_CONSTRUCTION_MATCHING_SUCCESS =
  "card/multiStep/CONSTRUCTION_MATCHING_SUCCESS";
const MULTISTEP_CARD_CUSTOMER_REQ_REQUEST =
  "card/multiStep/CONST_CUSTOMER_REQ_REQUEST";
const MULTISTEP_CARD_CUSTOMER_REQ_SUCCESS =
  "card/multiStep/CONST_CUSTOMER_REQ_SUCCESS";
const MULTISTEP_SAVE_CONST_CUSTOMER_REQ_REQUEST =
  "multistep/SAVE_CONST_CUSTOMER_REQ_REQUEST";
const MULTISTEP_SAVE_CONST_CUSTOMER_REQ_SUCCESS =
  "multistep/SAVE_CONST_CUSTOMER_REQ_SUCCESS";
const MULTISTEP_END_ALLOCATION_SUCCESS = "multiStep/END_ALLOCATION_SUCCESS";
const MULTISTEP_CARD_CHANGE_STEP = "card/multiStep/CHANGE_STEP";
const MULTISTEP_ALLOCATE_UPDATE_STEP_1 = "multiStep/ALLOCATE_UPDATE_STEP_1";
const MULTISTEP_CARD_ALLOCATE_UPDATE_CONSTRUCTION_REQ =
  "card/multiStep/ALLOCATE_UPDATE_CONSTRUCTION_REQ";
const MULTISTEP_CARD_ALLOCATE_UPDATE_CUSTOMER_REQ =
  "card/multiStep/ALLOCATE_UPDATE_CUSTOMER_REQ";
const MULTISTEP_ALLOCATE = "multiStep/ALLOCATE";
const MULTISTEP_UPDATE_REQUIREMENT_QUANTITIES =
  "multiStep/UPDATE_REQUIREMENT_QUANTITIES";
const MULTISTEP_CARD_ERROR = "card/multiStep/ERROR";

// Action creators
function requestStep2() {
  return {
    type: MULTISTEP_STEP_2_REQUEST,
  };
}

function receiveStep2(data: any) {
  return {
    type: MULTISTEP_STEP_2_SUCCESS,
    payload: data,
  };
}

function requestSaveStep5() {
  return {
    type: MULTISTEP_SAVE_STEP_5_REQUEST,
  };
}

function receiveSaveStep5() {
  return {
    type: MULTISTEP_SAVE_STEP_5_SUCCESS,
  };
}

function requestStep6() {
  return {
    type: MULTISTEP_STEP_6_REQUEST,
  };
}

function receiveStep6(data: any) {
  return {
    type: MULTISTEP_STEP_6_SUCCESS,
    payload: data,
  };
}

function requestCustomerReq() {
  return {
    type: MULTISTEP_CUSTOMER_REQ_REQUEST,
  };
}

function receiveCustomerReq(data: any) {
  return {
    type: MULTISTEP_CUSTOMER_REQ_SUCCESS,
    payload: data,
  };
}

function requestSaveCustomerReq() {
  return {
    type: MULTISTEP_SAVE_CUSTOMER_REQ_REQUEST,
  };
}

function receiveSaveCustomerReq() {
  return {
    type: MULTISTEP_SAVE_CUSTOMER_REQ_SUCCESS,
  };
}

function requestStartAllocation() {
  return {
    type: MULTISTEP_CARD_START_ALLOCATION_REQUEST,
  };
}

function receiveStartAllocation(data: any) {
  return {
    type: MULTISTEP_CARD_START_ALLOCATION_SUCCESS,
    payload: data,
  };
}

function requestSaveAllocationConstruction() {
  return {
    type: MULTISTEP_CARD_SAVE_ALLOCATION_CONSTR_REQUEST,
  };
}

function receiveSaveAllocationConstruction() {
  return {
    type: MULTISTEP_CARD_SAVE_ALLOCATION_CONSTR_SUCCESS,
  };
}

function requestConstructionMatching() {
  return {
    type: MULTISTEP_CARD_CONSTRUCTION_MATCHING_REQUEST,
  };
}

function receiveConstructionMatching(data: any) {
  return {
    type: MULTISTEP_CARD_CONSTRUCTION_MATCHING_SUCCESS,
    payload: data,
  };
}

function requestConstructionCustomerReq() {
  return {
    type: MULTISTEP_CARD_CUSTOMER_REQ_REQUEST,
  };
}

function receiveConstructionCustomerReq(data: any) {
  return {
    type: MULTISTEP_CARD_CUSTOMER_REQ_SUCCESS,
    payload: data,
  };
}

function requestSaveConstructionCustomerReq() {
  return {
    type: MULTISTEP_SAVE_CONST_CUSTOMER_REQ_REQUEST,
  };
}

function receiveSaveConstructionCustomerReq(data: any) {
  return {
    type: MULTISTEP_SAVE_CONST_CUSTOMER_REQ_SUCCESS,
    payload: data,
  };
}

function errorAllocation(error: any) {
  return {
    type: MULTISTEP_CARD_ERROR,
    payload: { error },
  };
}

function hasChangedStep(step: number) {
  return {
    type: MULTISTEP_CARD_CHANGE_STEP,
    payload: step,
  };
}

function allocateUpdateStep1(
  id: number,
  index: number,
  value: number,
  requirements: any
) {
  return {
    type: MULTISTEP_ALLOCATE_UPDATE_STEP_1,
    payload: {
      id: id,
      index: index,
      value: value,
      requirements: requirements,
    },
  };
}

export function allocateUpdateConstructionReq(
  id: number,
  index: number,
  value: number
) {
  return {
    type: MULTISTEP_CARD_ALLOCATE_UPDATE_CONSTRUCTION_REQ,
    payload: {
      id: id,
      index: index,
      value: value,
    },
  };
}

export function allocateUpdateCustomerReq(
  id: number,
  index: number,
  value: number,
  requirement: { id: number; index: number; type: string },
  customer: { id: number; index: number }
) {
  return {
    type: MULTISTEP_CARD_ALLOCATE_UPDATE_CUSTOMER_REQ,
    payload: {
      id: id,
      index: index,
      value: value,
      requirement: requirement,
      customer: customer,
    },
  };
}

function allocateData(
  id: number,
  index: number,
  value: number,
  isDoubleCount: boolean
) {
  return {
    type: MULTISTEP_ALLOCATE,
    payload: {
      id: id,
      index: index,
      value: value,
      isDoubleCount: isDoubleCount,
    },
  };
}

function updateReqQuantities() {
  return {
    type: MULTISTEP_UPDATE_REQUIREMENT_QUANTITIES,
  };
}

export function editAllocatedData(
  id: number,
  index: number,
  value: number,
  isDoubleCount: boolean,
  isDiesel: boolean
) {
  return async (dispatch: any, getState: any) => {
    const state = getState().card.allocation.multiStep;
    if (state.activeStep < 4) {
      dispatch(allocateUpdateConstructionReq(id, index, value));
    } else if (state.activeStep < 7) {
      dispatch(allocateData(id, index, value, isDoubleCount));
      dispatch(updateReqQuantities());
    }
  };
}

export function startAllocation() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.card.allocation.multiStep) {
      dispatch(requestStartAllocation());
      try {
        const response = await api_startAllocation();
        if (response.valid) {
          dispatch(receiveStartAllocation(response));
        }
      } catch (error) {
        dispatch(errorAllocation(error));
      }
    }
    return Promise.resolve;
  };
}

function goToStep7() {
  return async (dispatch: any) => {
    dispatch(requestCustomerReq());
    try {
      const response = await api_allocateCustomer();
      if (response.valid) {
        dispatch(receiveCustomerReq(response.customers));
      }
    } catch (error) {
      dispatch(errorAllocation(error));
    }
  };
}

function goToStep8() {
  return async (dispatch: any, getState: any) => {
    const state = getState().card.allocation.multiStep;
    dispatch(requestSaveCustomerReq());

    const getCustomerReqAllocated = () => {
      const allocated_sorted = state.steps[6].allocated_sorted;
      let allocated: any = {};
      Object.keys(allocated_sorted).length > 0 &&
        Object.keys(allocated_sorted).map((cust_id: any, cust_idx: number) => {
          Object.values(allocated_sorted[cust_id]).map(
            (req: any, req_idx: number) =>
              Object.values(req).map((type: any, type_idx: number) =>
                Object.keys(type).map((row_id: any, idx: number) => {
                  allocated = {
                    ...allocated,
                    [cust_id]: {
                      ...allocated[cust_id],
                      [row_id]: type[row_id],
                    },
                  };
                })
              )
          );
        });
      return allocated;
    };
    const apiAllocated = getCustomerReqAllocated();

    try {
      const response = await api_setAllocateCustomer(apiAllocated);
      if (response.valid) {
        dispatch(receiveSaveCustomerReq());
      }
    } catch (error) {
      dispatch(errorAllocation(error));
    }
  };
}

function goToStep5() {
  return async (dispatch: any, getState: any) => {
    dispatch(requestSaveAllocationConstruction());
    const state = getState().card.allocation.multiStep;
    try {
      const response = await api_setAllocationConstruction({
        ...state.steps[0].allocated,
        ...state.steps[1].allocated,
        ...state.steps[2].allocated,
        ...state.steps[3].allocated,
      });
      if (response.valid) {
        dispatch(receiveSaveAllocationConstruction());
        dispatch(requestConstructionMatching());
        const response = await api_constructionStatus();
        if (response.valid) {
          dispatch(receiveConstructionMatching(response));
        }
      }
    } catch (error) {
      dispatch(errorAllocation(error));
    }
  };
}

function goToStep6() {
  return async (dispatch: any) => {
    dispatch(requestConstructionCustomerReq());
    try {
      const response = await api_allocateConstructionReports();
      if (response.valid) {
        dispatch(receiveConstructionCustomerReq(response.customers));
      }
    } catch (error) {
      dispatch(errorAllocation(error));
    }
  };
}

function endAllocation() {
  return async (dispatch: any, getState: any) => {
    dispatch(requestSaveConstructionCustomerReq());
    const state = getState().card.allocation.multiStep;

    const getCustomerReqAllocated = () => {
      const allocated_sorted = state.steps[5].allocated_sorted;

      //console.log("checking missing step2: "+JSON.stringify(allocated_sorted))

      let allocated: any = {};
      Object.keys(allocated_sorted).length > 0 &&
      Object.keys(allocated_sorted).map((cust_id, cust_idx) => { 
        Object.keys(allocated_sorted[cust_id]).map((an_id, an_idx)=> { 
                allocated = {
                  ...allocated,
                  [an_id]: {
                    ...allocated_sorted[cust_id][an_id],
                  },
                };
              })
            });
      return allocated;
    };
    const apiAllocated = getCustomerReqAllocated();

    try {
      const response = await api_setAllocateConstructionReports(apiAllocated);
      //console.log("checking missing step2: "+JSON.stringify(apiAllocated))
      if (response.valid) {
        dispatch(receiveSaveConstructionCustomerReq(response));
      }
    } catch (error) {
      dispatch(errorAllocation(error));
    }
  };
}

export function changeStep(step: number) {
  return async (dispatch: any, getState: any) => {
    const state = getState().card.allocation.multiStep;
    if (
      !state.isFetching &&
      (state.steps[step].isDirty || step - state.activeStep <= 1)
    ) {
      dispatch(hasChangedStep(step));

      // if moving forward run the API calls
      if (step > state.activeStep) {
        if (step === 4) {
          dispatch(goToStep5());
        }
        if (step === 5) {
          dispatch(goToStep6());
        }
        if (step > 5) {
          dispatch(endAllocation());
        }
      }
      return Promise.resolve;
    }
  };
}

// Reducer Definition
export default handleActions<MultiStepState>(
  {
    [MULTISTEP_STEP_2_REQUEST]: handleStepRequest,
    [MULTISTEP_STEP_2_SUCCESS]: handleStep2Success,
    [MULTISTEP_SAVE_STEP_5_REQUEST]: handleStepRequest,
    [MULTISTEP_SAVE_STEP_5_SUCCESS]: handleSaveStepSuccess,
    [MULTISTEP_STEP_6_REQUEST]: handleStepRequest,
    [MULTISTEP_STEP_6_SUCCESS]: handleStep6Success,
    [MULTISTEP_CUSTOMER_REQ_REQUEST]: handleStepRequest,
    [MULTISTEP_CUSTOMER_REQ_SUCCESS]: handleCustomerReqSuccess,
    [MULTISTEP_SAVE_CUSTOMER_REQ_REQUEST]: handleStepRequest,
    [MULTISTEP_SAVE_CUSTOMER_REQ_SUCCESS]: handleSaveStepSuccess,
    [MULTISTEP_CARD_START_ALLOCATION_REQUEST]: handleStartAllocationRequest,
    [MULTISTEP_CARD_START_ALLOCATION_SUCCESS]: handleStartAllocationSuccess,
    [MULTISTEP_CARD_SAVE_ALLOCATION_CONSTR_REQUEST]: handleStepRequest,
    [MULTISTEP_CARD_SAVE_ALLOCATION_CONSTR_SUCCESS]: handleSaveStepSuccess,
    [MULTISTEP_CARD_CONSTRUCTION_MATCHING_REQUEST]: handleStepRequest,
    [MULTISTEP_CARD_CONSTRUCTION_MATCHING_SUCCESS]:
      handleConstructionMatchingSuccess,
    [MULTISTEP_CARD_CUSTOMER_REQ_REQUEST]: handleStepRequest,
    [MULTISTEP_CARD_CUSTOMER_REQ_SUCCESS]: handleCustomerReqSuccess,
    [MULTISTEP_SAVE_CONST_CUSTOMER_REQ_REQUEST]: handleStepRequest,
    [MULTISTEP_SAVE_CONST_CUSTOMER_REQ_SUCCESS]: handleSaveStepSuccess,
    [MULTISTEP_END_ALLOCATION_SUCCESS]: handleEndAllocationSuccess,
    [MULTISTEP_CARD_CHANGE_STEP]: handleChangeStep,
    [MULTISTEP_ALLOCATE_UPDATE_STEP_1]: handleAllocateUpdateStep1,
    [MULTISTEP_CARD_ALLOCATE_UPDATE_CONSTRUCTION_REQ]:
      handleAllocateUpdateConstructionReq,
    [MULTISTEP_CARD_ALLOCATE_UPDATE_CUSTOMER_REQ]:
      handleallocateUpdateCustomerReq,
    [MULTISTEP_ALLOCATE]: handleAllocate,
    [MULTISTEP_UPDATE_REQUIREMENT_QUANTITIES]: handleUpdateReqQuantities,
    [MULTISTEP_CARD_ERROR]: handleError,
  } as any,
  initialState
);

// Reducer Description

function handleStepRequest(state: MultiStepState, action: Action<any>) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handleStep2Success(state: MultiStepState, action: Action<any>) {
  const updateAvailableQuantities = () => {
    const steps_id = [
      "gasoline_req",
      "adv_biofuels_req",
      "general_req",
      "above_req",
    ];
    let available_quantities = state.available_quantities_part2;
    steps_id.map((step: string) => {
      action.payload[step].biotemplates.map((row: any) => {
        let newValue = row.available_quantity;
        available_quantities = {
          ...available_quantities,
          [row.id]: newValue,
        };
      });
    });
    return available_quantities;
  };

  const getRequirementQuantities = (data: any) => {
    const { biotemplates, ...rest } = data;
    return { ...rest };
  };

  const getTotAllocated = (data: any) => {
    let totSimple = 0;
    let totDouble = 0;
    let totDoubleOnly = 0;
    data.map((row: any) => {
      totSimple += parseFloat(row.allocated_quantity);
      totDouble +=
        row.double_counted_norway === "Ja"
          ? parseFloat(row.allocated_quantity) * 2
          : parseFloat(row.allocated_quantity);
      totDoubleOnly +=
        row.double_counted_norway === "Ja"
          ? parseFloat(row.allocated_quantity) * 2
          : 0;
    });
    return { simple: totSimple, double: totDouble, doubleOnly: totDoubleOnly };
  };

  const getAllocated = () => {
    let allocated: any = {
      gasoline_req: {},
      adv_biofuels_req: {},
      general_req: {},
      above_req: {},
    };
    Object.keys(allocated).map((step: string) => {
      action.payload[step].biotemplates.map((row: any) => {
        allocated = {
          ...allocated,
          [step]: { ...allocated[step], [row.id]: row.allocated_quantity },
        };
      });
    });
    return allocated;
  };

  return {
    ...state,
    isFetching: false,
    customer_requirements: {
      ...state.customer_requirements,
      //...cust_requirements,
    },
    purchased: { ...state.purchased, ...action.payload.purchased_volumes },
    requirement_quantities_init: {
      gasoline_req: getRequirementQuantities(action.payload.gasoline_req),
      adv_biofuels_req: getRequirementQuantities(
        action.payload.adv_biofuels_req
      ),
      general_req: getRequirementQuantities(action.payload.general_req),
      above_req: getRequirementQuantities(action.payload.above_req),
    },
    available_quantities_part2: updateAvailableQuantities(),
    steps: {
      ...state.steps,
      ...{
        1: {
          ...state.steps[1],
          data: action.payload.gasoline_req.biotemplates,
          allocated: getAllocated().gasoline_req,
          sumAllocated: getTotAllocated(
            action.payload.gasoline_req.biotemplates
          ),
        },
        2: {
          ...state.steps[2],
          data: action.payload.adv_biofuels_req.biotemplates,
          allocated: getAllocated().adv_biofuels_req,
          sumAllocated: getTotAllocated(
            action.payload.adv_biofuels_req.biotemplates
          ),
        },
        3: {
          ...state.steps[3],
          data: action.payload.general_req.biotemplates,
          allocated: getAllocated().general_req,
          sumAllocated: getTotAllocated(
            action.payload.general_req.biotemplates
          ),
        },
        4: {
          ...state.steps[4],
          data: action.payload.above_req.biotemplates,
          allocated: getAllocated().above_req,
          sumAllocated: getTotAllocated(action.payload.above_req.biotemplates),
        },
      },
    },
    error: undefined as any,
  };
}

function handleSaveStepSuccess(state: MultiStepState, action: Action<any>) {
  return {
    ...state,
    isFetching: false,
  };
}

function handleStep6Success(state: MultiStepState, action: Action<any>) {
  let isComplete =
    state.steps[1].isComplete &&
    state.steps[2].isComplete &&
    state.steps[3].isComplete &&
    state.steps[4].isComplete;

  const getStatus = () => {
    if (
      state.steps[1].status === "Not Complete" ||
      state.steps[2].status === "Not Complete" ||
      state.steps[3].status === "Not Complete" ||
      state.steps[4].status === "Not Complete"
    )
      return "Not Complete";

    if (
      state.steps[1].status === "Overallocated" ||
      state.steps[2].status === "Overallocated" ||
      state.steps[3].status === "Overallocated" ||
      state.steps[4].status === "Overallocated"
    )
      return "Overallocated";

    return "Complete";
  };
  return {
    ...state,
    isFetching: false,
    steps: {
      ...state.steps,
      ...{
        5: {
          ...state.steps[5],
          isComplete: isComplete,
          status: getStatus(),
          sustainable_batches: action.payload.sustainable_batches,
          allocation_status_volume: action.payload.allocation_status_volume,
          allocation_status_ton_equivalence:
            action.payload.allocation_status_ton_equivalence,
        },
      },
    },
  };
}

function handleStartAllocationRequest(
  state: MultiStepState,
  action: Action<any>
) {
  return {
    ...initialState,
    isFetching: true,
    activeStep: 0,
    steps: initialState.steps,
    error: undefined as any,
  };
}

function handleStartAllocationSuccess(
  state: MultiStepState,
  action: Action<any>
) {
  let { fame, hvo, etanol, nafta } = action.payload;

  let biotemplates_fame = fame.biotemplates;
  if (!biotemplates_fame) biotemplates_fame = [];

  let biotemplates_hvo = hvo.biotemplates;
  if (!biotemplates_hvo) biotemplates_hvo = [];

  let biotemplates_etanol = etanol.biotemplates;
  if (!biotemplates_etanol) biotemplates_etanol = [];

  let biotemplates_nafta = nafta.biotemplates;
  if (!biotemplates_nafta) biotemplates_nafta = [];

  const getAvailableQuantities = () => {
    let available_quantities = {};
    biotemplates_fame.map(
      (row: any) =>
        (available_quantities = {
          ...available_quantities,
          [row.id]: row.available_quantity,
        })
    );
    biotemplates_hvo.map(
      (row: any) =>
        (available_quantities = {
          ...available_quantities,
          [row.id]: row.available_quantity,
        })
    );
    biotemplates_etanol.map(
      (row: any) =>
        (available_quantities = {
          ...available_quantities,
          [row.id]: row.available_quantity,
        })
    );
    biotemplates_nafta.map(
      (row: any) =>
        (available_quantities = {
          ...available_quantities,
          [row.id]: row.available_quantity,
        })
    );
    return available_quantities;
  };

  const getAllocated1 = () => {
    let allocated: any = {};
    biotemplates_fame.map(
      (row: any, row_idx: number) =>
        (allocated = {
          ...allocated,
          [row.id]: biotemplates_fame[row_idx].allocated_quantity,
        })
    );
    return allocated;
  };

  const isCompleteStep1 = () => {
    return fame.remaining <= 0;
  };

  const statusStep1 = () => {
    let status = "Not Complete";

    if (fame.remaining <= 0) {
      if (fame.remaining < 0) status = "Overallocated";
      else status = "Complete";
    }

    return status;
  };

  const getAllocated2 = () => {
    let allocated: any = {};
    biotemplates_hvo.map(
      (row: any, row_idx: number) =>
        (allocated = {
          ...allocated,
          [row.id]: biotemplates_hvo[row_idx].allocated_quantity,
        })
    );
    return allocated;
  };

  const isCompleteStep2 = () => {
    return hvo.remaining <= 0;
  };

  const statusStep2 = () => {
    let status = "Not Complete";

    if (hvo.remaining <= 0) {
      if (hvo.remaining < 0) status = "Overallocated";
      else status = "Complete";
    }

    return status;
  };

  const getAllocated3 = () => {
    let allocated: any = {};
    biotemplates_etanol.map(
      (row: any, row_idx: number) =>
        (allocated = {
          ...allocated,
          [row.id]: biotemplates_etanol[row_idx].allocated_quantity,
        })
    );
    return allocated;
  };

  const isCompleteStep3 = () => {
    return etanol.remaining <= 0;
  };

  const statusStep3 = () => {
    let status = "Not Complete";

    if (etanol.remaining <= 0) {
      if (etanol.remaining < 0) status = "Overallocated";
      else status = "Complete";
    }

    return status;
  };

  const getAllocated4 = () => {
    let allocated: any = {};
    biotemplates_nafta.map(
      (row: any, row_idx: number) =>
        (allocated = {
          ...allocated,
          [row.id]: biotemplates_nafta[row_idx].allocated_quantity,
        })
    );
    return allocated;
  };

  const isCompleteStep4 = () => {
    return nafta.remaining <= 0;
  };

  const statusStep4 = () => {
    let status = "Not Complete";

    if (nafta.remaining <= 0) {
      if (nafta.remaining < 0) status = "Overallocated";
      else status = "Complete";
    }

    return status;
  };

  return {
    ...state,
    isFetching: false,
    hasStarted: true,
    available_quantities_construction: getAvailableQuantities(),
    construction_quantities_hvo: hvo,
    construction_quantities_fame: fame,
    construction_quantities_etanol: etanol,
    construction_quantities_nafta: nafta,
    steps: {
      ...state.steps,
      ...{
        0: {
          ...state.steps[0],
          ...{
            isComplete: isCompleteStep1(),
            status: statusStep1(),
            data: fame.biotemplates,
            allocated: getAllocated1(),
          },
        },
        1: {
          ...state.steps[1],
          ...{
            isComplete: isCompleteStep2(),
            status: statusStep2(),
            data: hvo.biotemplates,
            allocated: getAllocated2(),
          },
        },
        2: {
          ...state.steps[2],
          ...{
            isComplete: isCompleteStep3(),
            status: statusStep3(),
            data: etanol.biotemplates,
            allocated: getAllocated3(),
          },
        },
        3: {
          ...state.steps[3],
          ...{
            isComplete: isCompleteStep4(),
            status: statusStep4(),
            data: nafta.biotemplates,
            allocated: getAllocated4(),
          },
        },
      },
    },
  };
}

function handleConstructionMatchingSuccess(
  state: MultiStepState,
  action: Action<any>
) {
  const isComplete = () =>
    state.steps[0].isComplete &&
    state.steps[1].isComplete &&
    state.steps[2].isComplete &&
    state.steps[3].isComplete;

  const getStatus = () => {
    if (
      state.steps[0].status === "Not Complete" ||
      state.steps[1].status === "Not Complete" ||
      state.steps[2].status === "Not Complete" ||
      state.steps[3].status === "Not Complete"
    )
      return "Not Complete";

    if (
      state.steps[0].status === "Overallocated" ||
      state.steps[1].status === "Overallocated" ||
      state.steps[2].status === "Overallocated" ||
      state.steps[3].status === "Overallocated"
    )
      return "Overallocated";

    return "Complete";
  };

  return {
    ...state,
    isFetching: false,
    steps: {
      ...state.steps,
      ...{
        4: {
          ...state.steps[4],
          isComplete: isComplete(),
          status: getStatus(),
          sustainable_batches: action.payload.sustainable_batches,
          allocation_status_volume: action.payload.allocation_status_volume,
          allocation_status_ton_equivalence:
            action.payload.allocation_status_ton_equivalence,
        },
      },
    },
  };
}

function handleCustomerReqSuccess(state: MultiStepState, action: Action<any>) {
  const data = action.payload;
  const getAllocatedSorted = () => {
    const types = ["fame", "hvo_hro", "etanol", "nafta"];
    let allocated: any = {};
    Object.keys(data).length > 0 &&
      data.map((cust: any, cust_idx: number) => {
        if (cust && cust.customer_requirements)
          return cust.customer_requirements.map((req: any, req_idx: number) =>
            types.map((type: any) => {
              req["biotemplates_" + type].map((row: any, idx: number) => {
                if (!allocated[cust.id])
                  allocated = { ...allocated, [cust.id]: {} };
                if (!allocated[cust.id][req.id])
                  allocated = {
                    ...allocated,
                    [cust.id]: { ...allocated[cust.id], [req.id]: {} },
                  };
                if (!allocated[cust.id][req.id][type])
                  allocated = {
                    ...allocated,
                    [cust.id]: {
                      ...allocated[cust.id],
                      [req.id]: {
                        ...allocated[cust.id][req.id],
                        [type]: {},
                      },
                    },
                  };

                allocated = {
                  ...allocated,
                  [cust.id]: {
                    ...allocated[cust.id],
                    [req.id]: {
                      ...allocated[cust.id][req.id],
                      [type]: {
                        ...allocated[cust.id][req.id][type],
                        [row.id]: row.allocated_ton_quantity,
                      },
                    },
                  },
                };
              });
            })
          );
      });
    return allocated;
  };
  const newAllocatedSorted = getAllocatedSorted();

  const getSumAllocated = () => {
    let sumAllocatedAll: any = {};

    Array.isArray(data) &&
      data.map((cust: any) => {
        if (cust && cust.customer_requirements)
          return cust.customer_requirements.map((req: any) => {
            let sumAllocated: any = {
              fame: 0,
              hvo_hro: 0,
              etanol: 0,
              nafta: 0,
            };
            let total = 0;
            Object.keys(sumAllocated).map((type) => {
              let sumType = 0;
              newAllocatedSorted[cust.id] &&
                newAllocatedSorted[cust.id][req.id] &&
                newAllocatedSorted[cust.id][req.id][type] &&
                Object.keys(newAllocatedSorted[cust.id][req.id][type]).map(
                  (row_id) =>
                    (sumType = +(
                      sumType +
                      parseFloat(
                        newAllocatedSorted[cust.id][req.id][type][row_id]
                      )
                    ).toFixed(2))
                );
              sumAllocated = { ...sumAllocated, [type]: sumType };
              total = +(total + sumType).toFixed(2);
            });
            sumAllocated = { ...sumAllocated, ["total"]: total };
            sumAllocatedAll = {
              ...sumAllocatedAll,
              [cust.id]: {
                ...sumAllocatedAll[cust.id],
                [req.id]: sumAllocated,
              },
            };
          });
      });
    return sumAllocatedAll;
  };
  const newSumAllocated = getSumAllocated();

  const isCompleteStep = () => {
    let isComplete = true;
    const data = action.payload;
    Object.keys(data).length > 0 &&
      data.map((cust: any, cust_idx: number) => {
        if (data[cust_idx] && data[cust_idx].customer_requirements)
          data[cust_idx].customer_requirements.map(
            (req: any, req_idx: number) => {
              if (
                newSumAllocated[cust.id][req.id]["fame"] <
                  req.required_emission_reduction_fame ||
                newSumAllocated[cust.id][req.id]["hvo_hro"] <
                  req.required_emission_reduction_hvo_hro ||
                newSumAllocated[cust.id][req.id]["etanol"] <
                  req.required_emission_reduction_etanol ||
                newSumAllocated[cust.id][req.id]["nafta"] <
                  req.required_emission_reduction_nafta
              )
                isComplete = false;
            }
          );
      });
    return isComplete;
  };

  const statusStep = () => {
    let status = "Complete";
    const data = action.payload;
    Object.keys(data).length > 0 &&
      data.map((cust: any, cust_idx: number) => {
        if (data[cust_idx] && data[cust_idx].customer_requirements)
          data[cust_idx].customer_requirements.map(
            (req: any, req_idx: number) => {
              if (
                newSumAllocated[cust.id][req.id]["fame"] <
                  req.required_emission_reduction_fame ||
                newSumAllocated[cust.id][req.id]["hvo_hro"] <
                  req.required_emission_reduction_hvo_hro ||
                newSumAllocated[cust.id][req.id]["etanol"] <
                  req.required_emission_reduction_etanol ||
                newSumAllocated[cust.id][req.id]["nafta"] <
                  req.required_emission_reduction_nafta
              ) {
                status = "Not Complete";
                return;
              }
              if (
                newSumAllocated[cust.id][req.id]["fame"] >
                  req.required_emission_reduction_fame ||
                newSumAllocated[cust.id][req.id]["hvo_hro"] >
                  req.required_emission_reduction_hvo_hro ||
                newSumAllocated[cust.id][req.id]["etanol"] >
                  req.required_emission_reduction_etanol ||
                newSumAllocated[cust.id][req.id]["nafta"] >
                  req.required_emission_reduction_nafta
              )
                status = "Overallocated";
            }
          );
      });
    return status;
  };

  return {
    ...state,
    isFetching: false,
    steps: {
      ...state.steps,
      ...{
        [state.activeStep]: {
          ...state.steps[state.activeStep],
          ...{
            isComplete: isCompleteStep(),
            status: statusStep(),
            data: action.payload,
            allocated_sorted: newAllocatedSorted,
            sumAllocated: newSumAllocated,
          },
        },
      },
    },
  };
}

function handleEndAllocationSuccess(
  state: MultiStepState,
  action: Action<any>
) {
  return {
    ...state,
    isFetching: false,
  };
}

function handleChangeStep(state: MultiStepState, action: Action<any>) {
  return {
    ...state,
    steps: {
      ...state.steps,
      ...{
        [state.activeStep]: {
          ...state.steps[state.activeStep],
          ...{
            isDirty: true, //state.activeStep != 8, //Step 9 is never set to dirty?
          },
        },
      },
    },
    activeStep: action.payload,
  };
}

function handleAllocate(state: MultiStepState, action: Action<any>) {
  const { [action.payload.id]: oldAllocated, ...allocatedRest } =
    state.steps[state.activeStep].allocated;

  const { [action.payload.id]: oldAvailable, ...availableRest } =
    state.available_quantities_part2;

  // set minimum to 0
  let newAllocatedValue = action.payload.value
    ? parseFloat(action.payload.value)
    : 0;
  if (newAllocatedValue < 0) newAllocatedValue = 0;

  // calculate available quantities
  let diffAllocated = oldAllocated
    ? newAllocatedValue - oldAllocated
    : newAllocatedValue - 0;

  let newAvailableValue = oldAvailable - diffAllocated;
  if (newAvailableValue < 0) {
    newAllocatedValue += newAvailableValue;
    newAvailableValue = 0;
  }

  let newAvailable = { [action.payload.id]: newAvailableValue };
  let newAllocated = {
    [action.payload.id]: newAllocatedValue,
  };

  let newAllocatedList = {
    ...allocatedRest,
    ...newAllocated,
  };

  let data = state.steps[state.activeStep].data;
  const getTotAllocated = () => {
    let totSimple = 0;
    let totDouble = 0;
    let totDoubleOnly = 0;
    data.map((row: any) => {
      let all_quantity = parseFloat(row.allocated_quantity);
      if (
        newAllocatedList[row.id] ||
        newAllocatedList[row.id] === 0 ||
        newAllocatedList[row.id] === ""
      )
        all_quantity = newAllocatedList[row.id];

      totSimple += all_quantity;
      totDouble +=
        row.double_counted_norway === "Ja" ? all_quantity * 2 : all_quantity;
      totDoubleOnly +=
        row.double_counted_norway === "Ja" ? all_quantity * 2 : 0;
    });
    return { simple: totSimple, double: totDouble, doubleOnly: totDoubleOnly };
  };

  return {
    ...state,
    available_quantities_part2: {
      ...availableRest,
      ...newAvailable,
    },
    steps: {
      ...state.steps,
      ...{
        [state.activeStep]: {
          ...state.steps[state.activeStep],
          ...{
            allocated: newAllocatedList,
            sumAllocated: getTotAllocated(),
          },
        },
      },
    },
  };
}

function handleAllocateUpdateStep1(state: MultiStepState, action: Action<any>) {
  const { [action.payload.id]: oldAllocated, ...allocatedRest } =
    state.steps[state.activeStep].allocated;

  const { [action.payload.id]: oldAvailable, ...availableRest } =
    state.available_quantities_part1;

  // set minimum to 0
  let newAllocatedValue = action.payload.value
    ? parseFloat(action.payload.value)
    : 0;
  if (newAllocatedValue < 0) newAllocatedValue = 0;

  // calculate available quantities
  let diffAllocated = oldAllocated
    ? newAllocatedValue - oldAllocated
    : newAllocatedValue - 0;

  let newAvailableValue = oldAvailable - diffAllocated;
  if (newAvailableValue < 0) {
    newAllocatedValue += newAvailableValue;
    newAvailableValue = 0;
  }

  let newAvailable = { [action.payload.id]: newAvailableValue };
  let newAllocated = { [action.payload.id]: newAllocatedValue };

  let newSustainabilityQuantities = {
    ...state.sustainability_quantities,
    [action.payload.requirements.allocated]:
      state.sustainability_quantities[action.payload.requirements.allocated] +
      diffAllocated,
    [action.payload.requirements.remaining]:
      state.sustainability_quantities[action.payload.requirements.remaining] -
      diffAllocated,
  };

  // Determine if complete

  const isComplete = () => {
    let isComplete = true;
    let {
      rem_single_count_biodisel,
      rem_double_count_biodisel,
      rem_single_count_ethanol,
      rem_dobule_count_ethanol,
    } = newSustainabilityQuantities;

    isComplete =
      rem_single_count_biodisel <= 0 &&
      rem_double_count_biodisel <= 0 &&
      rem_single_count_ethanol <= 0 &&
      rem_dobule_count_ethanol <= 0;

    return isComplete;
  };

  const statusStep1 = () => {
    let {
      rem_single_count_biodisel,
      rem_double_count_biodisel,
      rem_single_count_ethanol,
      rem_dobule_count_ethanol,
    } = newSustainabilityQuantities;
    let status = "Not Complete";

    if (
      rem_single_count_biodisel <= 0 &&
      rem_double_count_biodisel <= 0 &&
      rem_single_count_ethanol <= 0 &&
      rem_dobule_count_ethanol <= 0
    ) {
      if (
        rem_single_count_biodisel < 0 ||
        rem_double_count_biodisel < 0 ||
        rem_single_count_ethanol < 0 ||
        rem_dobule_count_ethanol < 0
      )
        status = "Overallocated";
      else status = "Complete";
    }

    return status;
  };

  return {
    ...state,
    sustainability_quantities: newSustainabilityQuantities,
    available_quantities_part1: {
      ...availableRest,
      ...newAvailable,
    },
    steps: {
      ...state.steps,
      ...{
        [state.activeStep]: {
          ...state.steps[state.activeStep],
          ...{
            isComplete: isComplete(),
            status: statusStep1(),
            allocated: {
              ...allocatedRest,
              ...newAllocated,
            },
          },
        },
      },
    },
  };
}

function handleAllocateUpdateConstructionReq(
  state: MultiStepState,
  action: Action<any>
) {
  const { [action.payload.id]: oldAllocated, ...allocatedRest } =
    state.steps[state.activeStep].allocated;

  const { [action.payload.id]: oldAvailable, ...availableRest } =
    state.available_quantities_construction;

  // set minimum to 0
  let newAllocatedValue = action.payload.value
    ? parseFloat(action.payload.value)
    : 0;
  if (newAllocatedValue < 0) newAllocatedValue = 0;

  // calculate available quantities
  let diffAllocated = oldAllocated
    ? newAllocatedValue - oldAllocated
    : newAllocatedValue - 0;

  let newAvailableValue = oldAvailable - diffAllocated;
  if (newAvailableValue < 0) {
    newAllocatedValue += newAvailableValue;
    diffAllocated += newAvailableValue;
    newAvailableValue = 0;
  }

  let newAvailable = { [action.payload.id]: newAvailableValue };
  let newAllocated = { [action.payload.id]: newAllocatedValue };

  let newConstructionQuantities = { remaining: 0 };

  if (state.activeStep === 0) {
    newConstructionQuantities = {
      ...state.construction_quantities_fame,
      ["allocated"]:
        state.construction_quantities_fame.allocated + diffAllocated,
      ["remaining"]:
        state.construction_quantities_fame.remaining - diffAllocated,
    };
  } else if (state.activeStep === 1) {
    newConstructionQuantities = {
      ...state.construction_quantities_hvo,
      ["allocated"]:
        state.construction_quantities_hvo.allocated + diffAllocated,
      ["remaining"]:
        state.construction_quantities_hvo.remaining - diffAllocated,
    };
  } else if (state.activeStep === 2) {
    newConstructionQuantities = {
      ...state.construction_quantities_etanol,
      ["allocated"]:
        state.construction_quantities_etanol.allocated + diffAllocated,
      ["remaining"]:
        state.construction_quantities_etanol.remaining - diffAllocated,
    };
  } else if (state.activeStep === 3) {
    newConstructionQuantities = {
      ...state.construction_quantities_nafta,
      ["allocated"]:
        state.construction_quantities_nafta.allocated + diffAllocated,
      ["remaining"]:
        state.construction_quantities_nafta.remaining - diffAllocated,
    };
  }

  const isComplete = () => {
    return newConstructionQuantities.remaining <= 0;
  };

  const statusStep = () => {
    let status = "Not Complete";
    if (newConstructionQuantities.remaining <= 0) {
      if (newConstructionQuantities.remaining < 0) status = "Overallocated";
      else status = "Complete";
    }

    return status;
  };

  let constructionQuantitiesState = {};
  if (state.activeStep === 0) {
    constructionQuantitiesState = {
      construction_quantities_fame: newConstructionQuantities,
    };
  } else if (state.activeStep === 1) {
    constructionQuantitiesState = {
      construction_quantities_hvo: newConstructionQuantities,
    };
  } else if (state.activeStep === 2) {
    constructionQuantitiesState = {
      construction_quantities_etanol: newConstructionQuantities,
    };
  } else if (state.activeStep === 3) {
    constructionQuantitiesState = {
      construction_quantities_nafta: newConstructionQuantities,
    };
  }

  return {
    ...state,
    ...constructionQuantitiesState,
    available_quantities_construction: {
      ...availableRest,
      ...newAvailable,
    },
    steps: {
      ...state.steps,
      ...{
        [state.activeStep]: {
          ...state.steps[state.activeStep],
          ...{
            isComplete: isComplete(),
            status: statusStep(),
            allocated: {
              ...allocatedRest,
              ...newAllocated,
            },
          },
        },
      },
    },
  };
}

function handleallocateUpdateCustomerReq(
  state: MultiStepState,
  action: Action<any>
) {
  //Get Allocated
  const req = action.payload.requirement;
  const cust = action.payload.customer;
  const activeStep = state.steps[state.activeStep];
  const { [req.id]: oldAllocatedRequirement, ...allocatedRestRequirements } =
    activeStep.allocated;

  const { [action.payload.id]: oldAllocated, ...allocatedRest } =
    activeStep.allocated && activeStep.allocated[req.id]
      ? activeStep.allocated[req.id]
      : {};

  //Get AllocatedSorted

  const {
    [cust.id]: oldAllocatedSortedCustomer,
    ...allocatedRestSortedCustomers
  } = activeStep.allocated_sorted ? activeStep.allocated_sorted : {};

  const {
    [req.id]: oldAllocatedSortedRequirement,
    ...allocatedRestSortedRequirements
  } =
    activeStep.allocated_sorted && activeStep.allocated_sorted[cust.id]
      ? activeStep.allocated_sorted[cust.id]
      : {};

  const { [action.payload.id]: oldAllocatedSorted, ...allocatedRestSorted } =
    activeStep.allocated_sorted &&
    activeStep.allocated_sorted[cust.id] &&
    activeStep.allocated_sorted[cust.id][req.id]
      ? activeStep.allocated_sorted[cust.id][req.id]
      : {};

  const {
    [action.payload.id]: oldAllocatedSortedType,
    ...allocatedRestSortedType
  } =
    activeStep.allocated_sorted &&
    activeStep.allocated_sorted[cust.id] &&
    activeStep.allocated_sorted[cust.id][req.id] &&
    activeStep.allocated_sorted[cust.id][req.id][req.type]
      ? activeStep.allocated_sorted[cust.id][req.id][req.type]
      : {};

  // calculate available quantities

  let newAllocatedValue = action.payload.value
    ? parseFloat(action.payload.value)
    : 0;
  if (newAllocatedValue < 0) newAllocatedValue = 0;

  // calculate available quantities

  let newAllocatedSorted = {
    [cust.id]: {
      ...allocatedRestSortedRequirements,
      [req.id]: {
        ...allocatedRestSorted,
        [req.type]: {
          ...allocatedRestSortedType,
          [action.payload.id]: newAllocatedValue,
        },
      },
    },
  };

  let newAllocatedSortedComplete = {
    ...allocatedRestSortedCustomers,
    ...newAllocatedSorted,
  };

  let newAllocated =
    action.payload.value !== null
      ? {
          [req.id]: {
            ...allocatedRest,
            [action.payload.id]: newAllocatedValue,
          },
        }
      : Object.keys(allocatedRest).length > 0
      ? {
          [req.id]: {
            ...allocatedRest,
          },
        }
      : {};

  let newAllocatedComplete = {
    ...allocatedRestRequirements,
    ...newAllocated,
  };

  const getSumAllocated = () => {
    let sumAllocated: any = { fame: 0, hvo_hro: 0, etanol: 0, nafta: 0 };
    let total = 0;
    Object.keys(sumAllocated).map((type) => {
      let sumType = 0;
      newAllocatedSorted[cust.id][req.id][type] &&
        Object.keys(newAllocatedSorted[cust.id][req.id][type]).map(
          (row_id) =>
            (sumType = +(
              sumType +
              parseFloat(newAllocatedSorted[cust.id][req.id][type][row_id])
            ).toFixed(2))
        );
      sumAllocated = { ...sumAllocated, [type]: sumType };
      total = +(total + sumType).toFixed(2);
    });
    sumAllocated = { ...sumAllocated, ["total"]: total };
    return sumAllocated;
  };

  let newSumAllocated = {
    ...activeStep.sumAllocated,
    [cust.id]: {
      ...activeStep.sumAllocated[cust.id],
      [req.id]: getSumAllocated(),
    },
  };

  // Determine if complete

  const isComplete = () => {
    let isComplete = true;
    const data = state.steps[state.activeStep].data;
    Object.keys(data).length > 0 &&
      data.map((cust: any, cust_idx: number) => {
        if (data[cust_idx] && data[cust_idx].customer_requirements)
          data[cust_idx].customer_requirements.map(
            (req: any, req_idx: number) => {
              if (
                newSumAllocated[cust.id][req.id]["fame"] <
                  req.required_emission_reduction_fame ||
                newSumAllocated[cust.id][req.id]["hvo_hro"] <
                  req.required_emission_reduction_hvo_hro ||
                newSumAllocated[cust.id][req.id]["etanol"] <
                  req.required_emission_reduction_etanol ||
                newSumAllocated[cust.id][req.id]["nafta"] <
                  req.required_emission_reduction_nafta
              )
                isComplete = false;
            }
          );
      });
    return isComplete;
  };

  const statusStep = () => {
    let status = "Complete";
    const data = state.steps[state.activeStep].data;
    Object.keys(data).length > 0 &&
      data.map((cust: any, cust_idx: number) => {
        if (data[cust_idx] && data[cust_idx].customer_requirements)
          data[cust_idx].customer_requirements.map(
            (req: any, req_idx: number) => {
              if (
                newSumAllocated[cust.id][req.id]["fame"] <
                  req.required_emission_reduction_fame ||
                newSumAllocated[cust.id][req.id]["hvo_hro"] <
                  req.required_emission_reduction_hvo_hro ||
                newSumAllocated[cust.id][req.id]["etanol"] <
                  req.required_emission_reduction_etanol ||
                newSumAllocated[cust.id][req.id]["nafta"] <
                  req.required_emission_reduction_nafta
              ) {
                status = "Not Complete";
                return;
              }
              if (
                newSumAllocated[cust.id][req.id]["fame"] >
                  req.required_emission_reduction_fame ||
                newSumAllocated[cust.id][req.id]["hvo_hro"] >
                  req.required_emission_reduction_hvo_hro ||
                newSumAllocated[cust.id][req.id]["etanol"] >
                  req.required_emission_reduction_etanol ||
                newSumAllocated[cust.id][req.id]["nafta"] >
                  req.required_emission_reduction_nafta
              )
                status = "Overallocated";
            }
          );
      });
    return status;
  };

  return {
    ...state,
    steps: {
      ...state.steps,
      ...{
        [state.activeStep]: {
          ...activeStep,
          ...{
            isComplete: isComplete(),
            status: statusStep(),
            allocated: newAllocatedComplete,
            allocated_sorted: newAllocatedSortedComplete,
            sumAllocated: newSumAllocated,
          },
        },
      },
    },
  };
}

function handleUpdateReqQuantities(state: MultiStepState, action: Action<any>) {
  // the calculations have to be done with integers!
  const X = state.steps[1].sumAllocated.simple;
  const Xdouble = state.steps[1].sumAllocated.double;
  const XdoubleOnly = state.steps[1].sumAllocated.doubleOnly;

  const Y = state.steps[2].sumAllocated.simple;
  const Ydouble = state.steps[2].sumAllocated.double;

  const Z = state.steps[3].sumAllocated.simple;
  const Zdouble = state.steps[3].sumAllocated.double;

  const M = state.steps[4].sumAllocated.simple;

  const PD = state.purchased.biodiesel_double_count;
  const PB = state.purchased.bioethanol_double_count;
  const PDS = state.purchased.biodiesel_single_count;
  const PBS = state.purchased.bioethanol_single_count;

  let PBS_all = 0;
  let PB_all = 0;
  let PD_all = 0;
  let PDS_all = 0;

  const getSequAll = (init_req: number, values: number[]) => {
    let allocated: number[] = [];
    let rem_req = init_req;
    values.map((val: number, index: number) => {
      if (rem_req <= 0) allocated[index] = 0;
      if (rem_req - val < 0) {
        allocated[index] = rem_req;
        rem_req = 0;
      } else {
        allocated[index] = val;
        rem_req = rem_req - val;
      }
    });
    return allocated;
  };

  const round = (value: number) => {
    return Math.round(value * 100) / 100;
  };

  const mult = (values: any[]) => {
    let result = 1;
    values.map((value: any) => {
      let newVal = value ? parseFloat(value.toFixed(2)) * 100 : 0;
      let res = result * 100;
      result = (res * newVal) / 10000;
    });
    return result;
  };

  const getPositive = (value: number) => {
    if (value < 0) return 0;
    else return value;
  };

  const getGasoReq = () => {
    const data = state.requirement_quantities_init.gasoline_req;
    const req = data.requirement;

    const seqAllocationPur = getSequAll(req, [PB, PBS]);
    PB_all = seqAllocationPur[0];
    PBS_all = seqAllocationPur[1];
    const newPurReq = PBS_all + PB_all;

    const newAllocated = X;
    const newRemaining = req - newPurReq - newAllocated - data.surplus;

    return {
      ...data,
      purchased: round(newPurReq),
      allocated: round(newAllocated),
      remaining: round(newRemaining),
    };
  };

  const getAdvBio = () => {
    const data = state.requirement_quantities_init.adv_biofuels_req;
    const ABR = data.requirement;

    const seqAllocationPur = getSequAll(
      ABR - mult([XdoubleOnly, 0.5]) - PB_all,
      [PD - PD_all, PB - PB_all]
    );

    let PD_all2 = seqAllocationPur[0];
    let PB_all2 = seqAllocationPur[1];
    const newPurReq = PD_all2 + PB_all2;
    PD_all = PD_all + seqAllocationPur[0];
    PB_all = PB_all + seqAllocationPur[1];

    const newAllocated = getPositive(mult([XdoubleOnly, 0.5]) + Y);
    const newRemaining = ABR - newPurReq - newAllocated - data.surplus;

    return {
      ...data,
      purchased: round(newPurReq),
      allocated: round(newAllocated),
      remaining: round(newRemaining),
    };
  };

  const getGeneralReq = () => {
    const data = state.requirement_quantities_init.general_req;

    const GR = data.requirement;

    const seqAllocationPur = getSequAll(
      GR -
        Xdouble -
        Ydouble -
        mult([PD_all, 2]) -
        mult([PB_all, 2]) -
        PDS_all -
        PBS_all,
      [
        mult([PD - PD_all, 2]),
        mult([PB - PB_all, 2]),
        PDS - PDS_all,
        PBS - PBS_all,
      ]
    );

    PD_all = PD_all + mult([seqAllocationPur[0], 0.5]);
    PB_all = PB_all + mult([seqAllocationPur[1], 0.5]);
    PDS_all = PDS_all + seqAllocationPur[2];
    PBS_all = PBS_all + seqAllocationPur[3];

    const newPurReq = mult([PD_all, 2]) + mult([PB_all, 2]) + PDS_all + PBS_all;

    const newAllocated = getPositive(Xdouble + mult([2, Y]) + Zdouble);
    const newRemaining = GR - newPurReq - newAllocated - data.surplus;

    return {
      ...data,
      purchased: round(newPurReq),
      allocated: round(newAllocated),
      remaining: round(newRemaining),
    };
  };

  const getAboveReq = () => {
    const data = state.requirement_quantities_init.above_req;
    const req = data.requirement;

    const newRequirement = getPositive(req - X - Y - Z);

    const newPurReq =
      PD + PB + PDS + PBS - (PD_all + PB_all + PDS_all + PBS_all);

    const newAllocated = getPositive(M);
    const newRemaining =
      newRequirement - (newPurReq + newAllocated) - data.surplus;
    return {
      ...data,
      requirement: round(newRequirement),
      purchased: round(newPurReq),
      allocated: round(newAllocated),
      remaining: round(newRemaining),
    };
  };

  const newReqQuantities: any = {
    gasoline_req: getGasoReq(),
    adv_biofuels_req: getAdvBio(),
    general_req: getGeneralReq(),
    above_req: getAboveReq(),
  };

  // Determine if complete

  const isComplete = (step: string) => {
    let isComplete = true;
    isComplete = newReqQuantities[step].remaining <= 0;
    return isComplete;
  };

  const getStatus = (step: string) => {
    if (newReqQuantities[step].remaining > 0) return "Not Complete";
    if (newReqQuantities[step].remaining < 0) return "Overallocated";
    return "Complete";
  };

  return {
    ...state,
    requirement_quantities: newReqQuantities,
    steps: {
      ...state.steps,
      ...{
        1: {
          ...state.steps[1],
          isComplete: isComplete("gasoline_req"),
          status: getStatus("gasoline_req"),
        },
        2: {
          ...state.steps[2],
          isComplete: isComplete("adv_biofuels_req"),
          status: getStatus("adv_biofuels_req"),
        },
        3: {
          ...state.steps[3],
          isComplete: isComplete("general_req"),
          status: getStatus("general_req"),
        },
        4: {
          ...state.steps[4],
          isComplete: isComplete("above_req"),
          status: getStatus("above_req"),
        },
      },
    },
  };
}

function handleError(state: MultiStepState, action: Action<ErrorPayload>) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}
