import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typograhpy from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import ButtonRounded from "../../components/ButtonRounded";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    settingCard: {
      width: "507px",
      "& .MuiCardContent-root": {
        padding: theme.spacing(9, 10),
        paddingBottom: theme.spacing(6),
      },
    },
    supportTitle: {
      "& h4": {
        textAlign: "center",
        marginBottom: theme.spacing(5),
      },
    },
    select: {},
    selectEmpty: {
      color: theme.palette.grey[300],
    },
    label: {
      marginTop: theme.spacing(2),
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
    },
    btnSend: {
      marginTop: theme.spacing(5),
      minWidth: "150px",
    },
  })
);

const SupportView: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Card className={classes.settingCard}>
        <CardContent className={classes.supportTitle}>
          <Typograhpy variant="h4" className={classes.supportTitle}>
            <FormattedMessage id="sidePanel.support" />
          </Typograhpy>
          <Form onSubmit={() => {}}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Typograhpy variant="body1" className={classes.label}>
                  <FormattedMessage id="supportView.type" />
                </Typograhpy>
                <Field name="biofuel_type">
                  {props => (
                    <Select
                      {...props.input}
                      fullWidth
                      className={clsx(classes.select, {
                        [classes.selectEmpty]: props.input.value === "",
                      })}
                      input={<OutlinedInput labelWidth={0} />}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <FormattedMessage id="exciseDutyView.transactionsView.filters.select" />
                      </MenuItem>
                      <MenuItem value="tech">
                        <FormattedMessage id="supportView.tech" />
                      </MenuItem>
                      <MenuItem value="admin">
                        <FormattedMessage id="settingsView.users.modal.admin" />
                      </MenuItem>
                    </Select>
                  )}
                </Field>
                <Typograhpy variant="body1" className={classes.label}>
                  <FormattedMessage id="supportView.subject" />
                </Typograhpy>
                <Field
                  component={TextField}
                  name="subject"
                  variant="outlined"
                  placeholder="Subject"
                  fullWidth
                />
                <Typograhpy variant="body1" className={classes.label}>
                  <FormattedMessage id="supportView.message" />
                </Typograhpy>
                <Field
                  component={TextField}
                  name="message"
                  variant="outlined"
                  placeholder="Write a message"
                  fullWidth
                  multiline
                  rows={4}
                />
                <div className={classes.btnContainer}>
                  <ButtonRounded
                    variant="contained"
                    color="primary"
                    className={classes.btnSend}
                  >
                    <FormattedMessage id="supportView.send" />
                  </ButtonRounded>
                </div>
              </form>
            )}
          </Form>
        </CardContent>
      </Card>
    </div>
  );
};

export default SupportView;
