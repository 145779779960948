import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router";
import CustomerReportPreview from "./CustomerReportPreview";
import ProofOfSustPreview from "./ProofOfSustPreview";

const PDFPreView: React.FC = () => {
  const match = useRouteMatch();
  if (match) {
    return (
      <Switch>
        <Route path={`${match.path}/customer-report`}>
          {/* template==true shows version 2 template 2 */}
          <CustomerReportPreview template2={true} />
        </Route>
        <Route path={`${match.path}/proof-sustainability`}>
          <ProofOfSustPreview />
        </Route>
        <Redirect to={`${match.path}`} />
      </Switch>
    );
  } else {
    return null;
  }
};

export default PDFPreView;
