import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import MainView from "./MainView";
import CreateEditView from "./CreateEditView";

type ReportType = "manual" | "original";
interface Props {
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  selectedMonth: number;
  setSelectedMonth: any;
  toggleReportType: ReportType;
  updataReportTypethenFetch: (toggleReportType: ReportType) => void;
  data: any;
  deleteAnItem: (period: string) => void;
  deleteError: any;
  updataItems: (value: any) => void;
  updatingError: any;
}

const ReportsRootView: React.FC<Props> = ({
  taxPeriods,
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
  toggleReportType,
  updataReportTypethenFetch,
  data,
  deleteAnItem,
  deleteError,
  updataItems,
  updatingError,
}) => {
  const match = useRouteMatch();

  if (match) {
    return (
      <Switch>
        <Route exact path={`${match.path}`}>
          <MainView
            taxPeriods={taxPeriods}
            selectedYearMonth={{
              selectedMonthIndex: selectedMonth,
              SetSelectedMonthIndex: setSelectedMonth,
              selectedYear: selectedYear,
              SetSelectedYear: setSelectedYear,
            }}
            toggleReportType={toggleReportType}
            pagedata={data}
            updataReportTypethenFetch={updataReportTypethenFetch}
          />
        </Route>
        <Route path={`${match.path}/create`}>
          <CreateEditView
            mode="create"
            selectedYearMonth={{
              selectedMonthIndex: selectedMonth,
              SetSelectedMonthIndex: setSelectedMonth,
              selectedYear: selectedYear,
              SetSelectedYear: setSelectedYear,
            }}
            deleteAnItem={deleteAnItem}
            deleteError={deleteError}
            updatingError={updatingError}
            updataItems={updataItems}
          />
        </Route>
        <Route path={`${match.path}/edit`}>
          <CreateEditView
            mode="edit"
            selectedYearMonth={{
              selectedMonthIndex: selectedMonth,
              SetSelectedMonthIndex: setSelectedMonth,
              selectedYear: selectedYear,
              SetSelectedYear: setSelectedYear,
            }}
            pagedata={data}
            deleteAnItem={deleteAnItem}
            deleteError={deleteError}
            updatingError={updatingError}
            updataItems={updataItems}
          />
        </Route>
        <Redirect to={`${match.path}`} />
      </Switch>
    );
  } else {
    return null;
  }
};

export default ReportsRootView;
