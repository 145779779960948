import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import getCustomerPDFReport from "../../../api/sustainability/customerReport/getCustomerPDFReport";
interface ReportState {
  isFetching: boolean;
  data: any[];
  error?: any;
}
export interface CustomerState {
  selected_customer: any;
  selected_year: any;
  report: ReportState;
}
// Initial state
const initialState = {
  selected_customer: { id: null, name: null, customer_no: null },
  selected_year: null,
  report: {
    isFetching: false,
    data: [],
    error: undefined,
  } as ReportState,
} as CustomerState;
// Actions
const CUSTOMER_SET_SELECTED_CUSTOMER =
  "sustainability/customer/SET_SELECTED_CUSTOMER";
const CUSTOMER_SET_SELECTED_YEAR = "sustainability/customer/SET_SELECTED_YEAR";
const CUSTOMER_REPORT_REQUEST = "sustainability/customer/report/REPORT_REQUEST";
const CUSTOMER_REPORT_ERROR = "sustainability/customer/report/REPORT_ERROR";
const CUSTOMER_REPORT_SUCCESS = "sustainability/customer/report/REPORT_SUCCESS";
const CUSTOMER_CLEAR_ERROR = "sustainability/customer/report/CLEAR_ERROR";

export const setSelectedCustomer = (customer: any) => {
  return {
    type: CUSTOMER_SET_SELECTED_CUSTOMER,
    payload: customer,
  };
};

export const setSelectedYear = (year: any) => {
  return {
    type: CUSTOMER_SET_SELECTED_YEAR,
    payload: {
      year,
    },
  };
};

function requestFiles() {
  return {
    type: CUSTOMER_REPORT_REQUEST,
  };
}

function errorFiles(error: any) {
  return {
    type: CUSTOMER_REPORT_ERROR,
    payload: { error },
  };
}

function receiveFiles(data: any) {
  return {
    type: CUSTOMER_REPORT_SUCCESS,
    payload: data,
  };
}
function clearError() {
  return {
    type: CUSTOMER_CLEAR_ERROR,
  };
}

export function clearErrorfromLastcall() {
  return (dispatch: any) => {
    dispatch(clearError());
  };
}

export function getCustomerPDFFiles(id: number, data: any) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.sustainability.customer.report) {
      dispatch(requestFiles());
      try {
        const data1 = await getCustomerPDFReport(id, data);
        data1 && data1.valid
          ? dispatch(receiveFiles(data1.data))
          : dispatch(errorFiles(data1.message));
      } catch (error) {
        dispatch(errorFiles(error));
      }
    }
    return Promise.resolve;
  };
}

// Reducer Definition
const CustomerReducer = handleActions<CustomerState>(
  {
    [CUSTOMER_SET_SELECTED_CUSTOMER]: handleSetSelectedCustomer,
    [CUSTOMER_SET_SELECTED_YEAR]: handleSetSelectedYear,
    [CUSTOMER_REPORT_REQUEST]: handleReportRequest,
    [CUSTOMER_REPORT_ERROR]: handleReportError,
    [CUSTOMER_REPORT_SUCCESS]: handleReportSuccess,
    [CUSTOMER_CLEAR_ERROR]: handleClearError,
  } as any,
  initialState
);

function handleClearError(state: CustomerState, action: Action<any>) {
  return {
    ...state,
    report: {
      ...state.report,
      error: undefined,
    },
  };
}
function handleSetSelectedCustomer(state: CustomerState, action: Action<any>) {
  return {
    ...state,
    selected_customer: action.payload,
  };
}

function handleSetSelectedYear(state: CustomerState, action: Action<any>) {
  return {
    ...state,
    selected_year: action.payload.year,
  };
}
function handleReportRequest(state: CustomerState) {
  return {
    ...state,
    report: {
      ...state.report,
      isFetching: true,
      error: undefined,
    },
  };
}

function handleReportError(state: CustomerState, action: Action<ErrorPayload>) {
  return {
    ...state,
    report: {
      ...state.report,
      isFetching: false,
      error: action.payload.error,
      data: undefined,
    },
  };
}

function handleReportSuccess(state: CustomerState, action: Action<any>) {
  return {
    ...state,
    report: {
      ...state.report,
      isFetching: false,
      data: action.payload,
      error: undefined,
    },
  };
}

export default CustomerReducer;
