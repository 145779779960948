import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import * as yup from "yup";
import makeValidate from "../../../../components/form/makeValidate";

import ButtonRounded from "../../../../components/ButtonRounded";
import ModalContainer from "../../../../components/ModalContainer";
import addEditCollab, {
  ICollab,
} from "../../../../api/sustainability/allocation/collaboration/addEdit";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      paddingTop: theme.spacing(2),
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnCancel: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      marginRight: theme.spacing(2.5),
    },
    labelName: {
      ...theme.typography.caption,
      letterSpacing: "-0.25px",
      color: theme.palette.grey[400],
    },
  })
);

interface ModalAddEditCollabProps {
  open: boolean;
  onClose: () => void;
  mode: "add" | "edit";
  collab?: ICollab;
}

const ModalAddEditCollab: React.FC<ModalAddEditCollabProps> = ({
  open,
  onClose,
  mode,
  collab,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const validationSchema = yup.object({
    id: yup.number(),
    org_name: yup
      .string()
      .required()
      .max(120),
    org_number: yup
      .number()
      .integer()
      .required()
      .min(0)
      .max(999999999),
    diesel_single: yup
      .number()
      .required()
      .min(-99999999)
      .max(99999999),
    diesel_double: yup
      .number()
      .required()
      .min(-99999999)
      .max(99999999),
    ethanol_single: yup
      .number()
      .required()
      .min(-99999999)
      .max(99999999),
    ethanol_double: yup
      .number()
      .required()
      .min(-99999999)
      .max(99999999),
  });

  const onSubmit = async (values: any) => {
    setIsError(false);

    const collabIdObj = collab && mode === "edit" ? { id: collab.id } : {}; // add 'id' in the edit mode
    const orgNumber: number = Number(
      String(values.org_number)
        .split(" ")
        .join("")
    ); // remove spaces from organization number

    const result = await addEditCollab({
      mode,
      collab: {
        ...values,
        org_number: orgNumber,
        ...collabIdObj,
      },
    });

    if (result) {
      onClose();
    } else {
      setIsError(true);
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={
        mode === "add" ? (
          <FormattedMessage id="allView.add" />
        ) : (
          <FormattedMessage id="settingsView.modalAddEdit.title.edit" />
        )
      }
      subtitle={<FormattedMessage id="addCollaboration.collaboration" />}
      maxWidth="md"
    >
      <div className={classes.modalContent}>
        <Typography variant="body2" paragraph align="center">
          <FormattedMessage id="addCollaboration.title1" />
          <br />
          <FormattedMessage id="addCollaboration.title2" />
        </Typography>
        <div>
          <Form
            onSubmit={onSubmit}
            validate={values => makeValidate(values, validationSchema)}
            initialValues={collab ? collab : {}}
          >
            {({ handleSubmit, submitting, hasValidationErrors }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      <FormattedMessage id="allocationView.mainView.table.company" />{" "}
                      <FormattedMessage id="settingsView.index.storageTerminal.name" />
                    </Typography>
                    <Field
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="org_name"
                      placeholder="Company Name"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      <FormattedMessage id="allocationView.mainView.table.organizationNumber" />
                    </Typography>
                    <Field
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="org_number"
                      placeholder="000 000 000"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      <FormattedMessage id="addCollaboration.bioDisel" />
                    </Typography>
                    <Field
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="diesel_single"
                      placeholder="0,00"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      <FormattedMessage id="addCollaboration.bioDiselDc" />
                    </Typography>
                    <Field
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="diesel_double"
                      placeholder="0,00"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      <FormattedMessage id="addCollaboration.bioGasolineSc" />
                    </Typography>
                    <Field
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="ethanol_single"
                      placeholder="0,00"
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.labelName}
                    >
                      <FormattedMessage id="addCollaboration.bioGasolineDc" />
                    </Typography>
                    <Field
                      className={classes.marginBottom}
                      component={TextField}
                      variant="outlined"
                      name="ethanol_double"
                      placeholder="0,00"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.btnContainer}>
                  <ButtonRounded
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                    className={classes.btnCancel}
                  >
                    <FormattedMessage id="settingsView.modal.cancel" />
                  </ButtonRounded>

                  <ButtonRounded
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting || hasValidationErrors}
                  >
                    <FormattedMessage id="settingsView.modal.save" />
                  </ButtonRounded>
                </Grid>
              </form>
            )}
          </Form>
        </div>

        {isError && (
          <Typography align="center" variant="body2" color="error">
            Failed to {mode} this customer.
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalAddEditCollab;
