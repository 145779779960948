/**
 * File drag n drop & select component
 */

import React, { useCallback } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import { useDropzone } from "react-dropzone";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
      border: `1px dashed ${theme.palette.grey[300]}`,
    },
    dragActive: {
      borderColor: theme.palette.primary.main,
      backgroundColor: "rgba(255, 172, 18, 0.05)",
    },
    content: {
      textAlign: "center",
    },
    icon: {
      cursor: "pointer",
      padding: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      borderRadius: "100%",
      boxShadow:
        "0 2px 10px 0 rgba(66, 75, 90, 0.15), 0 2px 10px 0 rgba(255, 172, 18, 0.05)",
    },
  })
);

interface DropZoneProps {
  handleFiles: (files: File[]) => void;
}

const DropZone: React.FC<DropZoneProps> = ({ handleFiles }) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleFiles(acceptedFiles);
    },
    [handleFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFileDialogActive,
  } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={clsx(classes.root, {
        [classes.dragActive]: isDragActive || isFileDialogActive,
      })}
    >
      <input {...getInputProps()} />
      <div className={classes.content}>
        <AddIcon color="primary" className={classes.icon} />

        <Typography variant="body2" color="primary">
          <FormattedMessage id={"component.dropZone.dragAndDrop"} />
        </Typography>
      </div>
    </div>
  );
};

export default DropZone;
