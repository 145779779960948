/**
 * Excise duty > Reports : Main view
 */

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SelectYearMonth from "../../../../components/SelectYearMonth";
import ButtonRounded from "../../../../components/ButtonRounded";
import ToggleButton from "../../../../components/ToggleButton";
import { ISelectedYearMonth } from "../interfaces";
import getDownloadReport from "../../../../api/exciseduty/reports/getDownloadReport";
import SmartTable from "../../../../components/SmartTable/index";
import { FormattedMessage } from "react-intl";
import useModal from "../../../../components/modal/useModal";
import ModalInfo from "../../../../components/modal/ModalInfo";
import ChangeHistory from "./ChangeHistory";
import ManualExciseDutyModal from "../FilesView/ManualExciseDutyModal";
import getReportsHeaders from "../../../../api/exciseduty/getReportsHeaders";
import reportsProperties from "./reports_properties";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      border: `1px solid ${theme.palette.grey[200]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[200]}`,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "inherit",
        fontWeight: "bold",
        paddingBottom: theme.spacing(2),
      },
    },
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    cardContainer: {
      padding: theme.spacing(3),
    },
    cardTopSection: {
      marginBottom: theme.spacing(3),
    },
    noData: {
      height: "300px",
      border: `1px solid ${theme.palette.grey[200]}`,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    noDataText: {
      color: theme.palette.grey[300],
    },
    threeButton: {
      margin: "0px",
      display: "flex",
      maxWidth: "570px",
    },
    singleButton: {
      width: "170px",
      padding: "13px 0",
      lineHeight: "14px",
      "&:not(:first-child)": {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("md")]: {
        width: "130px",
        padding: "12px 0 9px 0",
        "&:not(:first-child)": {
          marginLeft: "10px",
        },
      },
    },
    toggleSetButton: {},
    changeHistory: {
      marginBottom: theme.spacing(2.5),
    },
  })
);

interface MainViewProps {
  taxPeriods: any[];
  selectedYearMonth: ISelectedYearMonth;
  toggleReportType: ReportType;
  updataReportTypethenFetch: (reportType: ReportType) => void;
  pagedata: any;
}

type ReportType = "manual" | "original";

const MainView: React.FC<MainViewProps> = ({
  taxPeriods,
  selectedYearMonth,
  toggleReportType,
  updataReportTypethenFetch,
  pagedata,
}) => {
  const [showManualExciseModal, setShowManualExciseModal] = useState<boolean>(
    false
  );
  const [infoMessage, SetInfoMessage] = useState("");

  const { openModalId, toggle } = useModal();

  const classes = useStyles();

  const openModal = (id: string, text: string) => {
    if (id === "info") SetInfoMessage(text);
    toggle(id);
  };

  const closeModal = () => {
    SetInfoMessage("");
    toggle();
  };

  const history = useHistory();

  const isManualSelected = toggleReportType === "manual";

  const { selectedMonthIndex, selectedYear } = selectedYearMonth;

  const openManualExciseModal = () => setShowManualExciseModal(true);
  const closeManualExciseModal = () => setShowManualExciseModal(false);

  const redirectToCreate = () => history.push("/excise-duty/reports/create");

  const handleToggleButton = (label: string) => {
    const toggleLabel = label.toLowerCase();
    if (["manual", "original"].indexOf(toggleLabel) > -1) {
      updataReportTypethenFetch(toggleLabel as ReportType);
    }
  };

  const handleCreate = async () => {
    // Same as filesView:
    // Transform API data & Do some checking here:
    // whether selected year-month exist in the data from API
    // "data" look up object shape: year number as key, month index array as value
    // { 2018: [1,3, ...]
    //   2019: [0, 5, 11, ...]
    // }
    //
    const results = await getReportsHeaders();
    if (results && Array.isArray(results)) {
      const data: { [key: number]: number[] } = {};
      results.forEach((item) => {
        const month = Number(item.month) - 1; // get month index based on 0 - 11
        const year = Number(item.year);
        data[year] = Array.isArray(data[year])
          ? [...data[year], month]
          : [month];
      });

      if (
        Array.isArray(data[selectedYear]) &&
        data[selectedYear].findIndex((m) => m === selectedMonthIndex) > -1
      ) {
        openManualExciseModal();
      } else {
        redirectToCreate(); // selected year month not found in API data
      }
    } else {
      redirectToCreate(); // API data not processable
    }
  };

  const handleEdit = () => {
    history.push("/excise-duty/reports/edit");
  };

  const handleDownload = async () => {
    const response = await getDownloadReport({
      year: selectedYear,
      month: selectedMonthIndex + 1,
    });
    if (response === null) {
      openModal(
        "info",
        "Failed to download file: Possible errors: no file for selected year-month or other errors."
      );
    }

    if (response !== null) {
      // emulate file download by clicking on an <a /> link
      const url = window.URL.createObjectURL(response);
      const a = document.createElement("a");
      a.href = url;
      a.download = `ReportFile-${selectedYear}-${selectedMonthIndex + 1}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  };

  const reports = pagedata.filter(
    (report: any) =>
      report.tax_year === selectedYear &&
      report.month === selectedMonthIndex + 1
  );

  const isData = Array.isArray(reports) && reports.length > 0;

  return (
    <div>
      <Grid container justify="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h2" className={classes.h2}>
            <FormattedMessage id="exciseDutyView.reportsView.index.heading" />
          </Typography>
        </Grid>
        <Grid item className={classes.threeButton}>
          <ButtonRounded
            variant="contained"
            color="primary"
            onClick={handleCreate}
            className={classes.singleButton}
            disabled={!isManualSelected}
          >
            <FormattedMessage id="exciseDutyView.reportsView.mainView.createNew" />
          </ButtonRounded>
          <ButtonRounded
            variant="contained"
            color="primary"
            onClick={handleEdit}
            className={classes.singleButton}
            disabled={!isManualSelected || !isData}
          >
            <FormattedMessage id="exciseDutyView.reportsView.mainView.editReport" />
          </ButtonRounded>
          <ButtonRounded
            variant="contained"
            color="primary"
            onClick={handleDownload}
            className={classes.singleButton}
            disabled={!isManualSelected || !isData}
          >
            <FormattedMessage id="exciseDutyView.reportsView.mainView.downloadReport" />
          </ButtonRounded>
        </Grid>
      </Grid>
      <SelectYearMonth
        dataYearMonth={taxPeriods}
        selectedYearMonth={selectedYearMonth}
      />
      <ChangeHistory
        className={classes.changeHistory}
        selectedMonthIndex={selectedMonthIndex}
        selectedYear={selectedYear}
      />
      <Card>
        <CardContent className={classes.cardContainer}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.cardTopSection}
          >
            <Grid item>
              <Typography variant="h5">
                {moment.months("MMMM", selectedMonthIndex)} {selectedYear}
              </Typography>
            </Grid>
            <Grid item className={classes.toggleSetButton}>
              <ToggleButton
                labels={["Original", "Manual"]}
                defaultLabelIndex={1}
                handleChange={handleToggleButton}
                size="small"
              />
            </Grid>
          </Grid>

          <SmartTable
            headCells={reportsProperties}
            rows={reports.length === 0 ? null : reports}
          />
        </CardContent>
      </Card>

      <ManualExciseDutyModal
        open={showManualExciseModal}
        onClose={closeManualExciseModal}
      />
      <ModalInfo
        text={infoMessage}
        open={openModalId === "info"}
        onClose={closeModal}
      />
    </div>
  );
};

export default MainView;
