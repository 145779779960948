import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { colors, typography } from "../config/theme";
import parseData from "../../../../components/texts/parseData";

const styles = StyleSheet.create({
  root: {},
  headingContainer: {
    backgroundColor: colors.green,
    flexDirection: "row",
    paddingHorizontal: 5,
    paddingVertical: 5,
    borderBottom: `1 solid ${colors.white}`,
  },
  heading: {
    flex: 4,
    paddingHorizontal: 8,
    justifyContent: "flex-end",
  },
  headingHash: { flex: 0.6, paddingHorizontal: 8 },
  headingText: {
    ...typography.defaultBold,
    color: colors.yellow,
  },
  headingTextSmall: {
    ...typography.smallBold,
    color: colors.yellow,
  },
  rowContainer: {
    flexDirection: "row",
    paddingHorizontal: 5,
    paddingVertical: 5,
    backgroundColor: colors.lightGreen,
    borderBottom: `1 solid ${colors.white}`,
  },
  rowCellsNummber: {
    ...typography.defaultBold,
    flex: 0.6,
    paddingHorizontal: 8,
  },
  rowCellsNummberSmall: {
    ...typography.smallBold,
  },
  rowCells: {
    ...typography.default,
    flex: 4,
    paddingHorizontal: 8,
  },
  rowCellsSmall: {
    ...typography.small,
  },
  tableBottom: {
    flexDirection: "row",
    backgroundColor: colors.lightGrey,
    paddingHorizontal: 4,
    paddingVertical: 5,
  },
  tableBottomNameCell: {
    flex: 4,
    paddingRight: 6,
    paddingLeft: 10,
  },
  tableBottomNameCellSmall: {
    flex: 4,
    paddingLeft: 10,
  },
  tableBottomValueCell: { flex: 4, paddingHorizontal: 8, textAlign: "right" },
  tableBottomTextCell: {
    flex: 25,
    paddingHorizontal: 8,
  },
  tableBottomTextCellLarge: {
    flex: 50,
    paddingHorizontal: 8,
  },
  thinCell: { flex: 3 },
  largeCell: { flex: 8 },
  rightAlign: { textAlign: "right" },
  centerAlign: { textAlign: "center" },
});

interface TableProps {
  columns: React.ReactNode[];
  rows?: React.ReactNode[][];
  footer?: { name: string; value?: string | number | null; text?: string };
  style?: ReactPDF.Style | ReactPDF.Styles;
  smallTextSize?: boolean;
}

const Table: React.FC<TableProps> = ({
  columns,
  rows,
  footer,
  style = [],
  smallTextSize = false,
}) => {
  const externalStyles = Array.isArray(style) ? style : [style];

  return (
    <View style={[styles.root, ...externalStyles]}>
      <View style={styles.headingContainer} fixed>
        {columns.map((column: any, headingIndex: any) => (
          <View
            key={`heading-${headingIndex}`}
            style={[
              styles.heading,
              column.id === "index" ? styles.headingHash : {},
              column.large ? styles.largeCell : {},
              column.thin ? styles.thinCell : {},
              column.rightAlign ? styles.rightAlign : {},
              column.center ? styles.centerAlign : {},
            ]}
          >
            <Text
              style={[
                styles.headingText,
                smallTextSize ? styles.headingTextSmall : {},
              ]}
            >
              {column.name}
            </Text>
          </View>
        ))}
      </View>

      {Array.isArray(rows) &&
        rows.map((row: any, rowIndex: number) => (
          <View
            key={`row-${rowIndex}`}
            style={styles.rowContainer}
            wrap={false}
          >
            {columns.map((column: any, cellIndex: number) => (
              <View
                key={`cell-${rowIndex}-${cellIndex}`}
                style={[
                  styles.rowCells,
                  smallTextSize ? styles.rowCellsSmall : {},
                  column.id === "index" ? styles.rowCellsNummber : {},
                  column.large ? styles.largeCell : {},
                  column.thin ? styles.thinCell : {},
                  column.rightAlign ? styles.rightAlign : {},
                  column.center ? styles.centerAlign : {},
                ]}
              >
                <Text>
                  {column.id === "index"
                    ? rowIndex + 1
                    : column.decimals
                    ? parseData(row[column.id], column.decimals)
                    : parseData(row[column.id])}
                  {column.percentage ? " %" : ""}
                  {column.m3 ? " m³" : ""}
                </Text>
              </View>
            ))}
          </View>
        ))}

      {footer && (
        <View style={styles.tableBottom}>
          <View style={{ flex: 0.6, paddingHorizontal: 7 }}></View>
          <View
            style={[
              styles.tableBottomNameCell,
              !footer.text ? styles.tableBottomNameCellSmall : {},
            ]}
          >
            <Text style={typography.defaultBold}>{footer.name}</Text>
          </View>
          <View style={styles.tableBottomValueCell}>
            <Text style={typography.defaultBold}>{footer.value}</Text>
          </View>
          <View
            style={[
              styles.tableBottomTextCell,
              !footer.text ? styles.tableBottomTextCellLarge : {},
            ]}
          >
            <Text style={typography.defaultBold}>{footer.text}</Text>
          </View>
        </View>
      )}
    </View>
  );
};

export default Table;
