import { combineReducers } from "redux";
import TaxPeriodsReducer, { TaxPeriodsState } from "./taxPeriods";
import FilesReducer, { FilesState } from "./files";
import TransactionsReducer, { TransactionsState } from "./transactions";
import ReportsReducer, { ReportsState } from "./reports";

export interface ExciseDutyState {
  taxPeriods: TaxPeriodsState;
  files: FilesState;
  transactions: TransactionsState;
  reports: ReportsState;
}

export default combineReducers({
  taxPeriods: TaxPeriodsReducer,
  files: FilesReducer,
  transactions: TransactionsReducer,
  reports: ReportsReducer,
});
