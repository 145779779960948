import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import MyProfileView from "../../views/DashboardView/MyProfileView";
import { setLanguage } from "../../reducers/profile";

interface Props {
  language: string;
  setLanguage: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      language: state.profile.language,
    }),
    (dispatch: any) => ({
      dispatch,
      setLanguage: (lang: string) => dispatch(setLanguage(lang)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(MyProfileView);
