import { getToken } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

const getHBLPOSReports = async (data: any) => {
  const token = getToken();

  let valid = false;
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/reservations/import_hbl_pos?customer_name=${data.customer_name && encodeURIComponent(data.customer_name)
        }&date_from=${data.date_from}&date_to=${data.date_to}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      var reportResult = await response.json();
      valid = reportResult && reportResult.success && reportResult.pos_reports;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (reportResult && reportResult.errors)
      return {
        valid: false,
        message: reportResult.errors,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data: reportResult.pos_reports,
  };
};

export default getHBLPOSReports;
