export default {
  EN: {
    header_title: "Biofuel sustainability documentation for ",
    header_subtitle: "Issued by Preem Norge AS",
    infoBox1_title: "General information",
    infoBox1_customer: "Customer",
    infoBox1_deliveryPeriod: "Delivery period",
    infoBox1_quantity: "Quantity",
    infoBox1_literBio: "liter",
    infoBox1_supplier: "Supplier",
    infoBox1_averageCO2reduction: "Average CO2 reduction",
    infoBox1_emissionSavings: "Emission savings",
    infoBox1_tonCO2equ: "ton CO2-ekv.",
    infoBox1_kgCO2equ: "kg CO2-ekv.",
    description_paragraph1:
      "Preem Norge AS declares that biofuel products covered by this document fulfills all sustainability criteria according to the Norwegian legislation as well as the European Union Renewable Energy Directive (RED) on the promotion of use of energy from renewable sources.",
    description_paragraph2:
      "The CO2 reduction values in this document includes feedstock transportation, possible pre-treatment, biofuel production, product transportation to customer location and distribution.",
    description_paragraph3:
      "The sustainability criteria of the biofuel products has been audited by PwC.",
    description_nameAndPosition: "Name and position",
    description_date: "Date",
    table_product: "Product",
    table_volume: "Volume",
    table_rawMaterial: "Raw Material",
    table_country: "Country",
    table_co2Red: "CO2-red.",
    table_doubleCount: "Advanced",
    table_total: "Total",
    table_totalLiterBiofuel: "liter of biofuel products",
    infoBox2_title: "Supplied products",
    infoBox2_liter: "liter",
    infoBox2_and: "and",
    infoBox2_purchasedVolume: "Purchased volume",
    infoBox2_biofuels: "Biofuels",
    footer_address: "Address",
    footer_addressBox: "Box",
    footer_addressNorway: "Norway",
    footer_email: "E-mail",
    footer_web: "Web",
    footer_phone: "Phone",
    footer_customerSupport: "Customer Support",
    footer_side: "Page",
    footer_of: "of",
  },
  NO: {
    header_title: "Dokumentasjon for bærekraftig biodrivstoff i ",
    header_subtitle: "Utstedt av Preem Norge AS",
    infoBox1_title: "Generell informasjon",
    infoBox1_customer: "Kunde",
    infoBox1_deliveryPeriod: "Leveringsperiode",
    infoBox1_quantity: "Volum",
    infoBox1_literBio: "liter biodrivstoff",
    infoBox1_supplier: "Leverandør",
    infoBox1_averageCO2reduction: "CO2-reduksjon (gjennomsnitt)",
    infoBox1_emissionSavings: "Utslippsreduksjon",
    infoBox1_tonCO2equ: "tonn CO2-ekvivalenter",
    infoBox1_kgCO2equ: "kg CO2-ekv.",
    description_paragraph1:
      "Preem Norge AS erklærer at biodrivstoff omfattet av denne rapporten oppfyller alle bærekraftskriterier i produktforskriften kapittel 3 og EU sitt fornybardirektiv (RED) artikkel 17.",
    description_paragraph2:
      "Verdier for CO2-reduksjon inkluderer transport av råvarer, eventuell forhåndsbehandling, produksjon av biodrivstoffet, transport til lager og distribusjon til kunde. Utslippsreduksjonen er beregnet i CO2-ekvivalenter på bakgrunn av standard fossile referanseverdier (2,87 kg/liter bensin og 3,23 kg/liter diesel). Alt volum i denne rapporten er angitt i standardliter.",
    description_paragraph3:
      "PwC har gjort en uavhengig verifikasjon av Preem Norge AS’ 2019-rapportering av bærekraftskriterier for biodrivstoff. PwC har også kontrollert at biodrivstoffvolumet i kunderapportene er i overenstemmelse med rapporteringen til Miljødirektoratet.  PwCs revisorerklæringer er tilgjengelig på preem.no.",
    description_nameAndPosition: "Navn og posisjon",
    description_date: "Dato",
    table_product: "Produkt",
    table_volume: "Volum",
    table_rawMaterial: "Råstoff",
    table_country: "Land",
    table_co2Red: "CO2-red.",
    table_doubleCount: "Avansert",
    table_total: "Totalt",
    table_totalLiterBiofuel: "liter biodrivstoff",
    infoBox2_title: "Leverte produkter",
    infoBox2_liter: "liter",
    infoBox2_and: "og",
    infoBox2_purchasedVolume: "Levert",
    infoBox2_biofuels: "Biodrivstoff",
    footer_address: "Adresse",
    footer_addressBox: "Postboks",
    footer_addressNorway: "Norge",
    footer_email: "E-post",
    footer_web: "Web",
    footer_phone: "Telefon",
    footer_customerSupport: "Kundeservice",
    footer_side: "Side",
    footer_of: "av",
  },
};
