import React from "react";
import { FormattedMessage } from "react-intl";

export default [
  {
    name: <FormattedMessage id="settingsView.index.taxClass.fuelType" />,
    id: "fuel_type",
    type: "select",
    params: "fuel_types",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.usageArea" />,
    id: "usage_area",
    type: "select",
    params: "usage_area",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.lowestSulphur" />,
    id: "sulphur_lowest_value",
    type: "text",
    optionnal: "true",
    decimals_format: 2,
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.highestSulphur" />,
    id: "sulphur_highest_value",
    type: "text",
    optionnal: "true",
    decimals_format: 2,
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.doubleCount" />,
    id: "double_count",
    type: "select",
    params: "double_counting",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.roadTax" />,
    id: "road_tax",
    type: "text",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.roadBioBelow" />,
    id: "road_bio_below_mandate",
    type: "text",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.roadBioAbove" />,
    id: "road_bio_above_mandate",
    type: "text",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.co2Tax" />,
    id: "co2_tax",
    type: "text",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.baseTax" />,
    id: "ground_tax",
    type: "text",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxClass.sulphurTax" />,
    id: "sulphur_tax",
    type: "text",
    optionnal: "true",
  },
];
