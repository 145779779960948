import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../../reducers/reducer";
import MultiStepView from "../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView";
import {
  allocateUpdateCustomerReq,
  changeStep,
} from "../../../reducers/sustainability/allocation/multiStep/index";

interface Props {
  periodName: string;
  isSimulation: boolean;
  isJoint: boolean;
  activeStep: number;
  isFetching: boolean;
  available_quantities_part1: any;
  steps: any;
  allocateUpdateCustomerReq: any;
  changeStep: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      periodName: state.sustainability.main.activePeriod
        ? state.sustainability.main.activePeriod.name
        : "-",
      isSimulation: state.sustainability.main.isSimulation,
      isJoint: state.sustainability.main.isJoint,
      activeStep: state.sustainability.allocation.multiStep.activeStep,
      isFetching: state.sustainability.allocation.multiStep.isFetching,
      available_quantities_part1:
        state.sustainability.allocation.multiStep.available_quantities_part1,
      steps: state.sustainability.allocation.multiStep.steps,
    }),
    (dispatch: any) => ({
      dispatch,
      changeStep: (step: number) => dispatch(changeStep(step)),
      allocateUpdateCustomerReq: (
        id: number,
        index: number,
        value: number,
        requirement: { id: number; index: number; type: string },
        customer: { id: number; index: number }
      ) =>
        dispatch(
          allocateUpdateCustomerReq(id, index, value, requirement, customer)
        ),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(MultiStepView);
