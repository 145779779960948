import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";

import avatarIcon from "../../../../assets/icons/avatar.svg";
import ButtonRounded from "../../../../components/ButtonRounded";
import ModalContainer from "../../../../components/ModalContainer";
import Delete from "../../../../api/settings/users/delete";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    userContainer: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 0),
    },
    btnCancel: {},
  })
);

interface ModalDeleteProps {
  open: boolean;
  onClose: () => void;
  user: {
    id: number;
    name: string;
  };
}

const ModalDeleteUser: React.FC<ModalDeleteProps> = ({
  open,
  onClose,
  user,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const handleDelete = async () => {
    const response = await Delete(user.id);

    if (response && response.success) {
      onClose();
      setIsError(false);
    } else {
      setIsError(true);
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={<FormattedMessage id="settingsView.users.modalDelete.warning" />}
    >
      <div className={classes.modalContent}>
        <Grid
          container
          alignItems="center"
          direction="column"
          spacing={2}
          className={classes.userContainer}
        >
          <Grid item>
            <Avatar src={avatarIcon} />
          </Grid>
          <Grid item>
            <Typography variant="body1">{user.name}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.btnContainer}>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="outlined"
              onClick={onClose}
              color="secondary"
            >
              {<FormattedMessage id="settingsView.modal.cancel" />}
            </ButtonRounded>
          </Grid>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={isError}
            >
              {<FormattedMessage id="settingsView.modal.delete" />}
            </ButtonRounded>
          </Grid>
        </Grid>
        {isError && (
          <Typography align="center" variant="body2" color="error">
            {<FormattedMessage id="settingsView.users.modalDelete.failed" />}
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalDeleteUser;
