import { ErrorPayload } from "../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchDashboardData from "../../api/dashboard/getDashboardData";

export interface DashboardState {
  isFetching: boolean;
  data: any;
  error?: any;
}

const initialState: DashboardState = {
  isFetching: false,
  data: {
    requirements: {
      gen_req_percentage: 0,
      gasoline_percentage: 0,
      adv_biofuel_percentage: 0,
    },
    volume_status: {
      biofuel_fulfillment: 0,
      bio_percentage_l: 0,
      bio_percentage_le: 0,
    },
    tax_status: {
      net_tax: 0,
    },
    sustainability_storage: {
      biodiesel_single_count: 0,
      biodiesel_double_count: 0,
      bioethanol_single_count: 0,
      bioethanol_double_count: 0,
    },
    delivered_volumes: {
      biodiesel_single: 0,
      biodiesel_double: 0,
      bioethanol_single: 0,
      bioethanol_double: 0,
    },
    delivered_summary: {
      total_bio: 0,
      total_biodiesel: 0,
      total_bioethanol: 0,
    },
  },
  error: undefined,
};

// Action types
const DASHBOARD_REQUEST = "dashboard/REQUEST";
const DASHBOARD_ERROR = "dashboard/ERROR";
const DASHBOARD_SUCCESS = "dashboard/SUCCESS";

// Action creators
function requestDashboardData() {
  return {
    type: DASHBOARD_REQUEST,
  };
}

function errorDashboardData(error: any) {
  return {
    type: DASHBOARD_ERROR,
    payload: { error },
  };
}

function receiveDashboardData(data: any) {
  return {
    type: DASHBOARD_SUCCESS,
    payload: data,
  };
}

export function getDashboardData() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.dashboard) {
      dispatch(requestDashboardData());
      try {
        const data = await fetchDashboardData(
          state.exciseDuty.taxPeriods.selectedYear
        );
        if (data.success) {
          dispatch(receiveDashboardData(data));
        }
      } catch (error) {
        dispatch(errorDashboardData(error));
      }
    }
    return Promise.resolve;
  };
}

// Reducers
const DashboardReducer = handleActions<DashboardState>(
  {
    [DASHBOARD_REQUEST]: handleDashboardRequest,
    [DASHBOARD_ERROR]: handleDashboardError,
    [DASHBOARD_SUCCESS]: handleDashboardSuccess,
  } as any,
  initialState
);

function handleDashboardRequest(state: DashboardState) {
  return {
    ...state,
    isFetching: true,
    error: undefined,
  };
}

function handleDashboardError(
  state: DashboardState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handleDashboardSuccess(state: DashboardState, action: Action<any>) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
    error: undefined,
  };
}

export default DashboardReducer;
