import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DropZone from "./DropZone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    enableTitleMinHeight: {
      minHeight: "64px",
    },
  })
);

interface DropZoneCardProps {
  title: string | React.ReactElement;
  handleFiles: (files: File[]) => void;
  disableTitleMinHeight?: boolean;
}

const DropZoneCard: React.FC<DropZoneCardProps> = ({
  title,
  handleFiles,
  disableTitleMinHeight = false,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          variant="h5"
          className={clsx(classes.title, {
            [classes.enableTitleMinHeight]: !disableTitleMinHeight,
          })}
        >
          {title}
        </Typography>
        <DropZone handleFiles={handleFiles} />
      </CardContent>
    </Card>
  );
};

export default DropZoneCard;
