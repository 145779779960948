import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import ButtonRounded from "../../components/ButtonRounded";
import ModalContainer from "../../components/ModalContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    container: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 0),
    },
    btnCancel: {},
  })
);

interface ModalConfirmProps {
  open: boolean;
  onClose: () => void;
  handleConfirm: () => void;
  handleReject: () => void;
  title: string;
  buttonTextYes?: string;
  buttonTextNo?: string;
}

const ModalConfirm: React.FC<ModalConfirmProps> = ({
  open,
  onClose,
  title,
  handleConfirm,
  handleReject,
  buttonTextYes = "Yes",
  buttonTextNo = "No",
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();
  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={title}
    >
      <div className={classes.modalContent}>
        <Grid container spacing={2} className={classes.btnContainer}>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="outlined"
              onClick={handleReject}
              color="secondary"
            >
              {buttonTextNo}
            </ButtonRounded>
          </Grid>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleConfirm}
              disabled={isError}
            >
              {buttonTextYes}
            </ButtonRounded>
          </Grid>
        </Grid>
        {isError && (
          <Typography align="center" variant="body2" color="error">
            Failed to upload this file.
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalConfirm;
