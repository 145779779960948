import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ButtonRounded from "../../../../components/ButtonRounded";
import ModalContainer from "../../../../components/ModalContainer";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    userContainer: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 5),
    },
    btnCancel: {},
    titleContainer: {
      padding: theme.spacing(3),
    },
  })
);

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalConfirmDelivery: React.FC<ModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles();

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      title={
        <div className={classes.titleContainer}>
          <div>
            <FormattedMessage id="sustainabilityView.reportsView.modal.confirmDelivery.title1" />
          </div>
          <div>
            <FormattedMessage id="sustainabilityView.reportsView.modal.confirmDelivery.title2" />
          </div>
        </div>
      }
    >
      <div className={classes.modalContent}>
        <Grid container spacing={2} className={classes.btnContainer}>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              onClick={onClose}
              color="secondary"
            >
              <FormattedMessage id="sustainabilityView.reportsView.modal.confirmDelivery.button.no" />
            </ButtonRounded>
          </Grid>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="primary"
              onClick={onConfirm}
            >
              <FormattedMessage id="sustainabilityView.reportsView.modal.confirmDelivery.button.yes" />
            </ButtonRounded>
          </Grid>
        </Grid>
      </div>
    </ModalContainer>
  );
};

export default ModalConfirmDelivery;
