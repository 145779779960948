import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import { TextField } from "final-form-material-ui";
import { Form, Field } from "react-final-form";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ButtonRounded from "../components/ButtonRounded";
import { FormattedMessage } from "react-intl";

import authResetUserPassword from "../api/authResetUserPassword";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      width: "1024px",
      margin: "auto",
      [theme.breakpoints.down('md')]: {
        width: "870px",
      },
      [theme.breakpoints.down('sm')]: {
        width: "640px",
      }
    },
    logo: {
      position: "absolute",
      left: "5%",
      top: "5%",
      [theme.breakpoints.between('sm', 'md')]: {
        top: "5%",
      },
      [theme.breakpoints.down('sm')]: {
        left: "0",
      }
    },
    title: {
      marginBottom: theme.spacing(6),
    },
    container: {
      display: "flex",
      height: "446px",
    },
    formContainer: {
      flex: 7,
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(0),
    },
    loginInputContainer: {
      "margin-bottom": theme.spacing(2.5),
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
      },
      "& .MuiInputAdornment-root": {
        color: theme.palette.grey[600],
      },
    },
    btnContainer: {
      maxWidth: "50%",
    },
    btnConfirm: {
      marginTop: theme.spacing(2.5),
      height: "40px",
    },
    oval: {
      color: theme.palette.primary.main,
      flex: 7,
      marginLeft: theme.spacing(8),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      overflow: "hidden",
      '&:before': {
        position: "absolute",
        content: "''",
        top: "-77px",
        left: "0",
        width: "600px",
        height: "600px",
        background: theme.palette.grey[900],
        borderRadius: "100%",
      },
    },
    iconLock: {
      transform: "scale(5)",
    },
  })
);

const validate = (values: any) => {
  const errors: { [k: string]: any } = {};
  if (!values.password1 || !(values.password1.length > 4)) {
    errors.password1 = !values.password1 ? (
      <FormattedMessage id={"loginView.errorEmpty"} />
    ) : (
      <FormattedMessage id={"loginView.errorFill"} />
    );
  }

  if (!values.password2 || !(values.password2 === values.password1)) {
    errors.password2 = !values.password2 ? (
      <FormattedMessage id={"loginView.errorEmpty"} />
    ) : (
      <FormattedMessage id={"loginView.errorPasswordNotIdentical"} />
    );
  }
  return errors;
};

const SetNewPassView: React.FC = () => {
  const history = useHistory();
  const { id } = useParams();
  const classes = useStyles();

  const onSubmit = async (values: any) => {
    if (id) {
      const loginAnswer = await authResetUserPassword(id, values.password1);
      if (!loginAnswer.valid) {
        return {
          password1: loginAnswer.message,
        };
      } else {
        history.push("/");
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <Typography variant="h5">
          <FormattedMessage id={"loginView.logo"} />
        </Typography>
      </div>
      <Paper className={classes.container}>
        <div className={classes.formContainer}>
          <Form
            onSubmit={onSubmit}
            validate={(values: any) => {
              return {
                ...validate(values),
              };
            }}
            render={({
              handleSubmit,
              active,
              submitting,
              hasValidationErrors,
            }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Typography
                  variant="h4"
                  component="h3"
                  className={classes.title}
                >
                  <FormattedMessage id={"loginView.setNewPassword"} />
                </Typography>
                <FormattedMessage id={"loginView.newPassword"}>
                  {newPassword => (
                    <Field
                      className={classes.loginInputContainer}
                      type="password"
                      component={TextField}
                      name="password1"
                      placeholder={newPassword}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon
                              color={
                                active === "password1" ? "action" : "disabled"
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>
                <FormattedMessage id={"loginView.repeatPassword"}>
                  {repeatPassword => (
                    <Field
                      className={classes.loginInputContainer}
                      type="password"
                      component={TextField}
                      name="password2"
                      placeholder={repeatPassword}
                      fullWidth
                      InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon
                              color={
                                active === "password2" ? "action" : "disabled"
                              }
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>
                <div className={classes.btnContainer}>
                  <ButtonRounded
                    className={classes.btnConfirm}
                    variant="contained"
                    color="primary"
                    type="submit"
                    fullWidth
                    disabled={submitting || hasValidationErrors}
                  >
                    <FormattedMessage id={"loginView.confirm"} />
                  </ButtonRounded>
                </div>
              </form>
            )}
          />
        </div>

        <div className={classes.oval}>
          <LockOutlinedIcon fontSize="large" className={classes.iconLock} />
        </div>
      </Paper>
    </div>
  );
};

export default SetNewPassView;
