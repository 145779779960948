export default {
  EN: {
    header_title: "Biofuel sustainability documentation",
    header_subtitle: "Issued by Preem Norge AS",
    infoBox1_title: "General information",
    infoBox1_customer: "Customer",
    infoBox1_product: "Product",
    infoBox1_quantity: "Quantity",
    infoBox1_m3: "m³",
    infoBox1_deliveryPeriod: "Delivery period",
    infoBox1_issued: "Issued",
    infoBox1_noteRef: "Note ref.",

    infoBox2_title: "Delivery information",
    infoBox2_vessel: "Vessel",
    infoBox2_shipment: "Shipment",
    infoBox2_bl_date: "B/L date",
    infoBox2_loading: "Loading",
    infoBox2_discharge: "Discharge",

    infoBox3_title: "Renewable summary",
    infoBox3_bio_product: "Bio product",
    infoBox3_quantity: "Quantity",
    infoBox3_total_emission: "Total emission",
    infoBox3_ghg_reduction: "GHG reduction",
    infoBox3_batches: "Batches",
    infoBox3_batch: "Batch",
    infoBox3_type_batches: "batches",
    infoBox3_type_batch: "batch",

    renewable_batches_title: "Renewable batches",
    renewable_batch_title: "Renewable batch",

    renewable_batch_id: "BKV batch #",
    renewable_batch_quantity: "Quantity",
    renewable_batch_certificate: "Certificate",
    renewable_batch_raw_material: "Raw material",
    renewable_batch_country_of_origin: "Country of origin",
    renewable_batch_site_start: "Site start",
    renewable_batch_etot: "Etot",
    renewable_batch_eec: "Eec",
    renewable_batch_el: "El",
    renewable_batch_ep: "Ep",
    renewable_batch_etd: "Etd",
    renewable_batch_ghg: "GHG",
    renewable_batch_eu: "Eu",
    renewable_batch_esca: "Esca",
    renewable_batch_eccs: "Eccs",
    renewable_batch_eccr: "Eccr",

    description_title: "Notes",
    description_paragraph1:
      "The product and renewable batches covered by this declaration complies with the EU Renewable Energy Directives (RED/RED II) and local Norwegian legislation.",
    description_paragraph2:
      "GHG emissions and emission savings in gCO2/MJ are calculated according to the methodology in RED II Article 31. The reference GHG value given in RED II Annex V forfossil transport fuel is equal to 94 gCO2/MJ.",
    description_paragraph3:
      "The total emission value (Etot) includes the following emission factors:",
    description_bullet1: "- extraction and cultivation (Eec)",
    description_bullet2: "- processing (Ep)",
    description_bullet3: "- transport and distribution (Etd)",
    description_bullet4: "- land use change (El)",
    description_bullet5: "- savings from soil carbon accumulation (Esca)",
    description_bullet6: "- carbon capture and storage (Eccs)",
    description_bullet7: "- carbon captue and replacement (Eccr)",
    description_paragraph4:
      "If the document specifies a raw material that is waste/residue, the raw material meets the definition of waste/residue according to RED II, ie. it was not intentionally produced and not intentionally modified, or contaminated, or discarded, to meet the definition of waste or residue.",
    description_paragraph5: "The mass balance method is used for traceability.",

    footer_address: "Address",
    footer_addressBox: "Box",
    footer_addressNorway: "Norway",
    footer_email: "E-mail",
    footer_web: "Web",
    footer_phone: "Phone",
    footer_customerSupport: "Customer Support",
    footer_side: "Page",
    footer_of: "of",
  },
  NO: {
    header_title: "Dokumentasjon for bærekraftig biodrivstoff",
    header_subtitle: "Utstedt av Preem Norge AS",
    infoBox1_title: "Generell informasjon",
    infoBox1_customer: "Kunde",
    infoBox1_product: "Produkt",
    infoBox1_quantity: "Volum",
    infoBox1_m3: "m³",
    infoBox1_deliveryPeriod: "Leveringsperiode",
    infoBox1_issued: "Utstedt",
    infoBox1_noteRef: "Dokumentreferanse",

    infoBox2_title: "Leverings informasjon",
    infoBox2_vessel: "Fartøy",
    infoBox2_shipment: "Forsendelse",
    infoBox2_bl_date: "B/L dato",
    infoBox2_loading: "Laster",
    infoBox2_discharge: "Utflod",

    infoBox3_title: "Fornybar oppsummering",
    infoBox3_bio_product: "Bio produkt",
    infoBox3_quantity: "Mengde",
    infoBox3_total_emission: "Totalt utslipp",
    infoBox3_ghg_reduction: "GHG reduksjon",
    infoBox3_batches: "Batcher",
    infoBox3_batch: "Batch",

    infoBox3_type_batches: "batcher",
    infoBox3_type_batch: "batch",


    renewable_batches_title: "Fornybare partier",
    renewable_batch_title: "Fornybare partier",

    renewable_batch_id: "BKV parti #",
    renewable_batch_quantity: "Mengde",
    renewable_batch_certificate: "Sertifikat",
    renewable_batch_raw_material: "Rå materiale",
    renewable_batch_country_of_origin: "Opprinnelsesland",
    renewable_batch_site_start: "Site start",
    renewable_batch_etot: "Etot",
    renewable_batch_eec: "Eec",
    renewable_batch_el: "El",
    renewable_batch_ep: "Ep",
    renewable_batch_etd: "Etd",
    renewable_batch_ghg: "GHG",
    renewable_batch_eu: "Eu",
    renewable_batch_esca: "Esca",
    renewable_batch_eccs: "Eccs",
    renewable_batch_eccr: "Eccr",

    description_title: "Notater",
    description_paragraph1:
      "Produktet og de fornybare partiene som dekkes av denne erklæringen er i samsvar med EUs fornybare energidirektiver (RED/RED II) og lokal norsk lovgivning.",
    description_paragraph2:
      "GHG-utslipp og utslippsbesparelser i gCO2/MJ beregnes i henhold til metodikken i RED II artikkel 31. Referanseverdien for GHG gitt i RED II vedlegg V for fossilt transportdrivstoff er lik 94 gCO2/MJ.",
    description_paragraph3:
      "Den totale utslippsverdien (Etot) inkluderer følgende utslippsfaktorer:",
    description_bullet1: "- utvinning og dyrking (EEC)",
    description_bullet2: "- behandling (Ep)",
    description_bullet3: "- transport og distribusjon (Etd)",
    description_bullet4: "- endret arealbruk (El)",
    description_bullet5: "- besparelser fra karbonakkumulering i jord (Esca)",
    description_bullet6: "- karbonfangst og -lagring (Eccs)",
    description_bullet7: "- karbonfangst og -erstatning (Eccr)",
    description_paragraph4:
      "Dersom dokumentet spesifiserer en råvare som er avfall/rest, oppfyller råstoffet definisjonen av avfall/rest etter RED II, dvs. det ble ikke med vilje produsert og ikke med vilje modifisert, eller forurenset eller kassert for å oppfylle definisjonen av avfall eller rester.",
    description_paragraph5: "Massebalansemetoden brukes for sporbarhet.",

    footer_address: "Adresse",
    footer_addressBox: "Postboks",
    footer_addressNorway: "Norge",
    footer_email: "E-post",
    footer_web: "Web",
    footer_phone: "Telefon",
    footer_customerSupport: "Kundeservice",
    footer_side: "Side",
    footer_of: "av",
  },
};
