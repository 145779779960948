/**
 * Delete a single user
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../utils";
import { BASE_URL } from "../../endpoints";

const Delete = async (id: number) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(`${BASE_URL}/api/bkv_mappings/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return { success: data.success };
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default Delete;
