/**
 * Sustainability Reporting > Storage > outgoing transaction
 */

import { getToken } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export const postBiotemplateReconciliation = async (values: any) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/reconciliation/save_reconciled_volume`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(values),
      });

      if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return data;
      } else if (response.status === 400) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};







