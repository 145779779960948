/**
 * Download sustainability report
 * For: sustainability reporting > reports > download report button
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export type Tfiletype =
  | "report_json"
  | "report_excel"
  | "subreport_excel"
  | "audit_excel";

interface IGetDownloadReport {
  type: Tfiletype;
  taxYear?: number;
}

const fileurlmappings = {
  report_json: "export_json_report",
  report_excel: "export_sustainability_excel_report",
  subreport_excel: "export_sustain_quantities_excel_report",
  audit_excel: "export_audit_excel_report",
};

const getDownloadReport = async ({ type, taxYear }: IGetDownloadReport) => {
  const token = getToken();
  const urlSuffix = fileurlmappings[type];

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/sustainability_reports/${urlSuffix}?tax_year=${taxYear}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.blob();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default getDownloadReport;
