import { FormattedMessage } from "react-intl";
import React from "react";

export default [
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.serialNumber" />
    ),
    id: "serial_number",
    type: "text",
    hide: "true",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.transactionType" />
    ),
    id: "transaction_type",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.storageTerminal" />
    ),
    id: "storage_terminal",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.salesProduct" />
    ),
    id: "sales_product",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.owner" />
    ),
    id: "owner",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.customer" />
    ),
    id: "customer",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.sulphur" />
    ),
    id: "sulfer_ppm",
    type: "text",
    alignRight: true,
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.fuelType" />
    ),
    id: "fuel_type",
    type: "select",
    params: "fuel_types",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.usageArea" />
    ),
    id: "usage_area",
    type: "select",
    params: "usage_area",
  },
  {
    name: <FormattedMessage id="settingsView.index.masterData.doubleCount" />,
    id: "double_count",
    type: "select",
    params: "double_counting",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.quantity" />
    ),
    id: "quantity",
    type: "text",
    alignRight: true,
  },
  {
    name: <FormattedMessage id="exciseDutyView.reportsView.mainView.period" />,
    id: "period",
    type: "date",
  },
];
