import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Form, Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import KeyboardDatePicker from "../../../../components/inputs/KeyboardDatePicker";
import ButtonRounded from "../../../../components/ButtonRounded";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ModalContainer from "../../../../components/ModalContainer";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import useModal from "../../../../components/modal/useModal";
import ModalDownloadCustomerReport from "./CustomerRequirement/ModalDownloadCustomerReport";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {
      minHeight: "220px",
      minWidth: "500px",
      display: "flex",
      flexDirection: "column",
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnLeft: {
      marginRight: theme.spacing(3),
    },
    labelName: {
      fontSize: "13px",
      color: theme.palette.grey[400],
    },
    red: {
      color: "red",
    },
    circularProgressContainer: {
      position: "absolute",
      zIndex: 10,
      left: 0,
      right: 0,
      top: "100px",
      bottom: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme.palette.background.paper,
    },
    circularProgress: {
      width: "48px",
      height: "48px",
    },
  })
);

interface ModalDownloadReportProps {
  open: boolean;
  onClose: () => void;
  selected_customer: any;
  setSelectedYear: (year: any) => void;
  getCustomerPDFFiles: (id: number, data: any) => any;
  CustomerPDFdata: any;
  taxPeriods: any;
  selectedYear: any;
  clearErrorfromLastcall: any;
}

const ModalDownloadReport: React.FC<ModalDownloadReportProps> = ({
  open,
  onClose,
  clearErrorfromLastcall,
  selected_customer,
  setSelectedYear,
  getCustomerPDFFiles,
  CustomerPDFdata,
  taxPeriods,
  selectedYear,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const classes = useStyles();
  const { openModalId, toggle } = useModal();

  const onSubmit = async (values: any) => {
    setIsError(false);
    //setSelectedYear(values.taxYear);
    setIsLoading(true);
    //window.alert(JSON.stringify(values));
    getCustomerPDFFiles(selected_customer.id, {
      customer_name: values.customerName,
      tax_year: values.taxYear,
      name: null,
      position: null,
      date: "31-10-2020",
      customer_nr: selected_customer.customer_no,
    });
  };

  const openDownloadModal = () => {
    toggle("download");
  };

  const closeDownloadModal = () => {
    toggle();
  };
  useEffect(() => {
    if (
      CustomerPDFdata.data !== undefined &&
      CustomerPDFdata.error === undefined &&
      isLoading
    ) {
      setIsLoading(false);
      openDownloadModal();
    }
  }, [CustomerPDFdata.data]);

  useEffect(() => {
    if (CustomerPDFdata.error !== undefined) {
      setIsError(true);
      setIsLoading(false);
    }
  }, [CustomerPDFdata.error]);

  const closeSelf = () => {
    setIsError(false);
    clearErrorfromLastcall();
    closeDownloadModal();
    onClose();
  };
  const required = (value: any) =>
    value && value !== "" ? undefined : "Required";
  return (
    <ModalContainer
      open={open}
      onClose={closeSelf}
      title="Generate Customer Report"
    >
      <div className={classes.modalContent}>
        {isLoading && (
          <Box flex={1} className={classes.circularProgressContainer}>
            <CircularProgress className={classes.circularProgress} />
          </Box>
        )}
        <Form
          onSubmit={onSubmit}
          initialValues={{
            customerName: selected_customer.name,
            name: "",
            position: "",
            taxYear: selectedYear,
            date: `${selectedYear}/03/31`,
            language: "",
            version: "",
          }}
          render={({
            handleSubmit,
            submitting,
            pristine,
            hasValidationErrors,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3} className={classes.marginBottom}>
                <Grid item xs={12} sm={12}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Customer Name <RequiredAsterisk />
                  </Typography>
                  <Field name="customerName">
                    {(props) => (
                      <TextField
                        {...props.input}
                        variant="outlined"
                        disabled
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Name <RequiredAsterisk />
                  </Typography>
                  <Field name="name" validate={required}>
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          {...input}
                          variant="outlined"
                          placeholder="Name"
                          fullWidth
                        />
                        {meta.error && meta.touched && (
                          <span className={classes.red}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Position <RequiredAsterisk />
                  </Typography>
                  <Field name="position" validate={required}>
                    {({ input, meta }) => (
                      <div>
                        <TextField
                          {...input}
                          variant="outlined"
                          placeholder="Position"
                          fullWidth
                        />
                        {meta.error && meta.touched && (
                          <span className={classes.red}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Tax Year <RequiredAsterisk />
                  </Typography>
                  <Field name="taxYear" component="select">
                    {(props) => (
                      <Select
                        fullWidth
                        displayEmpty
                        {...props.input}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        input={<OutlinedInput labelWidth={0} fullWidth />}
                      >
                        {taxPeriods
                          ? taxPeriods.map((year: any, index: number) => (
                              <MenuItem
                                value={Object.keys(year)[0]}
                                key={
                                  "Sus.View_Reports_Year_" +
                                  Object.keys(year)[0] +
                                  "-" +
                                  index
                                }
                              >
                                {Object.keys(year)[0]}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      // <SmartSelect this part is only useful when we do not want to display only full year
                      //   {...props.input}
                      //   name="tax_year"
                      //   value={props.input.value}
                      //   onChange={props.input.onChange}
                      //   displayEmpty
                      //   fullWidth
                      //   type="tax_years"
                      // />
                    )}
                    {/* //when the tax year is disabled, only showed 2020
                    {(props) => (
                      <TextField
                        {...props.input}
                        variant="outlined"
                        disabled
                        fullWidth
                      />
                    )} */}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Date <RequiredAsterisk />
                  </Typography>
                  <Field name="date">
                    {({input,meta}) => (
                      <KeyboardDatePicker
                        {...input}
                        inputVariant="outlined"
                        format="YYYY-MM-DD"
                        placeholder={moment().format("YYYY-MM-DD")}
                        value={input.value ? input.value : null}
                        onChange={(value: any) => {
                          input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                        }}
                        fullWidth
                      />
                    )}
                  </Field>
                </Grid>

                {/* user choose language for the download pdf report */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Report Language <RequiredAsterisk />
                  </Typography>
                  <Field name="language" component="select" validate={required}>
                    {({ input, meta }) => (
                      <div>
                        <Select
                          {...input}
                          name="language"
                          value={input.value}
                          onChange={input.onChange}
                          input={<OutlinedInput labelWidth={0} fullWidth />}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Norsk">Norsk</MenuItem>
                          <MenuItem value="English">English</MenuItem>
                        </Select>
                        {meta.error && meta.touched && (
                          <span className={classes.red}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </Grid>

                {/* users choose between two reports version */}
                <Grid item xs={12} sm={6}>
                  <Typography
                    variant="body2"
                    gutterBottom
                    className={classes.labelName}
                  >
                    Report Version <RequiredAsterisk />
                  </Typography>
                  <Field name="version" component="select" validate={required}>
                    {({ input, meta }) => (
                      <div>
                        <Select
                          {...input}
                          name="versuib"
                          value={input.value}
                          onChange={input.onChange}
                          input={<OutlinedInput labelWidth={0} fullWidth />}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Simple">Simple Version</MenuItem>
                          <MenuItem value="Full">Full Version</MenuItem>
                        </Select>
                        {meta.error && meta.touched && (
                          <span className={classes.red}>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <ButtonRounded
                    type="submit"
                    disabled={submitting || hasValidationErrors}
                    fullWidth
                    variant="contained"
                    color="primary"
                  >
                    <Typography>Generate Customer Report</Typography>
                  </ButtonRounded>
                </Grid>
              </Grid>
              <ModalDownloadCustomerReport
                open={openModalId === "download"}
                onClose={closeDownloadModal}
                paramData={{ ...values }}
                extraData={{
                  version: values.version,
                  langu: values.language,
                  CustomerPDFdata: CustomerPDFdata,
                }}
              />
            </form>
          )}
        />

        {isError && (
          <Typography align="center" variant="body2" color="error">
            Failed to perform this operation.
          </Typography>
        )}
        {isError && CustomerPDFdata && CustomerPDFdata.error !== undefined && (
          <Typography align="center" variant="body2" color="error">
            {/* try display error message in a clean format */}
            {JSON.stringify(CustomerPDFdata.error)
              .replace('{"', "")
              .replace('":["', " : ")
              .replace('"]}', "")}
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalDownloadReport;
