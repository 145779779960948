import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import ButtonRounded from "../../../../components/ButtonRounded";
import ManualExciseDutyModal from "./ManualExciseDutyModal";
import getReportsHeaders from "../../../../api/exciseduty/getReportsHeaders";
import { ISelectedYearMonth } from "../interfaces";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      height: "100%",
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    content: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
  })
);

interface NewExciseDutyCardProps {
  selectedYearMonth: ISelectedYearMonth;
}

const NewExciseDutyCard: React.FC<NewExciseDutyCardProps> = ({
  selectedYearMonth,
}) => {
  const [showManualExciseModal, setShowManualExciseModal] = useState<boolean>(
    false
  );
  const classes = useStyles();
  const history = useHistory();
  const { selectedMonthIndex, selectedYear } = selectedYearMonth;

  const openManualExciseModal = () => setShowManualExciseModal(true);
  const closeManualExciseModal = () => setShowManualExciseModal(false);
  const redirectToCreate = () => history.push("/excise-duty/reports/create");

  const handleManualExcise = async () => {
    // Transform API data & Do some checking here:
    // whether selected year-month exist in the data from API
    // "data" look up object shape: year number as key, month index array as value
    // { 2018: [1,3, ...]
    //   2019: [0, 5, 11, ...]
    // }
    //
    const results = await getReportsHeaders();
    if (results && Array.isArray(results)) {
      const data: { [key: number]: number[] } = {};
      results.forEach(item => {
        const month = Number(item.month) - 1; // get month index based on 0 - 11
        const year = Number(item.year);
        data[year] = Array.isArray(data[year])
          ? [...data[year], month]
          : [month];
      });

      if (
        Array.isArray(data[selectedYear]) &&
        data[selectedYear].findIndex(m => m === selectedMonthIndex) > -1
      ) {
        openManualExciseModal();
      } else {
        redirectToCreate(); // selected year month not found in API data
      }
    } else {
      redirectToCreate(); // API data not processable
    }
  };

  return (
    <>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <div>
            <Typography variant="h5" className={classes.title}>
              <FormattedMessage
                id={"exciseDutyView.filesView.exciseDutyReportCreate"}
              />
            </Typography>
            <Typography variant="body1">
              {" "}
              <FormattedMessage
                id={"exciseDutyView.filesView.exciseDutyReportUploadSubtitle"}
              />
            </Typography>
          </div>
          <ButtonRounded
            variant="contained"
            color="primary"
            onClick={handleManualExcise}
          >
            <FormattedMessage
              id={"exciseDutyView.filesView.exciseDutyReportUploadCreate"}
            />
          </ButtonRounded>
        </CardContent>
      </Card>
      <ManualExciseDutyModal
        open={showManualExciseModal}
        onClose={closeManualExciseModal}
      />
    </>
  );
};

export default NewExciseDutyCard;
