import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import parseData from "../../../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      padding: theme.spacing(3),
      paddingTop: 0,
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
      //   backgroundColor: theme.palette.grey[50],
      // },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.body2,
        [theme.breakpoints.up("xl")]: {
          width: "25%",
        },
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontWeight: 500,
      },
    },
    green: {
      backgroundColor: "#cce6d8",
    },
    red: {
      backgroundColor: "#ffdad1",
    },
    yellow: {
      backgroundColor: "#ffd588",
    },
  })
);

const tableHeadings = ["Sold", "Requirements", "Allocated", "Remaining"];

interface Props {
  data: any;
}

const OverviewTableFulfillment: React.FC<Props> = ({ data }) => {
  const classes = useStyles();

  const tableRows = data
    ? [
        {
          sold: "Bio Components Diesel Single Counting",
          requirements: data.req_single_count_biodisel,
          allocated: data.all_single_count_biodisel,
          remaining: data.rem_single_count_biodisel,
        },
        {
          sold: "Bio Components Diesel Advanced",
          requirements: data.req_double_count_biodisel,
          allocated: data.all_double_count_biodisel,
          remaining: data.rem_double_count_biodisel,
        },
        {
          sold: "Bio Components Gasoline Single Counting",
          requirements: data.req_single_count_bioetanol,
          allocated: data.all_single_count_ethanol,
          remaining: data.rem_single_count_ethanol,
        },
        {
          sold: "Bio Components Gasoline Advanced",
          requirements: data.req_double_count_bioetanol,
          allocated: data.all_double_count_ethanol,
          remaining: data.rem_dobule_count_ethanol,
        },
      ]
    : [];

  const parseVal = (value: any) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) parsed *= -1;
      return parseData(parsed, 2);
    } else return parsed;
  };

  const getStatus = (remaining: any) => {
    if (remaining > 0) return "Not Complete";
    if (remaining < 0) return "Overallocated";
    return "Complete";
  };

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeadings.map((heading, hidx) => (
              <TableCell key={hidx}>{heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {tableRows.map((row, ridx) => (
            <TableRow
              key={ridx}
              className={clsx({
                [classes.green]: getStatus(row.remaining) === "Complete",
                [classes.red]: getStatus(row.remaining) === "Not Complete",
                [classes.yellow]: getStatus(row.remaining) === "Overallocated",
              })}
            >
              <TableCell variant="head">{parseVal(row.sold)}</TableCell>
              <TableCell>{parseVal(row.requirements)}</TableCell>
              <TableCell>{parseVal(row.allocated)}</TableCell>
              <TableCell>{parseVal(row.remaining)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default OverviewTableFulfillment;
