import React from "react";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import IndicatorBox from "../../../../../../containers/card/multiStep/IndicatorBoxContainer";
import EditableTableContainer from "../../../../../../containers/card/multiStep/EditableTableContainer";
import MatchingTablesContainer from "../../../../../../containers/card/multiStep/MatchingTablesContainer";
import OverviewPanel from "./OverviewPanel";
import OverviewTableContainer from "../../../../../../containers/card/multiStep/OverviewTableContainer";
import OverviewTableFulfillmentContainer from "../../../../../../containers/card/multiStep/OverviewTableFulfillmentContainer";
import OverviewTableConstructionContainer from "../../../../../../containers/card/multiStep/OverviewTableConstructionContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    btnContainer: {
      marginBottom: theme.spacing(3),
    },
    btn: {
      paddingTop: theme.spacing(0.8),
      paddingBottom: theme.spacing(0.8),
      minWidth: "110px",
      "&:first-child": {
        marginRight: theme.spacing(2),
      },
    },
    btnDark: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.common.white,
    },
    cardContent: {
      maxHeight: "calc(100vh - 250px)",
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(3),
      paddingBottom: 0,
    },
    overviewPanel: {
      backgroundColor: theme.palette.common.white,
      marginLeft: theme.spacing(-3),
      marginRight: theme.spacing(-3),
      marginBottom: theme.spacing(-3),
    },
  })
);

interface CardEditableTableProps {
  step: number;
  handleNext: () => void;
  handleBack: () => void;
}

const CardEditableTable: React.FC<CardEditableTableProps> = ({
  step,
  handleBack,
  handleNext,
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          className={classes.btnContainer}
        >
          <Grid item>
            <IndicatorBox />
          </Grid>
          <Grid item>
            {step > 0 && (
              <ButtonRounded
                variant="contained"
                className={clsx(classes.btn, classes.btnDark)}
                onClick={handleBack}
              >
                Back
              </ButtonRounded>
            )}
            <ButtonRounded
              variant="contained"
              color="primary"
              className={classes.btn}
              onClick={handleNext}
            >
              Next
            </ButtonRounded>
          </Grid>
        </Grid>

        {step === 4 ? <MatchingTablesContainer /> : <EditableTableContainer />}

        <OverviewPanel className={classes.overviewPanel}>
          <OverviewTableConstructionContainer />
        </OverviewPanel>
      </CardContent>
    </Card>
  );
};

export default CardEditableTable;
