/**
 * Download sustainability report
 * For: sustainability reporting > reports > download report button
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";


const getDownloadHBLReport = async ( reservation_id: number) => {
  const token = getToken();

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/reservations/export_pos_excel_report?reservation_id=${reservation_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.blob();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default getDownloadHBLReport;
