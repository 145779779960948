import { withStyles, Theme } from "@material-ui/core/styles";
import MuiStepLabel from "@material-ui/core/StepLabel";

const StepLabel = withStyles((theme: Theme) => ({
  labelContainer: {
    width: "90%",
  },
  label: {
    color: theme.palette.grey[600],
    ...theme.typography.body2,
    "&.MuiStepLabel-completed": {
      color: theme.palette.grey[600],
      ...theme.typography.body2,
    },
    "&.MuiStepLabel-active": {
      color: theme.palette.text.primary,
      ...theme.typography.body2,
    },
  },
}))(MuiStepLabel);

export default StepLabel;
