import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { State } from "../../../../reducers/reducer";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ButtonRounded from "../../../../components/ButtonRounded";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import SearchIcon from "@material-ui/icons/Search";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import { ISettingProps } from "../../../../components/modal/Interfaces";

import ModalAddEdit from "../../../../components/modal/ModalAddEdit";
import ModalDelete from "../../../../components/modal/ModalDelete";

import useModal from "../../../../components/modal/useModal";
import { FormattedMessage } from "react-intl";

import parseData from "../../../../components/texts/parseData";

import {
  getBkvMappingByType,
  RAW_MATERIAL_ORIGIN,
} from "../../../../api/settings/bkvMappings/get";
import DeleteModal from "../../../../components/modal/bkv-modal/DeleteModal";
import EditModal from "../../../../components/modal/bkv-modal/EditModal";
import ToggleButton from "../../../../components/ToggleButton";
import Favourite from "../../../../api/settings/bkvMappings/favourite";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backBtn: {
      textTransform: "none",
      marginBottom: theme.spacing(2),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    flagText: {
      maxWidth: "650px",
      paddingBottom: theme.spacing(1),
      color: theme.palette.error.main,
    },
    inputSearch: {
      marginRight: theme.spacing(2),
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 8px",
      },
      [theme.breakpoints.down("md")]: {
        width: "200px",
      },
    },
    responsiveTable: {
      minWidth: "425px",
      overflowX: "auto",
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "inherit",
        fontWeight: "bold",
        padding: "10px 0px",
      },
      "& .MuiTableRow-head:nth-child(odd)": {
        "& .MuiTableCell-head": {
          padding: "0px",
          borderRight: `4px solid ${theme.palette.common.white}`,
        },
      },
      "& .MuiTableCell-body": {
        minWidth: "130px",
        padding: "5px 10px",
      },
    },
    iconDelete: {
      color: theme.palette.error.main,
    },
    avatar: {
      width: "30px",
      height: "30px",
    },
  })
);

// @ts-ignore
const RawMaterialOriginView: React.FC = () => {
  const [contentList, setContentList] = useState<any[]>([]);
  const [showFlagText, setShowFlagText] = useState<boolean>(false);
  const [selectedSetting, setSelectedSetting] = useState<any>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState(0);
  const [selectedValue, setSelectedValue] = useState<any>("");
  const [firstTranslation, setFirstTranslation] = useState<any[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const history = useHistory();
  const classes = useStyles();

  const setFavourite = async (id: any) => {
    const res = await Favourite(id);
    if (res && res.success) {
      fetchData();
    } else {
      fetchData();
    }
  };

  const trackFirstValue = (response: any) => {
    const translationTracker: any = [];
    const idTracker: any = [];
    response.forEach((res: any) => {
      if (!translationTracker.includes(res.translation)) {
        translationTracker.push(res.translation);
        idTracker.push(res.id);
      }
      setFirstTranslation(idTracker);
    });
  };

  const fetchData = async () => {
    const response = await getBkvMappingByType(RAW_MATERIAL_ORIGIN);
    if (response) {
      trackFirstValue(response);
      setContentList(response);
    }
  };

  const filteredContentList = Array.isArray(contentList)
    ? contentList.filter((row) => {
        let filteredOut = true;
        Object.values(row).map((val) => {
          if (String(val).toLowerCase().indexOf(searchText.toLowerCase()) > -1)
            filteredOut = false;
        });
        return !filteredOut;
      })
    : [];

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Button
        color="primary"
        className={classes.backBtn}
        onClick={() => history.goBack()}
      >
        <Icon color="primary">arrow_back</Icon>
        <Typography variant="h6">
          &nbsp; <FormattedMessage id="settingsView.back" />
        </Typography>
      </Button>
      <Card>
        <CardContent>
          {/* Top Search Bar */}
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.titleBar}
          >
            <Grid item>
              <Typography variant="h5">{"Raw Material Origin"}</Typography>
              {showFlagText && (
                <Typography className={classes.flagText} variant="body1">
                  <FormattedMessage id="settingsView.flagText" />
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <TextField
                    placeholder="Search"
                    className={classes.inputSearch}
                    value={searchText}
                    onChange={(e: any) => setSearchText(e.target.value)}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <ButtonRounded
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setSelectedValue({});
                      setShowEditModal(true);
                    }}
                  >
                    {"Add new Raw Material Origin"}
                  </ButtonRounded>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Table */}
          <div className={classes.responsiveTable}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{"Value"}</TableCell>
                  <TableCell>{"Translation"}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(filteredContentList) &&
                  filteredContentList.map((value: any, rowIndex) => (
                    <TableRow>
                      <TableCell>{value.value}</TableCell>
                      <TableCell>{value.translation}</TableCell>
                      <TableCell>
                        <Grid container justify="flex-end" alignItems="center">
                          <ToggleButton
                            disabled={
                              firstTranslation.includes(value.id) &&
                              value.is_favourite
                            }
                            labels={["Favourite", "Set Favourite"]}
                            defaultLabelIndex={
                              value.is_favourite === true ? 0 : 1
                            }
                            size="small"
                            handleChange={() => {
                              setFavourite(value.id);
                            }}
                          />
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <Grid container justify="flex-end" alignItems="center">
                          <Grid item>
                            <IconButton
                              onClick={() => {
                                setSelectedValue(value);
                                setShowEditModal(true);
                              }}
                              color="primary"
                            >
                              <EditOutlinedIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                setSelectedId(value.id);
                                setShowDeleteModal(true);
                              }}
                              className={classes.iconDelete}
                            >
                              <DeleteOutlinedIcon fontSize="small" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </div>
          <DeleteModal
            id={selectedId}
            title={"Raw Material Origin"}
            open={showDeleteModal}
            fetchData={fetchData}
            onClose={() => setShowDeleteModal(false)}
          />
          <EditModal
            values={selectedValue}
            type={RAW_MATERIAL_ORIGIN}
            title={"Raw Material Origin"}
            fetchData={fetchData}
            open={showEditModal}
            onClose={() => setShowEditModal(false)}
            s
          />
        </CardContent>
      </Card>
    </div>
  );
};

export default RawMaterialOriginView;
