/**
 * Get files list
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

interface IgetTablesData {
  taxYear: number;
}

const getTablesData = async ({ taxYear }: IgetTablesData) => {
  const token = getToken();
  let valid = false;

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/sustainability_reports?tax_year=${taxYear}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      var result = await response.json();
      valid = result && result.success;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (result && result.errors)
      return {
        valid: false,
        message: result.errors,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    ...result,
  };
};

export default getTablesData;
