/**
 *  Dashboard: Settings View with routing
 */

import React, { useState, useEffect } from "react";
import { Switch, Route, match as Match, Redirect } from "react-router-dom";
import MainView from "./MainView";
import AppVariablesContainer from "../../../containers/settings/AppVariablesContainer";
import { getViews } from "./config";
import { getSettingAccesses } from "../../../api/settings/getSettingAccesses";
import BiofuelTypeView from "./BkvMappingsView/BiofuelTypeView";
import RawMaterialView from "./BkvMappingsView/RawMaterialView";
import RawMaterialOriginView from "./BkvMappingsView/RawMaterialOriginView";
import SettingView from "./SettingView";
interface SettingsViewProps {
  match: Match; // any; // from react router
}

const SettingsView: React.FC<SettingsViewProps> = ({ match }) => {
  const [settingViews, setSettingViews] = useState<any>(null);
  const fetchData = async () => {
    const data = await getSettingAccesses();
    if (data && data.valid) {
      setSettingViews(getViews(data));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Switch>
        <Route
            path={`${match.url}/bkv_mappings/biofuel_type_mappings`}
            component={BiofuelTypeView}
        />
        <Route
            path={`${match.url}/bkv_mappings/raw_material_mappings`}
            component={RawMaterialView}
        />
        <Route
            path={`${match.url}/bkv_mappings/rawmaterial_origins_mappings`}
            component={RawMaterialOriginView}
        />
      <Route exact path={match.url} component={MainView} />
      {settingViews &&
        settingViews.map((view: any, idx: any) =>
          view.isRestricted ? null : (
            <Route
              key={idx}
              path={`${match.url}${view.path}`}
              render={() => <view.component {...view.props} />}
            />
          )
        )}
      <Route
        path={`${match.url}/app_variables`}
        component={AppVariablesContainer}
      />

      <Redirect to={match.url} />
    </Switch>
  );
};

export default SettingsView;
