import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import * as yup from "yup";
import ButtonRounded from "../../../../components/ButtonRounded";
import ModalContainer from "../../../../components/ModalContainer";
import makeValidate from "../../../../components/form/makeValidate";
import addEditCustomer from "../../../../api/sustainability/customers/addEditCustomer";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      paddingTop: theme.spacing(2),
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnCancel: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      marginRight: theme.spacing(2.5),
    },
    labelName: {
      fontSize: "13px",
      color: theme.palette.grey[400],
    },
  })
);

interface ModalAddEditCutomerProps {
  open: boolean;
  onClose: () => void;
  mode: "add" | "edit";
  onSucceed: (customer: any) => void;
  customer?: {
    id: number;
    organizationNum: number;
    name: string;
  };
}

const ModalAddEditCutomer: React.FC<ModalAddEditCutomerProps> = ({
  open,
  onClose,
  mode,
  onSucceed,
  customer,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();

  const validationSchema = yup.object({
    customer_name: yup.string().required(),
    organization_number: yup.number().required(),
  });

  const onSubmit = async (values: any) => {
    setIsError(false);

    const customerIdObj =
      customer && mode === "edit" ? { id: customer.id } : {}; // add 'id' in the edit mode
    const orgNumber: number = Number(
      String(values.organization_number)
        .split(" ")
        .join("")
    ); // remove spaces from organization number

    const updatedCustomer = {
      name: values.customer_name,
      organization_number: orgNumber,
      ...customerIdObj,
    };

    const result = await addEditCustomer({
      mode,
      customer: updatedCustomer,
    });

    if (result) {
      onSucceed(updatedCustomer);
      onClose();
    } else {
      setIsError(true);
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={
        mode === "add" ? (
          <FormattedMessage id="customersView.modalAddEdit.new" />
        ) : (
          <FormattedMessage id="settingsView.modalAddEdit.title.edit" />
        )
      }
      subtitle={
        <FormattedMessage id="exciseDutyView.transactionsView.filters.customer" />
      }
    >
      <div className={classes.modalContent}>
        <div>
          <Form
            onSubmit={onSubmit}
            validate={values => makeValidate(values, validationSchema)}
            initialValues={
              customer
                ? {
                    customer_name: customer.name,
                    organization_number: customer.organizationNum,
                  }
                : {}
            }
          >
            {({ handleSubmit, submitting, hasValidationErrors }) => (
              <form onSubmit={handleSubmit} noValidate>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.labelName}
                >
                  <FormattedMessage id="customersView.customerTable.customerName" />
                </Typography>
                <Field
                  className={classes.marginBottom}
                  component={TextField}
                  variant="outlined"
                  name="customer_name"
                  placeholder="Customer Name"
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                />
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.labelName}
                >
                  <FormattedMessage id="allocationView.mainView.table.organizationNumber" />
                </Typography>
                <Field
                  className={classes.marginBottom}
                  component={TextField}
                  variant="outlined"
                  name="organization_number"
                  placeholder="000 000 000"
                  fullWidth
                  InputProps={{ disableUnderline: true }}
                />

                <Grid container className={classes.btnContainer}>
                  {/* <Grid item xs={6}> */}
                  <ButtonRounded
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={onClose}
                    className={classes.btnCancel}
                  >
                    <FormattedMessage id="settingsView.modal.cancel" />
                  </ButtonRounded>
                  {/* </Grid>
                  <Grid item xs={6}> */}
                  <ButtonRounded
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting || hasValidationErrors}
                  >
                    <FormattedMessage id="settingsView.modal.save" />
                  </ButtonRounded>
                  {/* </Grid> */}
                </Grid>
              </form>
            )}
          </Form>
        </div>

        {isError && (
          <Typography align="center" variant="body2" color="error">
            <FormattedMessage id="customersView.modalAddEdit.errorMsgPrefix" />{" "}
            {mode}{" "}
            <FormattedMessage id="customersView.modalAddEdit.errorMsgSuffix" />
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalAddEditCutomer;
