/**
 *  Protected route: redirects if not authenticated
 */

import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { isAuthenticated } from "../api/utils";

interface ProtectedRouteProps extends RouteProps {
  component: React.FunctionComponent<any> | React.ComponentClass<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated() ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default ProtectedRoute;
