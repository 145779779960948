import React, { useState, useEffect } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import MonthButton from "./MonthButton";
import { ISelectedYearMonth } from "../views/DashboardView/ExciseDutyView/interfaces";
import getTaxPeriods from "../api/exciseduty/getTaxPeriods";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "1110px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: theme.spacing(3, 0, 3.8, 0),
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        fontSize: "14px",
      },
    },
    select: {
      width: "150px",
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
  })
);

const months = [
  <FormattedMessage id="component.selectYearMonth.january" />,
  <FormattedMessage id="component.selectYearMonth.february" />,
  <FormattedMessage id="component.selectYearMonth.march" />,
  <FormattedMessage id="component.selectYearMonth.april" />,
  <FormattedMessage id="component.selectYearMonth.may" />,
  <FormattedMessage id="component.selectYearMonth.june" />,
  <FormattedMessage id="component.selectYearMonth.july" />,
  <FormattedMessage id="component.selectYearMonth.august" />,
  <FormattedMessage id="component.selectYearMonth.september" />,
  <FormattedMessage id="component.selectYearMonth.october" />,
  <FormattedMessage id="component.selectYearMonth.november" />,
  <FormattedMessage id="component.selectYearMonth.december" />,
];

interface SelectYearMonthProps {
  dataYearMonth: any;
  selectedYearMonth: ISelectedYearMonth;
}

const SelectYearMonth: React.FC<SelectYearMonthProps> = ({
  dataYearMonth,
  selectedYearMonth,
}) => {
  const [taxPeriods, setTaxPeriods] = useState<any>({});
  const [taxYears, setTaxYears] = useState<number[]>([]);
  const classes = useStyles();

  const {
    selectedMonthIndex,
    SetSelectedMonthIndex,
    selectedYear,
    SetSelectedYear,
  } = selectedYearMonth;

  const handleSelectYear = (e: React.ChangeEvent<{ value: unknown }>) => {
    SetSelectedYear(e.target.value as number);
  };
  const handleSelectMonth = (id: number) => SetSelectedMonthIndex(id);

  /*
  function: fetchTaxPeriods
  description:
    Fetch year month data & transform the API data for using inside the component.
    Shape after transformation: a lookup object with year as key and array of booleans as value.

    {
       2019: [true, false, .....],
       2020: [false, true,..]
     }

  */
  const fetchTaxPeriods = async () => {
    if (dataYearMonth && Array.isArray(dataYearMonth)) {
      const periods: any = {};

      dataYearMonth.forEach(yearObj => {
        const year = Object.keys(yearObj)[0]; // get year
        periods[year] = yearObj[year].map((month: any) => month.status); // insert only month status against each year
      });
      setTaxPeriods(periods); // set all year-month data for required for months buttons

      const years = Object.keys(periods).map(period => Number(period)); // get only years required for years drop-down
      setTaxYears(years); // Set years for drop-down list
    }
  };

  useEffect(() => {
    fetchTaxPeriods();
  }, [dataYearMonth]); // selectedYear, selectedMonthIndex

  const selectedYearsMonths = taxPeriods[selectedYear] as []; // months data for a selected year

  return (
    <div className={classes.root}>
      <Select
        fullWidth
        className={classes.select}
        input={<OutlinedInput labelWidth={0} />}
        value={selectedYear}
        onChange={handleSelectYear}
      >
        {Array.isArray(taxYears) &&
          taxYears.map((year, index) => (
            <MenuItem value={year} key={"YearMonth" + year + index}>
              {year}
            </MenuItem>
          ))}
      </Select>
      {months.map((month, index) => (
        <MonthButton
          key={"MonthButton" + index}
          month={month}
          index={index}
          selected={index === selectedMonthIndex}
          handleSelect={handleSelectMonth}
          filled={
            Array.isArray(selectedYearsMonths) && selectedYearsMonths[index]
          }
        />
      ))}
    </div>
  );
};

export default SelectYearMonth;
