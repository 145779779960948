import { FormattedMessage } from "react-intl";
import React from "react";

export default [
  {
    name: (
      <FormattedMessage id="settingsView.index.fossilReferences.fuelType" />
    ),
    id: "fuel_type",
    type: "select",
    params: "biocomponent_type",
    decimals_format: 8,
  },
  {
    name: <FormattedMessage id="settingsView.index.fossilReferences.value" />,
    id: "value",
    type: "text",
    decimals_format: 8,
  },
];
