/**
 * Get confirmation state
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

interface IgetConfirmState {
  taxYear: number;
}

const getConfirmState = async ({ taxYear }: IgetConfirmState) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/card_sustainability_reports/confirm_state?tax_year=${taxYear}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default getConfirmState;
