import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import StorageView from "../../views/DashboardView/CardView/StorageView";
import { toggleSimulation } from "../../reducers/card/main";
import { IAppVariables } from "../../types/ApiInterfaces";

interface Props {
  isSimulation: boolean;
  app_variables: IAppVariables;
  toggleSimulation: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      isSimulation: state.card.main.isSimulation,
      app_variables: state.settings.app_variables.data,
    }),
    (dispatch: any) => ({
      dispatch,
      toggleSimulation: () => dispatch(toggleSimulation()),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(StorageView);
