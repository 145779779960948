import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import AppVariablesView from "../../views/DashboardView/SettingsView/AppVariables";
import {
  getAppVariables,
  editAppVariables,
} from "../../reducers/settings/app_variables";
import { IAppVariables } from "../../types/ApiInterfaces";

interface Props {
  data: IAppVariables;
  getAppVariables: any;
  editAppVariables: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      data: state.settings.app_variables.data,
    }),
    (dispatch: any) => ({
      dispatch,
      getAppVariables: () => dispatch(getAppVariables()),
      editAppVariables: (data: object) => dispatch(editAppVariables(data)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(AppVariablesView);
