import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../../reducers/reducer";
import MatchingTablesView from "../../../views/DashboardView/CardView/AllocationView/MultiStepView/components/MatchingTables";

interface Props {
  sustainable_batches: any[];
  allocation_status_volume: any;
  allocation_status_ton_equivalence: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      sustainable_batches:
        state.card.allocation.multiStep.steps[
          state.card.allocation.multiStep.activeStep
        ].sustainable_batches,
      allocation_status_volume:
        state.card.allocation.multiStep.steps[
          state.card.allocation.multiStep.activeStep
        ].allocation_status_volume,
      allocation_status_ton_equivalence:
        state.card.allocation.multiStep.steps[
          state.card.allocation.multiStep.activeStep
        ].allocation_status_ton_equivalence,
    }),
    (dispatch: any) => ({
      dispatch,
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(MatchingTablesView);
