import React from "react";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    textContainer: {
      marginRight: theme.spacing(4),
    },
    textPrimary: {
      color: theme.palette.primary.main,
      marginLeft: theme.spacing(1),
    },
  })
);

interface StatusModeTypeProps {
  className?: string;
  mode: string;
  type?: string;
  period: string;
}

const StatusModeType: React.FC<StatusModeTypeProps> = ({
  className = "",
  mode,
  type,
  period,
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.textContainer}>
        <Typography variant="h6">
          Allocation Mode:
          <span className={classes.textPrimary}>{mode}</span>
        </Typography>
      </div>
      <div className={classes.textContainer}>
        <Typography variant="h6">
          Period:
          <span className={classes.textPrimary}>{period}</span>
        </Typography>
      </div>
      {type && (
        <div className={classes.textContainer}>
          <Typography variant="h6">
            Fulfillment Type:
            <span className={classes.textPrimary}>{type}</span>
          </Typography>
        </div>
      )}
    </div>
  );
};

export default StatusModeType;
