/**
 *
 */
import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import Box from "@material-ui/core/Box";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    div: {
      display: "flex",
      alignItems: "centre",
    },
    icon: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(-1),
      margin: theme.spacing(-1),
    },
    lighter: {
      color: "rgba(255, 172, 18, 0.5)",
    },
  })
);

// sort an array of objects by given common object key and sorting order
export function sortArrayObj(
  arrayObj: any[],
  key: string,
  order: "asc" | "desc"
) {
  return [...arrayObj].sort((a, b) => {
    const A = String(a[key]).toUpperCase(); // ignore upper and lowercase
    const B = String(b[key]).toUpperCase(); // ignore upper and lowercase

    if (A < B) {
      return order === "asc" ? -1 : 1;
    }
    if (A > B) {
      return order === "asc" ? 1 : -1;
    }

    // must be equal
    return 0;
  });
}

// hook for maintaining sorting arrow state
export function useToggleSortingOrder() {
  const [sortingOrder, setSortingOrder] = useState<{
    active: boolean;
    order: "asc" | "desc";
  }>({ active: false, order: "asc" });

  // inactive -> asc -> desc ---> inactive
  const toggleSortingOrder = () => {
    if (sortingOrder.active) {
      if (sortingOrder.order === "asc") {
        setSortingOrder({ active: true, order: "desc" });
      } else {
        setSortingOrder({ active: false, order: "asc" });
      }
    } else {
      setSortingOrder({ active: true, order: "asc" });
    }
  };

  const resetSortingOrder = () =>
    setSortingOrder({ active: true, order: "asc" });

  return {
    sortingOrder,
    toggleSortingOrder,
    resetSortingOrder,
  };
}

const SortableArrow: React.FC<SvgIconProps & {
  active: boolean;
  order: any;
}> = ({ active, order }) => {
  const classes = useStyles();
  return (
    <div className={classes.div}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <ArrowDropUp
          color="primary"
          className={clsx(classes.icon, {
            [classes.lighter]: active && order === "desc",
          })}
        ></ArrowDropUp>
        <ArrowDropDown
          color="primary"
          className={clsx(classes.icon, {
            [classes.lighter]: active && order === "asc",
          })}
        ></ArrowDropDown>
      </Box>
    </div>
  );
};

export default SortableArrow;
