import { combineReducers } from "redux";
import MultiStepReducer, { MultiStepState } from "./multiStep";
import soldVolumesReducer, { SoldVolumesState } from "./soldVolumes";
import ConstructionDieselVolumesReducer, {
  ConstructionDieselVolumesState,
} from "./constructionDieselVolumes";

export interface AllocationState {
  multiStep: MultiStepState;
  soldVolumes: SoldVolumesState;
  constructionDieselVolumes: ConstructionDieselVolumesState;
}

export default combineReducers({
  multiStep: MultiStepReducer,
  soldVolumes: soldVolumesReducer,
  constructionDieselVolumes: ConstructionDieselVolumesReducer,
});
