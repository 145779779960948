import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import FilesContainer from "../../../containers/card/FilesContainer";
import CustomersContainer from "../../../containers/card/CustomerContainer";
import StorageContainer from "../../../containers/card/StorageContainer";
import AllocationView from "./AllocationView";
import ReportsContainer from "../../../containers/card/ReportsContainer";

const CardView: React.FC = () => {
  const match = useRouteMatch();
  if (match) {
    return (
      <Switch>
        <Route path={`${match.path}/files`} component={FilesContainer}></Route>
        <Route
          exact
          path={`${match.path}/storage`}
          component={StorageContainer}
        ></Route>
  
        <Route
          path={`${match.path}/customers`}
          component={CustomersContainer}
        ></Route>
        <Route
          path={`${match.path}/allocation`}
          component={AllocationView}
        ></Route>
        <Route
          path={`${match.path}/reports`}
          component={ReportsContainer}
        ></Route>
      </Switch>
    );
  } else {
    return null;
  }
};

export default CardView;
