import DashboardMainView from "../views/DashboardView/MainView";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import { setSelectedYear } from "../reducers/exciseDuty/taxPeriods";
import { getDashboardData } from "../reducers/dashboard";

const mapStateToProps = (state: State) => ({
  taxPeriods: state.exciseDuty.taxPeriods.data,
  selectedYear: state.exciseDuty.taxPeriods.selectedYear,
  data: state.dashboard.data,
});

const mapDispatchToProps = {
  setSelectedYear,
  getDashboardData,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardMainView);
