import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import clsx from "clsx";

import SidePanel from "./SidePanel";
import DashboardContainer from "../../containers/DashboardContainer";
import DocumentDBView from "./DocumentDBView";
import ExciseDutyView from "./ExciseDutyView";
import SustainabilityView from "./SustainabilityView";
import MyProfileContainer from "../../containers/profile/MyProfileContainer";
import SettingsView from "./SettingsView";
import SupportView from "./SupportView";
import CardView from "./CardView";
import ModalDownloadPOS from "./SustainabilityView/StorageView/ModalDownloadPOS";
import ModalDownloadHBLPOS from "./SustainabilityView/StorageView/ModalDownloadHBLPOS";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    viewContainer: {
      flexGrow: 1,
      padding: theme.spacing(4),
      minHeight: "100vh",
      width: "calc(100% - 250px)",
    },
    simulationMode: {
      padding: theme.spacing(3.5),
      border: `4px solid ${theme.palette.primary.main}`,
      "&::before": {
        content: "'Simulation Mode'",
        fontSize: theme.typography.body2.fontSize,
        fontWeight: 500,
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        top: 4,
        right: 4,
        padding: theme.spacing(0.5, 2),
      },
    },
  })
);

interface Props {
  getAppVariables: any;
  isSimulation: boolean;
  isCardSimulation: boolean;
  modal_show_download_pos: boolean;
  setShowModalDownloadPOS: (show: boolean) => void;
  showModalDownloadHBLPOS: boolean;
  setShowModalDownloadHBLPOS: (show: boolean) => void;
}

const DashboardView: React.FC<Props> = ({
  isSimulation,
  isCardSimulation,
  modal_show_download_pos,
  setShowModalDownloadPOS,
  showModalDownloadHBLPOS,
  setShowModalDownloadHBLPOS,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const isMultiStepView =
    location.pathname === "/sustainability/allocation/start";
  const isCardMultiStepView = location.pathname === "/card/allocation/start";

  const isBiofuelStorageView = location.pathname === "/sustainability/storage";
  const isCardStorageView = location.pathname === "/card/storage";
  return (
    <div className={classes.root}>
      <SidePanel
        modal_show_download_pos={modal_show_download_pos}
        setShowModalDownloadPOS={setShowModalDownloadPOS}
        showModalDownloadHBLPOS={showModalDownloadHBLPOS}
        setShowModalDownloadHBLPOS={setShowModalDownloadHBLPOS}
      />
      <div
        className={clsx(
          classes.viewContainer,
          {
            [classes.simulationMode]:
              isSimulation && (isMultiStepView || isBiofuelStorageView),
          },
          {
            [classes.simulationMode]:
              isCardSimulation && (isCardMultiStepView || isCardStorageView),
          }
        )}
      >
        <Switch>
          <Route exact path="/" component={DashboardContainer} />
          <Route path="/document-database" component={DocumentDBView} />
          <Route path="/excise-duty" component={ExciseDutyView} />
          <Route path="/sustainability" component={SustainabilityView} />
          <Route path="/profile" component={MyProfileContainer} />
          <Route path="/settings" component={SettingsView} />
          <Route path="/support" component={SupportView} />
          <Route path="/card" component={CardView} />
          <Redirect to="/" />
        </Switch>
      </div>

      <ModalDownloadPOS
        open={modal_show_download_pos}
        onClose={() => setShowModalDownloadPOS(false)}
      />
    <ModalDownloadHBLPOS
        open={showModalDownloadHBLPOS}
        onClose={() => setShowModalDownloadHBLPOS(false)}
    />
    </div>
  );
};

export default DashboardView;
