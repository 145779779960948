import { withStyles, Theme } from "@material-ui/core/styles";
import MuiStepConnector from "@material-ui/core/StepConnector";

const StepConnector = withStyles((theme: Theme) => ({
  alternativeLabel: {
    zIndex: -1,
    left: `calc(-50% + ${theme.spacing(2)}px)`,
    right: `calc(50% + ${theme.spacing(2)}px)`,
  },
  line: {
    height: 1,
    border: 0,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 1,
  },
}))(MuiStepConnector);

export default StepConnector;
