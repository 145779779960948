import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import ButtonRounded from "../../../../components/ButtonRounded";
import ButtonArrowBack from "../../../../components/ButtonArrowBack";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { FormattedMessage } from "react-intl";
import Box from "@material-ui/core/Box";
import SortableArrow, {
  sortArrayObj,
  useToggleSortingOrder,
} from "../../../../components/SmartTable/SortableArrow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnArrowBack: {
      marginLeft: theme.spacing(-2),
      marginBottom: theme.spacing(3),
    },
    btnAddNew: {
      minWidth: "180px",
    },
    cardContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    inputSearch: {
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 8px",
      },
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        padding: "0",
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "inherit",
        fontWeight: "bold",
        padding: "10px 0px",
      },
    },
    tableSortableHeader: {
      cursor: "pointer",
      display: "inline-block",
    },
    noData: {
      "&.MuiTableCell-root": {
        padding: theme.spacing(1.5, 2),
      },
    },
    iconDelete: {
      color: theme.palette.error.main,
    },

    link: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      paddingLeft: theme.spacing(2),
    },
  })
);

interface CompaniesViewProps {
  companyList: any;
  setCurrentCompany: (company: any) => void;
  openAddModal: () => void;
  openEditModal: (company: any) => void;
  openDeleteModal: (company: any) => void;
}

const CompaniesView: React.FC<CompaniesViewProps> = ({
  companyList,
  setCurrentCompany,
  openAddModal,
  openEditModal,
  openDeleteModal,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const { sortingOrder, toggleSortingOrder } = useToggleSortingOrder();
  const classes = useStyles();

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  // apply search
  const filteredCompanyList = Array.isArray(companyList)
    ? companyList.filter(
        company =>
          String(company.name)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1
        /* ||
          String(company.email)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1 ||
          String(company.org_number)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1 ||
          String(company.customer_name)
            .toLowerCase()
            .indexOf(searchText.toLowerCase()) > -1 */
      )
    : [];

  const sortedCompanyList = sortingOrder.active
    ? sortArrayObj(filteredCompanyList, "name", sortingOrder.order)
    : filteredCompanyList;

  const hasNoData =
    Array.isArray(sortedCompanyList) && sortedCompanyList.length === 0;

  return (
    <div>
      <ButtonArrowBack className={classes.btnArrowBack} />
      <Card>
        <CardContent className={classes.cardContent}>
          {/* Top Search Bar */}
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.titleBar}
          >
            <Grid item>
              <Typography variant="h5">
                {<FormattedMessage id="settingsView.index.users.heading" />}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <TextField
                    placeholder="Search"
                    className={classes.inputSearch}
                    value={searchText}
                    onChange={handleSearchInput}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <ButtonRounded
                    color="primary"
                    variant="contained"
                    className={classes.btnAddNew}
                    onClick={openAddModal}
                  >
                    <FormattedMessage id="settingsView.index.users.addNewCompany" />
                  </ButtonRounded>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Users Table */}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div
                    onClick={toggleSortingOrder}
                    className={classes.tableSortableHeader}
                  >
                    <Box display="flex" alignItems="center">
                      <FormattedMessage id="settingsView.index.users.companyName" />{" "}
                      <SortableArrow
                        active={sortingOrder.active}
                        order={sortingOrder.order}
                      />
                    </Box>
                  </div>
                </TableCell>
                {/* <TableCell>
                  <FormattedMessage id="settingsView.users.modal.companyEmail" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="settingsView.users.modal.orgNumber" />
                </TableCell>
                <TableCell>
                  <FormattedMessage id="customersView.customerTable.customerName" />
                </TableCell> */}
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(sortedCompanyList) &&
                sortedCompanyList.map((company: any) => (
                  <TableRow key={company.id}>
                    <TableCell variant="head">
                      <Link
                        onClick={() => setCurrentCompany(company)}
                        to={`/settings/users/${company.id}`}
                        className={classes.link}
                      >
                        {company.name}
                      </Link>
                    </TableCell>

                    {/* <TableCell>{company.email}</TableCell>
                    <TableCell>{company.org_number}</TableCell>
                    <TableCell>{company.customer_name}</TableCell> */}

                    <TableCell align={"right"}>
                      <IconButton
                        color="primary"
                        onClick={() => openEditModal(company)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton
                        className={classes.iconDelete}
                        onClick={() => openDeleteModal(company)}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {hasNoData && (
                <TableRow>
                  <TableCell colSpan={4} className={classes.noData}>
                    <FormattedMessage id="settingsView.users.noCompany" />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
};

export default CompaniesView;
