import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    common: { black: "#1f2432", white: "#ffffff" },
    primary: {
      light: "#ffd588",
      main: "#ffac12",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ff441a",
      light: "#ffa18c",
      contrastText: "#ffffff",
    },
    action: {
      active: "#ffac12",
      disabled: "#c2d1d9",
    },
    error: { main: "#ff441a", light: "#ffa18c" },
    grey: {
      50: "#f2f5f7",
      300: "#c2d1d9",
      600: "#a1aeb7",
      900: "#424b5a",
      400: "#505D68",
    },
    text: { primary: "#424b5a", secondary: "#a1aeb7" },
    background: { paper: "#ffffff", default: "#f2f5f7" },
  },
  typography: {
    fontFamily: "Ubuntu",
    h1: { fontSize: "66px" },
    h2: { fontSize: "45px", lineHeight: 1.31 },
    h3: { fontSize: "38px", fontWeight: "bold", lineHeight: 1.26 },
    h4: { fontSize: "28px", fontWeight: "bold", lineHeight: 1.36 },
    h5: { fontSize: "21px", fontWeight: "bold", lineHeight: 1.48 },
    h6: { fontSize: "18px", fontWeight: 500, lineHeight: 1.33 },
    body1: { fontSize: "16px", lineHeight: 1.5 },
    body2: { fontSize: "13px", lineHeight: 1.5 },
    button: { fontSize: "10px", fontWeight: 300, letterSpacing: "1px" },
  },
  overrides: {
    MuiInput: {
      root: {
        color: "#303030",
        background: "#ffffff",
        borderRadius: 1,
        padding: "8px 10px",
      },
      input: {
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0px 1000px white inset",
          transition: "background-color 5000s ease-in-out 0s",
        },
        "&::placeholder": {
          textOverflow: "ellipsis !important",
          color: "#c2d1d9",
          opacity: 1,
        },
      },
    },
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          backgroundColor: "#f2f5f7",
        },
      },
      input: {
        "&.Mui-disabled": {
          backgroundColor: "#f2f5f7",
        },
      },
    },
    MuiOutlinedInput: {
      input: {
        backgroundColor: "#ffffff",
        padding: "11px",
        "&:-webkit-autofill": {
          WebkitBoxShadow: "0 0 0px 1000px white inset",
          transition: "background-color 5000s ease-in-out 0s",
        },
        "&::placeholder": {
          textOverflow: "ellipsis !important",
          color: "#c2d1d9",
          opacity: 1,
        },
      },
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#c2d1d9",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#c2d1d9",
        },
        "&$focused $notchedOutline": {
          borderColor: "#c2d1d9",
          borderWidth: 1,
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
        "&$disabled": {
          backgroundColor: "#c2d1d9",
          color: "#424b5a",
        },
      },
    },
    MuiIconButton: {
      root: {
        "&:hover": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: "-10px 10px 20px 0 rgba(30, 30, 30, 0.05)",
      },
    },
    MuiRadio: {
      root: {
        color: "#ffac12",
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        margin: "0px",
        minHeight: "0px",
        "&$expanded": {
          margin: "0px",
          minHeight: "0px",
        },
      },
      content: {
        margin: "0px",
        minHeight: "0px",
        "&$expanded": {
          margin: "0px",
          minHeight: "0px",
        },
      },
    },
    MuiFab: {
      root: {
        boxShadow: "none",
      },
      sizeSmall: {
        width: "24px",
        height: "24px",
        minHeight: "auto",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTableSortLabel: {
      root: { display: "flex", justifyContent: "space-between" },
    },
    MuiList: {
      root: { outline: "none" },
    },
  },
});

export default theme;
