import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ButtonRounded from "../../../../components/ButtonRounded";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import ModalContainer from "../../../../components/ModalContainer";
import KeyboardDatePicker from "../../../../components/inputs/KeyboardDatePicker";
import arrayMutators from "final-form-arrays";
import { Form, Field } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import CreatableSelect from "react-select/creatable";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { FormattedMessage } from "react-intl";
import postBkvUnMapData from "../../../../api/settings/bkvMappings/postBkvUnMapData";
import moment from "moment";
import getAppVariables from "../../../../api/settings/appVariables/get";
import ModalHblPreview from "./ModalHblPreview";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    btnContainer: {
      marginTop: theme.spacing(3),
    },
    marginBottom: {
      "margin-bottom": theme.spacing(2),
    },
    btnLeft: {
      marginRight: theme.spacing(3),
    },
    noteText: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
    commentTitle: {
      fontWeight: 500,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
    errorMsg: {
      marginTop: theme.spacing(2),
    },
    fixedWidth: {
      width: theme.spacing(22),
    },
    creatableSelect: {
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: 0,
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        minHeight: "35px",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__value-container": {
        padding: "0px 8px",
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
  })
);

interface ModalSaveHblTransactionProps {
  open: boolean;
  onClose: () => void;
  data: any;
  generalData: {
    customer_name: any;
    volume: any;
    biofuel_type: any;
    delivery_period: any;
    storage_date: any;
    status: any;
  };
}

const ModalSaveHblTransaction: React.FC<ModalSaveHblTransactionProps> = ({
  open,
  onClose,
  data,
  generalData,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const [customerDataList, setCustomerDataList] = useState<any[]>([]);
  const [storageTerminalsData, setStorageTerminalsData] = useState<any[]>([]);
  const [dischargePortsData, setDischargePortsData] = useState<any[]>([]);
  const [formData, setFormData] = useState<any[]>([]);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const classes = useStyles();

  const hasData: boolean = Array.isArray(data) && data.length > 0;

  const hasErrors = (error: any) => {
    if (error) {
      if (error["general_data"] && Array.isArray(error["general_data"])) {
        return error["general_data"].length > 0 ? true : false;
      }
    }
    return false;
  }

  const handleDataSubmit = async (values: any) => {
    let newFormData: any[] = [];
    newFormData.push({
      customer_name: values['general_data'][0]['customer_name'],
      volume: generalData.volume,
      biofuel_type: generalData.biofuel_type,
      delivery_period: generalData.delivery_period,
      storage_date: generalData.storage_date,
      status: generalData.status,
      comment: values['general_data'][0]['comment'],
      shipment_id: values['general_data'][0]['shipment_id'],
      vessel: values['general_data'][0]['vessel'],
      discharge_port: values['general_data'][0]['discharge_port'],
      b_l_date: moment(values['general_data'][0]['b_l_date']).format("YYYY-MM-DD"),
      counterparty: values['general_data'][0]['customer_name'],
      biotemplates: data,
    });

    setFormData(newFormData);
    setShowSaveModal(true);
  };

  const closeSaveModal = () => {
    setShowSaveModal(false);
  };

  const getCustomerList = () => {
    let customerList: any[] = [];
    customerDataList.forEach(
      (customer: any) =>
        !customerList.includes(customer) && customerList.push(customer)
    );
    return customerList;
  };

  const getStorageTerminals = () => {
    let storageTerminals: any[] = [];
    storageTerminalsData.forEach(
      (storageTerminal: any) =>
        !storageTerminals.includes(storageTerminal) && storageTerminals.push(storageTerminal)
    );
    return storageTerminals;
  };

  const getDischargePorts = () => {
    let dischargePorts: any[] = [];
    dischargePortsData.forEach(
      (dischargePort: any) =>
        !dischargePorts.includes(dischargePort) && dischargePorts.push(dischargePort)
    );
    return dischargePorts;
  };

  const fetchAppVariables = async () => {
    const response = await getAppVariables();
    setCustomerDataList(response.data.res_customer);
    setStorageTerminalsData(response.data.storage_terminals);
    setDischargePortsData(response.data.dischange_ports);
  }

  useEffect(() => {
    formData.push(
      {
        customer_name: generalData.customer_name,
        counterparty: generalData.customer_name,
        shipment_id: "",
        vessel: "",
        b_l_date: new Date(),
        discharge_port: "",
        comment: "",
      }
    )
    fetchAppVariables();
  }, [generalData]);


  const required = (value: any) => {
    if (value && value !== "") {
      setIsError(false);
      return undefined;
    } else {
      setIsError(true);
      return "* Value Required";
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title="Save Transaction"
      maxWidth="xs"
    >
      <div className={classes.modalContent}>
        <Typography variant="body1" paragraph className={classes.noteText}>
          Note: Saving this transaction will reset your allocation (reporting
          and simulation)
        </Typography>
        <Typography variant="body1" paragraph>
          To save the transaction, leave a comment describing what changes were
          made and why.
        </Typography>
        <Form
          onSubmit={handleDataSubmit}
          mutators={{ ...arrayMutators }}
          initialValues={{
            general_data: formData,
          }}
        >
          {({
            handleSubmit,
            submitting,
            pristine,
            errors,
            form: {
              mutators: { push },
            },
          }) => (
            <form onSubmit={handleSubmit}>
              <FieldArray name="general_data" >
                {({ fields }) =>
                  fields.map((name, index) => {
                    return (
                      <div>
                        <Typography variant="body1" className={classes.commentTitle}>
                          Customer Name <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.customer_name`} validate={required}>
                          {(props) => (
                            <CreatableSelect
                              className={classes.creatableSelect}
                              classNamePrefix="react-select"
                              placeholder="Customer"
                              components={{
                                DropdownIndicator: () => <ArrowDropDownIcon />,
                              }}
                              options={getCustomerList().map((customer: any) => ({
                                value: customer,
                                label: customer,
                              }))}
                              value={
                                props.input.value
                                  ? {
                                    value: props.input.value,
                                    label: props.input.value,
                                  }
                                  : null
                              }
                              onChange={(value: any) => {
                                props.input.onChange(value.label);
                              }}
                            />
                          )}
                        </Field>
                        <Typography variant="body1" className={classes.commentTitle}>
                          B/L Date <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.b_l_date`} validate={required}>
                          {({input,meta}) => (
                            <KeyboardDatePicker
                              {...input}
                              inputVariant="outlined"
                              format="YYYY-MM-DD"
                              fullWidth
                              placeholder={moment().format("YYYY-MM-DD")}
                              value={input.value ? input.value : null}
                              onChange={(value: any) => {
                                input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                              }}
                            />
                          )}
                        </Field>
                        <Typography variant="body1" className={classes.commentTitle}>
                          Shipment ID <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.shipment_id`} validate={required}>
                          {props => (
                            <FormattedMessage id="Shipment ID">
                              {fmsg => (
                                <TextField
                                  {...props.input}
                                  variant="outlined"
                                  placeholder={fmsg as string}
                                  fullWidth
                                />
                              )}
                            </FormattedMessage>
                          )}
                        </Field>
                        <Typography variant="body1" className={classes.commentTitle}>
                          Vessel <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.vessel`} validate={required}>
                          {props => (
                            <FormattedMessage id="Vessel">
                              {fmsg => (
                                <TextField
                                  {...props.input}
                                  variant="outlined"
                                  placeholder={fmsg as string}
                                  fullWidth
                                />
                              )}
                            </FormattedMessage>
                          )}
                        </Field>
                        <Typography variant="body1" className={classes.commentTitle}>
                          Discharge Port <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.discharge_port`} validate={required}>
                          {(props) => (
                            <CreatableSelect
                              className={classes.creatableSelect}
                              classNamePrefix="react-select"
                              placeholder="Discharge Port"
                              components={{
                                DropdownIndicator: () => <ArrowDropDownIcon />,
                              }}
                              options={getDischargePorts().map((dischargePort: any) => ({
                                value: dischargePort,
                                label: dischargePort,
                              }))}
                              value={
                                props.input.value
                                  ? {
                                    value: props.input.value,
                                    label: props.input.value,
                                  }
                                  : null
                              }
                              onChange={(value: any) => {
                                props.input.onChange(value.label);
                              }}
                            />
                          )}
                        </Field>
                        <Typography variant="body1" className={classes.commentTitle}>
                          Comment <RequiredAsterisk />
                        </Typography>
                        <Field name={`${name}.comment`} validate={required}>
                          {props => (
                            <FormattedMessage id="Comment">
                              {fmsg => (
                                <TextField
                                  {...props.input}
                                  variant="outlined"
                                  multiline
                                  placeholder={fmsg as string}
                                  fullWidth
                                  rows={4}
                                />
                              )}
                            </FormattedMessage>
                          )}
                        </Field>
                      </div>
                    )
                  }
                  )}
              </FieldArray>
              <Grid container spacing={2} className={classes.btnContainer}>
                <Grid item xs={6}>
                  <ButtonRounded
                    fullWidth
                    variant="contained"
                    onClick={onClose}
                    color="secondary"
                  >
                    {<FormattedMessage id="settingsView.modal.cancel" />}
                  </ButtonRounded>
                </Grid>
                <Grid item xs={6}>
                  <ButtonRounded
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={submitting || pristine || hasErrors(errors)}
                  >
                    {<FormattedMessage id="settingsView.modal.save" />}
                  </ButtonRounded>
                </Grid>
              </Grid>
            </form>
          )}
        </Form>

        {isError && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}>
            Please Filled all required field.
          </Typography>
        )}
        {!hasData && (
          <Typography
            align="center"
            variant="body2"
            color="error"
            className={classes.errorMsg}
          >
            Allocated quantity in the table can not be blank.
          </Typography>
        )}
      </div>

      <ModalHblPreview
        data={formData}
        title={"HBL Export Preview Table"}
        handleSubmit={() => { onClose(); }}
        open={showSaveModal}
        onClose={closeSaveModal}
      />

    </ModalContainer>
  );
};

export default ModalSaveHblTransaction;
