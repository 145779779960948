/**
 * Download excise duty report
 * For: excise duty reports > reports > download report button
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

interface IGetDownloadReport {
  year: number;
  month: number;
}
const getDownloadReport = async ({ year, month }: IGetDownloadReport) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/manual_tax_reports/download_report?tax_year=${year}&month=${month}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.blob();
        return data;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default getDownloadReport;
