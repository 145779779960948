import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { connect } from "react-redux";
import { State } from "../reducers/reducer";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: theme.palette.primary.main,
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
  })
);

interface Props {
  name: string;
  value: any;
  type: string;
  onChange?: (e: any) => void;
  fullWidth?: boolean;
  displayEmpty?: boolean;
  className?: any;
  filterValue?: any;
}

interface StateProps {
  data: any;
  dispatch?: any; // fix warning
  emptyMenuItem?: React.ReactNode;
}

const mapStateToProps = (state: State, ownProps: Props) => ({
  data: state.settings.app_variables.data[ownProps.type],
});

const SmartSelect = ({
  data,
  name,
  value,
  onChange,
  className,
  dispatch,
  emptyMenuItem,
  filterValue,
  ...rest
}: SelectProps & StateProps & Props) => {
  const classes = useStyles();

  //if props filterValue array is not empty, filter strings
  let filteredData = data.values;
  if (filterValue && Array.isArray(filterValue) && filterValue.length > 0) {
    filteredData = data.values.filter(
      (value: any) => !filterValue.includes(value)
    );
  }

  return (
    <Select
      {...rest}
      name={name}
      value={value}
      onChange={onChange}
      input={<OutlinedInput labelWidth={0} />}
      className={clsx(classes.select, className, {
        [classes.greyText]: !value || value === "",
      })}
    >
      <MenuItem value="">
        {emptyMenuItem || (
          <FormattedMessage id="dropdown.placeholder.select" />
        )}
      </MenuItem>

      {filteredData.map((value: any, index: number) => (
        <MenuItem key={value + "select" + index} value={value}>
          {value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default connect(mapStateToProps)(SmartSelect);
