import { getToken } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export default async () => {
  const token = getToken();

  let valid = false;
  let data = [];

  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/reconciliation`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      var biofuelList = await response.json();
      valid = biofuelList;

      data = biofuelList.data;
    } catch (except) {
      valid = false; // network error
    }
  } else {
    return null; // No token
  }

  if (!valid) {
    if (biofuelList && biofuelList.error)
      return {
        valid: false,
        message: biofuelList.error,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data,
  };
};
