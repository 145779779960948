import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const sustainabilityPool = async () => {
  const token = getToken();
  let valid = false;

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/sustainability_reports/sustainability_pool`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      var result = await response.json();
      valid =
        result &&
        result.success &&
        result.sustainable_batches &&
        result.allocation_status_volume &&
        result.allocation_status_ton_equivalence;
    } catch (except) {
      valid = false;
    }
  }

  if (!valid) {
    if (result && result.errors)
      return {
        valid: false,
        message: result.errors,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    sustainable_batches: result.sustainable_batches,
    allocation_status_volume: result.allocation_status_volume,
    allocation_status_ton_equivalence: result.allocation_status_ton_equivalence,
  };
};

export default sustainabilityPool;
