export default [
  {
    name: "Volume (L15)",
    id: "volume",
    aggregated_data: [
      {
        name: "FAME",
        id: "volume_fame",
      },
      {
        name: "HVO/HRO",
        id: "volume_hvo_hro",
      },
      {
        name: "Etanol",
        id: "volume_etanol",
      },
      {
        name: "Nafta",
        id: "volume_nafta",
      },
      {
        name: "Fossil",
        id: "volume_fossil",
      },
    ],
  },
  {
    name: "Energy content (MJ)",
    id: "energy_content_total",
    aggregated_data: [
      {
        name: "FAME",
        id: "energy_content_fame",
      },
      {
        name: "HVO/HRO",
        id: "energy_content_hvo_hro",
      },
      {
        name: "Etanol",
        id: "energy_content_etanol",
      },
      {
        name: "Nafta",
        id: "energy_content_nafta",
      },
      {
        name: "Fossil",
        id: "energy_content_fossil",
      },
    ],
  },
  {
    name: "Emissions WTW (tCO2e)",
    id: "emission_total",
    aggregated_data: [
      {
        name: "FAME",
        id: "emission_fame",
      },
      {
        name: "HVO/HRO",
        id: "emission_hvo_hro",
      },
      {
        name: "Etanol",
        id: "emission_etanol",
      },
      {
        name: "Nafta",
        id: "emission_nafta",
      },
    ],
  },
  {
    name: "Fossil ref. (tCO2e)",
    id: "fossil_ton",
  },
  {
    name: "Emission red. (tCO2e)",
    id: "emission_reduction_total",
    aggregated_data: [
      {
        name: "FAME",
        id: "emission_reduction_fame",
      },
      {
        name: "HVO/HRO",
        id: "emission_reduction_hvo_hro",
      },
      {
        name: "Etanol",
        id: "emission_reduction_etanol",
      },
      {
        name: "Nafta",
        id: "emission_reduction_nafta",
      },
    ],
  },
  {
    name: "Emission red. (%)",
    id: "emission_reduction_percentage",
  },
];
