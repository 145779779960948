import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { colors, typography } from "../config/theme";

const styles = StyleSheet.create({
  container: {
    marginVertical: 3,
    width: "49%",
    flexDirection: "row",
  },
  superscript: {
    ...typography.small,
    textAlign: "left",
    fontSize: 4.5,
    marginTop: -1,
    marginRight: 1,
  },
  text: {
    ...typography.small,
    textAlign: "left",
    fontSize: 5.5,
    display: "flex",
    flexWrap: "nowrap",
  },
});

interface Props {
  superscript: string;
  text: string;

  style?: ReactPDF.Style | ReactPDF.Style[];
}

const FootnoteV2: React.FC<Props> = ({ superscript, text, style = [] }) => {
  const externalStyles = Array.isArray(style) ? style : [style];

  return (
    <View style={styles.container}>
      <Text style={styles.superscript}>{superscript}</Text>
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

export default FootnoteV2;
