export const page = {
  size: { width: 473.68, height: 841.89 },
};

export const colors = {
  green: "#154734",
  lightGreen: "#d0dad6",
  yellow: "#ffac3a",
  lightYellow: "#ffeed7",
  lightGrey: "#ece9e5",
  white: "#ffffff",
  black: "#000000",
};

export const typography = {
  default: {
    fontFamily: "PreemSans",
    fontSize: 9,
    color: colors.black,
  },
  defaultBold: {
    fontFamily: "PreemSans Bold",
    fontSize: 9,
    color: colors.black,
  },
  small: {
    fontFamily: "PreemSans",
    fontSize: 7,
    color: colors.black,
  },
  smallBold: {
    fontFamily: "PreemSans Bold",
    fontSize: 7,
    color: colors.black,
  },
  smallItalic: {
    fontFamily: "PreemSans Italic",
    fontSize: 7,
    color: colors.black,
  },
};
