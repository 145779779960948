import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import DropZoneCard from "../../../../components/DropZoneCard";
import FilesTable, { IFile } from "../../../../components/FilesTable";

import { FormattedMessage } from "react-intl";
import { checkBkvMapData, saveBkvMapData } from "../../../../api/settings/bkvMappings/postBkvUnMapData";
import deleteFile from "../../../../api/sustainability/files/deleteFile";
import uploadFile from "../../../../api/sustainability/files/uploadFile";
import ModalConfirm from "../../../../components/FilesTable/ModalConfirm";
import ModalDeliveryTable from "../../../../components/FilesTable/ModalDeliveryTable";
import ModalInfo from "../../../../components/modal/ModalInfo";
import useModal from "../../../../components/modal/useModal";
import { string } from "yup";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        color: theme.palette.primary.main,
        fontSize: "14px",
      },
    },
    filelistContainer: {
      marginTop: theme.spacing(2),
    },
    select: {
      width: "150px",
      margin: theme.spacing(3, 0),
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        width: "100px",
      },
    },
  })
);

interface Props {
  files: any;
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  getFiles: any;
}

interface IUploadFile {
  file: File;
  year: number;
  type: "biotemplate" | "customer_sale" | "preem_ab_biotemplate";
  m3: boolean;
}

interface IDeliveryData {
  data: any[];
  success: boolean;
}

const FilesView: React.FC<Props> = ({
  files,
  taxPeriods,
  selectedYear,
  setSelectedYear,
  getFiles,
}) => {
  const [filterList, setFilterList] = useState<any[]>([]);
  const [infoModal, setInfoModal] = useState({ title: "", description: "" });
  const [xlsfiles, setXlsfiles] = useState<File>();
  const [type, setType] = useState("");
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [m3, setM3] = useState<boolean>(false);
  const [showModalConfirmM3, setShowModalConfirmM3] = useState<boolean>(false);
  const [showModalDeliveryTable, setShowModalDeliveryTable] = useState<boolean>(false);
  const [deliveryData, setDeliveryData] = useState<IDeliveryData>(
    { data: [], success: false }
  );
  const [errorData, setErrorData] = useState({});
  const [unMappedData, setUnMappedData] = useState({});
  const [message, setMessage] = React.useState("Hello World");

  const { openModalId, toggle } = useModal();
  const classes = useStyles();

  const openModal = (id: string, text: string, message: string) => {
    if (id === "info") {
      setInfoModal({ title: text, description: message });
    }
    toggle(id);
  };

  const closeModal = () => {
    toggle();
  };

  const closeModalConfirm = () => {
    setShowModalConfirm(false);
  };

  const closeModalDeliveryTable = () => {
    setShowModalDeliveryTable(false);
  };

  const submitMappedData = async (values: any[]) => {
    const response = await saveBkvMapData({
      file: xlsfiles!,
      values: values,
      year: selectedYear,
      m3: m3
    });
    closeModalDeliveryTable();
    getFiles();
    // @ts-ignore
    if (response && response.success) {
      openModal("info", `File uploaded successfully`, "");
    } else {
      openModal(
        "info",
        `File uploaded failed`,
        "You need to choose the correct year for file uploading"
      );
    }
  };


  const checkFileData = async (values: any[]) => {
    const response = await checkBkvMapData(values);
    if (response) {
      setDeliveryData({
        data: response.data,
        success: response.success
      });
    }
  };

  const confirmM3 = async (file: any) => {
    setShowModalConfirmM3(false);
    setM3(true);
    const resposeConfirmM3 = await uploadFile({
      file: file,
      year: selectedYear,
      type,
      m3: true,
    } as IUploadFile);
    if (resposeConfirmM3 && resposeConfirmM3.success) {
      getFiles();
      setDeliveryData({
        data: resposeConfirmM3.data,
        success: resposeConfirmM3.success
      });
      setShowModalDeliveryTable(true);
    }
    else {
      let resposeConfirmM3Msg: String = "";
      if (resposeConfirmM3 !== null && resposeConfirmM3.msg !== null) {
        resposeConfirmM3Msg = resposeConfirmM3.msg;
      }
      openModal(
        "info",
        `File uploaded failed`,
        `${resposeConfirmM3Msg}`,
      );
    }
  };
  const confirmLetters = async (file: any) => {
    setShowModalConfirmM3(false);
    setM3(false);
    const resposeConfirmLetters = await uploadFile({
      file: file,
      year: selectedYear,
      type,
      m3: false,
    } as IUploadFile);

    if (resposeConfirmLetters && resposeConfirmLetters.success) {
      getFiles();
      setDeliveryData({
        data: resposeConfirmLetters.data,
        success: resposeConfirmLetters.success
      });
      setDeliveryData(resposeConfirmLetters);
      setShowModalDeliveryTable(true);
    }
    else {
      let resposeConfirmLettersMsg: String = "";
      if (resposeConfirmLetters !== null && resposeConfirmLetters.msg !== null) {
        resposeConfirmLettersMsg = resposeConfirmLetters.msg;
      }

      openModal(
        "info",
        `File uploaded failed`,
        `${resposeConfirmLettersMsg}`,
      );
    }
  };
  const closeModalConfirmM3 = () => {
    setShowModalConfirmM3(false);
  };

  const handleUploadFile = async (
    files: File[],
    type: "biotemplate" | "customer_sale" | "preem_ab_biotemplate"
  ) => {
    setType(type);
    setXlsfiles(files[0]);

    if (type === "preem_ab_biotemplate" || type === "biotemplate") {
      // setShowModalConfirmM3(true);
      await confirmM3(files[0]);
      getFiles();
    }
    else {
      const res = await uploadFile({
        file: files[0],
        year: selectedYear,
        type,
        m3: false,
      });
      if (res && res.success) {
        openModal("info", `File uploaded successfully`, "");
        getFiles();
      } else {
        let warning = new String();
        if (res) {
          warning = res.msg;
        }
        openModal(
          "info",
          `File uploaded failed`,
          warning.toString()
        );
      }

    }

  };

  const handleDeleteFile = async (file: IFile) => {
    const res = await deleteFile(file.id, file.type);

    if (res && res.success) {
      openModal("info", `File deleted successfully`, "");
      getFiles();
    } else {
      openModal("info", `File deleted failed`, "Network related problem");
    }
  };

  const filterFileList = (year: number, files: any[]) => {
    if (files && Array.isArray(files)) {
      const newFilteredList = files.filter(
        (item: any) => parseInt(item.tax_year) === year
      );
      setFilterList(newFilteredList);
    }
  };

  useEffect(() => {
    filterFileList(selectedYear, files);
  }, [selectedYear, files]);

  // @ts-ignore

  return (
    <div className={classes.root}>
      <Typography variant="h2">
        <FormattedMessage id="exciseDutyView.filesView.heading" />
      </Typography>
      <Select
        fullWidth
        className={classes.select}
        input={<OutlinedInput labelWidth={0} />}
        value={selectedYear}
        onChange={e => setSelectedYear(e.target.value)}
      >
        {taxPeriods
          ? taxPeriods.map((year: any, index: number) => (
            <MenuItem
              value={Object.keys(year)[0]}
              key={
                "Sus.View_Files_Year_" + Object.keys(year)[0] + "-" + index
              }
            >
              {Object.keys(year)[0]}
            </MenuItem>
          ))
          : null}
      </Select>
      <Grid container spacing={2}>
        {/*
        <Grid item xs={4}>
          <DropZoneCard
            title={
              <FormattedMessage id="sustainabilityView.filesView.newBiotemplateFile" />
            }
            handleFiles={files => handleUploadFile(files, "biotemplate")}
          />
        </Grid>
        */}
        <Grid item xs={4}>
          <DropZoneCard
            title={
              <FormattedMessage id="sustainabilityView.filesView.uploadCustomerSaleFile" />
            }
            handleFiles={files => handleUploadFile(files, "customer_sale")}
          />
        </Grid>
        <Grid item xs={4}>
          <DropZoneCard
            title={
              <FormattedMessage id="sustainabilityView.filesView.newBiotemplateFile" />
            }
            handleFiles={files => handleUploadFile(files, "preem_ab_biotemplate")}
          />
        </Grid>
      </Grid>
      <div className={classes.filelistContainer}>
        <FilesTable fileList={filterList} handleDeleteFile={handleDeleteFile} />
      </div>
      <ModalInfo
        text={infoModal.title}
        message={infoModal.description}
        open={openModalId === "info"}
        onClose={closeModal}
      />
      <ModalConfirm
        handleConfirm={() => {
          confirmM3(xlsfiles);
        }}
        handleReject={() => {
          confirmLetters(xlsfiles);
        }}
        open={showModalConfirmM3}
        onClose={closeModalConfirmM3}
        title="Is upload in liters or m3?"
        buttonTextYes="M3"
        buttonTextNo="Liters"
      />
      <ModalDeliveryTable
        formSubmit={submitMappedData}
        open={showModalDeliveryTable}
        title="Check Mapping Data"
        data={deliveryData.data}
        year={selectedYear}
        onClose={closeModalDeliveryTable}
        m3={m3}
      />
    </div>
  );
};

export default FilesView;
