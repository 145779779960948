/**
 *
 */
import React, { Fragment } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Tooltip from "../../../../../../components/Tooltip";
import parseData from "../../../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    green: {
      position: "relative",
      width: "20px",
      height: "20px",
      borderRadius: "2px",
      background: "linear-gradient(to bottom, #0080008a 0%, green 100%)",
      display: "block",
    },
    red: {
      position: "relative",
      width: "20px",
      height: "20px",
      borderRadius: "2px",
      background: "linear-gradient(to bottom, #ff00007d 0%, red 100%)",
      display: "block",
    },
    yellow: {
      position: "relative",
      width: "20px",
      height: "20px",
      borderRadius: "2px",
      background: "linear-gradient(to bottom, #ffd588 0%, #ffac12 100%)",
      display: "block",
    },
    div: {
      display: "flex",
      alignItems: "centre",
    },
    title: {
      paddingLeft: "8px",
      fontWeight: "bold",
      paddingRight: "20px",
      [theme.breakpoints.down("md")]: {
        paddingRight: "10px",
      },
    },
  })
);

export interface IIndicator {
  status: boolean;
  text: string;
  detailedNumbers: number[];
}

interface Props {
  sustainability_quantities: any;
  requirement_quantities: any;
  construction_quantities_fame: any;
  construction_quantities_hvo: any;
  activeStep: number;
}

const IndicatorBox: React.FC<Props> = ({
  sustainability_quantities,
  requirement_quantities,
  construction_quantities_fame,
  construction_quantities_hvo,
  activeStep,
}) => {
  const classes = useStyles();

  const getStatus = (remaining: any) => {
    if (remaining > 0) return "Not Complete";
    if (remaining < 0) return "Overallocated";
    return "Complete";
  };

  const indicatorBoxList1 = sustainability_quantities
    ? [
        {
          status: getStatus(
            sustainability_quantities.rem_single_count_biodisel
          ),
          text: "BD ET",
          detailedNumbers: [
            sustainability_quantities.all_single_count_biodisel,
            sustainability_quantities.req_single_count_biodisel,
          ],
        },
        {
          status: getStatus(
            sustainability_quantities.rem_double_count_biodisel
          ),
          text: "BD DT",
          detailedNumbers: [
            sustainability_quantities.all_double_count_biodisel,
            sustainability_quantities.req_double_count_biodisel,
          ],
        },
        {
          status: getStatus(sustainability_quantities.rem_single_count_ethanol),
          text: "BT ET",
          detailedNumbers: [
            sustainability_quantities.all_single_count_ethanol,
            sustainability_quantities.req_single_count_bioetanol,
          ],
        },
        {
          status: getStatus(sustainability_quantities.rem_dobule_count_ethanol),
          text: "BT DT",
          detailedNumbers: [
            sustainability_quantities.all_double_count_ethanol,
            sustainability_quantities.req_double_count_bioetanol,
          ],
        },
      ]
    : [];

  const indicatorBoxList2 = requirement_quantities
    ? [
        {
          status: getStatus(requirement_quantities.gasoline_req.remaining),
          text: "Sub Req. BT (L)",
          detailedNumbers: [
            requirement_quantities.gasoline_req.allocated +
              requirement_quantities.gasoline_req.purchased,
            requirement_quantities.gasoline_req.requirement,
          ],
        },
        {
          status: getStatus(requirement_quantities.adv_biofuels_req.remaining),
          text: "Sub Req. AB (L)",
          detailedNumbers: [
            requirement_quantities.adv_biofuels_req.allocated +
              requirement_quantities.adv_biofuels_req.purchased,
            requirement_quantities.adv_biofuels_req.requirement,
          ],
        },
        {
          status: getStatus(requirement_quantities.general_req.remaining),
          text: "Gen. Req. (LE)",
          detailedNumbers: [
            requirement_quantities.general_req.allocated +
              requirement_quantities.general_req.purchased,
            requirement_quantities.general_req.requirement,
          ],
        },
        {
          status: getStatus(requirement_quantities.above_req.remaining),
          text: "Above Gen. Req. (L)",
          detailedNumbers: [
            requirement_quantities.above_req.allocated +
              requirement_quantities.above_req.purchased,
            requirement_quantities.above_req.requirement,
          ],
        },
      ]
    : [];

  const indicatorBoxList3 =
    construction_quantities_fame && construction_quantities_hvo
      ? [
          {
            status: getStatus(construction_quantities_hvo.remaining),
            text: "Construction HVO",
            detailedNumbers: [
              construction_quantities_hvo.allocated,
              construction_quantities_hvo.requirement,
            ],
          },
          {
            status: getStatus(construction_quantities_fame.remaining),
            text: "Construction FAME",
            detailedNumbers: [
              construction_quantities_fame.allocated,
              construction_quantities_fame.requirement,
            ],
          },
        ]
      : [];

  const getText = (numbers: any[]) => {
    const number1 = parseData(numbers[0]);
    const number2 = parseData(numbers[1]);
    return `${number1} of ${number2}`;
  };

  let indicatorBoxList = indicatorBoxList2;
  if (activeStep === 0) indicatorBoxList = indicatorBoxList1;
  else if (activeStep >= 7) indicatorBoxList = indicatorBoxList3;

  return (
    <div className={classes.div}>
      {Array.isArray(indicatorBoxList) &&
        indicatorBoxList.map((item, idx) => (
          <Fragment key={idx}>
            <Tooltip title={getText(item.detailedNumbers)}>
              <span
                className={clsx({
                  [classes.green]: item.status === "Complete",
                  [classes.red]: item.status === "Not Complete",
                  [classes.yellow]: item.status === "Overallocated",
                })}
              ></span>
            </Tooltip>
            <Typography variant="body2" className={classes.title}>
              {item.text}
            </Typography>
          </Fragment>
        ))}
    </div>
  );
};

export default IndicatorBox;
