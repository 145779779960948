import React from "react";
import { PDFViewer, Font } from "@react-pdf/renderer";
import ProofOfSust from "./ProofOfSust";

const ProofOfSustPreview: React.FC = () => {
  return (
    <PDFViewer
      style={{
        width: "100%",
        height: "calc(100vh - 10px)",
      }}
    >
      <ProofOfSust />
    </PDFViewer>
  );
};

export default ProofOfSustPreview;
