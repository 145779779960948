import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import CustomersView from "../../views/DashboardView/CardView/CustomersView";
import { setShowModalCardDownloadReport } from "../../reducers/modals"; //is  this need a copy?? need further lookup
import {
  setSelectedCustomer,
  getCustomerPDFFiles,
  clearErrorfromLastcall,
} from "../../reducers/card/customer";
import { setSelectedYear } from "../../reducers/exciseDuty/taxPeriods";

interface Props {
  modal_show_download_report: boolean;
  setShowModalDownloadReport: any;
  selected_customer: any;
  setSelectedCustomer: any;
  setSelectedYear: any;
  isFetching: boolean;
  getCustomerPDFFiles: any;
  CustomerPDFdata: any;
  taxPeriods: any;
  selectedYear: any;
  clearErrorfromLastcall: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      modal_show_download_report: state.modals.show_card_download_report,
      selected_customer: state.card.customer.selected_customer,
      isFetching: state.card.customer.report.isFetching,
      CustomerPDFdata: state.card.customer.report,
      taxPeriods: state.exciseDuty.taxPeriods.data,
      selectedYear: state.exciseDuty.taxPeriods.selectedYear,
    }),
    (dispatch: any) => ({
      dispatch,
      setShowModalDownloadReport: (show: boolean) =>
        dispatch(setShowModalCardDownloadReport(show)),
      setSelectedCustomer: (customer: any) =>
        dispatch(setSelectedCustomer(customer)),
      setSelectedYear: (year: number) => dispatch(setSelectedYear(year)),
      getCustomerPDFFiles: (id: number, data: any) =>
        dispatch(getCustomerPDFFiles(id, data)),
      clearErrorfromLastcall: () => dispatch(clearErrorfromLastcall()),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(CustomersView);
