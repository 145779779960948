/**
 * Create a user based on a generated user name
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

interface IcreateMerchantUser {
  merchantID: number;
  userName: string;
  userRole: string;
}

const createMerchantUser = async ({
  merchantID,
  userName,
  userRole,
}: IcreateMerchantUser) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/merchants/${merchantID}/users`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            role: userRole,
            user_name: userName,
          }),
        }
      );

      if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return {
          success: userName === data.users.user_name,
        };
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default createMerchantUser;
