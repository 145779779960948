import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { typography, colors } from "../config/theme";
import translation from "./translation";
import moment from "moment";

const styles = StyleSheet.create({
  root: {},
  title: {
    ...typography.defaultBold,
    color: colors.green,
    marginTop: 4,
    marginBottom: 10,
  },
  para: {
    ...typography.default,
    marginTop: -2,
    marginBottom: 15,
    textAlign: "left",
  },
});

interface DescriptionProps {
  description: any;
  language: "EN" | "NO";
  style?: ReactPDF.Style | ReactPDF.Styles;
}

const Description: React.FC<DescriptionProps> = ({
  language,
  style = [],
  description,
}) => {
  const externalStyles = Array.isArray(style) ? style : [style];
  const t = translation[language];

  return (
    <View style={[styles.root, ...externalStyles]}>
      <Text style={styles.title}>{t.description_title}</Text>
      <Text style={styles.para}>{t.description_paragraph1}</Text>
      <Text style={styles.para}>{t.description_paragraph2}</Text>
      <Text style={styles.para}>{t.description_paragraph3}</Text>
    </View>
  );
};

export default Description;
