import React from "react";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      color: theme.palette.primary.main,
      textAlign: "end",
      width: "80px",
      [theme.breakpoints.down("md")]: {
        width: "80px",
      },
      "& .MuiSelect-select": {
        "&:focus": {
          backgroundColor: "unset",
        },
      },
    },
  })
);

interface YearSelectProps {
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
}

const YearSelect: React.FC<YearSelectProps> = ({
  taxPeriods,
  selectedYear,
  setSelectedYear,
}) => {
  const classes = useStyles();

  return (
    <Select
      fullWidth
      className={classes.select}
      input={<InputBase />}
      IconComponent={ExpandMoreIcon}
      value={selectedYear}
      onChange={e => setSelectedYear(e.target.value)}
    >
      {taxPeriods
        ? taxPeriods.map((year: any, index: number) => (
            <MenuItem
              value={Object.keys(year)[0]}
              key={"TaxYear_" + Object.keys(year)[0] + "-" + index}
            >
              {Object.keys(year)[0]}
            </MenuItem>
          ))
        : null}
    </Select>
  );
};

export default YearSelect;
