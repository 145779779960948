import { getToken, getTokenData } from "./utils";
import { BASE_URL } from "./endpoints";

const changeEmail = async (new_email: string) => {
  const token = getToken();
  const tokenData = getTokenData();

  let valid = false;

  if (token && tokenData) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/merchants/${tokenData.ownership_id}/email/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            email: new_email,
          }),
        }
      );

      var changeMailResult = await response.json();
      valid = changeMailResult && changeMailResult.success;
    } catch (except) {
      valid = false; // network error
    }

    if (!valid) {
      if (changeMailResult && changeMailResult.errors)
        return {
          valid: false,
          message: changeMailResult.errors,
        };
      return {
        valid: false,
        message: "Something unexpected happened",
      };
    }

    return {
      valid: true,
    };
  }
};

export default changeEmail;
