/**
 * Get merchant product list
 * only accessible to admins
 */
import axios, { AxiosRequestConfig } from "axios";
import { getToken, isAdmin } from "../../utils";
import { BASE_URL } from "../../endpoints";


const postBkvUnMapData = async ({
  values,
}: {
  values: any;
}) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const fd = {
        values : values
      };

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(`${BASE_URL}/api/bkv_mappings`, fd, config);

      if (response.status === 200) {
        return { success: response.data.success };
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export const checkBkvMapData = async (values: any) => {
      const token = getToken();
      if (token && isAdmin()) {
    try {
      const fd = new FormData();
      // @ts-ignore
      fd.append("values", JSON.stringify(values));

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(`${BASE_URL}/api/biotemplate_groups/preem_ab_check`, fd, config);

      if (response.status === 200) {
        return { data: response.data, success: response.data.success };
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

interface IUploadFile {
  file: File;
  values: any;
  year: number;
  m3: boolean;
}

export const saveBkvMapData = async ({
                            file,
                            values,
                            year,
                            m3 = false,
                          }: IUploadFile): Promise<{ success: boolean, data: any[] } | null> => {
  const token = getToken();
  if (token) {
    const fd = new FormData();
    // const data = values.bio_templates;
    fd.append("xlxs_file", file);
    // @ts-ignore
    fd.append("values", JSON.stringify(values.bio_templates));
    fd.append("tax_year", String(year));
    fd.append("m3", String(m3));

    // const fd = {
    //   values : values.bio_templates,
    //   m3: String(m3),
    //   tax_year: year,
    //   xlxs_file: file
    // };

    const config: AxiosRequestConfig = {
      headers: {
        Authorization: token,
        "Content-Type": "multipart/form-data",
      },
    };
    try {
      const response = await axios.post(`${BASE_URL}/api/biotemplate_groups/preem_ab_save`, fd, config);
      if (response.status === 200) {
        return { data: response.data, success: response.data.success };
      } else {
        return null;
      }
    } catch {
      return { success: false, data: [] };
    }
  } else {
    return { success: false, data: [] };
  }
};

export default postBkvUnMapData;
