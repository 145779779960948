/**
 * Edit a single user
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

interface IeditMerchantUser {
  merchantID: number;
  userID: number;
  userRole: string;
}

const editMerchantUser = async ({
  merchantID,
  userID,
  userRole,
}: IeditMerchantUser) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/v1/merchants/${merchantID}/users/${userID}
      `,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            role: userRole,
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        return {
          success: data.success,
        };
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default editMerchantUser;
