import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import ButtonArrowBack from "../../../../components/ButtonArrowBack";
import ButtonRounded from "../../../../components/ButtonRounded";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Fab from "@material-ui/core/Fab";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import SearchIcon from "@material-ui/icons/Search";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import avatarIcon from "../../../../assets/icons/avatar.svg";
import getMerchantUsers from "../../../../api/settings/users/merchants/getMerchantUsers";

import ModalAddUser from "./ModalAddUser";
import ModalEditUser from "./ModalEditUser";
import ModalDeleteUser from "./ModalDeleteUser";
import ModalProductMapping from "./ModalProductMapping";
import { FormattedMessage } from "react-intl";

import Box from "@material-ui/core/Box";
import SortableArrow, {
  sortArrayObj,
  useToggleSortingOrder,
} from "../../../../components/SmartTable/SortableArrow";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topBtnContainer: {
      marginBottom: theme.spacing(4),
    },
    btnArrowBack: {
      marginLeft: theme.spacing(-2),
    },
    titleBar: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    inputSearch: {
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 8px",
      },
    },
    topBtn: {
      minWidth: "200px",
      marginLeft: theme.spacing(2),
    },
    cardContent: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    table: {
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
        backgroundColor: theme.palette.grey[50],
      },
      "& .MuiTableCell-root": {
        border: "none",
        padding: "0",
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontSize: "inherit",
        fontWeight: "bold",
        padding: "10px 0px",
      },
    },
    tableSortableHeader: {
      cursor: "pointer",
      display: "inline-block",
    },
    iconDelete: {
      color: theme.palette.error.main,
    },
    iconTopEdit: {},
    iconTopDelete: {
      marginLeft: theme.spacing(2),
    },
    avatar: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
      width: "30px",
      height: "30px",
    },
    noData: {
      "&.MuiTableCell-root": {
        padding: theme.spacing(1.5, 2),
      },
    },
  })
);

interface UsersViewProps {
  currentCompany: {
    id: number;
    name: string;
    org_number: number;
    email: string;
  };
  openModalEditCompany: () => void;
  openModalDeleteCompany: () => void;
}

const UsersView: React.FC<UsersViewProps> = ({
  currentCompany,
  openModalEditCompany,
  openModalDeleteCompany,
}) => {
  const [userList, setUserList] = useState<any>(null);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [showModalAddUser, setShowModalAddUser] = useState<boolean>(false);
  const [showModalEditUser, setShowModalEditUser] = useState<boolean>(false);
  const [showModalDeleteUser, setShowModalDeleteUser] = useState<boolean>(
    false
  );
  const [showModalProductMapping, setShowProductMapping] = useState<boolean>(
    false
  );
  const {
    sortingOrder,
    toggleSortingOrder,
    resetSortingOrder,
  } = useToggleSortingOrder();
  const [activeColumnName, setActiveColumnName] = useState<string>("");

  const toggleActiveColumnName = (name: string) => {
    setActiveColumnName(prevValue => {
      if (prevValue !== name) {
        resetSortingOrder();
      } else {
        toggleSortingOrder();
      }
      return name;
    });
  };

  const history = useHistory();
  const classes = useStyles();

  const openModalAddUser = () => {
    setShowModalAddUser(true);
  };
  const closeModalAddUser = () => {
    fetchData();
    setShowModalAddUser(false);
  };
  const openModalEditUser = (user: any) => {
    setCurrentUser(user);
    setShowModalEditUser(true);
  };
  const closeModalEditUser = () => {
    fetchData();
    setCurrentUser(null);
    setShowModalEditUser(false);
  };
  const openModalDeleteUser = (user: any) => {
    setCurrentUser(user);
    setShowModalDeleteUser(true);
  };
  const closeModalDeleteUser = () => {
    fetchData();
    setCurrentUser(null);
    setShowModalDeleteUser(false);
  };

  const openModalProductMapping = () => {
    setShowProductMapping(true);
  };

  const closeModalProductMapping = () => {
    setShowProductMapping(false);
  };

  const fetchData = async () => {
    const data = await getMerchantUsers(currentCompany.id);
    if (data) {
      setUserList(data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const sortedUserList =
    sortingOrder.active && Array.isArray(userList)
      ? sortArrayObj(userList, activeColumnName, sortingOrder.order)
      : userList;

  const hasNoData =
    Array.isArray(sortedUserList) && sortedUserList.length === 0;

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.topBtnContainer}
      >
        <Grid item>
          <ButtonArrowBack className={classes.btnArrowBack} />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <ButtonRounded
                color="primary"
                variant="contained"
                className={classes.topBtn}
                onClick={openModalProductMapping}
              >
                <FormattedMessage id="settingsView.users.button.productMapping" />
              </ButtonRounded>
            </Grid>
            <Grid item>
              <ButtonRounded
                color="primary"
                variant="contained"
                onClick={openModalAddUser}
                className={classes.topBtn}
              >
                <FormattedMessage id="settingsView.users.button.addUsers" />
              </ButtonRounded>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Card>
        <CardContent className={classes.cardContent}>
          {/* Top Search Bar */}
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className={classes.titleBar}
          >
            <Grid item>
              {/* <Typography variant="h5">
                {<FormattedMessage id="settingsView.index.users.heading" />}
              </Typography> */}
              <Grid container alignItems="center" spacing={4}>
                <Grid item>
                  <Typography variant="h5">{currentCompany.name}</Typography>
                  <Typography variant="subtitle1">
                  Customer nr. {currentCompany.org_number}
                  </Typography>
                </Grid>
                <Grid item>
                  <Fab
                    color="primary"
                    className={classes.iconTopEdit}
                    onClick={openModalEditCompany}
                  >
                    <EditOutlinedIcon />
                  </Fab>
                  <Fab
                    color="secondary"
                    className={classes.iconTopDelete}
                    onClick={openModalDeleteCompany}
                  >
                    <DeleteOutlinedIcon />
                  </Fab>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {/* <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <TextField
                    placeholder="Search"
                    className={classes.inputSearch}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <ButtonRounded
                    color="primary"
                    variant="contained"
                    onClick={openModalAddUser}
                  >
                    {<FormattedMessage id="settingsView.index.users.addNew" />}
                  </ButtonRounded>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>

          {/* Users Table */}
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div
                    onClick={() => toggleActiveColumnName("user_name")}
                    className={classes.tableSortableHeader}
                  >
                    <Box display="flex" alignItems="center">
                      <FormattedMessage id="settingsView.index.users.userName" />
                      <SortableArrow
                        active={
                          activeColumnName === "user_name" &&
                          sortingOrder.active
                        }
                        order={sortingOrder.order}
                      />
                    </Box>
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    onClick={() => toggleActiveColumnName("email")}
                    className={classes.tableSortableHeader}
                  >
                    <Box display="flex" alignItems="center">
                      <FormattedMessage id="settingsView.users.modal.companyEmail" />
                      <SortableArrow
                        active={
                          activeColumnName === "email" && sortingOrder.active
                        }
                        order={sortingOrder.order}
                      />
                    </Box>
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    onClick={() => toggleActiveColumnName("role")}
                    className={classes.tableSortableHeader}
                  >
                    <Box display="flex" alignItems="center">
                      <FormattedMessage id="settingsView.index.users.role" />
                      <SortableArrow
                        active={
                          activeColumnName === "role" && sortingOrder.active
                        }
                        order={sortingOrder.order}
                      />
                    </Box>
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    onClick={() => toggleActiveColumnName("status")}
                    className={classes.tableSortableHeader}
                  >
                    <Box display="flex" alignItems="center">
                      <FormattedMessage id="settingsView.index.users.status" />
                      <SortableArrow
                        active={
                          activeColumnName === "status" && sortingOrder.active
                        }
                        order={sortingOrder.order}
                      />
                    </Box>
                  </div>
                </TableCell>
                <TableCell> </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(sortedUserList) &&
                sortedUserList.map((user: any) => (
                  <TableRow key={user.id}>
                    <TableCell variant="head">
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Avatar className={classes.avatar} src={avatarIcon} />
                        </Grid>
                        <Grid item>{user.user_name}</Grid>
                      </Grid>
                    </TableCell>
                    <TableCell>{currentCompany.email}</TableCell>
                    <TableCell>
                      {String(user.role).toLowerCase() === "merchant"
                        ? "Reseller"
                        : user.role}
                    </TableCell>
                    <TableCell>{user.status}</TableCell>
                    <TableCell align={"right"}>
                      <IconButton
                        color="primary"
                        onClick={() => openModalEditUser(user)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                      <IconButton
                        className={classes.iconDelete}
                        onClick={() => openModalDeleteUser(user)}
                      >
                        <DeleteOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              {!Array.isArray(userList) || hasNoData ? (
                <TableRow>
                  <TableCell colSpan={4} className={classes.noData}>
                    <FormattedMessage id="settingsView.users.noUser" />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {currentUser && (
        <>
          <ModalDeleteUser
            open={showModalDeleteUser}
            onClose={closeModalDeleteUser}
            user={{ id: currentUser.id, name: currentUser.user_name }}
          />
          <ModalEditUser
            open={showModalEditUser}
            onClose={closeModalEditUser}
            user={{
              merchantID: currentCompany.id,
              id: currentUser.id,
              name: currentUser.user_name,
              role: currentUser.role,
              email: currentCompany.email,
            }}
          />
        </>
      )}
      <ModalAddUser
        open={showModalAddUser}
        onClose={closeModalAddUser}
        company={{
          id: currentCompany.id,
          email: currentCompany.email,
        }}
      />
      <ModalProductMapping
        merchantID={currentCompany.id}
        open={showModalProductMapping}
        onClose={closeModalProductMapping}
      />
    </div>
  );
};

export default UsersView;
