import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import StorageView from "../../views/DashboardView/SustainabilityView/StorageView";
import { toggleSimulation } from "../../reducers/sustainability/main";
import { setShowModalDownloadPOS, setShowModalDownloadHBLPOS } from "../../reducers/modals";
import { IAppVariables } from "../../types/ApiInterfaces";

interface Props {
  isSimulation: boolean;
  app_variables: IAppVariables;
  toggleSimulation: any;
  modal_show_download_pos: boolean;
  setShowModalDownloadPOS: (show: boolean) => void;
  showModalDownloadHBLPOS: boolean;
  setShowModalDownloadHBLPOS: (show: boolean) => void;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      isSimulation: state.sustainability.main.isSimulation,
      app_variables: state.settings.app_variables.data,
      modal_show_download_pos: state.modals.show_download_pos,
      showModalDownloadHBLPOS: state.modals.show_download_hbl_pos,
    }),
    (dispatch: any) => ({
      dispatch,
      toggleSimulation: () => dispatch(toggleSimulation()),
      setShowModalDownloadPOS: (show: boolean) =>
        dispatch(setShowModalDownloadPOS(show)),
      setShowModalDownloadHBLPOS: (show: boolean) =>
        dispatch(setShowModalDownloadHBLPOS(show)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(StorageView);
