import React, { useState, useEffect } from "react";
import * as moment from "moment";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { FormattedMessage } from "react-intl";
import SelectYearMonth from "../../../../components/SelectYearMonth";
import { IAppVariables } from "../../../../types/ApiInterfaces";

import Filters, {
  IFilterParameters,
} from "../../../../components/FilterPanel/Filters";
import filterSetup from "./transaction_filter_properties";
import SmartTable from "../../../../components/SmartTable/index";
import transactionProperties from "./transaction_properties";

import getTransactions from "../../../../api/exciseduty/transactions/get";
import editTransaction from "../../../../api/exciseduty/transactions/edit";
import deleteTransaction from "../../../../api/exciseduty/transactions/delete";

import useModal from "../../../../components/modal/useModal";
import ModalAddEdit from "../../../../components/modal/ModalAddEdit";
import ModalDelete from "../../../../components/modal/ModalDelete";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(3),
      margin: theme.spacing(3, 0),
    },
  })
);

interface Props {
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  selectedMonth: number;
  setSelectedMonth: any;
}

const isInInterval = (val: any, interval: any) => {
  if (Array.isArray(interval)) {
    const absVal = Math.abs(parseFloat(val));
    return !(absVal < interval[0]) && !(absVal > interval[1]);
  }
  return true;
};

const isAfter = (str: string, date2: any) => {
  var date1 = moment.default(str, "YYYY-MM-DD");
  return moment.default(date1).diff(date2, "days") >= 0;
};

const isBefore = (str: string, date2: any) => {
  var date1 = moment.default(str, "YYYY-MM-DD");
  return moment.default(date1).diff(date2, "days") <= 0;
};

const TransactionsView: React.FC<Props> = ({
  taxPeriods,
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
}) => {
  const classes = useStyles();
  const [list, setList] = useState<IFilterParameters[]>([]);
  const [dateFilteredList, setDateFilteredList] = useState<IFilterParameters[]>(
    []
  );
  const [filteredList, setFilteredList] = useState<IFilterParameters[]>([]);
  const [filter, setFilter] = useState<IFilterParameters>({});

  const { openModalId, toggle } = useModal();
  const [selectedEntry, setSelectedEntry] = useState<any>(null);

  const openModal = (id: string, selectedEntry?: any) => {
    if (selectedEntry) setSelectedEntry(selectedEntry);
    toggle(id);
  };

  const closeModal = () => {
    getTransactionList();
    toggle();
  };

  const filterList = (
    key: string,
    value: string | moment.Moment | number | number[]
  ) => {
    var new_filter = { ...filter, [key]: value };
    if (value === null || value === "") delete new_filter[key];
    setFilter(new_filter);

    const filterKeys = Object.keys(new_filter);
    const filteredList = dateFilteredList.filter((item: any) => {
      //validate all filter criteria
      return filterKeys.every(id => {
        if (id === "quantity" || id === "sulfer_ppm") {
          return isInInterval(item[id], new_filter[id]);
        } else if (id === "period_from") {
          return isAfter(item["period"], new_filter[id]);
        } else if (id === "period_to")
          return isBefore(item["period"], new_filter[id]);
        else if (item[id] && !item[id].toString().includes(new_filter[id])) {
          return false;
        }
        return true;
      });
    });

    setFilteredList(filteredList);
  };

  const clearFilter = () => {
    setFilteredList(dateFilteredList);
    setFilter({});
  };

  const getTransactionList = async () => {
    const res = await getTransactions();

    if (res && res.valid) {
      setList(res.data);
      getDateFilteredList(selectedYear, selectedMonth, res.data);
    }
  };

  const getDateFilteredList = (year: number, month: number, list: any) => {
    const filteredResult = list.filter(
      (result: any) => result.tax_year === year && result.month === month + 1
    );
    setDateFilteredList(filteredResult);
    setFilteredList(filteredResult);
  };

  useEffect(() => {
    getTransactionList();
  }, []);

  useEffect(() => {
    getDateFilteredList(selectedYear, selectedMonth, list);
  }, [selectedMonth, selectedYear, list]);

  return (
    <div>
      <Typography variant="h2">
        <FormattedMessage id="exciseDutyView.transactionsView.heading" />
      </Typography>
      <SelectYearMonth
        dataYearMonth={taxPeriods}
        selectedYearMonth={{
          selectedMonthIndex: selectedMonth,
          SetSelectedMonthIndex: setSelectedMonth,
          selectedYear: selectedYear,
          SetSelectedYear: setSelectedYear,
        }}
      />
      <Filters
        setup={filterSetup}
        filterValues={{ ...filter }}
        clearFilter={clearFilter}
        onChange={filterList}
      />
      <Paper className={classes.paper}>
        <SmartTable
          headCells={transactionProperties}
          smartProps={{
            initFilteredHead: {},
            selectEntry: setSelectedEntry,
            deleteEntry: () => openModal("delete", selectedEntry),
            editEntry: () => openModal("edit", selectedEntry),
          }}
          rows={dateFilteredList.length === 0 ? null : filteredList}
        />
      </Paper>

      {selectedEntry && (
        <>
          <ModalDelete
            name={"Excise Duty Transaction"}
            id={selectedEntry.id}
            Delete={deleteTransaction}
            open={openModalId === "delete"}
            onClose={closeModal}
          />
          <ModalAddEdit
            name={"Excise Duty Transaction"}
            open={openModalId === "edit"}
            onSave={editTransaction}
            editParams={selectedEntry}
            params={transactionProperties}
            onClose={closeModal}
          />
        </>
      )}
    </div>
  );
};

export default TransactionsView;
