/**
 *  A modal template component for building consistent modals / dialogs
 */

import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
    content: {
      minWidth: "430px",
      padding: theme.spacing(6.25),
      // paddingTop: theme.spacing(8),
      position: "relative",
    },
    iconClose: {
      position: "absolute",
      top: theme.spacing(1.25),
      left: theme.spacing(1.25),
      color: theme.palette.grey[900],
    },
    message: {
      textAlign: "center",
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
  })
);

interface ModalContainerProps {
  open: boolean;
  onClose: () => void;
  title: string | React.ReactElement;
  subtitle?: string | React.ReactElement;
  children: React.ReactNode;
  details?: string;
  maxWidth?: DialogProps["maxWidth"];
  fullWidth?: boolean;
}

const ModalContainer: React.FC<ModalContainerProps> = ({
  open,
  onClose,
  title,
  subtitle,
  children,
  details,
  maxWidth,
  fullWidth,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <DialogContent className={classes.content}>
        <IconButton className={classes.iconClose} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          {title}
          {subtitle}
        </Typography>
        <Typography variant="body1" className={classes.message}>
          {details}
        </Typography>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ModalContainer;
