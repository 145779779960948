import { ErrorPayload } from "../../../common/payloads/ErrorActionPayload";
import { Action, handleActions } from "redux-actions";
import fetchTransactions from "../../../api/exciseduty/transactions/get";

// State Interface
export interface TransactionsState {
  isFetching: boolean;
  data: any[];
  error?: any;
}

// Initial state
const initialState = {
  isFetching: false,
  data: [],
  error: undefined,
} as TransactionsState;

// Actions
const TRANSACTIONS_REQUEST = "exciseDuty/transactions/REQUEST";
const TRANSACTIONS_ERROR = "exciseDuty/transactions/ERROR";
const TRANSACTIONS_SUCCESS = "exciseDuty/transactions/SUCCESS";

// Action creators
function requestTransactions() {
  return {
    type: TRANSACTIONS_REQUEST,
  };
}

function errorTransactions(error: any) {
  return {
    type: TRANSACTIONS_ERROR,
    payload: { error },
  };
}

function receiveTransactions(data: any) {
  return {
    type: TRANSACTIONS_SUCCESS,
    payload: data,
  };
}

export function getTransactions() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    if (state.exciseDuty.transactions.data) {
      dispatch(requestTransactions());
      try {
        const data = await fetchTransactions();
        dispatch(receiveTransactions(data));
      } catch (error) {
        dispatch(errorTransactions(error));
      }
    }
    return Promise.resolve;
  };
}

// Reducer Definition
const TransactionsReducer = handleActions<TransactionsState>(
  {
    [TRANSACTIONS_REQUEST]: handleTransactionsRequest,
    [TRANSACTIONS_ERROR]: handleTransactionsError,
    [TRANSACTIONS_SUCCESS]: handleTransactionsSuccess,
  } as any,
  initialState
);

// Reducer Description
function handleTransactionsRequest(state: TransactionsState) {
  return {
    ...state,
    isFetching: true,
    error: undefined as any,
  };
}

function handleTransactionsError(
  state: TransactionsState,
  action: Action<ErrorPayload>
) {
  return {
    ...state,
    isFetching: false,
    error: action.payload.error,
  };
}

function handleTransactionsSuccess(
  state: TransactionsState,
  action: Action<any>
) {
  return {
    ...state,
    isFetching: false,
    data: action.payload,
    error: undefined as any,
  };
}

export default TransactionsReducer;
