import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { colors, typography } from "../config/theme";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginVertical: 3,
  },

  label: {
    ...typography.default,
    textAlign: "left",
    paddingHorizontal: 0.8,
    width: 72,
    display: "flex",
    flexShrink: 1,
    alignSelf: "center",
    fontFamily: "PreemSans Bold",
    fontSize: 8,
  },
  input: {
    ...typography.default,
    backgroundColor: colors.white,
    marginRight: 15,
    width: 130,
    textAlign: "left",
    paddingVertical: 0.9,
    fontSize: 8,
    display: "flex",
    flexWrap: "nowrap",
    //marginVertical: 2,
    padding: 2,
  },
});

interface Props {
  label: string;
  input: string;
  input2: string;
  style?: ReactPDF.Style | ReactPDF.Style[]; // | ReactPDF.Styles;
}

const Inputfield2V2: React.FC<Props> = ({
  label,
  input,
  input2,
  style = [],
}) => {
  const externalStyles = Array.isArray(style) ? style : [style];

  return (
    <View style={styles.container}>
      <Text style={styles.label}>{label}</Text>
      <View>
        <Text style={styles.input}>{input}</Text>
        <Text style={[styles.input, { marginTop: 6, marginBottom: 2 }]}>
          {input2}
        </Text>
      </View>
    </View>
  );
};

export default Inputfield2V2;
