import React from "react";
import { FormattedMessage } from "react-intl";

export default [
  {
    name: <FormattedMessage id="settingsView.index.salesProduct.code" />,
    id: "code",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.salesProduct.name" />,
    id: "name",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.salesProduct.season" />,
    id: "season",
    type: "text",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.salesProduct.productType" />,
    id: "product_type",
    type: "text",
    optionnal: "true",
  },
  {
    name: <FormattedMessage id="settingsView.index.salesProduct.percentage" />,
    id: "percentage",
    type: "text",
    optionnal: "true",
    decimals_format: 2,
  },
  {
    name: <FormattedMessage id="settingsView.index.salesProduct.usageArea" />,
    id: "usage_area",
    type: "text",
  },
];
