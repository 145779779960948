import { BASE_URL } from "./endpoints";

const authPromptResetPassword = async username => {
  let data = {
    user_name: username,
  };

  let valid = false;

  try {
    var response = await fetch(
      `${BASE_URL}/api/auth/prompt_reset_password`,
      {
        method: "POST",
        cache: "no-cache",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    var loginResult = await response.json();
    valid = loginResult.success;
  } catch (except) {
    valid = false;
  }

  if (!valid) {
    if (
      loginResult &&
      loginResult.error &&
      loginResult.error.user_authentication
    )
      return {
        valid: false,
        message: loginResult.error.user_authentication,
      };

    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    auth_token: loginResult.auth_token,
  };
};

export default authPromptResetPassword;
