import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import promiseMiddleware from "redux-promise";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducer";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any;
  }
}

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
export const loggerMiddleware = createLogger();

export const middleware =
  process.env.NODE_ENV === "development"
    ? applyMiddleware(
        sagaMiddleware,
        promiseMiddleware,
        thunkMiddleware,
        loggerMiddleware
      )
    : applyMiddleware(sagaMiddleware, promiseMiddleware, thunkMiddleware);

export const store = createStore(
  persistedReducer,
  compose(
    middleware,
    process.env.NODE_ENV === "development" &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f: any) => f
  )
);

export const persistor = persistStore(store);
