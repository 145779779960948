import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import FilesView from "../../../containers/sustainability/FilesContainer";
import CustomersContainer from "../../../containers/sustainability/CustomerContainer";
import StorageContainer from "../../../containers/sustainability/StorageContainer";
import OutgoingTransContainer from "../../../containers/sustainability/storage/OutgoingTransContainer";
import AllocationView from "./AllocationView";
import ReportsContainer from "../../../containers/sustainability/ReportsContainer";
import OutgoingTransHBLContainer from "../../../containers/sustainability/storage/OutgoingTransHBLContainer";
import BiofuelVolumeTransferContainer from "../../../containers/sustainability/storage/BiofuelVolumeTransferContainer";
import BiofuelReconciliationContainer from "../../../containers/sustainability/storage/BiofuelReconciliationContainer";
import BiofuelReconciliationVolumeContainer from "../../../containers/sustainability/storage/BiofuelReconciliationVolumeContainer";

const SustainabilityView: React.FC = () => {
  const match = useRouteMatch();

  if (match) {
    return (
      <Switch>
        <Route path={`${match.path}/files`} component={FilesView}></Route>
        <Route
          exact
          path={`${match.path}/storage`}
          component={StorageContainer}
        ></Route>
        <Route
          path={`${match.path}/storage/outgoing-transaction-hbl`}
          component={OutgoingTransHBLContainer}
        ></Route>
        <Route
          path={`${match.path}/storage/outgoing-transaction`}
          component={OutgoingTransContainer}
        ></Route>

        <Route
          path={`${match.path}/storage/biofuel-volume-transaction`}
          component={BiofuelVolumeTransferContainer}
        ></Route>
          <Route
              path={`${match.path}/storage/biofuel-reconciliation`}
              component={BiofuelReconciliationContainer}
          ></Route>
          <Route
              path={`${match.path}/storage/reconcile-volume/:terminal/:biofuel/:avail_volume/:reconcile_date`}
              component={BiofuelReconciliationVolumeContainer}
          ></Route>
        <Route
          path={`${match.path}/customers`}
          component={CustomersContainer}
        ></Route>
        <Route
          path={`${match.path}/allocation`}
          component={AllocationView}
        ></Route>
        <Route
          path={`${match.path}/reports`}
          component={ReportsContainer}
        ></Route>
      </Switch>
    );
  } else {
    return null;
  }
};

export default SustainabilityView;
