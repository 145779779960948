import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async (id: number) => {
  const token = getToken();
  let valid = false;

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/register_transactions/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
        }
      );

      var deleteResult = await response.json();
      valid = deleteResult && deleteResult.success;
    } catch (except) {
      valid = false; // network error
    }

    if (!valid) {
      if (deleteResult && deleteResult.error)
        return {
          success: false,
          message: deleteResult.error,
        };
      return {
        success: false,
        message: "Something unexpected happened",
      };
    }

    return {
      success: true,
    };
  }
};
