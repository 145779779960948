export default {
  en: {
    required: "This field required",
    repeat_password: "The password does not match",
    repeat_email: "The email does not match",
    min: (min: number) => "Should be at least " + min + " characters",
    max: (max: number) => "Should be less than " + max + " characters",
  },
  no: {
    required: "Må spesifiseres",
    repeat_password: "Passordene samsvarer ikke",
    repeat_email: "E-postadressene samsvarer ikke",
    min: (min: number) => "Minst " + min + " tegn",
    max: (max: number) => "Maks " + max + " tegn",
  },
};
