import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import { useRouteMatch } from "react-router-dom";
import avatarIcon from "../../../../assets/icons/avatar.svg";
import ButtonRounded from "../../../../components/ButtonRounded";
import ModalContainer from "../../../../components/ModalContainer";
import deleteMerchant from "../../../../api/settings/users/merchants/deleteMerchant";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContent: {},
    userContainer: {
      padding: theme.spacing(2),
    },
    btnContainer: {
      padding: theme.spacing(2, 0),
    },
    btnCancel: {},
  })
);

interface ModalDeleteProps {
  open: boolean;
  onClose: () => void;
  setCurrentCompany: (company: any) => void;
  company: {
    id: number;
    name: string;
  };
}

const ModalDeleteCompany: React.FC<ModalDeleteProps> = ({
  open,
  onClose,
  company,
  setCurrentCompany,
}) => {
  const [isError, setIsError] = useState<boolean>(false);
  const classes = useStyles();
  const match = useRouteMatch("/settings/users/:id");

  const handleDelete = async () => {
    const response = await deleteMerchant(company.id);
    if (response && response.success) {
      onClose();
      setIsError(false);
      if (match) {
        setCurrentCompany(null);
      }
    } else {
      setIsError(true);
    }
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      title={
        <FormattedMessage id="settingsView.users.modalDelete.warningCompany" />
      }
    >
      <div className={classes.modalContent}>
        <Grid
          container
          alignItems="center"
          direction="column"
          spacing={2}
          className={classes.userContainer}
        >
          <Grid item>
            <Avatar src={avatarIcon} />
          </Grid>
          <Grid item>
            <Typography variant="body1">{company.name}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.btnContainer}>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="outlined"
              onClick={onClose}
              color="secondary"
            >
              {<FormattedMessage id="settingsView.modal.cancel" />}
            </ButtonRounded>
          </Grid>
          <Grid item xs={6}>
            <ButtonRounded
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={isError}
            >
              {<FormattedMessage id="settingsView.modal.delete" />}
            </ButtonRounded>
          </Grid>
        </Grid>
        {isError && (
          <Typography align="center" variant="body2" color="error">
            {
              <FormattedMessage id="settingsView.users.modalDelete.failedCompany" />
            }
          </Typography>
        )}
      </div>
    </ModalContainer>
  );
};

export default ModalDeleteCompany;
