import React, { useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { connect } from "react-redux";
import { State } from "../../../../reducers/reducer";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import { FormattedMessage } from "react-intl";
import TextField from "@material-ui/core/TextField";
//import parseData from "../../../../components/texts/parseData";
import {
  updateSoldVolumes,
  checkSoldVolumes,
} from "../../../../reducers/sustainability/allocation/soldVolumes/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      color: theme.palette.primary.main,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),

      padding: 0,
    },
    greyText: {
      color: theme.palette.action.disabled,
    },
  })
);

interface Props {
  type: string;
  displayEmpty?: boolean;
  className?: any;
}

interface StateProps {
  data: any;
  default_value: any;
  taxYearId: number;
  gasolineLimitReached: boolean;
  biodieselLimitReached: boolean;
  dispatch?: any; // fix warning
  emptyMenuItem?: React.ReactNode;
}

const mapStateToProps = (state: State, ownProps: Props) => ({
  data: state.sustainability.allocation.soldVolumes.data,
  default_value: state.sustainability.allocation.soldVolumes.default,
  taxYearId: state.sustainability.main.activePeriod
    ? state.sustainability.main.activePeriod.id
    : 0,
  gasolineLimitReached:
    state.sustainability.allocation.soldVolumes.gasolineLimitReached,
  biodieselLimitReached:
    state.sustainability.allocation.soldVolumes.biodieselLimitReached,
});

interface DispatchProps {
  updateSoldVolumes: (value: number, type: string) => any;
  checkSoldVolumes: (value: number, type: string) => any;
}

const mapDispatchToProps = {
  updateSoldVolumes,
  checkSoldVolumes,
};

const SoldVolumeTextField = ({
  data,
  default_value,
  taxYearId,
  gasolineLimitReached,
  biodieselLimitReached,
  updateSoldVolumes,
  checkSoldVolumes,
  type = "",
  className,
  dispatch,
  emptyMenuItem,
  ...rest
}: SelectProps & StateProps & DispatchProps) => {
  const [value, setValue] = useState<string>(
    default_value["sold_" + type]
      ? parseFloat(default_value["sold_" + type])
      : data[type]
  );

  const classes = useStyles();

  const isInvalid = (): boolean => {
    if (type === "etanol" || type === "nafta") return gasolineLimitReached;
    if (type === "hvo_hro" || type === "fame") return biodieselLimitReached;
    return false;
  };

  return (
    <TextField
      className={classes.textfield}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        let newVal = e.target.value ? e.target.value : "0";
        checkSoldVolumes(parseFloat(newVal), type);
      }}
      onBlur={(e) => {
        let newVal = e.target.value ? e.target.value : "0";
        setValue(newVal);
        updateSoldVolumes(parseFloat(newVal), type);
      }}
      variant="outlined"
      type="number"
      inputProps={{
        style: {
          textAlign: "right",
          padding: "6px 0px 6px 0px",
          margin: 0,
          fontSize: "13px",
        },
      }}
      disabled={Object.keys(default_value).length > 0}
      error={isInvalid()}
    />
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SoldVolumeTextField);
