import React from "react";
import { FormattedMessage } from "react-intl";
export default [
  {
    name: <FormattedMessage id="settingsView.index.taxYears.name" />,
    id: "name",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxYears.year" />,
    id: "year",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxYears.startMonth" />,
    id: "start_month",
    type: "select",
    params: "months",
  },
  {
    name: <FormattedMessage id="settingsView.index.taxYears.endMonth" />,
    id: "end_month",
    type: "select",
    params: "months",
  },
];
