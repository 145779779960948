import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
    },
    rootSmall: {
      height: "100%",
    },
    content: {
      display: "flex",
      width: "100%",
      margin: theme.spacing(5),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      justifyContent: "space-between",
      alignItems: "center",
    },
    contentSmall: {
      margin: theme.spacing(3, 1),
    },
    restricted: {
      color: theme.palette.grey[600],
    },
    link: {
      textDecoration: "none",
      color: "inherit",
    },
    linkRestricted: {
      pointerEvents: "none",
    },
    lockIcon: { color: theme.palette.grey[300] },
    chevronIcon: {
      color: theme.palette.primary.main,
      background: theme.palette.grey[50],
      borderRadius: "50%",
    },
    titleHeight: {
      [theme.breakpoints.down("sm")]: {
        minHeight: "48px",
        display: "flex",
        alignItems: "center",
      },
    },
  })
);

interface SettingsCardProps {
  title: string | React.ReactElement;
  isRestricted?: boolean;
  path: string;
  smallSize?: boolean;
}

const SettingsCard: React.FC<SettingsCardProps> = ({
  title,
  isRestricted,
  path,
  smallSize = false,
}) => {
  const classes = useStyles();

  return (
    <Link
      to={path}
      className={clsx(classes.link, { [classes.linkRestricted]: isRestricted })}
    >
      <Card
        className={clsx(classes.root, {
          [classes.restricted]: isRestricted,
          [classes.rootSmall]: smallSize,
        })}
      >
        <CardContent
          className={clsx(classes.content, {
            [classes.contentSmall]: smallSize,
          })}
        >
          <Typography
            variant="h6"
            color={isRestricted ? "inherit" : "primary"}
            className={classes.titleHeight}
          >
            {title}
          </Typography>
          <Icon
            className={isRestricted ? classes.lockIcon : classes.chevronIcon}
          >
            {isRestricted ? "lock_outlined_icon" : "chevron_right"}
          </Icon>
        </CardContent>
      </Card>
    </Link>
  );
};

export default SettingsCard;
