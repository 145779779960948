import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async () => {
  const token = getToken();

  let valid = false;
  let data = {};
  let limit = {};
  let default_value = {};

  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/sold_volume_components`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      var soldVolumeResult = await response.json();
      valid = soldVolumeResult && soldVolumeResult.success;
      var soldVol = soldVolumeResult.sold_volume_components;

      data = soldVol;
      if (soldVol.nafta && soldVol.etanol && soldVol.fame && soldVol.hvo_hro)
        data = {
          ...soldVol,
          nafta: parseFloat(soldVol.nafta),
          etanol: parseFloat(soldVol.etanol),
          fame: parseFloat(soldVol.fame),
          hvo_hro: parseFloat(soldVol.hvo_hro),
        };
      limit = soldVolumeResult.sold_volume_limit;
      default_value = soldVolumeResult.default_sold_volume;
    } catch (except) {
      valid = false; // network error
    }
  }

  if (!valid) {
    if (soldVolumeResult && soldVolumeResult.error)
      return {
        valid: false,
        message: soldVolumeResult.error,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    data: data,
    limit: limit,
    default: default_value,
  };
};
