import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FilterListIcon from "@material-ui/icons/FilterList";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleFilters: {
      display: "inline-block",
    },
    titleFiltersContent: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: theme.spacing(0.5),
      alignItems: "center",
      display: "flex",
      justifyContent: "space-evenly",
      width: "90px",
      height: "25px",
    },
    text: {
      fontWeight: 500,
    },
  })
);

const FiltersTitle: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.titleFilters}>
      <div className={classes.titleFiltersContent}>
        <FilterListIcon />
        <Typography variant="button" component="div" className={classes.text}>
          <FormattedMessage id="exciseDutyView.transactionsView.filtersTitle" />
        </Typography>
      </div>
    </div>
  );
};

export default FiltersTitle;
