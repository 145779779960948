import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../../reducers/reducer";
import OutgoingTransView from "../../../views/DashboardView/SustainabilityView/StorageView/OutgoingTransView";
import { IAppVariables } from "../../../types/ApiInterfaces";

interface Props {
  app_variables: IAppVariables;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      app_variables: state.settings.app_variables.data,
    }),
    (dispatch: any) => ({
      dispatch,
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(OutgoingTransView);
