import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import ReportsView from "../../views/DashboardView/ExciseDutyView/ReportsView";
import {
  setSelectedYear,
  setSelectedMonth,
} from "../../reducers/exciseDuty/taxPeriods";
import {
  getExciseDutyReport,
  updataReportTypethenFetch,
  deleteAnItem,
  updataItems,
} from "../../reducers/exciseDuty/reports";

type ReportType = "manual" | "original";
interface Props {
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  selectedMonth: number;
  setSelectedMonth: any;
  toggleReportType: ReportType;
  getExciseDutyReport: (toggleReportType: ReportType) => void;
  updataReportTypethenFetch: (toggleReportType: ReportType) => void;
  data: any;
  deleteAnItem: (period: string) => void;
  deleteError: any;
  updataItems: (value: any) => void;
  updatingError: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      taxPeriods: state.exciseDuty.taxPeriods.data,
      selectedYear: state.exciseDuty.taxPeriods.selectedYear,
      selectedMonth: state.exciseDuty.taxPeriods.selectedMonth,
      toggleReportType: state.exciseDuty.reports.toggleReportType,
      data: state.exciseDuty.reports.data,
      deleteError: state.exciseDuty.reports.deleteError,
      updatingError: state.exciseDuty.reports.updatingError,
    }),
    (dispatch: any) => ({
      dispatch,
      setSelectedYear: (year: number) => dispatch(setSelectedYear(year)),
      setSelectedMonth: (month: number) => dispatch(setSelectedMonth(month)),
      getExciseDutyReport: (toggleReportType: ReportType) =>
        dispatch(getExciseDutyReport(toggleReportType)),
      updataReportTypethenFetch: (toggleReportType: ReportType) =>
        dispatch(updataReportTypethenFetch(toggleReportType)),
      deleteAnItem: (period: string) => dispatch(deleteAnItem(period)),
      updataItems: (value: any) => dispatch(updataItems(value)),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {
      this.props.getExciseDutyReport(this.props.toggleReportType);
    },
  })
)(ReportsView);
