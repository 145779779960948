import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { Form, Field } from "react-final-form";
import { TextField } from "final-form-material-ui";
import ButtonRounded from "../../../components/ButtonRounded";
import changeEmail from "../../../api/changeEmail";
import * as yup from "yup";
import makeValidate from "../../../components/form/makeValidate";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: "center",
      marginBottom: theme.spacing(4),
    },
    content: {
      minWidth: "430px",
      padding: theme.spacing(6.25),
      position: "relative",
    },
    iconClose: {
      position: "absolute",
      top: theme.spacing(1),
      left: theme.spacing(1),
      color: theme.palette.grey[900],
    },
    inputContainer: {
      "margin-bottom": theme.spacing(2),
      "& .MuiInput-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "2px 10px",
      },
      "& input": {
        fontSize: "14px",
      },
    },
    labelName: {
      fontSize: "13px",
      color: theme.palette.grey[400],
    },
    btnContainer: {
      marginTop: theme.spacing(2),
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-between",
    },
    btnCancel: {
      backgroundColor: theme.palette.error.main,
      color: "white",
      marginRight: theme.spacing(2.5),
    },
  })
);

interface ChangeEmailModalProps {
  open: boolean;
  onClose: () => void;
  updateEmail: (newEmail: string) => void;
}

const ChangeEmailModal: React.FC<ChangeEmailModalProps> = ({
  open,
  onClose,
  updateEmail,
}) => {
  const classes = useStyles();

  const validationSchema = yup.object({
    new_email: yup
      .string()
      .min(6)
      .required(),
    repeat_email: yup
      .string()
      .oneOf([yup.ref("new_email"), null])
      .required(),
  });

  const onSubmit = async (values: any) => {
    const submitResponse = await changeEmail(values.new_email);

    if (submitResponse && !submitResponse.valid) {
      return {
        new_email: <FormattedMessage id="myProfileView.changeEmail.failed" />,
      };
    }
    updateEmail(values.new_email); // update profile data
    onClose(); // Close the modal on success
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className={classes.content}>
        <IconButton className={classes.iconClose} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h4" className={classes.title}>
          {<FormattedMessage id="myProfileView.index.changeEmail" />}
        </Typography>
        <Form
          onSubmit={onSubmit}
          validate={values => makeValidate(values, validationSchema)}
        >
          {({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Typography
                variant="body2"
                gutterBottom
                className={classes.labelName}
              >
                {<FormattedMessage id="myProfileView.changeEmail.newEmail" />}
              </Typography>
              <Field
                className={classes.inputContainer}
                component={TextField}
                placeholder="New Email"
                name="new_email"
                fullWidth
                InputProps={{ disableUnderline: true }}
              />
              <Typography
                variant="body2"
                gutterBottom
                className={classes.labelName}
              >
                {
                  <FormattedMessage id="myProfileView.changeEmail.repeatNewEmail" />
                }
              </Typography>
              <Field
                className={classes.inputContainer}
                component={TextField}
                placeholder="Repeat New Email"
                name="repeat_email"
                fullWidth
                InputProps={{ disableUnderline: true }}
              />
              <Grid container className={classes.btnContainer}>
                <ButtonRounded
                  fullWidth
                  variant="contained"
                  className={classes.btnCancel}
                  onClick={onClose}
                >
                  {<FormattedMessage id="settingsView.modal.cancel" />}
                </ButtonRounded>

                <ButtonRounded
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                >
                  {<FormattedMessage id="settingsView.modal.save" />}
                </ButtonRounded>
              </Grid>
            </form>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeEmailModal;
