import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { typography, colors } from "../config/theme";

const styles = StyleSheet.create({
  root: {},
  para: {
    ...typography.default,
    marginBottom: 10,
    textAlign: "left",
  },
  smallTitle: {
    ...typography.defaultBold,
    color: colors.green,
    marginBottom: 10,
  },
});

interface DescriptionProps {
  style?: ReactPDF.Style | ReactPDF.Styles;
}

const Description: React.FC<DescriptionProps> = ({ style = [] }) => {
  const externalStyles = Array.isArray(style) ? style : [style];

  return (
    <View style={[styles.root, ...externalStyles]}>
      <Text style={styles.smallTitle}>Background</Text>
      <Text style={styles.para}>
        The products covered by this declaration complies with the EC Renewable
        Energy Directive (RED) on the promotion of use of energy from renewable
        sources.
      </Text>
      <Text style={styles.para}>
        GHG values in the document includes feedstock transportation, possible
        pre-treatment, biofuel production, product transportation to customer
        location and distribution. The biomass used to produce the products
        fulfills all sustainability criteria according to RED Article 17 and
        local Norwegian legislation.
      </Text>
      <Text style={styles.para}>
        The production plant has been in operation on or before 5 October 2015.
      </Text>
    </View>
  );
};

export default Description;
