import React from "react";
import ReactPDF, { View, Text, StyleSheet } from "@react-pdf/renderer";
import { colors, typography } from "../config/theme";

const styles = StyleSheet.create({
  root: {
    marginBottom: 2,
  },
  spaceBottom: {
    marginBottom: 10,
  },
});

interface FooterInfoTextProps {
  title: string;
  colored?: boolean; // added green color to title
  spaceBottom?: boolean;
  value: string;
  style?: ReactPDF.Style | ReactPDF.Styles;
}

const FooterInfoText: React.FC<FooterInfoTextProps> = ({
  title,
  value,
  spaceBottom = false,
  style = [],
}) => {
  const externalStyles = Array.isArray(style) ? style : [style];

  return (
    <View
      style={[
        styles.root,
        spaceBottom ? styles.spaceBottom : {},
        ...externalStyles,
      ]}
    >
      <Text style={[typography.smallBold, { color: colors.green }]}>
        {title}{" "}
        <Text style={[typography.small, { color: colors.green }]}>{value}</Text>
      </Text>
    </View>
  );
};

export default FooterInfoText;
