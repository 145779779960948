import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "60%",
    },
    padding: {
      padding: theme.spacing(4),
    },
    icon: {
      width: "48px",
      height: "48px",
    },
  })
);

interface Props {
  text: string;
}

const LoadingView: React.FC<Props> = ({ text }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress className={classes.icon} />
      <Typography className={classes.padding}>{text}</Typography>
    </div>
  );
};

export default LoadingView;
