import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { TextField } from "final-form-material-ui";
import moment from "moment";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import * as yup from "yup";
import ButtonRounded from "../../../../components/ButtonRounded";
import SmartSelect from "../../../../components/SmartSelect";
import ToggleButton from "../../../../components/ToggleButton";
import makeValidate from "../../../../components/form/makeValidate";
import KeyboardDatePicker from "../../../../components/inputs/KeyboardDatePicker";
import RequiredAsterisk from "../../../../components/texts/RequiredAsterisk";
import { IAppVariables } from "../../../../types/ApiInterfaces";
import EditableTable from "./EditableTable";
import ModalSaveTransaction from "./ModalSaveTransaction";
import { getNormalBiotemplates } from "../../../../api/sustainability/storage/outgoing-transaction/getBiotemplates";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    h2: {
      [theme.breakpoints.down("md")]: {
        fontSize: "32px",
      },
    },
    btn: {
      width: "170px",
      padding: "13px 0",
      lineHeight: "14px",
      "&:not(:first-child)": {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("md")]: {
        width: "130px",
        padding: "12px 0 9px 0",
        "&:not(:first-child)": {
          marginLeft: "10px",
        },
      },
    },
    toggleContainer: {
      marginTop: theme.spacing(3),
    },
    topCard: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: 4,
      boxShadow: "-10px 10px 20px 0 rgba(30, 30, 30, 0.05)",
      padding: theme.spacing(2),
    },
    cardContent: {
      "&.MuiCardContent-root:last-child": {
        paddingBottom: theme.spacing(2),
      },
      "& .MuiOutlinedInput-input": {
        padding: theme.spacing(1, 1.5),
      },
    },
    cardTable: {
      padding: theme.spacing(3),
      paddingRight: theme.spacing(2),
    },
    fields: {
      position: "relative",
      "& .MuiFormHelperText-root.Mui-error": {
        position: "absolute",
        bottom: theme.spacing(-1.6),
        whiteSpace: "nowrap",
      },
      "& .MuiFormHelperText-contained": {
        margin: 0,
      },
    },
    creatableSelect: {
      ...theme.typography.body1,

      "& .react-select__control": {
        padding: 0,
        borderColor: theme.palette.grey[300],
        boxShadow: "none",
        minHeight: "35px",
        "&:hover": {
          borderColor: theme.palette.grey[300],
        },
      },
      "& .react-select__placeholder": {
        color: theme.palette.grey[300],
      },
      "& .react-select__value-container": {
        padding: "0px 8px",
      },
      "& .react-select__single-value": {
        color: theme.palette.primary.main,
      },
      "& .react-select__indicator-separator": {
        backgroundColor: "unset",
      },
      "& .react-select__indicators": {
        color: theme.palette.primary.main,
      },
      "& .react-select__option": {
        "&:active": {
          backgroundColor: "rgba(0, 0, 0, 0.14)",
        },
      },
      "& .react-select__option--is-focused": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "& .react-select__option--is-selected": {
        backgroundColor: "rgba(0, 0, 0, 0.14)",
        color: theme.palette.text.primary,
      },
    },
  })
);

interface Props {
  app_variables: IAppVariables;
}

const OutgoingTransView: React.FC<Props> = ({ app_variables }) => {
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [volume, setVolume] = useState<any>(null);
  const [headData, setHeadData] = useState<any>(null);
  const [tableData, setTableData] = useState<any>(null);
  const [editedTableData, setEditedTableData] = useState<any>(null);

  const classes = useStyles();
  const history = useHistory();

  const getCustomerList = () => {
    let customerList = app_variables.res_customer.values;
    app_variables.customer.values.forEach(
      (customer: any) =>
        !customerList.includes(customer) && customerList.push(customer)
    );
    return customerList;
  };

  const openSaveModal = () => {
    triggerTableSubmit();
    setShowSaveModal(true);
  };
  const closeSaveModal = () => {
    setEditedTableData(null);
    setShowSaveModal(false);
  };

  const triggerTableSubmit = () => {
    if (document) {
      const outgoingTableForm = document.getElementById(
        "storage_outgoing_table_form"
      );
      if (outgoingTableForm) {
        outgoingTableForm.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    }
  };

  const validationSchema = yup.object({
    customer_name: yup.string().min(3).required(),
    volume: yup
      .number()
      .max(99999999999999)
      .typeError("Please enter a number.")
      .required(),
    biofuel_type: yup.string().required(),
    delivery_period: yup.string().min(3).required(),
    storage_date: yup.string().required(),
  });

  const handleChooseProducts = async (values: any) => {
    setHeadData({ ...values });
    setVolume(values.volume);
    const reservationValues = {
      customer_name: values.customer_name,
      volume: values.volume,
      biofuel_type: values.biofuel_type,
      delivery_period: values.delivery_period,
      storage_date: moment(values.storage_date).format("YYYY-MM-DD"),
    };
    const res = await getNormalBiotemplates(reservationValues);
    if (res) {
      setTableData(res.biotemplates);
    }
  };

  const handleTableData = (values: any) => {
    if (values && Array.isArray(values.biotemplates)) {
      const filteredValues = values.biotemplates.filter(
        (data: any) => data.allocated_quantity
      );

      if (filteredValues.length > 0) {
        const bioValues = filteredValues.map((data: any) => ({
          id: data.id,
          quantity: data.allocated_quantity,
        }));

        if (bioValues.length > 0) {
          setEditedTableData(bioValues);
        }
      }
    }
  };

  const haveTableData = tableData !== null;

  return (
    <div className={classes.root}>
      <Form
        onSubmit={handleChooseProducts}
        validate={(values) => makeValidate(values, validationSchema)}
        initialValues={
          headData
            ? headData
            : {
              customer_name: "",
              volume: null,
              biofuel_type: "",
              delivery_period: "",
              storage_date: new Date(),
              status: "completed",
            }
        }
      >
        {({ handleSubmit, submitting, pristine, hasValidationErrors }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h2" className={classes.h2}>
                  Add Outgoing Transaction
                </Typography>
              </Grid>
              <Grid item>
                <ButtonRounded
                  variant="contained"
                  color="secondary"
                  className={classes.btn}
                  onClick={() => history.goBack()}
                >
                  cancel
                </ButtonRounded>
                <ButtonRounded
                  variant="contained"
                  color="primary"
                  className={classes.btn}
                  onClick={openSaveModal}
                  disabled={!canSubmit}
                >
                  save
                </ButtonRounded>
              </Grid>
            </Grid>

            <div className={classes.topCard}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                className={classes.cardContent}
              >
                <Grid item xs className={classes.toggleContainer}>
                  <ToggleButton
                    labels={["Completed", "Blocked"]}
                    size="small"
                    handleChange={() => { }}
                    disabled
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    Customer Name <RequiredAsterisk />
                  </Typography>
                  <Field name="customer_name">
                    {(props) => (
                      <CreatableSelect
                        isDisabled={haveTableData}
                        className={classes.creatableSelect}
                        classNamePrefix="react-select"
                        placeholder="Customer"
                        components={{
                          DropdownIndicator: () => <ArrowDropDownIcon />,
                        }}
                        options={getCustomerList().map((customer: any) => ({
                          value: customer,
                          label: customer,
                        }))}
                        value={
                          props.input.value
                            ? {
                              value: props.input.value,
                              label: props.input.value,
                            }
                            : null
                        }
                        onChange={(value: any) => {
                          props.input.onChange(value.label);
                        }}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    Total Volume (L) <RequiredAsterisk />
                  </Typography>
                  <Field
                    component={TextField}
                    name="volume"
                    variant="outlined"
                    placeholder="0,00"
                    fullWidth
                    className={classes.fields}
                    disabled={haveTableData}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    Biofuel Type <RequiredAsterisk />
                  </Typography>
                  <Field name="biofuel_type">
                    {(props) => (
                      <SmartSelect
                        {...props.input}
                        fullWidth
                        displayEmpty
                        type={"biofuel_type"}
                        disabled={haveTableData}
                      />
                    )}
                  </Field>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    Delivery Period <RequiredAsterisk />
                  </Typography>
                  <Field
                    component={TextField}
                    name="delivery_period"
                    variant="outlined"
                    placeholder="e.g. Q1 2019"
                    fullWidth
                    className={classes.fields}
                    disabled={haveTableData}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    Storage Date <RequiredAsterisk />
                  </Typography>
                  <Field name="storage_date">
                    {({ input, meta }) => (
                      <KeyboardDatePicker
                        {...input}
                        inputVariant="outlined"
                        format="YYYY-MM-DD"
                        placeholder={moment().format("YYYY-MM-DD")}
                        value={input.value ? input.value : null}
                        onChange={(value: any) => {
                          input.onChange(moment(new Date(value)).format("YYYY-MM-DD"));
                        }}
                        className={classes.fields}
                        disabled={haveTableData}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </div>

            <Card>
              <CardContent className={classes.cardTable}>
                <EditableTable
                  data={tableData ? tableData : null}
                  volume={volume}
                  disabledChooseProducts={
                    submitting || pristine || hasValidationErrors
                  }
                  onSubmit={handleTableData}
                  handleChooseProducts={handleSubmit}
                  canSubmit={canSubmit}
                  setCanSubmit={setCanSubmit}
                />
              </CardContent>
            </Card>
          </form>
        )}
      </Form>
      {headData && (
        <ModalSaveTransaction
          data={{
            customer_name: headData.customer_name,
            volume: headData.volume,
            biofuel_type: headData.biofuel_type,
            delivery_period: headData.delivery_period,
            storage_date: headData.storage_date,
            status: headData.status,
            biotemplates: editedTableData,
          }}
          open={showSaveModal}
          onClose={closeSaveModal}
        />
      )}
    </div>
  );
};

export default OutgoingTransView;
