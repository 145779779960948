import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async (params: any, id: any) => {
  const token = getToken();
  let valid = false;

  if (token) {
    try {
      var response = await fetch(
        `${BASE_URL}/api/register_transactions/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(params),
        }
      );

      var editResult = await response.json();
      valid = editResult && editResult.success;
    } catch (except) {
      valid = false; // network error
    }
  } else {
    return null; // No token
  }

  if (!valid) {
    if (editResult && editResult.errors)
      return {
        valid: false,
        message: editResult.errors,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    ...editResult,
  };
};
