import React, { useState, useEffect } from "react";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ViewMore from "./ViewMore";
import getCommentHistories from "../../../../api/exciseduty/reports/getCommentHistories";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    cardContainer: {
      padding: theme.spacing(3),
    },
    topTitle: {
      marginBottom: theme.spacing(2),
    },
    historyItemsContainer: {},
    historyItem: {
      border: `1px solid ${theme.palette.primary.main}`,
      backgroundColor: "rgba(255, 172, 18, 0.2)",
      borderRadius: "5px",
      padding: theme.spacing(1.5, 3),
      marginBottom: theme.spacing(1.5),
      "&:last-child": {
        marginBottom: 0,
      },
    },
    historyItemEmpty: {
      border: `1px solid ${theme.palette.grey[300]}`,
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.text.secondary,
    },
    historyItemComment: {
      fontWeight: "bold",
    },
  })
);

interface ChangeHistoryProps {
  className?: string;
  selectedYear: number;
  selectedMonthIndex: number;
}

const ChangeHistory: React.FC<ChangeHistoryProps> = ({
  className = "",
  selectedYear,
  selectedMonthIndex,
}) => {
  const [allCommentHistories, setAllCommentHistories] = useState<any[]>([]);
  const [viewMore, setViewMore] = useState<boolean>(false);
  const classes = useStyles();

  const toggleViewMore = () => setViewMore(state => !state);

  const fetchAllCommentHistories = async () => {
    const res = await getCommentHistories();
    if (Array.isArray(res) && res.length > 0) {
      setAllCommentHistories(res);
    }
  };

  useEffect(() => {
    fetchAllCommentHistories();
  }, []);

  const filteredHistories: any[] = allCommentHistories.filter(
    history =>
      history.tax_year === selectedYear &&
      history.month === selectedMonthIndex + 1
  );
  let commentHistories: any[] = [];

  if (filteredHistories.length > 0) {
    commentHistories = viewMore ? filteredHistories : [filteredHistories[0]];
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.cardContainer}>
        <Grid
          container
          justify="space-between"
          alignItems="center"
          className={classes.topTitle}
        >
          <Grid item>
            <Typography variant="h5">
              <FormattedMessage id="exciseDutyView.reportsView.index.changeHistory" />
            </Typography>
          </Grid>
          <Grid item>
            {filteredHistories.length > 1 && (
              <ViewMore more={viewMore} onClick={toggleViewMore} />
            )}
          </Grid>
        </Grid>

        <div className={classes.historyItemsContainer}>
          {Array.isArray(commentHistories) &&
            commentHistories.map(commentHistory => (
              <div className={classes.historyItem} key={commentHistory.id}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item>
                    <Typography
                      variant="body2"
                      className={classes.historyItemComment}
                    >
                      {commentHistory.comment}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">
                      {commentHistory.user_name} / {commentHistory.created_time}{" "}
                      / {commentHistory.created_date}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            ))}
        </div>
        {Array.isArray(commentHistories) && commentHistories.length === 0 && (
          <div className={clsx(classes.historyItem, classes.historyItemEmpty)}>
            <Typography variant="body2" className={classes.historyItemComment}>
              <FormattedMessage id="exciseDutyView.reportsView.index.noHistory" />
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ChangeHistory;
