/**
 * add/edit a collaboration
 */

import { getToken } from "../../../utils";
import { BASE_URL } from "../../../endpoints";

export interface ICollab {
  id?: number;
  org_name: string;
  org_number: number;
  diesel_single: string;
  diesel_double: string;
  ethanol_single: string;
  ethanol_double: string;
}

interface IAddEdit {
  mode: "add" | "edit";
  collab: ICollab;
}

const addEdit = async ({ mode, collab }: IAddEdit) => {
  const token = getToken();
  const urlSuffix = mode === "edit" ? `/${collab.id}` : "";

  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/sustainability_quantities${urlSuffix}`,
        {
          method: mode === "add" ? "POST" : "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            organization_name: collab.org_name,
            organizer_number: collab.org_number,
            biodiesel_single_count: collab.diesel_single,
            biodiesel_double_count: collab.diesel_double,
            bioetanol_single_count: collab.ethanol_single,
            bioetanol_double_count: collab.ethanol_double,
          }),
        }
      );

      if (response.status === 200 || response.status === 201) {
        const data = await response.json();
        return data.success;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default addEdit;
