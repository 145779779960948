import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import TransactionsContainer from "../../../containers/exciseDuty/TransactionsContainer";
import FilesContainer from "../../../containers/exciseDuty/FilesContainer";
import ReportsContainer from "../../../containers/exciseDuty/ReportsContainer";

const ExciseDutyView: React.FC = () => {
  const match = useRouteMatch();

  if (match) {
    return (
      <Switch>
        <Route path={`${match.path}/files`} component={FilesContainer}></Route>
        <Route
          path={`${match.path}/transactions`}
          component={TransactionsContainer}
        ></Route>
        <Route
          path={`${match.path}/reports`}
          component={ReportsContainer}
        ></Route>
        <Redirect to={`${match.path}/files`} />
      </Switch>
    );
  } else {
    return null;
  }
};

export default ExciseDutyView;
