import React from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "react-final-form";

// list of all the categories id that should be identical to allow grouping of values
export const aggregateProperties: { required: string[]; addUp: string[] } = {
  required: [
    "biofuel_type",
    // "biofuel_type_usage",
    "raw_material",
    "raw_material_origin",
    "double_counted_norway",
    "reduction_in_climate_emission",
    "bonus_factor_eb",
    "documentation_bonus_factor_eb",
    "bonus_factor_esca",
    "documentation_bonus_factor_esca",
    "documentation_emission_of_climate",
    "documentation_area_criteria",
    "documentation_mass_balance",
    "fuel_type",
    "certification_id",
    "storage_terminal",
    "red_version",
  ],
  addUp: ["quantity", "volume", "equivalent_volume"],
};

export default [
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.serialNumber" />
    ),
    id: "serial_number",
    type: "text",
    hide: "true",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.transactionType" />
    ),
    id: "transaction_type",
    type: "select",
    params: "transaction_type",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.quantity" />
    ),
    id: "quantity",
    type: "text",
    hide: "true",
    alignRight: true,
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col1" />
    ),
    id: "biofuel_type",
    type: "select",
    params: "biofuel_type",
  },
  // {
  //   name: (
  //     <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col15" />
  //   ),
  //   id: "biofuel_type_usage",
  //   type: "select",
  //   params: "biofuel_type_usage",
  // },
  {
    name: <FormattedMessage id="sustainabilityView.storage.l15" />,
    id: "volume",
    type: "text",
    alignRight: true,
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col3" />
    ),
    id: "raw_material",
    type: "select",
    params: "raw_material",
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.storage.filters.rawOrigin" />
    ),
    id: "raw_material_origin",
    type: "select",
    params: "origin",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.doubleCounting" />
    ),
    id: "double_counted_norway",
    type: "select",
    params: "double_counting",
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col6" />
    ),
    id: "reduction_in_climate_emission",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col7" />
    ),
    id: "equivalent_volume",
    type: "text",
    alignRight: true,
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col8" />
    ),
    id: "bonus_factor_eb",
    type: "select",
    params: "flag_variable",
  },
  {
    name: <FormattedMessage id="sustainabilityView.storage.docEb" />,
    id: "documentation_bonus_factor_eb",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col9" />
    ),
    id: "bonus_factor_esca",
    type: "select",
    params: "flag_variable",
  },
  {
    name: <FormattedMessage id="sustainabilityView.storage.docEsca" />,
    id: "documentation_bonus_factor_esca",
    type: "text",
  },
  {
    name: <FormattedMessage id="sustainabilityView.storage.filters.docGhg" />,
    id: "documentation_emission_of_climate",
    type: "select",
    params: "documentation_ghg_reduction",
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col11" />
    ),
    id: "documentation_area_criteria",
    type: "select",
    params: "documentation_area_criteria",
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col12" />
    ),
    id: "documentation_mass_balance",
    type: "select",
    params: "documentation_mass_balance",
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col13" />
    ),
    id: "certification_id",
    type: "text",
  },
  {
    name: (
      <FormattedMessage id="sustainabilityView.reportsView.batchesTable.col14" />
    ),
    id: "fuel_type",
    type: "select",
    params: "fuel_types",
  },
  {
    name: <FormattedMessage id="sustainabilityView.storage.date" />,
    id: "storage_date",
    type: "date",
  },
  {
    name: (
      <FormattedMessage id="exciseDutyView.transactionsView.filters.storageTerminal" />
    ),
    id: "storage_terminal",
    type: "select",
    params: "storage_terminals",
  },
  {
    name: <FormattedMessage id="modalSave.comment" />,
    id: "comment",
    type: "text",
    optionnal: true,
  },
  {
    name: (
        <FormattedMessage id="modalSave.red_version" />
    ),
    id: "red_version",
    type: "select",
    params: "red_version",
  },
];
