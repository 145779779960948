import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import FilesView from "../../views/DashboardView/ExciseDutyView/FilesView";
import {
  getTaxPeriods,
  setSelectedYear,
  setSelectedMonth,
} from "../../reducers/exciseDuty/taxPeriods";
import { getFiles } from "../../reducers/exciseDuty/files";
interface Props {
  files: any;
  taxPeriods: any[];
  selectedYear: number;
  setSelectedYear: any;
  selectedMonth: number;
  setSelectedMonth: any;
  getFiles: any;
  getTaxPeriods: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      files: state.exciseDuty.files.data,
      taxPeriods: state.exciseDuty.taxPeriods.data,
      selectedYear: state.exciseDuty.taxPeriods.selectedYear,
      selectedMonth: state.exciseDuty.taxPeriods.selectedMonth,
    }),
    (dispatch: any) => ({
      dispatch,
      setSelectedYear: (year: number) => dispatch(setSelectedYear(year)),
      setSelectedMonth: (month: number) => dispatch(setSelectedMonth(month)),
      getFiles: () => dispatch(getFiles()),
      getTaxPeriods: () => dispatch(getTaxPeriods()),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {
      this.props.getFiles();
    },
  })
)(FilesView);
