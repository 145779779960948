import { combineReducers } from "redux";
import ProfileReducer, { ProfileState } from "./profile";
import SettingsReducer, { SettingsState } from "./settings";
import NavigationReducer, { NavigationState } from "./navigation";
import SustainabilityReducer, { SustainabilityState } from "./sustainability";
import CardReducer, { CardState } from "./card";
import ExciseDutyReducer, { ExciseDutyState } from "./exciseDuty";
import DashboardReducer, { DashboardState } from "./dashboard";
import ModalsReducer, { ModalsState } from "./modals";

export interface State {
  navigation: NavigationState;
  exciseDuty: ExciseDutyState;
  sustainability: SustainabilityState;
  profile: ProfileState;
  settings: SettingsState;
  dashboard: DashboardState;
  modals: ModalsState;
  card:CardState;
}
export default combineReducers({
  navigation: NavigationReducer,
  exciseDuty: ExciseDutyReducer,
  sustainability: SustainabilityReducer,
  profile: ProfileReducer,
  settings: SettingsReducer,
  dashboard: DashboardReducer,
  modals: ModalsReducer,
  card:  CardReducer,
});
