import ReactPDF, { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { colors, typography } from "../config/theme";

const styles = StyleSheet.create({
  root: {
    marginBottom: 3,
  },
  spaceBottom: {
    marginBottom: 10,
  },
});

interface InfoTextProps {
  title: string;
  colored?: boolean; // added green color to title
  spaceBottom?: boolean;
  value: string | number;
  largeValue?: boolean;
  style?: ReactPDF.Style | ReactPDF.Styles;
}

const InfoText3: React.FC<InfoTextProps> = ({
  title,
  value,
  colored = false,
  spaceBottom = false,
  largeValue = false,
  style = [],
}) => {
  const externalStyles = Array.isArray(style) ? style : [style];

  return (
    <View
      style={[
        styles.root,
        spaceBottom ? styles.spaceBottom : {},
        ...externalStyles,
        { flex: 1, flexDirection: "row", alignItems: "center" },
      ]}
    >
      <Text
        style={[
          typography.smallBold,
          colored ? { color: colors.green } : {},
          largeValue ? { flex: 0.6 } : { flex: 0.9 },
        ]}
      >
        {title}
      </Text>
      <View
        style={[
          {
            backgroundColor: colors.white,
            padding: "2px",
            marginLeft: "2px",
            height: "15px",
          },
          largeValue ? { flex: 1.4 } : { flex: 1.1 },
        ]}
      >
        <Text style={[
          typography.small,
          {
            position: "relative",
            top: "1px"
          }
        ]}>{value}</Text>
      </View>
    </View>
  );
};

export default InfoText3;
