import { getToken } from "../utils";
import { BASE_URL } from "../endpoints";

export default (setting: string) => {
  return async (params: any, id: number) => {
    const token = getToken();
    let valid = false;

    if (token) {
      try {
        var API_URL = `${BASE_URL}/api/${setting}/${id}`;
        if (setting === "tax_years")
          API_URL = `${BASE_URL}/api/v1/${setting}/${id}`;

        var response = await fetch(API_URL, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(params),
        });

        var editResult = await response.json();
        valid = editResult && editResult.success;

        var response = await fetch(`${BASE_URL}/api/${setting}/${id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify(params),
        });

        var editResult = await response.json();
        valid = editResult && editResult.success;
      } catch (except) {
        valid = false; // network error
      }
    } else {
      return null; // No token
    }

    if (!valid) {
      if (editResult && editResult.errors)
        return {
          valid: false,
          message: editResult.errors,
        };
      return {
        valid: false,
        message: "Something unexpected happened",
      };
    }

    return {
      valid: true,
      ...editResult,
    };
  };
};
