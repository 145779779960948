import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

export default async (params: any) => {
  const token = getToken();
  let valid = false;

  if (token) {
    try {
      var response = await fetch(`${BASE_URL}/api/card_biotemplates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(params),
      });

      var createResult = await response.json();
      valid = createResult && createResult.success;
    } catch (except) {
      valid = false; // network error
    }
  } else {
    return null; // No token
  }

  if (!valid) {
    if (createResult && createResult.errors)
      return {
        valid: false,
        message: createResult.errors,
      };
    return {
      valid: false,
      message: "Something unexpected happened",
    };
  }

  return {
    valid: true,
    ...createResult,
  };
};
