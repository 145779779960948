/**
 * Get all card customers list
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const getAllCustomers = async () => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(`${BASE_URL}/api/card_customers`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return data.card_customers;
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default getAllCustomers;
