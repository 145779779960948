import { useState } from "react";

const useModal = () => {
  const [openModalId, setId] = useState();

  function toggle(id?: string) {
    id ? setId(id) : setId(null);
  }

  return {
    openModalId,
    toggle,
  };
};

export default useModal;
