import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../../reducers/reducer";
import OverviewTableView from "../../../views/DashboardView/SustainabilityView/AllocationView/MultiStepView/components/OverviewTable";

interface Props {
  requirement_quantities: any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      requirement_quantities:
        state.sustainability.allocation.multiStep.requirement_quantities,
    }),
    (dispatch: any) => ({
      dispatch,
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {},
  })
)(OverviewTableView);
