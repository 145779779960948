import React from "react";
import TextField from "@material-ui/core/TextField";

export default ({ input: { name, value, ...restInput }, meta, ...rest }) => (
  <TextField
    {...rest}
    {...restInput}
    name={name}
    helperText={
      meta.touched
        ? meta.submitError
          ? meta.submitError
          : meta.error
        : undefined
    }
    error={(meta.submitError || meta.error) && meta.touched}
    value={value}
  />
);
