/**
 * Delete a biotemplate file
 * only accessible to admins
 */

import { getToken, isAdmin } from "../../utils";
import { BASE_URL } from "../../endpoints";

const deleteFile = async (id: number, type: string | number) => {
  const token = getToken();
  if (token && isAdmin()) {
    try {
      const API_NAME =
        type === "biotemplate_groups"
          ? "biotemplate_groups"
          : "v1/customer_sales_groups";
      const response = await fetch(`${BASE_URL}/api/${API_NAME}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      });

      if (response.status === 200) {
        const data = await response.json();
        return { success: data.success };
      } else {
        return null;
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token or not admin
  }
};

export default deleteFile;
