import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { FormattedMessage } from "react-intl";
import parseData from "../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: "auto",
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableBody-root .MuiTableRow-root:nth-child(even)": {
        backgroundColor: theme.palette.common.white,
      },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.body2,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontWeight: "bold",
      },
    },
    tableBody: {
      backgroundColor: theme.palette.grey[50],
    },
  })
);

interface SimpleTableProps {
  columns: any[];
  rows: any[][];
  className?: string;
  disableParseDataOnColumns?: boolean[];
}

const SimpleTable: React.FC<SimpleTableProps> = ({
  columns,
  rows,
  className = "",
  disableParseDataOnColumns = [],
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {columns.map((heading, headingIndex) => (
              <TableCell key={`heading-${headingIndex}`}>{heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={classes.tableBody}>
          {Array.isArray(rows) && rows.length > 0 ? (
            rows.map((cells, cellsIndex) => (
              <TableRow key={`row-${cellsIndex}`}>
                {cells.map((cell, cellIndex) => (
                  <TableCell key={`cell-${cellsIndex}-${cellIndex}`}>
                    {disableParseDataOnColumns[cellIndex]
                      ? cell
                      : parseData(cell, 2)}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <Typography variant="body2" align="center">
                  <FormattedMessage id="exciseDutyView.reportsView.mainView.noData" />
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default SimpleTable;
