import excise_duty_rates from "./excise_duty_rates";
import storage_terminal from "./storage_terminal";
import government_requirement from "./government_requirement";
import tax_years from "./tax_years";
import masterdata from "./masterdata";
import tax_classes from "./tax_classes";
import sales_product from "./sales_product";
import fossil_references from "./fossil_references";
import energy_contents from "./energy_contents";
import ghg_rates from "./ghg_rates";
import sold_components from "./sold_components";
import { FormattedMessage } from "react-intl";
import React from "react";
import raw_materials from "./raw_materials";

export default [
  {
    title: <FormattedMessage id="settingsView.exciseDutyRate" />,
    id: "excise_duty_rates",
    params: excise_duty_rates,
  },
  {
    title: <FormattedMessage id="settingsView.storageTerminal" />,
    id: "storage_terminals",
    params: storage_terminal,
  },
  {
    title: <FormattedMessage id="settingsView.govtRequirement" />,
    id: "government_requirements",
    params: government_requirement,
  },
  {
    title: <FormattedMessage id="settingsView.taxYears" />,
    id: "tax_years",
    params: tax_years,
  },
  {
    title: <FormattedMessage id="settingsView.masterData" />,
    id: "masterdata",
    params: masterdata,
  },
  {
    title: <FormattedMessage id="settingsView.taxClass" />,
    id: "tax_classes",
    params: tax_classes,
  },
  {
    title: <FormattedMessage id="settingsView.salesProduct" />,
    id: "sales_products",
    params: sales_product,
  },
  {
    title: <FormattedMessage id="settingsView.fossilReferences" />,
    id: "fossil_references",
    params: fossil_references,
  },
  {
    title: <FormattedMessage id="settingsView.energyContents" />,
    id: "energy_contents",
    params: energy_contents,
  },
  {
    title: <FormattedMessage id="settingsView.ghgRates" />,
    id: "ghg_rates",
    params: ghg_rates,
  },
  {
    title: <FormattedMessage id="settingsView.soldComponents" />,
    id: "sold_components",
    params: sold_components,
  },
  {
    title: <FormattedMessage id="settingsView.rawMaterials" />,
    id: "raw_materials",
    params: raw_materials,
  },
];
