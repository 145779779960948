import React from "react";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import ModalContainer from "../../../../components/ModalContainer";
import ButtonRounded from "../../../../components/ButtonRounded";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnContainer: {
      marginTop: theme.spacing(3),
      padding: theme.spacing(0, 4),
    },
  })
);

interface ManualExciseDutyModalProps {
  open: boolean;
  onClose: () => void;
}

const ManualExciseDutyModal: React.FC<ManualExciseDutyModalProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const redirectToCreate = () => {
    onClose();
    history.push("/excise-duty/reports/create");
  };

  return (
    <ModalContainer
      title={
        <FormattedMessage id="exciseDutyView.filesView.manualExciseDutyModal.title" />
      }
      open={open}
      onClose={onClose}
    >
      <Typography variant="body1" paragraph align="center">
        <FormattedMessage id="exciseDutyView.filesView.manualExciseDutyModal.message" />
      </Typography>

      <Typography variant="h6" align="center" gutterBottom>
        <FormattedMessage id="exciseDutyView.filesView.manualExciseDutyModal.continue" />
      </Typography>
      <Grid container spacing={2} className={classes.btnContainer}>
        <Grid item xs={6}>
          <ButtonRounded
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onClose}
          >
            <FormattedMessage id="exciseDutyView.filesView.manualExciseDutyModal.no" />
          </ButtonRounded>
        </Grid>
        <Grid item xs={6}>
          <ButtonRounded
            fullWidth
            variant="contained"
            color="primary"
            onClick={redirectToCreate}
          >
            <FormattedMessage id="exciseDutyView.filesView.manualExciseDutyModal.yes" />
          </ButtonRounded>
        </Grid>
      </Grid>
    </ModalContainer>
  );
};

export default ManualExciseDutyModal;
