import MuiFlatPagination from "material-ui-flat-pagination";
import { Theme } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

const Pagination = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.spacing(0.5),
  },
  rootStandard: {
    borderRadius: 0,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
  },
  rootCurrent: {
    borderRadius: 0,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
  },
  rootEnd: {
    "&:first-child": {
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 0,
    },
  },
  sizeSmall: {
    padding: "0px 6px",
  },
}))(MuiFlatPagination);

export default Pagination;
