/**
 * Delete excise duty report
 */

import { getToken } from "../../utils";
import { BASE_URL } from "../../endpoints";

const deleteReport = async (period: string) => {
  const token = getToken();
  if (token) {
    try {
      const response = await fetch(
        `${BASE_URL}/api/manual_tax_reports/delete_report`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            period,
          }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        return { success: data.success };
      } else {
        return { status: response.status };
      }
    } catch {
      return null; // network error
    }
  } else {
    return null; // No token
  }
};

export default deleteReport;
