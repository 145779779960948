import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import EditableTableCustomers from "./EditableTableCustomers";
import parseData from "../../../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      padding: theme.spacing(3),
      paddingTop: 0,
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.body2,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontWeight: 500,
        backgroundColor: "rgba(255, 172, 18, 0.2)",
      },
    },
    tableRowTitle: {
      flexDirection: "row",
      backgroundColor: theme.palette.common.white,
    },
    greyBackground: { backgroundColor: theme.palette.grey[50] },
    green: { backgroundColor: "#D1E5D9" },
    red: { backgroundColor: "#ffdad1" },
    yellow: { backgroundColor: "#ffd588" },
    flex: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    rowTitle: {
      fontWeight: 500,
    },
    btnAllocation: {
      width: theme.spacing(17),
      marginLeft: theme.spacing(2),
    },
    collapseRow: {
      backgroundColor: theme.palette.common.white,
    },
  })
);

const tableHeadings = [
  { name: "Volume", size: "12.5%", alignRight: "true" },
  { name: "FAME", size: "12.5%", alignRight: "true" },
  { name: "HVO/HRO", size: "12.5%", alignRight: "true" },
  { name: "Etanol", size: "12.5%", alignRight: "true" },
  { name: "Nafta", size: "12.5%", alignRight: "true" },
  { name: "Required Total", size: "12.5%", alignRight: "true" },
  { name: "Allocated Total", size: "12.5%", alignRight: "true" },
  { name: "Remaining Total", size: "12.5%", alignRight: "true" },
];

interface Props {
  stepData: any;
  requirements: any[];
  customer: { id: number; index: number };
  allocateUpdateCustomerReq: any;
}

const CustomerReqTable: React.FC<Props> = ({
  stepData,
  requirements,
  customer,
  allocateUpdateCustomerReq,
}) => {
  const [showAllocation, setShowAllocation] = useState<any>({
    index: null,
    type_id: null,
  });
  const classes = useStyles();

  const toggleShowAllocation = (index: number, type_id: string) =>
    setShowAllocation(
      showAllocation.index === index && showAllocation.type_id === type_id
        ? {
            index: null,
            type_id: null,
          }
        : { index: index, type_id: type_id }
    );

  const getSumAllocated = (cust_id: any, req_id: any, type: string): number => {
    return (
      stepData.sumAllocated[cust_id] &&
      stepData.sumAllocated[cust_id][req_id] &&
      stepData.sumAllocated[cust_id][req_id][type]
    );
  };

  const getStatus = (cust_id: any, requirement: any): string => {
    let sumFame = getSumAllocated(cust_id, requirement.id, "fame");
    let sumHvoHro = getSumAllocated(cust_id, requirement.id, "hvo_hro");
    let sumEtanol = getSumAllocated(cust_id, requirement.id, "etanol");
    let sumNafta = getSumAllocated(cust_id, requirement.id, "nafta");

    if (
      sumFame < requirement.required_emission_reduction_fame ||
      sumHvoHro < requirement.required_emission_reduction_hvo_hro ||
      sumEtanol < requirement.required_emission_reduction_etanol ||
      sumNafta < requirement.required_emission_reduction_nafta
    )
      return "Not Complete";
    if (
      sumFame > requirement.required_emission_reduction_fame ||
      sumHvoHro > requirement.required_emission_reduction_hvo_hro ||
      sumEtanol > requirement.required_emission_reduction_etanol ||
      sumNafta > requirement.required_emission_reduction_nafta
    )
      return "Overallocated";

    return "Complete";
  };

  /* const parseVal = (value: any) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) parsed *= -1;
      return parseData(parsed, 2);
    } else return parsed;
  }; */

  const showAllocationButton = (
    index: number,
    type_name: string,
    type_id: string
  ) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => toggleShowAllocation(index, type_id)}
        className={classes.btnAllocation}
      >
        {showAllocation.index === index && showAllocation.type_id === type_id
          ? "Hide Allocation " + type_name
          : "View Allocation " + type_name}
      </Button>
    );
  };

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeadings.map((heading, hidx) => (
              <TableCell
                align={heading.alignRight ? "right" : "left"}
                style={{ width: heading.size }}
                key={heading.name + "_heading"}
              >
                {heading.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {requirements.map((requirement: any, index: number) => (
          <TableBody key={"customer" + customer.id + requirement.id}>
            <TableRow>
              <TableCell colSpan={10} className={classes.tableRowTitle}>
                <div className={classes.flex}>
                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.rowTitle}
                  >
                    {requirement.product_name}
                  </Typography>
                  <div>
                    {showAllocationButton(index, "FAME", "fame")}
                    {showAllocationButton(index, "HVO/HRO", "hvo_hro")}
                    {showAllocationButton(index, "Etanol", "etanol")}
                    {showAllocationButton(index, "Nafta", "nafta")}
                  </div>
                </div>
              </TableCell>
            </TableRow>

            <TableRow
              className={clsx({
                [classes.green]:
                  getStatus(customer.id, requirement) === "Complete",
                [classes.red]:
                  getStatus(customer.id, requirement) === "Not Complete",
                [classes.yellow]:
                  getStatus(customer.id, requirement) === "Overallocated",
              })}
            >
              <TableCell align="right">
                {parseData(requirement.volume, 2)}
              </TableCell>
              <TableCell align="right">
                {parseData(
                  getSumAllocated(customer.id, requirement.id, "fame"),
                  2
                ) +
                  " / " +
                  parseData(requirement.required_emission_reduction_fame, 2)}
              </TableCell>
              <TableCell align="right">
                {parseData(
                  getSumAllocated(customer.id, requirement.id, "hvo_hro"),
                  2
                ) +
                  " / " +
                  parseData(requirement.required_emission_reduction_hvo_hro, 2)}
              </TableCell>
              <TableCell align="right">
                {parseData(
                  getSumAllocated(customer.id, requirement.id, "etanol"),
                  2
                ) +
                  " / " +
                  parseData(requirement.required_emission_reduction_etanol, 2)}
              </TableCell>
              <TableCell align="right">
                {parseData(
                  getSumAllocated(customer.id, requirement.id, "nafta"),
                  2
                ) +
                  " / " +
                  parseData(requirement.required_emission_reduction_nafta, 2)}
              </TableCell>
              <TableCell align="right">
                {parseData(requirement.emission_reduction_total, 2)}
              </TableCell>
              <TableCell align="right">
                {parseData(
                  getSumAllocated(customer.id, requirement.id, "total"),
                  2
                )}
              </TableCell>
              <TableCell align="right">
                {parseData(
                  (
                    requirement.emission_reduction_total -
                    getSumAllocated(customer.id, requirement.id, "total")
                  ).toFixed(2),
                  2
                )}
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell
                colSpan={10}
                className={classes.collapseRow}
                style={{ padding: 0 }}
              >
                <Collapse in={showAllocation.index === index}>
                  <EditableTableCustomers
                    stepData={stepData}
                    data={requirement["biotemplates_" + showAllocation.type_id]}
                    type={showAllocation.type_id}
                    customer={customer}
                    requirement={{
                      id: requirement.id,
                      index: index,
                      type: showAllocation.type_id,
                    }}
                    allocateUpdate={allocateUpdateCustomerReq}
                  />
                </Collapse>
              </TableCell>
            </TableRow>
          </TableBody>
        ))}
      </Table>
    </>
  );
};

export default CustomerReqTable;
