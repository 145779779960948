import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import parseData from "../../../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      padding: theme.spacing(3),
      paddingTop: 0,
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
      //   backgroundColor: theme.palette.grey[50],
      // },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        ...theme.typography.body2,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontWeight: 500,
      },
    },
    green: {
      backgroundColor: "#cce6d8",
    },
    red: {
      backgroundColor: "#ffdad1",
    },
    yellow: {
      backgroundColor: "#ffd588",
    },
  })
);

const tableHeadings = ["", "Requirement", "Allocated", "Remaining"];

// const tableRows = new Array(10).fill(tableRow);

interface Props {
  construction_quantities_hvo: any;
  construction_quantities_fame: any;
}

const OverviewTableConstruction: React.FC<Props> = ({
  construction_quantities_hvo,
  construction_quantities_fame,
}) => {
  const classes = useStyles();

  const rows =
    construction_quantities_hvo && construction_quantities_fame
      ? [
          {
            title: "Construction Requirement HVO (L)",
            ...construction_quantities_hvo,
          },
          {
            title: "Construction Requirement FAME (L)",
            ...construction_quantities_fame,
          },
        ]
      : [];

  const parseVal = (value: number) => {
    let parsed = value;
    if (parsed) {
      if (value > -1 && value < 0) parsed *= -1;
      return parseData(parsed, 2);
    } else return parsed;
  };

  const getStatus = (remaining: any) => {
    if (remaining > 0) return "Not Complete";
    if (remaining < 0) return "Overallocated";
    return "Complete";
  };

  return (
    <div className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {tableHeadings.map((heading, hidx) => (
              <TableCell key={hidx}>{heading}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, ridx) => (
            <TableRow
              key={ridx}
              className={clsx({
                [classes.green]: getStatus(row.remaining) === "Complete",
                [classes.red]: getStatus(row.remaining) === "Not Complete",
                [classes.yellow]: getStatus(row.remaining) === "Overallocated",
              })}
            >
              <TableCell variant="head">{row.title}</TableCell>
              <TableCell>{parseVal(row.requirement)}</TableCell>
              <TableCell>{parseVal(row.allocated)}</TableCell>
              <TableCell>{parseVal(row.remaining)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default OverviewTableConstruction;
