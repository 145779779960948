import { compose, lifecycle } from "recompose";
import { connect } from "react-redux";
import { State } from "../../reducers/reducer";
import AllocationView from "../../views/DashboardView/CardView/AllocationView/MainView";
import {
  toggleSimulation,
  toggleJoint,
  toggleSplitYear,
} from "../../reducers/card/main";
import { startAllocation } from "../../reducers/card/allocation/multiStep";
import {
  getSoldVolumes,
  createSoldVolumes,
  deleteSoldVolumes,
} from "../../reducers/card/allocation/soldVolumes";

import {
  getConstructionDieselVolumes,
  createConstructionDieselVolumes,
  deleteConstructionDieselVolumes,
} from "../../reducers/card/allocation/constructionDieselVolumes";
import {getcardVolumes} from  "../../reducers/card/allocation/cardVolumes"

interface Props {
  isSimulation: boolean;
  isJoint: boolean;
  isFirstSplit: boolean;
  hasStarted: boolean;
  splitYear: any[];
  activePeriod: any;
  soldVolumes: any;
  constructionDieselVolumes: any;
  toggleSimulation: any;
  toggleJoint: any;
  toggleSplitYear: any;
  startAllocation: any;
  getSoldVolumes: any;
  createSoldVolumes: any;
  deleteSoldVolumes: any;
  getConstructionDieselVolumes: any;
  createConstructionDieselVolumes: any;
  deleteConstructionDieselVolumes: any;
  cardVolumePageData:any;
  getcardVolumes:any;
  cardVolumePageSum:any;
}

export default compose<Props, {}>(
  connect(
    (state: State) => ({
      isSimulation: state.card.main.isSimulation,
      isJoint: state.card.main.isJoint,
      isFirstSplit: state.card.main.isFirstSplit,
      hasStarted: state.card.allocation.multiStep.hasStarted,
      splitYear: state.card.main.splitYear,
      activePeriod: state.card.main.activePeriod,
      soldVolumes: state.card.allocation.soldVolumes,
      constructionDieselVolumes:
        state.card.allocation.constructionDieselVolumes,
        cardVolumePageData: state.card.allocation.cardVolumes.data,
        cardVolumePageSum: state.card.allocation.cardVolumes.sum
    }),
    (dispatch: any) => ({
      dispatch,
      toggleSimulation: () => dispatch(toggleSimulation()),
      toggleJoint: () => dispatch(toggleJoint()),
      toggleSplitYear: () => dispatch(toggleSplitYear()),
      startAllocation: () => dispatch(startAllocation()),
      getSoldVolumes: () => dispatch(getSoldVolumes()),
      createSoldVolumes: () => dispatch(createSoldVolumes()),
      deleteSoldVolumes: () => dispatch(deleteSoldVolumes()),
      getConstructionDieselVolumes: () =>
        dispatch(getConstructionDieselVolumes()),
      createConstructionDieselVolumes: () =>
        dispatch(createConstructionDieselVolumes()),
      deleteConstructionDieselVolumes: () =>
        dispatch(deleteConstructionDieselVolumes()),
      getcardVolumes:()=>dispatch(getcardVolumes()),
    })
  ),
  lifecycle<Props, State>({
    componentDidMount() {
      //this.props.getSoldVolumes();
    },
  })
)(AllocationView);
