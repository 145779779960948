import { FormattedMessage } from "react-intl";
import React from "react";

export default [
  [
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.storageTerminal" />
      ),
      id: "storage_terminal",
      type: "select",
      params: "storage_terminals",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.salesProduct" />
      ),
      id: "sales_product",
      type: "select",
      params: "sales_products",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.fuelType" />
      ),
      id: "fuel_type",
      type: "select",
      params: "fuel_types",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.usageArea" />
      ),
      id: "usage_area",
      type: "select",
      params: "usage_area",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.transactionType" />
      ),
      id: "transaction_type",
      type: "select",
      params: "transaction_type",
    },
  ],
  [
    {
      name: <FormattedMessage id="settingsView.index.masterData.doubleCount" />,
      id: "double_count",
      type: "select",
      params: "flag_variable",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.owner" />
      ),
      id: "owner",
      type: "select",
      params: "owners",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.customer" />
      ),
      id: "customer",
      type: "select",
      params: "customer",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.quantity" />
      ),
      id: "quantity",
      type: "slider",
      params: { min: 0, max: 20000000, step: 1000 },
    },
  ],
  [
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.serialNumber" />
      ),
      id: "serial_number",
      type: "select",
      params: "serial_numbers",
    },
    {
      name: {
        from: (
          <FormattedMessage id="exciseDutyView.transactionsView.filters.periodFrom" />
        ),
        to: (
          <FormattedMessage id="exciseDutyView.transactionsView.filters.periodTo" />
        ),
      },
      id: { from: "period_from", to: "period_to" },
      type: "period",
    },
    {
      name: (
        <FormattedMessage id="exciseDutyView.transactionsView.filters.sulphur" />
      ),
      id: "sulfer_ppm",
      type: "slider",
      params: { min: 0, max: 1250, step: 50 },
    },
  ],
];
