import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backBtn: {
      textTransform: "none",
    },
  })
);

interface ButtonArrowBackProps {
  className?: string;
}

const ButtonArrowBack: React.FC<ButtonArrowBackProps> = ({
  className = "",
}) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Button
      color="primary"
      className={`${classes.backBtn} ${className}`}
      onClick={() => history.goBack()}
    >
      <Icon color="primary" fontSize="small">
        arrow_back
      </Icon>
      <Typography variant="body1">
        &nbsp; <FormattedMessage id="settingsView.back" />
      </Typography>
    </Button>
  );
};

export default ButtonArrowBack;
