import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ButtonRounded from "../../../../../../components/ButtonRounded";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "60%",
    },
    padding: {
      padding: theme.spacing(4),
    },
    icon: {
      width: "48px",
      height: "48px",
    },
  })
);

interface Props {
  text: string;
}

const SuccessView: React.FC<Props> = ({ text }) => {
  const classes = useStyles();
  const history = useHistory();

  const goToReports = () => {
    history.push(`/card/reports`);
  };

  return (
    <div className={classes.root}>
      <CheckCircleOutlineIcon className={classes.icon} color="primary" />
      <Typography className={classes.padding}>{text}</Typography>
      <ButtonRounded variant="contained" color="primary" onClick={goToReports}>
        Go to Reports
      </ButtonRounded>
    </div>
  );
};

export default SuccessView;
