import React from "react";
import { FormattedMessage } from "react-intl";

export default [
  {
    name: (
      <FormattedMessage id="settingsView.index.masterData.storageTerminal" />
    ),
    id: "storage_terminal",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.masterData.productNr" />,
    id: "product_nr",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.masterData.productName" />,
    id: "product_name",
    type: "text",
  },
  {
    name: <FormattedMessage id="settingsView.index.masterData.sulphur" />,
    id: "sulphur",
    type: "text",
    decimals_format: 2,
  },
  {
    name: <FormattedMessage id="settingsView.index.masterData.fuelType" />,
    id: "fuel_type",
    type: "select",
    params: "fuel_types",
  },
  {
    name: <FormattedMessage id="settingsView.index.masterData.usageArea" />,
    id: "usage_area",
    type: "select",
    params: "usage_area",
  },
  {
    name: (
      <FormattedMessage id="settingsView.index.masterData.fossilPercentage" />
    ),
    id: "fossil_percentage",
    type: "text",
    decimals_format: 2,
  },
  {
    name: <FormattedMessage id="settingsView.index.masterData.bioPercentage" />,
    id: "bio_percentage",
    type: "text",
    decimals_format: 2,
  },
  {
    name: <FormattedMessage id="settingsView.index.masterData.doubleCount" />,
    id: "double_count",
    type: "select",
    params: "double_counting",
  },
];
