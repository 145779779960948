import React from "react";
import clsx from "clsx";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import parseData from "../../../../../../components/texts/parseData";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowY: "auto",
    },
    table: {
      border: `1px solid ${theme.palette.grey[300]}`,
      marginBottom: theme.spacing(4),
      // "& .MuiTableBody-root .MuiTableRow-root:nth-child(odd)": {
      //   backgroundColor: theme.palette.grey[50],
      // },
      "& .MuiTableCell-root": {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        whiteSpace: "nowrap",
        ...theme.typography.body2,
      },
      "& .MuiTableCell-head": {
        color: "inherit",
        fontWeight: "bold",
      },
    },
    topHeading: {
      "&.MuiTableCell-root": {
        color: theme.palette.primary.main,
      },
    },
    subHeading: {
      "&.MuiTableCell-root": {
        backgroundColor: "#ffeed7",
      },
    },
    bottomTotal: {
      "&.MuiTableCell-root": {
        backgroundColor: "#cce6d8",
        fontWeight: "bold",
      },
    },
    bgGreen: {
      backgroundColor: "#cce6d8",
    },
    bgRed: {
      backgroundColor: "#ffdad1",
    },
    rightAlign: {
      textAlign: "right",
    },
  })
);

interface AllocStatusTableProps {
  headers: string[];
  subHeaders: string[][];
  rows: string[][];
  percentageIndexes?: any;
  className?: string;
}

const AllocStepsStatusTable: React.FC<AllocStatusTableProps> = ({
  headers,
  subHeaders,
  rows,
  percentageIndexes = {},
  className = "",
}) => {
  const classes = useStyles();

  const getTotalRow = () => {
    var titleLength = 0;
    var totalRow: any[] = [];

    if (!rows[0]) return { length: 0, values: [] };

    for (var i = 0; i < rows[0].length; i++) {
      if (i > 0 && (Number(rows[0][i]) || Number(rows[0][i]) === 0)) break;
      titleLength += 1;
    }

    rows.map((row, rowIndex) =>
      row.map((val, valIndex) => {
        if (valIndex >= titleLength && (Number(val) || Number(val) === 0)) {
          if (!totalRow[valIndex]) totalRow[valIndex] = 0;
          totalRow[valIndex] += parseFloat(val);
        } else if (valIndex >= titleLength && !totalRow[valIndex])
          totalRow[valIndex] = null;
      })
    );

    for (var i = 0; i < rows[0].length; i++) {
      if (percentageIndexes[i]) totalRow[i] = totalRow[i] / rows.length;
    }

    return { length: titleLength, values: totalRow };
  };

  const totalRow = getTotalRow();

  const isNumber = (i: any) => Number(i) || Number(i) === 0;

  return (
    <div className={clsx(classes.root, className)}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers.map((header, headingIndex) => (
              <TableCell
                key={`heading-${headingIndex}`}
                colSpan={subHeaders[headingIndex].length}
                className={classes.topHeading}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {subHeaders.map((sub, subIndex) =>
              sub.map((subHeader, subHeaderIndex) => (
                <TableCell
                  className={classes.subHeading}
                  key={`heading-${subIndex}-${subHeaderIndex}`}
                >
                  {subHeader}
                </TableCell>
              ))
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((cells, cellsIndex) => (
            <TableRow key={`row-${cellsIndex}`}>
              {cells.map((cell, cellIndex) => (
                <TableCell
                  key={`cell-${cellsIndex}-${cellIndex}`}
                  className={clsx({
                    [classes.bgGreen]:
                      cell &&
                      typeof cell === "string" &&
                      cell.indexOf("OK") != -1,
                    [classes.bgRed]:
                      cell &&
                      typeof cell === "string" &&
                      cell.indexOf("Not sufficient") != -1,
                    [classes.rightAlign]: isNumber(cell),
                  })}
                >
                  {(cellIndex > 0 ? parseData(cell, 2) : cell) +
                    (percentageIndexes[cellIndex] ? " %" : "")}
                </TableCell>
              ))}
            </TableRow>
          ))}
          <TableRow key={`row-total`}>
            <TableCell
              colSpan={totalRow.length}
              key={`cell-total`}
              className={classes.bottomTotal}
            >
              {"Grand Total"}
            </TableCell>
            {totalRow.values.map((val, valIndex) => (
              <TableCell
                key={`cell-total-${valIndex}`}
                className={clsx({
                  [classes.bottomTotal]: true,
                  [classes.rightAlign]: isNumber(val),
                })}
              >
                {parseData(val, 2) + (percentageIndexes[valIndex] ? " %" : "")}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default AllocStepsStatusTable;
